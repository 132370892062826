<div class="row g-4 mb-3">
    <div class="col-sm-auto">
        <div>
            <button type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="create-btn" routerLink="./create">
                <i class="ri-add-line align-bottom me-1"></i> Add Truck </button>
        </div>
    </div>
    <div class="col-sm">
        <div class="d-flex justify-content-sm-end">
            <div class="search-box ms-2">
                <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                <i class="ri-search-line search-icon"></i>
            </div>
        </div>
    </div>
</div>
<div class="table-responsive table-card mt-3 mb-1">
    <table class="table align-middle table-nowrap mb-0" *ngIf="allVehicles.length != 0">
        <thead class="table-light">
            <tr>
                <th class="sort" data-sort="unitNumber" sortable="unitNumber" (sort)="onSort($event)">Unit Number</th>
                <th class="sort" data-sort="year" sortable="year" (sort)="onSort($event)">Year</th>
                <th class="sort" data-sort="make" sortable="make" (sort)="onSort($event)">Make</th>
                <th class="sort" data-sort="model" sortable="model" (sort)="onSort($event)">Model</th>
                <th class="sort" data-sort="vin" sortable="vin" (sort)="onSort($event)">VIN</th>
                <th class="sort" data-sort="licensePlate" sortable="licensePlate" (sort)="onSort($event)">License Plate</th>
                <th class="sort" data-sort="type" sortable="type" (sort)="onSort($event)">Type</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vehicle of allVehicles">
                <td>
                    <ngb-highlight [result]="vehicle.unitNumber + ''" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [term]="service.searchTerm"></ngb-highlight>
                    {{vehicle.year}}
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.make" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.model" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.vehicleIdNumber" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="vehicle.licensePlateNumber" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="getVehicleTypeNameById(vehicle.type)" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <div class="d-flex gap-2">
                        <div class="edit">
                            <button class="btn btn-sm btn-primary edit-item-btn" (click)="onEditClick(vehicle)">Edit</button>
                        </div>
                        <div class="remove">
                            <button class="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" (click)="deleteVehicles(vehicle)">Delete</button>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>