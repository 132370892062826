import { BROKER_PRODUCT_IDS, CARRIER_PRODUCT_IDS, SHIPPER_PRODUCT_IDS } from "src/configs/products.config";
import { AccountType } from "./account.model";

export type InvoiceDisplay = Invoice & {totalString: string};

export interface Invoice {
    accountId: string;
    dateCreated: string;
    dateDue: number;
    datePaid: string;
    discount: number;
    discountCode: string;
    invoiceId: string;
    invoiceNumber: string;
    provider: string;
    providerId: string;
    providerUrl: string;
    subtotal: number;
    tax: number;
    total: number;
    status: string;
    lineItems: InvoiceLineItem[];
}

export interface InvoiceLineItem {
    lineId: string;
    invoiceId: string;
    productId: string;
    quantity: number;
}

export interface InvoicePayload {
    lineItems: InvoiceLineItemPayload[];
}

export interface InvoiceLineItemPayload {
    productId: string;
    quantity: number;
}

export function getPreSaleProductId(accountType: AccountType): string | null {
    //hardcoded accountTypes
    switch(accountType) {
        case 'carrier-account':
            return CARRIER_PRODUCT_IDS.CALPS;
        case 'shipper-account':
            return SHIPPER_PRODUCT_IDS.SALPS;
        case 'broker-account':
            return BROKER_PRODUCT_IDS.FIALPS;
        default:
            return null;
    }
}

/**
 * Get FEF Plus Compliance Ids
 * @param accountType 
 * @returns 
 */
export function getProductId(accountType: AccountType): string | null {
    //hardcoded accountTypes
    switch(accountType) {
        case 'carrier-account':
            return CARRIER_PRODUCT_IDS.CFEFPC;
        case 'shipper-account':
            return SHIPPER_PRODUCT_IDS.SFEFPC;
        case 'broker-account':
            return BROKER_PRODUCT_IDS.FEFFIPC;
        default:
            return null;
    }
}

/**
 * Get the FEF product ID based on the account type
 * @param accountType 
 * @returns 
 */
export function getFEFProductId(accountType: AccountType): string | null {
    switch(accountType) {
        case 'carrier-account':
            return CARRIER_PRODUCT_IDS.CFEAL;
        /* case 'shipper-account':
            return 'PRODUCT ID HERE';
        case 'broker-account':
            return 'PRODUCT ID HERE'; */
        default:
            return null;
    }
}
