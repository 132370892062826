import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { TrailerModel, TrailerTypeModel } from 'src/app/core/services/models/models';
import { removeFromArray } from 'src/app/core/utils/commons';
import Swal from 'sweetalert2';
import { AbstractPagesComponent } from '../../AbstractPagesComponent';
import { VehiclesService } from 'src/app/core/services/vehicles-service/vehicle.service';
import { NgbdGridJsSortableHeader, SortEvent } from 'src/app/core/services/vehicles-service/gridjs-sortable.directive';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicle-trailers-tab',
  templateUrl: './vehicle-trailers-tab.component.html',
  styleUrls: ['./vehicle-trailers-tab.component.scss']
})
export class VehicleTrailersTabComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  allTrailers: TrailerModel[] = [];
  trailerTypesData: TrailerTypeModel[]  = [];

  @ViewChildren(NgbdGridJsSortableHeader) headers!: QueryList<NgbdGridJsSortableHeader>;

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    public service: VehiclesService,
    private router: Router,
  ) {
    super();
    this.subscriptions.push(
      this.authService.subscribeToSelectedAccount(async (data: any) => {
        return this.loadData();
      })
    );
  }

  ngOnInit(): void {
    this.getAllTrailers();
    this.loadTrailers();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadData(): Promise<void> {
    this.allTrailers = await this.httpService.listTrailers().toPromise();
  }

  async loadTrailers(): Promise<void> {
    this.trailerTypesData = await this.httpService.listTrailerTypes().toPromise();
  }

  getAllTrailers() {
    this.httpService.listTrailers().subscribe((data) => {
      this.allTrailers = data;
    })
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  getTrailerTypeNameById(id: string) {
    return this.trailerTypesData?.find(el => el?.trailerTypeId === id)?.name;
  }

  customSearchFn(term: string, item: any) {
    item.technicalName = item.technicalName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.technicalName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.technicalName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  deleteTrailer(trailer: TrailerModel) {
    Swal.fire({
      title: 'Are you Sure?',
      text: 'Are you sure you want to delete ' + trailer.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        this.httpService.deleteTrailer(trailer.trailerId).subscribe((data) => {
          this.allTrailers = removeFromArray(this.allTrailers, (a) => a.trailerId === trailer.trailerId);
        });
      }
    });
    
  }

  onEditClick(trailer: TrailerModel) {
    console.log({trailer})
    this.router.navigateByUrl("/pages/vehicles-and-equipment/trailers/edit", {state: trailer})
  }

}
