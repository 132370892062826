/**
 * Monthly Price
 */

import { PlanObj } from "./pricing.model";

 const MonthlyPlan =  [
  {
    id : 2,
    type :"Free Procurement Management Platform",
    rate : 0,
    price: 150,
    description : "Get Started Today",
    projects : 8,
    Customers :499,
    FTP:7,
    supportClass:"primary",
    supportClassSymbol :"checkbox",
    storageClass :"danger",
    storageClassSymbol :"close",
    domainClass :"danger",
    domainClassSymbol :"close",
    ribbon:false,
    planButtonClassname: "primary",
    url:"free",
    features: [
        "",
        "3 System Users",
        "Trading Relationships",
        "Load Board Access",
        "Intergraded 'TMS'",
        "Driver Qualification Files",
        "Dashboard & Reports",
        "Capacity & Rates",
        "Calendar",
        "Shipper/Broker Integrations",
        "Unlimited Customizable Users",
        "ELD Integrations",
        "Quick Start Links",
        "Shipper & Broker Search",
        "Lane Bidding Tool",
        "Service Area Tools",
        "Step by Step Sign Up Process",
        "Public Profiles Page",
        "Compliance Suite",
        "24/7 Online Support",
        "Cloud Based Platform"
    ]
},
  {
    id : 3,
    type :"Pre-Release Asset License",
    rate : 150,
    price: 400,
    description : "Each asset license bought before 12.31.2023 is locked at $150/month for 24 months",
    projects : 15,
    Customers :"Unlimited",
    FTP:12,
    supportClass:"primary",
    supportClassSymbol :"checkbox",
    storageClass :"primary",
    storageClassSymbol :"checkbox",
    domainClass :"danger",
    domainClassSymbol :"close",
    ribbon:true,
    planButtonClassname: "primary",
    url:"paid",
    features: [
        "",
        "Unlimited Users",
        "Unlimited Trading Relationships",
        "Load Board Access",
        "Intergraded 'TMS'",
        "Driver Qualification Files",
        "Dashboard & Reports",
        "Capacity & Rates",
        "Calendar",
        "Shipper/Broker Integrations",
        "Unlimited Customizable Users",
        "ELD Integrations",
        "Quick Start Links",
        "Shipper & Broker Search",
        "Lane Bidding Tool",
        "Service Area Tools",
        "Step by Step Sign Up Process",
        "Public Profiles Page",
        "Compliance Suite",
        "24/7 Online Support",
        "Cloud-Based Platform"
    ]
  },
]

const CarrierCostingPlan =  [
    {
        id : 5,
        type :"Pre-Release Asset License",
        rate : 0,
        price: 150,
        description : "Sign Up for Free",
        projects : 8,
        Customers :499,
        FTP:7,
        supportClass:"primary",
        supportClassSymbol :"checkbox",
        storageClass :"danger",
        storageClassSymbol :"close",
        domainClass :"danger",
        domainClassSymbol :"close",
        ribbon:false,
        planButtonClassname: "primary",
        url:"free",
        features: [
            "1 Free Asset License Per Carrier Authority",
            "3 System Users",
            "Trading Relationships",
            "Load Board Access",
            "Intergraded 'TMS'",
            "Driver Qualification Files",
            "Dashboard & Reports",
            "Capacity & Rates",
            "Calendar",
            "Shipper/Broker Integrations",
            "Unlimited Customizable Users",
            "ELD Integrations",
            "Quick Start Links",
            "Shipper & Broker Search",
            "Lane Bidding Tool",
            "Service Area Tools",
            "Step by Step Sign Up Process",
            "Public Profiles Page",
            "Compliance Suite",
            "24/7 Online Support",
            "Cloud Based Platform"
        ]
    },
    {
        id : 6,
        type :"Pre-Release Asset License",
        rate : 150,
        price: 400,
        description : "Each asset license bought before 12.31.2023 is locked at $150/month for 24 months",
        projects : 15,
        Customers :"Unlimited",
        FTP:12,
        supportClass:"primary",
        supportClassSymbol :"checkbox",
        storageClass :"primary",
        storageClassSymbol :"checkbox",
        domainClass :"danger",
        domainClassSymbol :"close",
        ribbon:true,
        planButtonClassname: "primary",
        url:"paid",
        features: [
            "",
            "Unlimited Users",
            "Unlimited Trading Relationships",
            "Load Board Access",
            "Intergraded 'TMS'",
            "Driver Qualification Files",
            "Dashboard & Reports",
            "Capacity & Rates",
            "Calendar",
            "Shipper/Broker Integrations",
            "Unlimited Customizable Users",
            "ELD Integrations",
            "Quick Start Links",
            "Shipper & Broker Search",
            "Lane Bidding Tool",
            "Service Area Tools",
            "Step by Step Sign Up Process",
            "Public Profiles Page",
            "Compliance Suite",
            "24/7 Online Support",
            "Cloud-Based Platform"
        ]
    },
  ]

const PlanFeatures: Array<PlanObj> = [
  {
    name: "Asset License",
    key: "asset_license",
    carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
    },
    shipper: {
        ffe: true,
        ps_asset_license: true,
    },
    broker: {
        ffe: true,
        ps_asset_license: true,
    }
  },
  {
      name: "Trading Relationships",
      key: "trading_relationships",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true,
      } ,
      broker: {
          ffe: false,
          ps_asset_license: true,
      }   
  },
  {
      name: "System Users",
      key: "system_users",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "User Profile",
      key: "user_profile",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Sign up wizard",
      key: "sign_up_wizard",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Onboarding QuickLinks",
      key: "onboarding_quicklinks",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Branch & Location Hierarchy",
      key: "branch_location_hierarchy",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Lane Bidding Tool",
      key: "lane_bidding_tool",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Capacity",
      key: "capacity",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Vehicles & Equipment Manager",
      key: "vehicles_and_equipment_manager",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Truck, Trailer and Combo",
      key: "truck_trailer_and_combo",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Compliance",
      key: "compliance",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Carrier Apps",
      key: "carrier_apps",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Carrier Driver Apps",
      key: "carrier_driver_apps",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Optimize Public Profile",
      key: "optimize_public_profile",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Logo and Branding",
      key: "logo_and_branding",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Company Description",
      key: "company_description",carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "Dashboard",
      key: "dashboard",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Reports",
      key: "reports",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Messages",
      key: "messages",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "My Calender",
      key: "my_calender",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Contracts/Document Upload",
      key: "contracts_document_upload",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Shipper Search",
      key: "shipper_search",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Freight Broker Search",
      key: "freight_broker_search",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Freight Forwarder Search",
      key: "freight_forwarder_search",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Contract Driver Search",
      key: "contract_driver_search",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Load Board Search",
      key: "load_board_search",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Dedicated Load Board",
      key: "dedicated_load_board",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "On-Demand Load Board",
      key: "on_demand_load_board",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Spot Load Board",
      key: "spot_load_board",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Interactive maps",
      key: "interactive_maps",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Service Areas & Zones Zip Code Tool",
      key: "service_areas_zones_zip_code_tool",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Rates",
      key: "rates",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Assets",
      key: "assets",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Equipment Compliance",
      key: "equipment_compliance",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Driver Qualification Files",
      key: "driver_qualification_files",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "MVR, PSP and Clearing House",
      key: "mvr_psp_and_clearing_house",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Digital Application",
      key: "digital_application",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Step by step compliance",
      key: "step_by_step_compliance",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Mode",
      key: "mode",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Service Windows",
      key: "service_windows",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Service Types",
      key: "service_types",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Shipper/Broker Integrations",
      key: "shipper_broker_integrations",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "ELD Integrations",
      key: "eld_integrations",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "24/7 Online Support",
      key: "24_7_online_support",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Cloud Based Platform",
      key: "cloud_based_platform",
      carrier: {
        ffe: true,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: true,
        ps_asset_license: true
      },
      broker: {
        ffe: true,
        ps_asset_license: true
      }
  },
  {
      name: "User Profile",
      key: "user_profile",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Users and Rolls",
      key: "users_and_rolls",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "User Public Profil",
      key: "user_public_profil",
      carrier: {
        ffe: false,
        ffe_plus_compliance: true,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "TMS",
      key: "tms",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
  {
      name: "Dedicated API",
      key: "dedicated_api",
      carrier: {
        ffe: false,
        ffe_plus_compliance: false,
        ps_asset_license: true,
        for_ever_asset_licence: true,
        carrier_asset_licence: true
      },
      shipper: {
        ffe: false,
        ps_asset_license: true
      },
      broker: {
        ffe: false,
        ps_asset_license: true
      }
  },
]

export { MonthlyPlan, CarrierCostingPlan, PlanFeatures };
