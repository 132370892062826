import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { ActiveProjects, MyTask, statDataCarrier, statDataShipper, TeamMembers } from './data';
import { NgbdModalIntroWizard } from './modals/intro-wizard-modal/intro-wizard-modal.component';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { MapsDataSource, MapsEventListener, MapsLayer, TrimbleMapsHelper } from 'src/app/core/services/models/trimble-maps.helper';
import { LatLng } from 'src/app/shared/trimble-map/trimble-map.component';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountPlanStatus } from 'src/app/core/services/models/account-plan.model';
import { HttpService } from 'src/app/core/services/http-service';
import { Invoice, getProductId, getPreSaleProductId } from 'src/app/core/services/models/invoice.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import Swal from 'sweetalert2';

const MAPS_SOURCE_NAME = 'branchSource';
const MAPS_LAYER_ID = 'branchPoints';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Dashboard'
    }
  ];
  activeModal?: NgbModalRef;
  @ViewChild('welcomeVideoModal') welcomeVideoModal!: Element;

  statData!: any;
  OverviewChart: any;
  ActiveProjects: any;
  MyTask: any;
  TeamMembers: any;
  status7: any;
  @ViewChild('scrollRef') scrollRef:any;

  trimbleMapOptions: Omit<TrimbleMaps.MapOptions, 'container'> = {
    style: TrimbleMaps.Common.Style.TRANSPORTATION, // hosted style id
    center: [-98.38, 38.69], // starting position
    zoom: 3 // starting zoom
  };
  geoJsonDataSources: MapsDataSource[] = [TrimbleMapsHelper.generateGeoJsonDataSource(MAPS_SOURCE_NAME, [])];
  mapLayers: MapsLayer[] = [
    {
      layer: {
        id: MAPS_LAYER_ID,
        type: 'circle',
        source: MAPS_SOURCE_NAME,
        paint: {
            'circle-radius': 12,
            'circle-color': '#33E',
            'circle-stroke-color': '#FFF',
            'circle-stroke-width': 4
        }
      }
    }
  ];
  mapListeners: MapsEventListener[];
  routeStops:LatLng[] = [];

  currentUser: any
  currentAccountSelected: any;
  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService
  ) {
  }

  async ngOnInit() {
    this.currentUser = this.auth.currentUserValue;
    this.currentAccountSelected = this.auth.currentAccountSelected;
    const plan: AccountPlanStatus =  this.activatedRoute.snapshot.queryParams['plan'];
    const billingProfileId: string =  this.activatedRoute.snapshot.queryParams['billingProfileId'];


    if(billingProfileId) {
      Swal.fire({
        title: 'Setting up your account...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      

      let productId = null;
      if(plan === 'paid') {
        productId = getPreSaleProductId(this.auth.currentAccountSelected.accountType)!;
        this.httpService.postInvoice(
          billingProfileId,
          {
            lineItems: [{
              productId: productId,
              quantity: 1
            }]
          }
        ).subscribe(
          res => {
            const successRes = <SuccessApiResponse<Invoice>> res;
            const invoice = successRes.data;
            console.log('[invoice for paid plan made]', invoice);
            this.router.navigate(
              [], 
              {
                relativeTo: this.activatedRoute,
                queryParams: {plan: null, billingProfileId: null}, 
                queryParamsHandling: 'merge'
              }
            ).then(() => {
              //Reloading
              console.log('[reloading....]');
              window.location.reload();
            });
          },
          error => {
            Swal.fire({
              title: 'Error',
              text: 'Failed to invoice for paid plan: ' + error.error.reason,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok',
            }).then((result) => {
              //do nothing
            });
          }
        );
      } 
      /*
      else {
        productId = getProductId(this.auth.currentAccountSelected.accountType)!;
      }
      */
    }

    /**
     * Fetches the data
     */
    this.fetchData();

    // Chart Color Data Get Function
    this._OverviewChart('["--vz-primary", "--vz-warning", "--vz-success"]');
    this._status7('["--vz-success", "--vz-primary", "--vz-warning", "--vz-danger"]');
    
  }

  ngAfterViewInit() {
    // this.scrollRef.SimpleBar.getScrollElement().scrollTop = 600;
  }

  // Chart Colors Set
  private getChartColorsArray(colors:any) {
    colors = JSON.parse(colors);
    return colors.map(function (value:any) {
      var newValue = value.replace(" ", "");
      if (newValue.indexOf(",") === -1) {
        var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
            if (color) {
            color = color.replace(" ", "");
            return color;
            }
            else return newValue;;
        } else {
            var val = value.split(',');
            if (val.length == 2) {
                var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                return rgbaColor;
            } else {
                return newValue;
            }
        }
    });
  }

  /**
 * Projects Overview
 */
  private _OverviewChart(colors:any) {
    colors = this.getChartColorsArray(colors);
    this.OverviewChart = {
      series: [{
          name: 'Number of Projects',
          type: 'bar',
          data: [34, 65, 46, 68, 49, 61, 42, 44, 78, 52, 63, 67]
      }, {
          name: 'Revenue',
          type: 'area',
          data: [89.25, 98.58, 68.74, 108.87, 77.54, 84.03, 51.24, 28.57, 92.57, 42.36, 88.51, 36.57]
      }, {
          name: 'Active Projects',
          type: 'bar',
          data: [8, 12, 7, 17, 21, 11, 5, 9, 7, 29, 12, 35]
      }],
      chart: {
          height: 374,
          type: 'line',
          toolbar: {
              show: false,
          }
      },
      stroke: {
          curve: 'smooth',
          dashArray: [0, 3, 0],
          width: [0,1, 0],
      },
      fill: {
          opacity: [1, 0.1, 1]
      },
      markers: {
          size: [0, 4, 0],
          strokeWidth: 2,
          hover: {
              size: 4,
          }
      },
      xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          axisTicks: {
              show: false
          },
          axisBorder: {
              show: false
          }
      },
      grid: {
          show: true,
          xaxis: {
              lines: {
                  show: true,
              }
          },
          yaxis: {
              lines: {
                  show: false,
              }
          },
          padding: {
              top: 0,
              right: -2,
              bottom: 15,
              left: 10
          },
      },
      legend: {
          show: true,
          horizontalAlign: 'center',
          offsetX: 0,
          offsetY: -5,
          markers: {
              width: 9,
              height: 9,
              radius: 6,
          },
          itemMargin: {
              horizontal: 10,
              vertical: 0
          },
      },
      plotOptions: {
          bar: {
              columnWidth: '30%',
              barHeight: '70%'
          }
      },
      colors: colors,
      tooltip: {
      shared: true,
      y: [{
          formatter: function (y:any) {
            if(typeof y !== "undefined") {
              return  y.toFixed(0);
            }
            return y;
            
          }
        }, {
          formatter: function (y:any) {
            if(typeof y !== "undefined") {
              return   "$" + y.toFixed(2) + "k";
            }
            return y;
            
          }
        }, {
          formatter: function (y:any) {
            if(typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
            
          }
        }]
      }
    };
  }

  /**
 *  Status7
 */
  private _status7(colors:any) {
    colors = this.getChartColorsArray(colors);
    this.status7 = {
      series: [125, 42, 58, 89],
      labels: ["Completed", "In Progress", "Yet to Start", "Cancelled"],
      chart: {
          type: "donut",
          height: 230,
      },
      plotOptions: {
          pie: {
              offsetX: 0,
              offsetY: 0,
              donut: {
                  size: "90%",
                  labels: {
                      show: false,
                  }
              },
          },
      },
      dataLabels: {
          enabled: false,
      },
      legend: {
          show: false,
      },
      stroke: {
          lineCap: "round",
          width: 0
      },
      colors: colors
    };
  }

  /**
   * Fetches the data
   */
  private fetchData() {
    if(this.currentUser.accountType == 'shipper') {
      this.statData = statDataShipper;
    } else {
      this.statData = statDataCarrier;
    }
    this.ActiveProjects = ActiveProjects;
    this.MyTask = MyTask;
    this.TeamMembers = TeamMembers;
  }

  // ngAfterViewInit(): void {
  //   // if (this.auth.currentUserValue.showWelcomeVideo && this.auth.currentUserValue.role != 'shipper-owner') {
  //   //   this.openModal();
  //   // }
  // }

  openModal(): void {
    this.activeModal?.close();
    // let modalRef = this.modalService.open(NgbdModalIntroWizard, { size: 'xl', centered: true, backdrop: 'static', keyboard : false });
    let modalRef = this.modalService.open(NgbdModalIntroWizard, { size: 'lg', centered: true, backdrop: 'static', keyboard : false });
  }

  closeModal(): void {
    this.activeModal?.close();
  }
}

