import { PageRequest } from "./table-pagination.helper";
export type UserType = 'shipper' | 'carrier' | 'driver' | 'helper';

export interface SuccessApiResponse<T> {
    error: false;
    data: T;
};
export interface ErrorApiResponse {
    error: true;
    reason: string;
}

/**
 * All responses from our endpoints will have this format
 */
export type ApiResponse<T> = SuccessApiResponse<T> | ErrorApiResponse;

export class PaginatedResults<T> {
    totalResults: number;
    pageSize: number;
    pages: number;
    page: number;
    results: T[];

    public static from<T>(data: T[], pageRequest: PageRequest): PaginatedResults<T>{
        const paginatedResult = new PaginatedResults<T>();
        paginatedResult.totalResults = data.length;
        paginatedResult.pageSize = pageRequest.pageSize;
        paginatedResult.page = pageRequest.page < 1 ? 1: pageRequest.page;
        paginatedResult.pages = Math.ceil(paginatedResult.totalResults/paginatedResult.pageSize);
        const {page, pageSize, totalResults} = paginatedResult;
        paginatedResult.results = [];
        for(let ndx= (page * pageSize) - pageSize, cnt=0; cnt < pageSize && ndx < totalResults; cnt++, ndx++){
            paginatedResult.results.push(data[ndx])
        }
        return paginatedResult;
    }
}

export interface ContractSignerModel {
    userId: string;
    userType: UserType;
    signingUrl: string;
    signingStatus?: string;
    email: string;
    firstName: string;
    lastName: string;
    status: string;
}

export interface ContractModel {
    contractId: string;
    name: string;
    status: string;
    adobeAgreementId?: string;
    signers?: ContractSignerModel[];
    senderAccountId: string;
    senderAccountType: string;
    senderAccountName: string;
    receiverAccountId: string;
    receiverAccountType: string;
    receiverAccountName: string;
}

export interface DocumentModel {
    documentId: string;
    accountId: string;
    filename: string;
    displayName: string;
    contentType: string;
    documentType: string;
    ready: boolean;
    modes: string[]
}

//#region ----------------- start of Trucks https://github.com/project-dtc/issues/blob/main/api/Trucks.md -----------------
export interface TruckTypeModel {
    truckTypeId: string;
    name: string;
    subName: string;
    grossWeightVehicleRating: number;
    weight: number;
    capacityWeight: number;
    capacityVolume: string;
}

export enum TruckOwnershipType {
    Purchased = 'Purchased',
    Rented = 'Rented',
    Leased = 'Leased',
}

export enum TruckTermUnit {
    Days = 'Days',
    Years = 'Years',
}

export interface TruckModel {
    truckId: string;
    accountId: string;
    name?: string;
    isInService: boolean;
    /** TruckType.truckTypeId */
    type: string;
    ownershipType: TruckOwnershipType;
    vendorName: string;
    /** in yyyy-mm-dd format */
    dateAcquired: string;
    termNumber: number;
    termUnits: TruckTermUnit;
    unitNumber?: number;
    year: number;
    make: string;
    model: string;
    vehicleIdNumber: string;
    licensePlateNumber: string;
    licensePlateState: string;
    grossWeightVehicleRating: number;
    eldCompany: string;
    eldMake: string;
    eldModel: string;
    eldYear: number;
}

type RequiredCreateTruckPayload = Required<Pick<TruckModel,
    'type'
    | 'ownershipType'
    | 'vendorName'
    | 'dateAcquired'
    | 'termNumber'
    | 'termUnits'
    | 'unitNumber'
    | 'year'
    | 'make'
    | 'model'
    | 'vehicleIdNumber'
    | 'licensePlateNumber'
    | 'licensePlateState'
    | 'eldCompany'
    | 'eldMake'
    | 'eldModel'
    | 'eldYear'
>>;
type OptionalCreateTruckPayload = Partial<Pick<TruckModel,
    'truckId'
    | 'name'
    | 'isInService'
>>;
export type CreateTruckPayload = RequiredCreateTruckPayload & OptionalCreateTruckPayload;

//#endregion ----------------- end of Trucks ----------------- 

//#region ----------------- start of Trailers https://github.com/project-dtc/issues/blob/main/api/Trailers.md -----------------
export type TrailerTypeModel = Omit<TruckTypeModel, 'truckTypeId'> & {
    trailerTypeId: string;
};
export const TrailerOwnershipType = TruckOwnershipType;
export const TrailerTermUnit = TruckTermUnit;

export type TrailerModel = Omit<TruckModel, 'truckId'> & {
    trailerId: string;
    typeName: TrailerTypeModel['name'];
};

type RequiredCreateTrailerPayload = RequiredCreateTruckPayload;
type OptionalCreateTrailerPayload = Omit<OptionalCreateTruckPayload, 'truckId'> & Partial<Pick<TrailerModel,
    'trailerId'
>>;
export type CreateTrailerPayload = RequiredCreateTrailerPayload & OptionalCreateTrailerPayload;

//#endregion ----------------- end of Trailers ----------------- 

//#region ----------------- start of Truck + Trailer Combos https://github.com/project-dtc/issues/blob/main/api/Truck%20Trailer%20Combos.md -----------------
export type TruckTrailerCombModel = {
    truckTrailerComboId: string;
    accountId: string;
    truckId: string;
    trailerId1: string;
    trailerId2: string;
    trailerId3: string;
    name?: string;
    isInService?: string;
}

type RequiredCreateTruckTrailerComboPayload = Required<Pick<TruckTrailerCombModel,
    'truckId'
    | 'trailerId1'
>>;
type OptionalCreateTruckTrailerComboPayload = Partial<Pick<TruckTrailerCombModel,
    'truckTrailerComboId'
    | 'trailerId2'
    | 'trailerId3'
    | 'name'
    | 'isInService'
>>;
export type CreateTruckTrailerComboPayload = RequiredCreateTruckTrailerComboPayload & OptionalCreateTruckTrailerComboPayload;

//#endregion ----------------- end of Truck + Trailer Combos ----------------- 

//#region ----------------- JSON asset models -----------------
export interface TruckAndTrailerType {
    'Make ': string;
    models: (string | number)[];
}

export interface AssetJsonModel {
    prettyName: string;
    techName: string;
}

export interface EldCompanyName {
    companyName: string;
    deviceName: string;
    modelNumber: string;
    association: string;
}

export interface ZipLngLat {
    Zip: string;
    Latitude: string;
    Longitude: string;
}
//#endregion ----------------- end of JSON asset models -----------------

export interface ILicense {
    license_id: string;
    account_id: string;
    product_id: string;
    product_name: string;
    has_helper: boolean;
    original_purchase_date: number;
    expiration_date: number;
    last_extension_date: number;
    last_extension_invoice: string;
    cancellation_date: number;
    cancelled: boolean;
}