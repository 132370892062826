import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule, NgbPaginationModule, NgbTypeaheadModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


// Swiper Slider
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

// Counter
import { CountToModule } from 'angular-count-to';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { GoogleMapsAutocompleteComponent } from './google-maps-autocomplete/google-maps-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafePipe } from './safe-pipe/safe.pipe';
import { ZipcodePickerComponent } from './zipcode-picker/zipcode-picker.component';
import { SearchComponent } from './search/search.component';
import { ShipperSearchComponent } from './shipper-search/shipper-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CapacityFormComponent } from './capacity-form/capacity-form.component';
import { NewBranchesComponent } from './new-branches/new-branches.component';
import { TreeTableModule } from 'primeng/treetable';
import { ChatComponent } from './chat/chat.component';
// import { ClientLogoComponent } from './landing/client-logo/client-logo.component';
// import { ServicesComponent } from './landing/services/services.component';
// import { CollectionComponent } from './landing/collection/collection.component';
// import { CtaComponent } from './landing/cta/cta.component';
// import { DesignedComponent } from './landing/designed/designed.component';
// import { PlanComponent } from './landing/plan/plan.component';
// import { FaqsComponent } from './landing/faqs/faqs.component';
// import { ReviewComponent } from './landing/review/review.component';
// import { CounterComponent } from './landing/counter/counter.component';
// import { WorkProcessComponent } from './landing/work-process/work-process.component';
// import { TeamComponent } from './landing/team/team.component';
// import { ContactComponent } from './landing/contact/contact.component';
// import { FooterComponent } from './landing/footer/footer.component';
// import { ScrollspyDirective } from './scrollspy.directive';

// Simplebar
import { SimplebarAngularModule } from 'simplebar-angular';

// Feather Icon
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { GoogleMapsSearchBoxComponent } from './google-maps-search-box/google-maps-search-box.component';
import { CalendarSettingsComponent } from './calendar/calendar-settings/calendar-settings.component';
import { TreeCheckboxComponent } from './tree-checkbox/tree-checkbox.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RouterModule } from '@angular/router';
import { ExcelDataComponent } from './excel-data/excel-data.component';
import { TablePaginationComponent } from './table-pagination/table-pagination.component';
import { TrimbleMapComponent } from './trimble-map/trimble-map.component';
import { MapboxZipcodePickerComponent } from './mapbox-zipcode-picker/mapbox-zipcode-picker.component';
import { MapboxHeatmapComponent } from './mapbox-heatmap/mapbox-heatmap.component';
import { ProjectsStatComponent } from './projects-stat/projects-stat.component';
import { OpenStreetMapComponent } from './open-street-map/open-street-map.component';
import { GoogleMapComponent } from './google-map/google-map.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    CapacityFormComponent,
    GoogleMapsAutocompleteComponent,
    GoogleMapsSearchBoxComponent,
    SafePipe,
    ZipcodePickerComponent,
    SearchComponent,
    ShipperSearchComponent,
    NewBranchesComponent,
    ChatComponent,
    CalendarSettingsComponent,
    TreeCheckboxComponent,
    ExcelDataComponent,
    TablePaginationComponent,
    TrimbleMapComponent,
    MapboxZipcodePickerComponent,
    MapboxHeatmapComponent,
    ProjectsStatComponent,
    OpenStreetMapComponent,
    GoogleMapComponent,
    // ClientLogoComponent,
    // ServicesComponent,
    // CollectionComponent,
    // CtaComponent,
    // DesignedComponent,
    // PlanComponent,
    // FaqsComponent,
    // ReviewComponent,
    // CounterComponent,
    // WorkProcessComponent,
    // TeamComponent,
    // ContactComponent,
    // FooterComponent,
    // ScrollspyDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbAccordionModule,
    SwiperModule,
    CountToModule,
    TreeTableModule,
    SimplebarAngularModule,
    FeatherModule.pick(allIcons),
    CheckboxModule,
    NgbPaginationModule,
  ],
  exports: [
    BreadcrumbsComponent,
    CapacityFormComponent,
    GoogleMapsAutocompleteComponent,
    GoogleMapsSearchBoxComponent,
    CalendarSettingsComponent,
    TreeCheckboxComponent,
    SafePipe,
    ZipcodePickerComponent,
    SearchComponent,
    ShipperSearchComponent,
    NewBranchesComponent,
    ChatComponent,
    ExcelDataComponent,
    TablePaginationComponent,
    TrimbleMapComponent,
    MapboxZipcodePickerComponent,
    MapboxHeatmapComponent,
    ProjectsStatComponent,
    OpenStreetMapComponent,
    GoogleMapComponent
    //  ClientLogoComponent, ServicesComponent, CollectionComponent, CtaComponent, DesignedComponent, PlanComponent, FaqsComponent, ReviewComponent, CounterComponent, WorkProcessComponent, TeamComponent, ContactComponent, FooterComponent, ScrollspyDirective
  ]
})
export class SharedModule { }
