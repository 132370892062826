<app-breadcrumbs [breadCrumbItems]="breadCrumbItems" (close)="reloadComponentCancel()"></app-breadcrumbs>
<!-- <div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="10" class="nav-item" (click)="setActiveTab(10);" *ngIf="profileTaskRem != 0">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-user"></i> Verification <span class="badge bg-danger ms-1">{{ profileTaskRem }}</span>
                        </a>
                        <ng-template ngbNavContent>
                            <app-complete-profile [showCard]="false"></app-complete-profile>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="1" class="nav-item" (click)="setActiveTab(1);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Company Profile
                        </a>
                        <ng-template ngbNavContent>
                            <app-company-settings></app-company-settings>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="20" class="nav-item" (click)="setActiveTab(1);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Company Public Profile
                        </a>
                        <ng-template ngbNavContent>
                            <app-company-settings></app-company-settings>
                        </ng-template>
                    </li>
                    <li *ngIf="showMode" [ngbNavItem]="11 " class="nav-item" (click)="setActiveTab(11);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Modes
                        </a>
                        <ng-template ngbNavContent>
                            <app-modes></app-modes>
                        </ng-template>
                    </li>
                    <li *ngIf="showMode" [ngbNavItem]="12 " class="nav-item" (click)="setActiveTab(12);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Service Areas
                        </a>
                        <ng-template ngbNavContent>
                            <app-service-areas></app-service-areas>
                        </ng-template>
                    </li>
                    <li *ngIf="showMode" [ngbNavItem]="16 " class="nav-item" (click)="setActiveTab(16);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Service Types
                        </a>
                        <ng-template ngbNavContent>
                            <app-service-types></app-service-types>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="18" class="nav-item" (click)="setActiveTab(18);" *ngIf="currentUser.accountType =='shipper'">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Lanes
                        </a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="19" class="nav-item" (click)="setActiveTab(19);" *ngIf="currentUser.accountType =='shipper'">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Rates
                        </a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li>
                    <li *ngIf="showRoles" [ngbNavItem]="13" class="nav-item" (click)="setActiveTab(13);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Roles
                        </a>
                        <ng-template ngbNavContent>
                            <app-roles></app-roles>
                        </ng-template>
                    </li>
                    <li *ngIf="showUsers" [ngbNavItem]="2" class="nav-item" (click)="setActiveTab(2);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-user"></i> Users
                        </a>
                        <ng-template ngbNavContent>
                            <app-user></app-user>
                        </ng-template>
                    </li>

                    <li [ngbNavItem]="4" class="nav-item" (click)="setActiveTab(4);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Billing
                        </a>
                        <ng-template ngbNavContent>
                            <app-billing></app-billing>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5" class="nav-item" (click)="setActiveTab(5);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Invoices
                        </a>
                        <ng-template ngbNavContent>
                            <app-invoices></app-invoices>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6" class="nav-item" (click)="setActiveTab(6);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-user"></i> Licenses
                        </a>
                        <ng-template ngbNavContent>
                            <app-licenses></app-licenses>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="7" class="nav-item" (click)="setActiveTab(7);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-user"></i> Document Templates
                        </a>
                        <ng-template ngbNavContent>
                            <app-contract-templates [showAddContractTemplate]="showAddContractTemplate" [showViewContractTemplate]="showViewContractTemplate" [contractDetailsData]="contractDetailsData" (breadcrumbItems)="breadcrumbItems($event)"></app-contract-templates>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="14" class="nav-item" (click)="setActiveTab(14);" [disabled]="userSubcription == 'free'">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-user"></i> Contract Signers
                        </a>
                        <ng-template ngbNavContent>
                            <app-contract-signers></app-contract-signers>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="15" class="nav-item" (click)="setActiveTab(15);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-user"></i> Chat Requests
                        </a>
                        <ng-template ngbNavContent>
                            <app-chat-request></app-chat-request>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item" (click)="setActiveTab(3);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Accounts Payable
                        </a>
                        <ng-template ngbNavContent>
                            <app-accounts-payable></app-accounts-payable>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="17" class="nav-item" (click)="setActiveTab(17);" *ngIf="currentUser.accountType =='shipper'">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Integrations
                        </a>
                        <ng-template ngbNavContent>
                            <app-api-integrations></app-api-integrations>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body p-4">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="1" class="nav-item" (click)="setActiveTab(1); setRoute(1);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Company
                        </a>
                        <ng-template ngbNavContent>
                            <app-company-menu (changeChildTab)="changeChildTab($event, 'company')" [activeTab]="childTab"></app-company-menu>
                            <!-- <app-modes></app-modes> -->
                            <!-- <app-service-areas></app-service-areas> -->
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="nav-item" (click)="setActiveTab(2); setRoute(2);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Service Areas
                        </a>
                        <ng-template ngbNavContent>
                            <app-zones-menu (changeChildTab)="changeChildTab($event, 'zones')" [activeTab]="childTab"></app-zones-menu>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item" 
                        (click)="
                            setActiveTab(3, 'nav'); 
                            setRoute(3);
                        ">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Lanes
                        </a>
                        <ng-template ngbNavContent>
                            <app-lanes-menu (changeChildTab)="changeChildTab($event, 'lanes')" [activeTab]="childTab"></app-lanes-menu>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" class="nav-item" (click)="setActiveTab(4); setRoute(4);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Branches
                        </a>
                        <ng-template ngbNavContent>
                            <div *ngIf="branchEvent.type === 'list-branch'">
                                <app-origin-point
                                    [isRouted]="false"
                                    (event)="branchEvent = $event"
                                ></app-origin-point>
                            </div>
                            <div *ngIf="branchEvent.type === 'add-branch'">
                               <app-add-branch
                                    [isRouted]="false"
                                    [parentBranch]="branchEvent.branch!"
                                    (event)="branchEvent = $event"
                               ></app-add-branch>
                            </div>
                            <div *ngIf="branchEvent.type === 'edit-branch'">
                                <app-edit-branch
                                     [isRouted]="false"
                                     [branchData]="branchEvent.branch!"
                                     (event)="branchEvent = $event"
                                ></app-edit-branch>
                             </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5"
                        (click)="
                            setActiveTab(5, 'nav'); 
                            setRoute(5);
                            promptUpgrade()
                        "
                        [disabled]="isTrial()"
                        class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Trading Relationships
                        </a>
                        <ng-template ngbNavContent>
                            <app-trading-relationships-menu (changeChildTab)="changeChildTab($event, 'trading-relationships')" [activeTab]="childTab"></app-trading-relationships-menu>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6" class="nav-item" (click)="setActiveTab(6); setRoute(6);">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Billing And Invoicing
                        </a>
                        <ng-template ngbNavContent>
                            <app-billing-and-invoicing-menu (changeChildTab)="changeChildTab($event, 'billing-and-invoice')" [activeTab]="childTab"></app-billing-and-invoicing-menu>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="7" class="nav-item" (click)="setActiveTab(7, 'nav'); setRoute(7); promptIntegrationUpgrade();" [disabled]="isDisableUsers()">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Users
                        </a>
                        <ng-template ngbNavContent>
                            <app-users-menu (changeChildTab)="changeChildTab($event, 'users')" [activeTab]="childTab"></app-users-menu>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="8" class="nav-item" (click)="setActiveTab(8, 'nav'); setRoute(8); promptIntegrationUpgrade();" [disabled]="isDisableIntegration()">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Integrations
                        </a>
                        <ng-template ngbNavContent>
                            <app-api-integrations [isModalOpen]="openModal"></app-api-integrations>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="9" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Global Settings
                        </a>
                        <ng-template ngbNavContent>
                            <div class="card">  
                                <div class="card-header">
                                    <ul ngbNav #setupNavContent="ngbNav" [activeId]="100" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                        <li [ngbNavItem]="100" 
                                            class="nav-item"
                                            *ngIf="tabsVisibility.global_settings_equiupment_compliance"> 
                                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                                Equipment Compliance
                                            </a>
                                            <ng-template ngbNavContent>
                                                <app-equipment-compliance 
                                                    [showBreadCrumb]="false"
                                                    [displaySection]="'form'">
                                                </app-equipment-compliance>
                                            </ng-template>
                                        </li>
                                        <li [ngbNavItem]="101" 
                                            class="nav-item"
                                            *ngIf="tabsVisibility.global_settings_driver_qualification"> 
                                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                                Driver Qualification
                                            </a>
                                            <ng-template ngbNavContent>
                                                <app-driver-qualification-files 
                                                    [showBreadCrumb]="false"
                                                    [displaySection]="'form'">
                                                </app-driver-qualification-files>
                                            </ng-template>
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-body">
                                    <div class="tab-content">
                                        <div [ngbNavOutlet]="setupNavContent"></div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>