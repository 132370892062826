export interface DOL_TAB_DATA {
  name: string,
  link: string,
  childs?: Array<DOL_TAB_DATA>
}

export const DOL_DATA: Array<DOL_TAB_DATA> = [
    {
      name: "Alabama",
      link: "https://www.alabama.gov/",
      childs: [
        {
          name: "Department of Transportation",
          link: "http://www.dot.state.al.us/"
        }
      ]
    },
    {
      name: "Alaska",
      link: "http://alaska.gov/",
      childs: [
        {
          name: "Department of Transportation",
          link: "http://www.dot.state.ak.us/"
        }
      ]
    },
    {
      name: "Arizona",
      link: "https://az.gov/",
      childs: [
        {
          name: "Department of Transportation",
          link: "http://www.azdot.gov/"
        }
      ]
    },
    {
      name: "Arkansas",
      link: "https://portal.arkansas.gov/",
      childs: [
        {
          name: "Arkansas Department of Transportation (ARDOT)",
          link: "https://www.ardot.gov/"
        }
      ]
    },
    {
      name: "California",
      link: "http://www.ca.gov/",
      childs: [
        {
          name: "Department of Transportation",
          link: "http://www.dot.ca.gov/"
        },
        {
          name: "PATH: Partners for Advanced Transit and Highways",
          link: "https://www.fhwa.dot.gov/exit.cfm?link=http://www.path.berkeley.edu/"
        },
        {
          name: "Division of Traffic Operations",
          link: "http://www.dot.ca.gov/hq/traffops/"
        },
        {
          name: "Division of Rail and Mass Transportation Program",
          link: "http://www.dot.ca.gov/hq/MassTrans/"
        }
      ]
    },
    {
      name: "Colorado",
      link: "http://www.colorado.gov/",
      childs: [
        {
          name: "Department of Transportation",
          link: "https://www.fhwa.dot.gov/exit.cfm?link=http://www.coloradodot.info/"
        }
      ]
    },
    {
      name: "Connecticut",
      link: "http://www.ct.gov/",
      childs: [
        {
          name: "Department of Transportation",
          link: "http://www.ct.gov/dot"
        }
      ]
    },
    {
      name: "Delaware",
      link: "http://delaware.gov/",
      childs: [
        {
          name: "Department of Transportation",
          link: "http://www.deldot.gov/"
        }
      ]
    },
    {
      name: "District of Columbia",
      link: "https://dc.gov/",
      childs: [
        {
          name: "Department of Transportation",
          link: "http://ddot.dc.gov/"
        }
      ]
    },
    {
      name: "Florida",
      link: "http://www.myflorida.com/",
      childs: [
        {
          name: "Department of Transportation",
          link: "http://www.fdot.gov/"
        },
        {
          name: "Department of Highway Safety and Motor Vehicles",
          link: "https://www.flhsmv.gov/"
        }
      ]
    },
    { 
      name: "Georgia",
      link: "http://georgia.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.dot.ga.gov/"
          }
      ]
  },
  { 
      name: "Hawaii",
      link: "http://www.hawaii.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://hidot.hawaii.gov/"
          },
          {
              name: "Oahu Metropolitan Planning Organization",
              link: "https://www.fhwa.dot.gov/exit.cfm?link=http://www.oahumpo.org"
          }
      ]
  },
  {
      name: "Idaho",
      link: "http://www.idaho.gov/",
      childs: [
          {
              name: "Idaho Transportation Department",
              link: "http://itd.idaho.gov/"
          }
      ]
  },
  {
      name: "Illinois",
      link: "http://www.state.il.us/",
      childs: [
          {
              name: "Department of Transportation",
              link: "https://idot.illinois.gov/"
          }
      ]
  },
  {
      name: "Indiana",
      link: "http://www.in.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.in.gov/indot/"
          }
      ]
  },
  {
      name: "Iowa",
      link: "http://www.iowa.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.iowadot.gov/"
          },
          {
              name: "Governor's Traffic Safety Bureau",
              link: "http://www.dps.state.ia.us/commis/gtsb/"
          }
      ]
  },
  {
      name: "Kansas",
      link: "http://www.kansas.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.ksdot.org/"
          }
      ]
  },
  {
      name: "Kentucky",
      link: "http://kentucky.gov/",
      childs: [
          {
              name: "Transportation Cabinet",
              link: "http://transportation.ky.gov/"
          }
      ]
  },
  {
      name: "Louisiana",
      link: "http://louisiana.gov/",
      childs: [
          {
              name: "Department of Transportation & Development",
              link: "http://wwwsp.dotd.la.gov/"
          }
      ]
  },
  {
      name: "Maine",
      link: "http://www.maine.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.maine.gov/mdot/"
          }
      ]
  },
  {
      name: "Maryland",
      link: "http://www.maryland.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.mdot.maryland.gov/"
          },
          {
              name: "State Highway Administration",
              link: "http://www.roads.maryland.gov/"
          }
      ]
  },
  {
      name: "Massachusetts",
      link: "http://www.mass.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.massdot.state.ma.us/"
          }
      ]
  },
  {
      name: "Michigan",
      link: "http://www.michigan.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.michigan.gov/mdot/"
          }
      ]
  },
  {
      name: "Minnesota",
      link: "http://mn.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.dot.state.mn.us/"
          }
      ]
  },
  {
      name: "Mississippi",
      link: "http://www.ms.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://mdot.ms.gov/"
          }
      ]
  },
  {
      name: "Missouri",
      link: "http://www.mo.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.modot.org/"
          }
      ]
  },
  {
      name: "Montana",
      link: "http://mt.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.mdt.mt.gov/"
          }
      ]
  },
  {
      name: "Nebraska",
      link: "http://www.state.ne.us/",
      childs: [
          {
              name: "Nebraska Department of Transportation",
              link: "https://dot.nebraska.gov/"
          }
      ]
  },
  {
      name: "Nevada",
      link: "http://nv.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.nevadadot.com/"
          }
      ]
  },
  {
      name: "New Hampshire",
      link: "http://www.nh.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.nh.gov/dot/"
          }
      ]
  },
  {
      name: "New Jersey",
      link: "http://www.state.nj.us/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.state.nj.us/transportation/"
          },
          {
              name: "Port Authority of New York and New Jersey",
              link: "http://www.panynj.gov/"
          }
      ]
  },
  {
      name: "New Mexico",
      link: "http://www.newmexico.gov/",
      childs: [
          {
              name: "Highway and Transportation Department",
              link: "http://dot.state.nm.us/"
          }
      ]
  },
  {
      name: "New York",
      link: "https://www.ny.gov/",
      childs: [
          {
              name: "New York State Department of Transportation",
              link: "https://www.ny.gov/"
          },
          {
              name: "New York Metropolitan Transportation Council",
              link: "https://www.fhwa.dot.gov/exit.cfm?link=http://www.nymtc.org"
          },
          {
              name: "Port Authority of New York and New Jersey",
              link: "http://www.panynj.gov/"
          },
          {
              name: "Thruway Authority, New York State",
              link: "http://www.thruway.ny.gov/"
          }
      ]
  },
  {
      name: "North Carolina",
      link: "http://www.ncgov.com/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.ncdot.gov/"
          },
          {
              name: "Division of Highways",
              link: "http://www.ncdot.org/doh/"
          },
          {
              name: "ITRE: UNC Institute for Transportation Research and Education",
              link: "https://www.fhwa.dot.gov/exit.cfm?link=http://itre.ncsu.edu"
          }
      ]
  },
  {
      name: "North Dakota",
      link: "http://www.nd.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.dot.nd.gov/"
          }
      ]
  },
  {
      name: "Ohio",
      link: "http://www.ohio.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.dot.state.oh.us/"
          }
      ]
  },
  {
      name: "Oklahoma",
      link: "http://www.oklaosf.state.ok.us/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.okladot.state.ok.us/"
          }
      ]
  },
  {
      name: "Oregon",
      link: "http://www.oregon.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.oregon.gov/odot/"
          }
      ]
  },
  {
      name: "Pennsylvania",
      link: "http://www.pa.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.penndot.gov/"
          }
      ]
  },
  {
      name: "Puerto Rico",
      link: "https://www.usa.gov/state-government/puerto-rico",
      childs: [
          {
              name: "Department of Transportation and Public Works",
              link: "http://its.dtop.gov.pr/"
          }
      ]
  },
  {
      name: "Rhode Island",
      link: "http://www.ri.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.dot.ri.gov/"
          }
      ]
  },
  {
      name: "South Carolina",
      link: "http://www.sc.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "https://www.scdot.org/"
          }
      ]
  },
  {
      name: "South Dakota",
      link: "http://www.sd.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.sddot.com/"
          }
      ]
  },
  {
      name: "Tennessee",
      link: "http://www.tn.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.tn.gov/tdot"
          },
          {
              name: "Johnson City MPO",
              link: "https://www.fhwa.dot.gov/exit.cfm?link=http://www.jcmpo.org/"
          }
      ]
  },
  {
      name: "Texas",
      link: "http://www.texas.gov/",
      childs: [
          {
              name: "Center for Transportation Research",
              link: "https://www.fhwa.dot.gov/exit.cfm?link=http://www.utexas.edu/research/ctr/"
          },
          {
              name: "Department of Transportation",
              link: "http://www.txdot.gov/"
          },
          {
              name: "Texas Transportation Institute",
              link: "https://www.fhwa.dot.gov/exit.cfm?link=http://tti.tamu.edu"
          }
      ]
  },
  {
      name: "Utah",
      link: "http://www.utah.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.udot.utah.gov/"
          }
      ]
  },
  {
      name: "Vermont",
      link: "http://www.vermont.gov/",
      childs: [
          {
              name: "Agency of Transportation",
              link: "http://vtrans.vermont.gov/"
          }
      ]
  },
  {
      name: "Virginia",
      link: "http://www.virginia.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.virginiadot.org/"
          },
          {
              name: "Virginia Tech Transportation Infrastructure & Systems Engineering Division",
              link: "https://www.fhwa.dot.gov/exit.cfm?link=http://www.vtti.vt.edu/"
          }
      ]
  },
  {
      name: "Washington",
      link: "http://access.wa.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.wsdot.wa.gov/"
          }
      ]
  },
  {
      name: "West Virginia",
      link: "http://www.wv.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.transportation.wv.gov/"
          }
      ]
  },
  {
      name: "Wisconsin",
      link: "http://www.wisconsin.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://wisconsindot.gov/"
          }
      ]
  },
  {
      name: "Wyoming",
      link: "http://www.wyo.gov/",
      childs: [
          {
              name: "Department of Transportation",
              link: "http://www.dot.state.wy.us/"
          }
      ]
  }
];