<div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div class="bg-overlay"></div>
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-1 mb-4 text-white">
                        <div>
                            <a routerLink="" class="d-inline-block auth-logo"></a>
                        </div>
                        <img src="../../../assets/logo-blue-background-login.png" />
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-6">
                    <div class="card mt-4">
                        <div class="card-body p-4 form-steps">
                            <div class="text-center mt-2">
                                <h3 class="text-primary">Bona Fide Agent Signup</h3>
                            </div>
                            <div class="p-2 mt-4">
                                <div class="row form-header-row">
                                    <h5 class="form-header-text">Company Information</h5>
                                </div>
                                <form [formGroup]="signUpForm">
                                    <div formGroupName="companyInformation">
                                        <div class="row mb-3">
                                            <div class="col-lg-3 padding-top" [ngClass]="righAlignClass">
                                                <label class="form-label">Company Name: <span class="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div class="col-lg-9">
                                                <input type="text" class="form-control" id="name-input" placeholder="" required formControlName="name" [ngClass]="{ 'is-invalid': submitted && formCompanyInformation['name'].errors }">
                                                <div class="invalid-feedback"> Enter name </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-lg-3 padding-top label-column" [ngClass]="righAlignClass">
                                                <label class="form-label">Modes: <span class="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div class="col-lg-9">
                                                <div class="field-checkbox" [ngClass]="{ 'is-invalid': submitted && !this.transpoModes.length }">
                                                    <p-checkbox name="groupname" value="FM" (onChange)="selectPermission()" [(ngModel)]="transpoModes" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox> Final Mile
                                                </div>
                                                <div class="field-checkbox">
                                                    <p-checkbox name="groupname" value="TL" (onChange)="selectPermission()" [(ngModel)]="transpoModes" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox> Truckload
                                                </div>
                                                <div class="invalid-feedback" style="padding-bottom: 10px;"> Pick at least 1 mode </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="p-2 mt-4">
                                <div class="row form-header-row">
                                    <h5 class="form-header-text">User Information</h5>
                                </div>
                                <form [formGroup]="signUpForm">
                                    <div class="row mb-3">
                                        <div class="col-lg-3 padding-top">
                                            <label class="form-label">First Name: <span class="text-danger">*</span>
                                            </label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" class="form-control" id="first-name-input" placeholder="" required formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f['firstName'].errors }">
                                            <div class="invalid-feedback"> Enter first name </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-lg-3 padding-top">
                                            <label class="form-label">Last Name: <span class="text-danger">*</span>
                                            </label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" class="form-control" id="last-name-input" placeholder="" required formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f['lastName'].errors }">
                                            <div class="invalid-feedback"> Enter last name </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-lg-3 padding-top">
                                            <label class="form-label">Title: <span class="opacity-0">*</span>
                                            </label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" class="form-control" id="title-input" placeholder="" formControlName="title">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-lg-3 padding-top">
                                            <label class="form-label">Phone: <span class="text-danger">*</span>
                                            </label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" class="form-control" id="phone-input" placeholder="" required formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f['phone'].errors }">
                                            <div class="invalid-feedback"> Enter phone </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-lg-3 padding-top">
                                            <label class="form-label">Email: <span class="text-danger">*</span>
                                            </label>
                                        </div>
                                        <div class="col-lg-9">
                                            <input type="text" class="form-control" id="email-input" placeholder="" required formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">
                                            <div class="invalid-feedback"> Enter email </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="alert alert-success" role="alert" *ngIf="isSuccess"> A verification message has been sent to your email. </div>
                            <div class="alert alert-danger" role="alert" *ngIf="isFailed"> There is already an account for this user, please use another email address. </div>
                            <div class="row">
                                <button class="btn btn-primary w-100" type="submit" (click)="submitForm()" [disabled]="isLoading">
                                    <span class="flex-grow-1 me-2"> Submit </span>
                                    <span class="spinner-border submit-spinner flex-shrink-0" role="status" *ngIf="isLoading">
                                        <span class="visually-hidden">Loading...</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 text-center">
                        <p class="mb-0">Already have an account? <a routerLink="/login" class="fw-semibold text-primary text-decoration-underline">Login</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-signup-footer></app-signup-footer>
</div>