import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { AccountType } from 'src/app/core/services/models/account.model';
import { ContractModel } from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { AbstractPagesComponent } from '../AbstractPagesComponent';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Contracts'
    }
  ];
  cols!: any[];
  loading!: boolean;
  lazy: false;
  files!: TreeNode<ContractModel>[];
  selectedRow: any;
  subscriptions: Subscription[] = [];
  showData = false;
  accountType: AccountType;
  isUserHasAddPermission: Boolean;
  constructor(
    private router: Router,
    private httpRequest: HttpService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionsService: PermissionsService
  ) {
    super();
    this.accountType = this.authService.currentAccountSelected.accountType;
    this.isUserHasAddPermission = this.permissionsService.isUserHasFeatureAccess("contracts_add");

    this.subscriptions.push(
      this.authService.subscribeToSelectedAccount(async () => {
        this.showData = false;
        return this.loadData();
      })
    )
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'name', header: 'Name', width: '30%' },
      { field: 'senderAccountName', header: 'Sender', width: '30%' },
      { field: 'receiverAccountName', header: 'Receiver', width: '30%' },
      { field: 'status', header: 'Status', width: '10%' },
    ];

    void this.loadData();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  override async loadData() {
    this.httpRequest.getContractList().subscribe((data) => {
      this.files = this.manipulateData(data);
      this.showData = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }, error => {
      this.showData = true;
      console.error('Error: ', error);
    });
  }

  manipulateData(dataFromAPI: ContractModel[]): TreeNode<ContractModel>[] {
    return dataFromAPI.map(data => ({
      data,
      leaf: true
    }));
  }

  goToViewContract(contractId: string): void {
    this.router.navigate([`./contracts/${contractId}`], {
      relativeTo: this.route.parent
    });
  }
}
