import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, combineLatest } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { User } from 'src/app/core/services/models/auth.models';
import { Branch } from 'src/app/core/services/models/branch.model';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model';
import { EquipmentComplianceRequest, EquipmentComplianceRequestPayload } from 'src/app/core/services/models/equipment-compliance.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { UserProfile } from 'src/app/core/services/models/user-profile.model';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import Swal from 'sweetalert2';

export type Sections = 'iframe' | 'form';

@Component({
  selector: 'app-equipment-compliance',
  templateUrl: './equipment-compliance.component.html',
  styleUrls: ['./equipment-compliance.component.scss']
})
export class EquipmentComplianceComponent implements OnInit {
  @Input() showBreadCrumb: boolean = true;
  @Input() displaySection: Sections = 'iframe';

  breadCrumbItems:BreadCrumbItem[] = [
    {label: 'Equipment Compliance'}
  ];

  showIframe: boolean = true;
  showPendingMessage: boolean = false;
  private subscriptions: Subscription[] = [];
  private selectedBranch: (Branch | null);
  equipComReq: EquipmentComplianceRequest;
  companyInfo: CompanyInfo;
  requestForm: FormGroup;
  submitted: boolean = false;
  currentUser: User;
  userProfle?: UserProfile;
  showForm: boolean = true;
  
  constructor(
    private httpRequest: HttpService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.auth.currentUserValue;
    this.subscriptions.push(
      combineLatest([
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([branch]) => {
          if(this.anyNull(branch) || this.allSelectedSame(branch)){
            return;
          }

          this.selectedBranch = branch;

          this.listEquipmentComplianceRequest();
          this.fetchCompanyInfo();
        }
      )
    );
  }

  initForm(companyInfo: CompanyInfo, userInfo: UserProfile) {
    let address = companyInfo.street + ', ' + companyInfo.city + ', ' + companyInfo.state + ', ' + companyInfo.zipCode;
    this.requestForm = this.formBuilder.group({
      userFirstName: [{value: userInfo.firstName, disabled: true}, [Validators.required]],
      userLastName: [{value: userInfo.lastName, disabled: true}, [Validators.required]],
      userRole: [{value: 'Owner', disabled: true}, [Validators.required]],
      companyName: [{value: companyInfo.legalName, disabled: true}, [Validators.required]],
      companyAddress: [{value: address, disabled: true}, [Validators.required]],
      companyPhoneNumber: [{value: companyInfo.phone, disabled: true}, [Validators.required]],
      companyEINNumber: [{value:  companyInfo.einNumber, disabled: true}, [Validators.required]],
      companyDOTNumber: [{value: companyInfo.dotNumber, disabled: true}, [Validators.required]],
      companyMCNumber: [{value: companyInfo.mcNumber, disabled: true}, [Validators.required]],
      companyTotalTrucks: [{value: companyInfo.totalPowerUnits, disabled: true}, [Validators.required]],
      companyTotalTrailers: [{value: companyInfo.totalPowerUnits, disabled: true}, [Validators.required]],
      contactFirstName: [{value: userInfo.firstName, disabled: false}],
      contactLastName: [{value: userInfo.lastName, disabled: false}],
      contactPhone: [{value: userInfo.phone, disabled: false}],
      contactEmail: [{value: userInfo.email, disabled: false}],
    })
  }

  get form() {
    return this.requestForm.controls;
  }

  private anyNull(branch: Branch | null): boolean{
    return !branch;
  }

  private allSelectedSame(branch: Branch | null): boolean{
    return this.selectedBranch?.branchId == branch?.branchId
  }

  redirectToLogin(){
    window.open('https://directtocarrier.ourvf.com/login', '_blank');
  }

  listEquipmentComplianceRequest() {
    this.httpRequest.listEquipmentComplianceRequest(this.selectedBranch?.companyInfoId!).subscribe(
      (res: SuccessApiResponse<any>) => {
        const data = res.data;
        console.log('[Equipment Compliance] data',data);

        if (data.length > 0) {
          this.equipComReq = data[0];
          this.showForm = false;
          return;
        }

        /* if (keys.length === 0) {
          this.showIframe = true;
        } else {
          if(this.equipComReq.status === 'approved') {
            this.showIframe = true;
          } else {
            this.showIframe = false;
            this.showPendingMessage = true;
          }
        } */
      },
      error =>{ }
    );
  }

  private fetchCompanyInfo(): void {
    this.httpRequest.getCompanyInfos(this.selectedBranch?.companyInfoId!).subscribe(
      res => {
        const successRes = <SuccessApiResponse<CompanyInfo>> res;
        this.companyInfo = successRes.data;
        this.httpRequest.getUsersByIdOrEmail(this.currentUser.userId!).subscribe(res => {
          const successRes = <SuccessApiResponse<UserProfile>> res;
          this.userProfle = successRes.data;
          this.initForm(this.companyInfo, this.userProfle);
        })
      },
      error =>{ }
    );
  }
  

  submitRequest() {
    let payload: EquipmentComplianceRequestPayload = {
      "userFirstName": "Ola",
      "userLastName": "Cross",
      "userRole": "Owner",
      "companyName": "Tables by Ola",
      "companyAddress": "244 Ogsi Loop",
      "companyEINNumber": "3032538712899584",
      "companyDOTNumber": "2688188632006656",
      "companyMCNumber": "8045773409222656",
      "companyTotalTrucks": 1,
      "companyTotalTrailers": 2,
      "contactFirstName": "Ola",
      "contactLastName": "Cross",
      "contactPhone": "(645) 930-8418",
      "contactEmail": "teri@ekurocosi.gov"
    }
    this.submitted = true;
    if(this.requestForm.invalid) {
      return;
    } else {
      this.httpRequest.createEquipmentComplianceRequest(
        this.selectedBranch?.companyInfoId!,
        this.requestForm.getRawValue()
      ).subscribe((data: any) => {
        Swal.fire({
          title: 'Success',
          text: 'Successfully created compliance request.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok'
        }).then(result => {
          //do nothing
          window.location.reload();
        });
      })
    }
  }

}
