<div class="btn-group">
    <div>
        <button type="submit" class="btn btn-primary licenses-btn" (click)="openModalBuyLicense()">Buy Licenses</button>
    </div>
    <div>
        <button type="submit" class="btn btn-primary licenses-btn" (click)="openModalContactSales()">Contact Sales</button>
    </div>
    <div *ngIf="loadSpinner">
        <button type="submit" class="btn btn-primary licenses-btn" id="refresh-btn" [disabled]="true">
        <span class="d-flex align-items-center">
            <span class="flex-grow-1 me-2">
                Refresh
            </span>
        <span class="spinner-border flex-shrink-0" role="status" >
            <span class="visually-hidden">Loading...</span>
        </span>
        </span>
    </button>
    </div>
    <div *ngIf="!loadSpinner">
        <button type="submit" class="btn btn-primary" id="refresh-btn" (click)="refresh()">
            Refresh
        </button>
    </div>
</div>

<table *ngIf="auth.currentAccountSelected.accountType === 'carrier-account'"
    class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th scope="col" width="200px" class="padded-spacer">Licenses Types</th>
            <th scope="col" width="100px" class="padded-spacer">Active Assets</th>
            <th scope="col" width="100px" class="padded-spacer">Available Assets</th>
            <th scope="col" width="100px" class="padded-spacer">Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr class="loader-body">
            <td class="bold padded-spacer-content">
                For Ever Free
            </td>
            <td class="placeholder-glow center">
                <span *ngIf="tableLoading;else fef_col_1" else class="placeholder col-2"></span>
                <ng-template #fef_col_1>1</ng-template>
            </td>
            <td class="placeholder-glow center-two">
                <span *ngIf="tableLoading;else fef_col_2" else class="placeholder col-2"></span>
                <ng-template #fef_col_2>0</ng-template>
            </td>
            <td class="center">
                <button class="btn btn-primary btn-sm" [disabled]="((availableForEverFreeLicenses.length || activeForEverFreeLicenses.length) && !tableLoading) ? false : true" (click)="openModalLicensesList('For Ever Free')">Edit</button>
            </td>
        </tr>
        <tr class="loader-body">
            <td class="bold padded-spacer-content">For Ever Free With Compliance</td>
            <td class="placeholder-glow center">
                <span *ngIf="tableLoading;else twc_col_1" else class="placeholder col-2"></span>
                <ng-template #twc_col_1>1</ng-template>
            </td>
            <td class="placeholder-glow center-two">
                <span *ngIf="tableLoading;else twc_col_2" else class="placeholder col-2"></span>
                <ng-template #twc_col_2>0</ng-template>
            </td>
            <td class="center">
                <button class="btn btn-primary btn-sm" [disabled]="((availableTrialWithComplianceLicenses?.length || activeTrialWithComplianceLicenses?.length) && !tableLoading) ? false : true" (click)="openModalLicensesList('Trial With Compliance')">Edit</button>
            </td>
        </tr>
        <tr class="loader-body">
            <td class="bold padded-spacer-content">Pre Sale</td>
            <td class="placeholder-glow center">
                <span *ngIf="tableLoading;else ps_col_1" else class="placeholder col-2"></span>
                <ng-template #ps_col_1>{{availablePreSaleLicenses?.length}}</ng-template>                
            </td>
            <td class="placeholder-glow center-two">
                <span *ngIf="tableLoading;else ps_col_2" else class="placeholder col-2"></span>
                <ng-template #ps_col_2>{{availablePowerUnits}}</ng-template>  
            </td>
            <td class="center">
                <button class="btn btn-primary btn-sm" [disabled]="((availablePreSaleLicenses?.length || activePreSaleLicenses?.length) && !tableLoading) ? false : true" (click)="openModalLicensesList('Pre Sale')">Edit</button>
                <button class="btn btn-primary btn-sm ml-1" [disabled]="((availablePreSaleLicenses?.length || activePreSaleLicenses?.length) && !tableLoading) ? false : true" (click)="upgrade('pre_sale')">Upgrade</button>
            </td>
        </tr>

        <tr class="loader-body">
            <td class="bold padded-spacer-content">For Ever</td>
            <td class="placeholder-glow center">
                <span *ngIf="tableLoading;else fe_col_1" else class="placeholder col-2"></span>
                <!-- <ng-template #fe_col_1>{{availableForEverLicenses?.length}}</ng-template> -->
                <ng-template #fe_col_1>0</ng-template>
            </td>
            <td class="placeholder-glow center-two">
                <span *ngIf="tableLoading;else fe_col_2" else class="placeholder col-2"></span>
                <!-- <ng-template #fe_col_2>{{activeForEverLicenses?.length}}</ng-template> -->
                <ng-template #fe_col_2>{{availablePreSaleLicenses?.length}}</ng-template> 
            </td>
            <td class="center">
                <button class="btn btn-primary btn-sm" [disabled]="((availableForEverLicenses?.length || activeForEverLicenses?.length) && !tableLoading) ? false : true" (click)="openModalLicensesList('ForEver')">Edit</button>
            </td>
        </tr>

        <!--
        <tr class="loader-body">
            <td class="placeholder-glow bold padded-spacer-content">Paid</td>
            <td class="placeholder-glow center">
                <span *ngIf="tableLoading;else p_col_1" else class="placeholder col-2"></span>
                <ng-template #p_col_1>{{0}}</ng-template>
            </td>
            <td class="placeholder-glow center-two">
                <span *ngIf="tableLoading;else p_col_2" else class="placeholder col-2"></span>
                <ng-template #p_col_2>{{0}}</ng-template>            
            </td>
            <td class="center">
                <button class="btn btn-primary btn-sm" [disabled]="((availablePaidLicenses?.length || activePaidLicenses?.length) && !tableLoading) ? false : true" (click)="openModalLicensesList('Paid')">Edit</button>
            </td>
        </tr>
        -->
    </tbody>
</table>

<table *ngIf="auth.currentAccountSelected.accountType === 'shipper-account' || auth.currentAccountSelected.accountType === 'broker-account'"
    class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th scope="col" width="200px" class="padded-spacer"></th>
            <th scope="col" width="100px" class="type-td">Assets</th>
            <th scope="col"></th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="bold padded-spacer-content">
                Available
            </td>
            <td class="center">
                {{trucks}}
            </td>
            <td class="center-two">
            </td>
        </tr>
        <tr>
            <td class="bold padded-spacer-content">Available For Refund</td>
            <td class="center">{{availableForRefund}}</td>
            <td class="center-two"></td>
        </tr>
        <tr>
            <td class="bold padded-spacer-content">Active On Load Board</td>
            <td class="center">{{driverOnlyActiveLoadOnBoard}}</td>
            <td class="center-two"></td>
        </tr>
        <tr>
            <td class="bold padded-spacer-content">Active with Carriers</td>
            <td class="center">{{driverOnlyActive}}</td>
            <td class="center-two"></td>
        </tr>
    </tbody>
</table>