<app-breadcrumbs *ngIf="isRouted" [showDefault]="false">
    <span class="title-part" (click)="returnBranch()">Branches</span>
    <ng-container *ngFor="let data of branchesBreadCrumbItems; index as i">
        <span class="title-separator">&nbsp;>&nbsp;</span>
        <span class="title-part" (click)="returnBranch(data)" *ngIf="data.node.data">{{ data.node.label }}</span>
    </ng-container>
</app-breadcrumbs>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row g-4 mb-3">
                    <div class="col-sm-auto">
                        <div>
                            <button type="button" class="btn btn-primary add-btn" id="create-btn" (click)="addBranches({})">
                                <i class="ri-add-line align-bottom me-1"></i> Add
                            </button>
                        </div>
                    </div>
                    <div class="col-sm">
                        <div class="d-flex justify-content-sm-end">
                            <div class="search-box ms-2">
                                <input pInputText type="text" class="form-control search" placeholder="Search..." (input)="tt.filterGlobal(getEventValue($event), 'contains')">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <p-treeTable #tt [value]="files" [columns]="cols" [lazy]="lazy" [scrollable]="true" [resizableColumns]="true" (onLazyLoad)="getBranches()"
                        columnResizeMode="expand" styleClass="p-treetable-sm no-select"
                        class="col-md-4"
                    >
                        <ng-template pTemplate="colgroup" let-columns>
                            <colgroup>
                                <col *ngFor="let col of columns" [style.width]="col.width">
                            </colgroup>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" ttResizableColumn>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {{col.header}}
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                            <tr *ngIf="isVisibleRow(rowNode)"
                                on-mouseover="hoveredRowData=rowData" on-mouseleave="hoveredRowData=undefined" (dblclick)="drillDown(rowNode)"
                            >
                                <td *ngFor="let col of columns; let i = index" [ngClass]="{'p-toggler-column': i === 0}">
                                    <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
                                    <i class="ri-dashboard-2-line branch-type-icon v-align" [ngClass]="branchIcons[rowData.type]"></i>&nbsp;
                                    <span class="can-select v-align"
                                    (click)="editBranches(rowData, rowNode)"
                                    >
                                    <a style="text-decoration: underline !important; color: #405189; cursor: pointer;">{{col.field === 'name' ? rowNode.node.label : rowData[col.field]}}</a>
                                    </span>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-sm-1">
                                            <div class="add" [hidden]="rowData.type == 'store' || rowData.type == 'remote-store' || rowData.type == '3pl-cross-dock'">
                                                <button class="btn btn-sm btn-primary" (click)="addBranches(rowData)">
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-treeTable>
                    <!-- <app-trimble-map
                        #trimbleMap
                        class="col-md-8"
                        [mapOptions]="trimbleMapOptions"
                        [sources]="geoJsonDataSources"
                        [layers]="mapLayers"
                        [listeners]="mapListeners"
                    ></app-trimble-map> -->
                    <div class="col-md-8" style="height: 620px;">
                        <app-google-map
                            [mapPoints]="googleMapData">
                        </app-google-map>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #addBranchModal role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="!editBranchBool">Add Branch</h5>
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="editBranchBool">Edit Branch</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="cancel()"></button>
    </div>
    <form [formGroup]="branchForm">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="typeInput" class="form-label">Type: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <ng-select class="custom form-control" [items]="shipperCompanyBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="rootAccountType == 'shipper-company'"></ng-select>
                    <ng-select class="custom form-control" [items]="carrierCompanyBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="rootAccountType == 'carrier-company'"></ng-select>
                    <ng-select class="custom form-control" [items]="hqBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="rootAccountType == 'hq'"></ng-select>
                    <ng-select class="custom form-control" [items]="subsidiaryBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="rootAccountType == 'subsidiary'"></ng-select>
                    <ng-select class="custom form-control" [items]="organizationBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="rootAccountType == 'organization'"></ng-select>
                    <ng-select class="custom form-control" [items]="regionBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="rootAccountType == 'region'"></ng-select>
                    <ng-select class="custom form-control" [items]="dcHubCrossDockBranchTypes" bindLabel="prettyName" bindValue="technicalName" formControlName="type" [ngClass]="{ 'is-invalid': submitted && form['type'].errors }" *ngIf="rootAccountType == 'dc' || rootAccountType == 'hub' || rootAccountType == '3ol-cross-dock' || rootAccountType == 'cross-dock'"></ng-select>
                    <div *ngIf="submitted && form['type'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['type'].errors['required']">Enter Type</div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="numberInput" class="form-label">Number: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="number-field" class="form-control" required formControlName="number" [ngClass]="{ 'is-invalid': submitted && form['number'].errors }" (input)="onNumberChange($event)" />
                    <div *ngIf="submitted && form['number'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['number'].errors['required']">Enter Number</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="numberInput" class="form-label">Name: <span class="opacity-0">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p id="name-value">{{ branchForm.controls['name'].value }}</p>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-lg-3 padding-top text-align-right label-column">
                    <label class="form-label">Modes: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <div class="field-checkbox" [ngClass]="{ 'is-invalid': submitted && !this.transpoModes.length }">
                        <div class="row">
                            <div class="col-lg-4">
                                <p-checkbox name="groupname" value="fm"
                                    [(ngModel)]="transpoModes" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    Final Mile
                            </div>
                            <div class="col-lg-4">
                                <select *ngIf="findTranspoMode('fm')" class="form- form-select mb-3">
                                    <option value="Zones">Zones</option>
                                    <option value="Lanes">Lanes</option>
                                    <option value="Zones + Lanes">Zones + Lanes</option>
                                </select>
                            </div>
                            <div class="col-lg-4"></div>
                        </div>
                    </div>
                    <div class="field-checkbox padding-top">
                        <div class="row">
                            <div class="col-lg-4">
                                <p-checkbox name="groupname" value="ftl"
                                    [(ngModel)]="transpoModes" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    Full Truckload
                            </div>
                            <div class="col-lg-4">
                                <select *ngIf="findTranspoMode('ftl')" class="form- form-select mb-3">
                                    <option value="Zones">Zones</option>
                                    <option value="Lanes">Lanes</option>
                                    <option value="Zones + Lanes">Zones + Lanes</option>
                                </select>
                            </div>
                            <div class="col-lg-4"></div>
                        </div>
                    </div>
                    <div class="field-checkbox padding-top">
                        <div class="row">
                            <div class="col-lg-4">
                                <p-checkbox name="groupname" value="ltl"
                                    [(ngModel)]="transpoModes" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    Less Than Truckload
                            </div>
                            <div class="col-lg-4">
                                <select *ngIf="findTranspoMode('ltl')" class="form- form-select mb-3">
                                    <option value="Zones">Zones</option>
                                    <option value="Lanes">Lanes</option>
                                    <option value="Zones + Lanes">Zones + Lanes</option>
                                </select>
                            </div>
                            <div class="col-lg-4"></div>
                        </div>
                    </div>
                    <div class="field-checkbox padding-top">
                        <div class="row">
                            <div class="col-lg-4">
                                <p-checkbox name="groupname" value="drayage"
                                    [(ngModel)]="transpoModes" ngDefaultControl [ngModelOptions]="{standalone: true}"></p-checkbox>
                                    Drayage
                            </div>
                            <div class="col-lg-4">
                                <select *ngIf="findTranspoMode('drayage')" class="form- form-select mb-3">
                                    <option value="Zones">Zones</option>
                                    <option value="Lanes">Lanes</option>
                                    <option value="Zones + Lanes">Zones + Lanes</option>
                                </select>
                            </div>
                            <div class="col-lg-4"></div>
                        </div>
                    </div>
                    <div class="invalid-feedback" style="padding-bottom: 10px;">
                        Pick at least 1 mode
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label class="form-label">Address: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <app-google-maps-search-box (onPlaceChanged)="onAddressChange($event)"></app-google-maps-search-box>
                    <br/>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="contactNameInput" class="form-label">Contact Name: <span class="opacity-0">*</span></label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="contactName-field" class="form-control" formControlName="contactName" />
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="contactPhoneInput" class="form-label">Contact Phone: <span class="opacity-0">*</span></label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="contactPhone-field" class="form-control" formControlName="contactPhone" />
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-lg-3 label-form text-align-right">
                    <label for="contactEmailInput" class="form-label">Contact Email: <span class="opacity-0">*</span></label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="contactEmail-field" class="form-control" formControlName="contactEmail" />
                </div>
            </div>
        </div>
        <div class="modal-footer" id="add-service-area-md-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>

                <button type="submit" class="btn btn-primary" (click)="addBranch('parent')" *ngIf="!selectedNode && !editBranchBool">
                    Save
                </button>
                <button type="submit" class="btn btn-primary" (click)="addBranch('child')" *ngIf="selectedNode && !editBranchBool">
                    Save
                </button>
                <button type="submit" class="btn btn-primary" (click)="editBranch()" *ngIf="!selectedNode && editBranchBool">
                    Save
                </button>
            </div>
        </div>
    </form>
</ng-template>
