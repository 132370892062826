import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { TreeNode } from 'primeng/api';
import { finalize, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { ServiceArea, ServiceAreaPayload, ServiceAreaZone } from "src/app/core/services/models/service-areas.model";
import { generateZoneColor, isStrArrayEqual, removeFromArray, truthyFilter } from 'src/app/core/utils/commons';
import { BranchesService, TreeNodeBranch } from 'src/app/pages/origin-points/origin-point/branches.service';
import { InputZipCodeGroup, MapboxZipcodePickerComponent, ZipCodeGroup } from 'src/app/shared/mapbox-zipcode-picker/mapbox-zipcode-picker.component';
import Swal from 'sweetalert2';
import { getLatitudesLongitudesCenterPoint, getZipCodeBoundaryExists, getZipCodeChildren, isValidZipCode } from 'src/app/core/utils/zip-code-utils';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { MapsDataSource, TrimbleMapsHelper } from 'src/app/core/services/models/trimble-maps.helper';
import { Expression } from 'mapbox-gl';
import tinycolor from "tinycolor2";
import { HeatmapHelper } from 'src/app/core/services/models/heatmap-helper';
import { MapboxHeatmapComponent, ZipCodeCounts } from 'src/app/shared/mapbox-heatmap/mapbox-heatmap.component';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { PostalCode } from 'src/app/core/services/models/postal-code.model';
import { AccountPlan, AccountPlanStatus } from 'src/app/core/services/models/account-plan.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBuyLicenses } from '../../../licenses/modals/buy-licenses/buy-licenses.component';

export enum TabEnum {
  SERVICE_AREA,
  ZONES,
  UNUSED,
  DATA,
};

export type ServiceAreaAndDefaultZoneId = {serviceArea: ServiceArea, defaultZoneId?: string}

type ServiceAreaZoneForm = ServiceAreaZone & {color?: string; uiId: string}
type ServiceAreaForm = (Partial<Omit<ServiceArea, 'zones'>> & Required<Pick<ServiceArea, 'postalCodes'>>) & {
  zones: ServiceAreaZoneForm[];
};

const SERVICE_AREA_GROUP_ID = 'service-area';
const ZONE_NAMES_SOURCE = 'zone-names-source';
const ZONE_NAMES_LAYER = 'zone-names-layer';
const UNUSED = 'unused';

function *generateZoneId(): Generator<string, string, string> {
  let id = 0;
  while (true) {
    yield ++id + '';
  }
}

@Component({
  selector: 'app-service-area-modal',
  templateUrl: './service-area-modal.component.html',
  styleUrls: ['./service-area-modal.component.scss']
})
export class ServiceAreaModalComponent implements OnInit, AfterViewInit {
  readonly separatorExp = /,| |\n|\r\n/;
  readonly unusedId = UNUSED;
  radiusMile: number = 50;
  radiusZipCode?: string;
  groupOpacity = 0.9;

  showHeatMap = false;
  newServiceArea = true;

  isZoneNamesLayerCreated = false;
  isZonesFetched = false;
  isMapsLoaded = false;

  zoneUiIdGenerator = generateZoneId();
  formError: Partial<{
    serviceAreaName: 'required' | 'non-unique'
  }> = {};

  private _origServiceArea?: ServiceArea;
  get origServiceArea(): ServiceArea | undefined {
    return this._origServiceArea;
  }

  heatmapHelper = new HeatmapHelper();
  unusedZipCodes: string[] = [];
  unusedZipCodesForm = {
    zoneName: 'Unused Zips',
    name: 'Unused Zips',
    zoneId: '',
    postalCodes: this.unusedZipCodes,
    uiId: UNUSED
  } as ServiceAreaZoneForm;

  @Input()
  refServiceAreas: ServiceArea[]; // will be used to validate when saving this service area

  private defaultZoneId?:string;

  private _serviceAreaForm: ServiceAreaForm;
  @Input('serviceAreaAndActiveZoneId')
  set serviceAreaAndActiveZoneId(serviceAreaAndActiveZoneId: ServiceAreaAndDefaultZoneId | undefined) {
    this.defaultZoneId = serviceAreaAndActiveZoneId?.defaultZoneId;
    this.serviceArea = serviceAreaAndActiveZoneId?.serviceArea;
  }

  set serviceArea(serviceArea: ServiceArea | undefined){
    this.submitted = false;
    this._origServiceArea = serviceArea && JSON.parse(JSON.stringify(serviceArea)) ;
    this._serviceAreaForm = {
      ...(serviceArea || {}),
      postalCodes: (serviceArea || {}).postalCodes || [],
      zones: serviceArea ? [] : [{
        name: 'Zone 1',
        zoneName: 'Zone 1',
        postalCodes: [] as string[],
        zoneId: '',
        serviceAreaId: '',
        uiId: this.zoneUiIdGenerator.next().value
      }],
      allowPOBoxes: true,
      showPOBoxes: true,
      autoSelectPOBoxes: true
    };

    if (serviceArea) {
      void this.initZones(serviceArea)
        .then(() => {
          this.isZonesFetched = true;
          this.selectedZone = this.getDefaultServiceAreaZoneForm();
          this.recomputeUnusedZipCodes();
          this.setZipCodeGroups();
          if (!this.isZoneNamesLayerCreated) {
            this.createZoneNamesLayer();
          }
        });
    } else {
      this.selectedZone = this.getDefaultServiceAreaZoneForm();
      this.isZonesFetched = true;
      if (!this.isZoneNamesLayerCreated) {
        this.createZoneNamesLayer();
      }
    }
    this.initBranches();
        // this.initServiceAreaForm();
  }

  getDefaultServiceAreaZoneForm(): ServiceAreaZoneForm {
    return this._serviceAreaForm.zones.find(zone => zone.zoneId === this.defaultZoneId) ?? this._serviceAreaForm.zones[0];
  }

  get serviceAreaForm(): ServiceAreaForm {
    return this._serviceAreaForm;
  }
  get serviceAreaZipCodesForm(): string[] {
    return this._serviceAreaForm.postalCodes;
  }
  set serviceAreaZipCodesForm(zipCodes: string[]) {
    this._serviceAreaForm.postalCodes = zipCodes;
  }
  recomputeUnusedZipCodes(): void {
    if (this.getNewServiceArea()) {
      this.unusedZipCodes = [];
    } else {
      const usedZipCodes = this.serviceAreaForm.zones.map(zone => zone.postalCodes).flat();
      this.unusedZipCodes = this.serviceAreaZipCodesForm?.filter(zip => !usedZipCodes.includes(zip)).sort((z1, z2) => +z1 - +z2) || [];
    }
  }
  get selectedZipCodes(): string[] | undefined {
    switch (this.activeTab) {
      case TabEnum.SERVICE_AREA:
        return this.serviceAreaZipCodesForm?.sort((z1, z2) => +z1 - +z2);
      case TabEnum.ZONES:
        if (this.selectedZone?.uiId !== UNUSED) {
          this.togleReadOnlyZipChipsInput(false);
          return this.selectedZone?.postalCodes?.sort((z1, z2) => +z1 - +z2);
        }
        this.togleReadOnlyZipChipsInput(true);
        return this.unusedZipCodes;
      case TabEnum.UNUSED:
        return this.unusedZipCodes;
      default:
        return [];
    }
  }
  set selectedZipCodes(zipCodes: string[] | undefined) {
    switch (this.activeTab) {
      case TabEnum.SERVICE_AREA:
        this.serviceAreaZipCodesForm = zipCodes || [];
        break;
      case TabEnum.ZONES:
        if (this.selectedZone) {
          const validZipCodes = zipCodes?.filter(zipCode => this.serviceAreaZipCodesForm.includes(zipCode)) // remove zipcodes that are not in service area zipcodes
          const selectedZoneId = this.getZipCodeGroupId(this.selectedZone);
          // de-select from previous zones
          validZipCodes?.forEach(zipCode => {
            // if in other group, remove
            const prevZone = this._serviceAreaForm.zones.find(z => this.getZipCodeGroupId(z) !== selectedZoneId && z.postalCodes?.includes(zipCode));
            if (prevZone) {
              prevZone.postalCodes = removeFromArray(prevZone.postalCodes, (z) => z === zipCode);
            }
          });

          this.selectedZone.postalCodes = validZipCodes || [];
        };
        break;
      default:
        // nothing
    }
    this.setZipCodeGroups();
  }

  private _zipCodeGroups: InputZipCodeGroup[] = [];
  setZipCodeGroups(): void {
    this.updateFirstZone();
    switch (this.activeTab) {
      case TabEnum.SERVICE_AREA:
        this._zipCodeGroups = [{
          zipCodes: this.serviceAreaForm.postalCodes,
          id: SERVICE_AREA_GROUP_ID
        }];
        break;
      case TabEnum.ZONES:
        this._zipCodeGroups = this.serviceAreaForm.zones.map((zone, index) => {
          if (!zone.color) {
            zone.color = generateZoneColor(index);
          }
          return {
            zipCodes: zone.postalCodes,
            id: this.getZipCodeGroupId(zone),
            color: zone.color,
          };
        });
        break;
      case TabEnum.UNUSED:
        this._zipCodeGroups = [{
          zipCodes: this.unusedZipCodes,
          id: SERVICE_AREA_GROUP_ID
        }];
        break;
      default:
        this._zipCodeGroups = [];
    }
    // console.log('[ServiceAreaModal] [setZipCodeGroups]', this.zipCodeGroups);
    this.recomputeUnusedZipCodes();
  }

  updateFirstZone() {
    if (!this.getNewServiceArea()) {
      return;
    }
    this.serviceAreaForm.zones[0].postalCodes = JSON.parse(JSON.stringify(this.serviceAreaForm.postalCodes));
  }

  get zipCodeGroups(): InputZipCodeGroup[] {
    return this._zipCodeGroups;
  }

  set zipCodeGroups(zipCodeGroups: InputZipCodeGroup[]) {
    if (this.activeTab === TabEnum.SERVICE_AREA) {
      this.serviceAreaForm.postalCodes = [...zipCodeGroups[0].zipCodes];
      this.recomputeUnusedZipCodes();
    } else if (this.activeTab === TabEnum.UNUSED) {
      this.serviceAreaForm.postalCodes = [...zipCodeGroups[0].zipCodes];
    } else if (this.activeTab === TabEnum.ZONES) {
      this._serviceAreaForm = {
        ...this._serviceAreaForm,
        zones: this._serviceAreaForm.zones.map(zone => {
          const groupId = this.getZipCodeGroupId(zone);
          const group = zipCodeGroups.find(g => g.id === groupId);
          if (!group) {
            console.warn('[ServiceAreaModal] [set zipCodeGroups] - group not found for zone', zone);
            return undefined;
          }
          return {
            ...zone,
            postalCodes: group.zipCodes,
            color: group.color,
          };
        }).filter(truthyFilter)
      };
      if (this.selectedZone) {
        const selectedZoneId = this.getZipCodeGroupId(this.selectedZone);
        this.selectedZone = this._serviceAreaForm.zones.find(z => this.getZipCodeGroupId(z) === selectedZoneId);
        this.focusOnZipChipsInput();  // need this, otherwise the zip codes chips list is not updating/reflecting on the UI
      }
    }
    this.recomputeUnusedZipCodes();
    this._zipCodeGroups = zipCodeGroups;
  }

  get selectableZipCodes(): string[] | undefined {
    const zipCodes = this.activeTab === this.tabEnum.SERVICE_AREA ? undefined :
      this.activeTab === this.tabEnum.UNUSED ? this.unusedZipCodes :
      this.serviceAreaForm.postalCodes;
    return zipCodes;
  }

  get focusedGroupId(): string | undefined {
    const id = this.activeTab === TabEnum.SERVICE_AREA ? undefined : this.selectedZone?.zoneId || this.selectedZone?.zoneName;
    // console.log(`[ServiceAreaModal] [focusedGroupId] - ${id}`, this.activeTab);
    return id;
  }

  *generateZoneId() {
    let id = 0;
    yield ++id;
  }

  saveLoading = false;
  submitted = false;
  // serviceAreaForm!: FormGroup;
  selectedBranches: TreeNode<TreeNodeBranch>[] = [];
  branches: TreeNode<TreeNodeBranch>[] = [];
  focusedZipCode = '';

  private _activeTab: TabEnum = TabEnum.SERVICE_AREA;

  get activeTab(): TabEnum{
    return this._activeTab;
  }

  @Input()
  set activeTab(tab: TabEnum){
    this._activeTab = tab;
  }

  readonly tabEnum = TabEnum;
  @ViewChild('mapbox')
  mapbox: MapboxHeatmapComponent;

  // zones
  selectedZone?: ServiceAreaZoneForm;
  currentEditZoneIndex?: number;
  currentEditZoneError = '';
  currentEditZoneSave = false;
  accountPlanStatus: AccountPlanStatus;

  @Input('modal')
  modal: NgbModalRef;

  subscriptions: Subscription[] = [];
  currentAccountPlan: AccountPlan;
  isPaidPlan: boolean;

  constructor(
    private authService: AuthService,
    private httpRequest: HttpService,
    private clipboard: Clipboard,
    private el: ElementRef,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.initBranches();
    this.setZipCodeGroups();
    this.heatmapHelper.setServiceType(this.heatmapHelper.selectedMapTab);
    this.subscriptions.push(
      this.authService.currentAccountPlanSubject.subscribe(
        accountPlan => {
          this.currentAccountPlan = accountPlan;
          this.isPaidPlan = this.currentAccountPlan.status === 'paid';
        }
      )
    );
    this.accountPlanStatus = this.authService.currentAccountPlanValue.status;
    // this.initServiceAreaForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit(){
    this.focusOnZipChipsInput();
  }

  readonly EMPTY_ZIPCODE_COUNTS = {
    counts: {},
    max: 0,
    total: 0
  };

  get zipCodeCounts(): ZipCodeCounts {
    if (this.showHeatMap) {
      return this.heatmapHelper.zipCodesCount;
    }

    return this.EMPTY_ZIPCODE_COUNTS;
  }

  getLeftMenuZones(): ServiceAreaZoneForm[] {
    return [this.unusedZipCodesForm, ...this.serviceAreaForm.zones];
  }

  focusOnZipChipsInput(): void {
    const inputElement = <HTMLElement>this.el.nativeElement
      .querySelector('#zip-chips .p-chips-input-token input');
    inputElement.click();
  }

  togleReadOnlyZipChipsInput(readOnly: boolean): void {
    const inputElement = <HTMLInputElement>this.el.nativeElement
      .querySelector('#zip-chips .p-chips-input-token input');
    inputElement.readOnly = readOnly;
  }

  // get serviceAreaFormControls() {
  //   return this.serviceAreaForm.controls;
  // }

  private initBranches(): void {
    this.httpRequest.getBranchesByHierarchy()
      .subscribe(res => {
        this.branches = BranchesService.getRecursiveTreeNodeBranch(res.data);
        const selectedBranchIds = this._origServiceArea?.branches?.map(branch => branch.branchId!) || [];
        this.selectedBranches = BranchesService.getSelectedTreeNodeBranch(selectedBranchIds, this.branches);
        this.branches.forEach((branch) => this.expandChildren(branch));
      });
  }

  private initZones(serviceArea: ServiceArea): Promise<void> {
    return this.httpRequest.getServiceAreaZones(serviceArea.serviceAreaId || '').pipe(
      map(zones => {
        this._origServiceArea!.zones = JSON.parse(JSON.stringify(zones));
        this._serviceAreaForm = {
          ...this._serviceAreaForm,
          zones: zones.map(z => ({
            ...z,
            uiId: z.zoneId || this.zoneUiIdGenerator.next().value
          })),
          allowPOBoxes: serviceArea.allowPOBoxes,
          showPOBoxes: serviceArea.showPOBoxes,
          autoSelectPOBoxes: serviceArea.autoSelectPOBoxes
        };
      })
    ).toPromise();
  }

  // private initServiceAreaForm() {
  //   this.submitted = false;
  //   this.serviceAreaForm = this.formBuilder.group({
  //     name: [this._serviceArea?.name || '', [Validators.required]],
  //     postalCodes: [[], [Validators.required]],
  //   });
  //   // this.selectedBranches = [];
  //   // this.branches.forEach((branch) => this.expandChildren(branch));
  // }

  private expandChildren(node:TreeNode){
    if(node.children){
      node.expanded=true;
      for(let cn of node.children){
        this.expandChildren(cn);
      }
    }
  }

  setActiveTab(tab: TabEnum): void {
    this.activeTab = tab;
    if (tab === TabEnum.SERVICE_AREA || tab === TabEnum.UNUSED) {
      this.groupOpacity = 0.9;
    } else {
      this.groupOpacity = 0.25;
    }
    this.setZipCodeGroups();

    this.togleReadOnlyZipChipsInput(this.activeTab === TabEnum.UNUSED);

    if (this.activeTab === TabEnum.ZONES) {
      if (this.isZoneNamesLayerCreated) {
        this.mapbox?.mapBox?.map?.setLayoutProperty(ZONE_NAMES_LAYER, 'visibility', 'visible');
      } else {
        this.createZoneNamesLayer();
      }
    } else {
      this.mapbox?.mapBox?.map?.setLayoutProperty(ZONE_NAMES_LAYER, 'visibility', 'none');
    }
  }

  getZipCodeGroupId(zone: ServiceAreaZone): string {
    return zone.zoneId || zone.zoneName || zone.name;
  }

  validateForm(): boolean {
    const validServiceAreaName = this.validateServiceAreaName();
    return validServiceAreaName;
  }

  validateServiceAreaName(): boolean {
    if (!this.serviceAreaForm.name?.trim()) {
      this.formError.serviceAreaName = 'required';
      return false;
    }
    if (!this.isUniqueServiceAreaName()) {
      this.formError.serviceAreaName = 'non-unique';
      return false;
    }

    delete this.formError.serviceAreaName;
    return true;
  }

  isUniqueServiceAreaName(): boolean {
    return !(this.refServiceAreas || []).find(sa => sa.name?.trim() === this.serviceAreaForm.name?.trim() && sa.serviceAreaId !== this.serviceAreaForm.serviceAreaId);
  }

  submitServiceArea() {
    this.submitted = true;
    if(!this.validateForm() || this.selectedBranches.length === 0){
      return;
    }
    this.saveLoading = true;

    const serviceArea$ = this._origServiceArea ?
      this.updateServiceArea(this._serviceAreaForm as ServiceArea) :
      this.addServiceArea();

    serviceArea$.subscribe(savedServiceArea => {
      this.submitZones(savedServiceArea.serviceAreaId || '').subscribe(savedZones => {
        this.serviceArea = {
          ...savedServiceArea,
          zones: savedZones
        };
        this.saveLoading = false;
        this.modal.close('SAVE');
      }, error => {
        this.saveLoading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to update service area zone: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      });
    }, error => {
      this.saveLoading = false;
      Swal.fire({
        title: 'Error',
        text: 'Failed to update service area: ' + error.error.reason,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      }).then((result) => {
        //do nothing
      });
    })
  }

  submitZones(serviceAreaId: string): Observable<ServiceAreaZone[]> {
    // const serviceAreaId = this.serviceAreaForm.serviceAreaId;
    // if (!serviceAreaId) {
    //   throw new Error('Unable to add zones, need to save service area first');
    // }

    // check zones to create/update/delete
    const createZones: ServiceAreaZone[] = this.serviceAreaForm.zones.filter(z => !z.zoneId);
    const updateZones: ServiceAreaZone[] = [];
    const deleteZones: ServiceAreaZone[] = [];

    this._origServiceArea?.zones?.forEach(origZone => {
      const formZone = this.serviceAreaForm.zones.find(z => z.zoneId === origZone.zoneId);
      if (!formZone) {
        deleteZones.push(origZone);
        return;
      }

      // check difference in origZone vs formZone
      if (origZone.zoneName !== formZone.zoneName || !isStrArrayEqual(origZone.postalCodes || [], formZone.postalCodes || [])) {
        updateZones.push(formZone);
        return;
      }
    });

    const submitZones$ = [
      ...createZones.map(z => this.addZone(z, serviceAreaId)),
      ...updateZones.map(z => this.updateZone(z)),
      ...deleteZones.map(z => this.httpRequest.deleteZones(z.zoneId).pipe(map(result => undefined)))
    ];

    if (submitZones$.length === 0) {
      return of([]);
    }

    return forkJoin(submitZones$).pipe(
      map(results => {
        return results.filter(truthyFilter);
      })
    );
  }

  private addZone(zone: ServiceAreaZone, serviceAreaId: string): Observable<ServiceAreaZone> {
    return this.httpRequest.addZone({
      serviceAreaId,
      name: zone.zoneName || zone.name,
      postalCodes: zone.postalCodes
    });
  }

  private updateZone(zone: ServiceAreaZone): Observable<ServiceAreaZone> {
    return this.httpRequest.updateZone(zone.zoneId, {
      serviceAreaId: zone.serviceAreaId,
      name: zone.zoneName || zone.name,
      postalCodes: zone.postalCodes
    });
  }

  private updateServiceArea(serviceArea: ServiceArea): Observable<ServiceArea> {
    // TODO: validate
    return this.httpRequest.updateServiceArea(
      serviceArea.serviceAreaId!,
      {
        ...this.serviceAreaForm as ServiceArea,
        accountId: this.authService.currentAccountSelected.accountId,
        // postalCodes: this.serviceAreaForm.value.postalCodes.map((postalCode:string) => postalCode.trim()),
        branches: this.selectedBranches.map(branch => branch.data!.branchId),
        // zones: serviceArea.zones
      } as ServiceAreaPayload
    );
  }

  private addServiceArea(): Observable<ServiceArea> {
    return this.httpRequest.addServiceArea(
      {
        ...this.serviceAreaForm as ServiceArea,
        // postalCodes: this.serviceAreaForm.value.postalCodes.map((postalCode:string) => postalCode.trim()),
        branches: this.selectedBranches.map(branch => branch.data!.branchId),
        modeId: this.authService.selectedModeSubject.value?.modeId,
      } as ServiceAreaPayload
    );
  }

  deleteServiceArea(){
    if (!this._origServiceArea || !this._origServiceArea.serviceAreaId) {
      return; // nothing to delete
    }

    Swal.fire({
      title: 'Are you Sure?',
      text: 'Are you sure you want to delete ' + this._origServiceArea.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (!result.value) {
        return;
      }

      this.saveLoading = true;
      this.httpRequest.deleteServiceArea(this._origServiceArea!.serviceAreaId!)
      .pipe(
        finalize(()=>this.saveLoading = false)
      ).subscribe(
        res => {
          this.modal.close('DELETE');
        },
        error => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to delete service area: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
    });
  }

  onAddZipCodes(event: { value: string }) {
    if (!isValidZipCode(event.value)) {
      this.selectedZipCodes = [
        ...new Set(this.selectedZipCodes?.filter(zipCode => zipCode !== event.value) ?? this.selectedZipCodes)
      ];
    } else if (this.serviceAreaForm.allowPOBoxes && this.serviceAreaForm.autoSelectPOBoxes) {
      const zipCodeChildren = getZipCodeChildren(event.value);
      if (zipCodeChildren && this.selectedZipCodes) {
        this.selectedZipCodes = [
          ...new Set([...this.selectedZipCodes, ...zipCodeChildren])
        ];
      }
    }
    this.fitBounds();
  }

  onRemoveZipCodes() {
    this.fitBounds();
  }

  onClickZipCode(event: { value: string }): void {
    if (!event || !event.value) {
      return;
    }
    this.mapbox?.mapBox?.fitBounds([event.value]);
    this.focusedZipCode = event.value;
  }

  onFocusedZipCode(focusedZipCode: any): void {
    this.focusedZipCode = focusedZipCode;
  }

  fitBounds(): void {
    this.mapbox?.mapBox?.fitBounds(this.serviceAreaZipCodesForm);
  }

  getNewServiceArea(): boolean {
    return this.newServiceArea && this.serviceAreaForm.zones && this.serviceAreaForm.zones.length === 1;
  }

  onZipCodeGroupChange(zipCodeGroup: ZipCodeGroup): void {
    if (this.newServiceArea && this.activeTab === TabEnum.ZONES) {
      this.newServiceArea = false;
    }
    this.updateFirstZone();
    console.log('[ServiceAreaModal] [onZipCodeGroupChange]', zipCodeGroup);
    // if (zipCodeGroup.id === SERVICE_AREA_GROUP_ID) {
    //   this.serviceAreaForm.postalCodes = [...zipCodeGroup.zipCodes];
    // } else {
    //   const zone = this.serviceAreaForm.zones.find(z => zipCodeGroup.id === z.zoneId || z.zoneName);
    //   if (zone) {
    //     zone.postalCodes = [...zipCodeGroup.zipCodes];
    //   }
    // }
  }

  onClickAddZone(): void {
    this.serviceAreaForm.zones.push({
      zoneName: '',
      name: '',
      zoneId: '',
      postalCodes: [],
      serviceAreaId: this.serviceAreaForm.serviceAreaId || '',
      uiId: this.zoneUiIdGenerator.next().value,
    });
    this.currentEditZoneIndex = this.serviceAreaForm.zones.length-1;
  }

  onClickRemoveZone(index: number): void {
    if (this.currentEditZoneIndex === index) {
      return;
    }
    if (this.selectedZone && this.getZipCodeGroupId(this._serviceAreaForm.zones[index]) === this.getZipCodeGroupId(this.selectedZone)) {
      // change the selected zone to next index if exists, otherwise previous index
      this.selectedZone = this._serviceAreaForm.zones[index + 1] || this._serviceAreaForm.zones[index - 1];
    }
    this._serviceAreaForm.zones.splice(index, 1);
    this.setZipCodeGroups();
  }

  validateZone(zone: ServiceAreaZoneForm, zoneName: string): void {
    if (!zoneName?.trim()) {
      this.currentEditZoneError = 'Zone Name is required';
      throw Error(this.currentEditZoneError);
    }
    if (this.serviceAreaForm.zones.find(z => (z.zoneName || z.name)?.trim() === zoneName?.trim() && z.uiId !== zone.uiId)) {
      this.currentEditZoneError = 'Zone Name not unique';
      throw Error(this.currentEditZoneError);
    }

    this.currentEditZoneError = '';
  }

  onClickSaveZone(zone: ServiceAreaZoneForm, zoneName: string): void {
    this.currentEditZoneSave = true;
    console.log('[ServiceAreaModal] [onClickSaveZone]', zone, zoneName);
    try {
      this.validateZone(zone, zoneName);
    } catch (e) {
      return;
    }

    zone.zoneName = zoneName;
    this.currentEditZoneIndex = undefined;
    this.selectedZone = zone;
    this.setZipCodeGroups();
    this.currentEditZoneSave = false;
  }

  onKeyupEditZone(zone: ServiceAreaZoneForm, zoneName: string): void {
    if (!this.currentEditZoneSave) {
      return;
    }
    try {
      this.validateZone(zone, zoneName);
    } catch (e) {
      return;
    }
  }

  copyZipCodes(popup:NgbPopover) {
    setTimeout(()=> popup.close(),1000);
    this.clipboard.copy(this.selectedZipCodes?.join(',') ?? '');
  }

  onClickSelectZipsRadius(): void {
    if (!this.radiusZipCode) {
      return;
    }
    this.httpRequest.queryPostalCodes(this.radiusZipCode, this.radiusMile + 'mi')
      .subscribe(response => {

        const successRes = <SuccessApiResponse<PostalCode[]>> response;
        let responseZips = successRes.data.map(r=>r.postalCode);

        if (!responseZips) {
          return;
        }
        if (!this.serviceAreaForm.allowPOBoxes) {
          responseZips = responseZips.filter(zip => getZipCodeBoundaryExists(zip));
        }
        const joinZipCodes = new Set<string>([...this.selectedZipCodes || [], ...responseZips]);  // unique
        this.selectedZipCodes = [...joinZipCodes];
        this.fitBounds();
      });
  }

  getZoneGeoJSON(): MapsDataSource {
    const features = this.serviceAreaForm.zones.map(zone => {
      if (zone.postalCodes.length === 0) {
        return undefined;
      }
      return {
        coordinates: getLatitudesLongitudesCenterPoint(zone.postalCodes),
        properties: {
          zoneName: zone.zoneName || zone.name
        }
      };
    }).filter(truthyFilter);
    return TrimbleMapsHelper.generateGeoJsonDataSource(ZONE_NAMES_SOURCE, features);
  }

  get mapZoneTextColor(): string | Expression {
    if (!this.selectedZone) {
      return 'black';
    }

    if (this.selectedZone.color && tinycolor(this.selectedZone.color).isLight()) {
      return 'black';
    }

    return [
      'case',
      ['==', ['get', 'zoneName'], this.selectedZone.zoneName || this.selectedZone.name], 'white',
      'black'
    ];
  }

  onZoneSelect(): void {
    this.mapbox?.mapBox.map?.setPaintProperty(ZONE_NAMES_LAYER, 'text-color', this.mapZoneTextColor);
  }

  onMapLoad(): void {
    this.isMapsLoaded = true;
    this.createZoneNamesLayer();
  }

  createZoneNamesLayer(): void {
    if (!this.isZonesFetched || !this.isMapsLoaded || this.isZoneNamesLayerCreated) {
      return;
    }

    const source = this.getZoneGeoJSON();
    this.mapbox.mapBox.map.addSource(source.name, source.data);

    this.mapbox.mapBox.map.addLayer({
      'id': ZONE_NAMES_LAYER,
      'type': 'symbol',
      'source': source.name,
      "minzoom": 5,
      'layout': {
        'text-field': ['get', 'zoneName'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        // 'text-radial-offset': 0.5,
        'text-justify': 'auto',
        // 'icon-image': ['get', 'icon']
        'visibility': this.activeTab === TabEnum.ZONES ? 'visible' : 'none',
        'text-size': [
          'interpolate',
          ['exponential', 2],
          ['zoom'],
          1, 12,
          10, 72
        ]
      },
      paint: {
        'text-color': this.mapZoneTextColor
      }
    });

    this.isZoneNamesLayerCreated = true;
  }

  onHeatmapUpload(event: any): void {
    // const data = this.h.convertUploadedFile(event);
    void this.heatmapHelper.convertUploadedFile(event)
      .then(() => {
        this.showHeatMap = true;
      });
  }

  clickAllowPoBoxes() {
    Swal.fire({
      title: 'Are you Sure?',
      text: this.serviceAreaForm.allowPOBoxes ? 'This action will remove all PO Box Zip Codes From the Service Area and Zones'
        : 'Add PO Box Zip codes for currently select Zip Codes',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value && this.serviceAreaForm.allowPOBoxes) {
        let zipCodeChildren: string[] = [];
        for (const zip of this.serviceAreaZipCodesForm) {
          const children = getZipCodeChildren(zip);
          if (children && children.length) {
            zipCodeChildren = [...zipCodeChildren, ...children];
          }
        }
        this.serviceAreaZipCodesForm = [...this.serviceAreaForm.postalCodes, ...zipCodeChildren];
        for (const zone  of this.serviceAreaForm.zones) {
          zone.postalCodes = zone.postalCodes.filter(zip => getZipCodeBoundaryExists(zip));
          zipCodeChildren = [];
          for (const zip of zone.postalCodes) {
            const children = getZipCodeChildren(zip);
            if (children && children.length) {
              zipCodeChildren = [...zipCodeChildren, ...children];
            }
          }
          zone.postalCodes = [...zone.postalCodes, ...zipCodeChildren];
        }
        this.setZipCodeGroups();
      } else if (result.value && !this.serviceAreaForm.allowPOBoxes) {
        this.serviceAreaZipCodesForm = this.serviceAreaZipCodesForm.filter(zip => getZipCodeBoundaryExists(zip));
        for (const zone  of this.serviceAreaForm.zones) {
          zone.postalCodes = zone.postalCodes.filter(zip => getZipCodeBoundaryExists(zip));
        }
        this.setZipCodeGroups();
      } else {
        this.serviceAreaForm.allowPOBoxes = !this.serviceAreaForm.allowPOBoxes;
      }
    });
  }

  promptUpgrade() {
    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed) {
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true })
      }
    })
  }

  onSpecialFieldsClick() {
    if(this.accountPlanStatus === 'trial_expired') {
      Swal.fire({
        title: "",
        text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Buy Pre-Release Assets'
      }).then(result => {
        if(result.isConfirmed){
          this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
        }
      });
    }
  }

}
