<!-- <div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="1" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Profile
                        </a>
                        <ng-template ngbNavContent>
                            <h1>Aw</h1>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Public Profile
                        </a>
                        <ng-template ngbNavContent>
                            <h1>Wit</h1>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Modes
                        </a>
                        <ng-template ngbNavContent>
                            <h1>Sayo</h1>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Service Types
                        </a>
                        <ng-template ngbNavContent>
                            <h1>Sayo</h1>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Chat Requests
                        </a>
                        <ng-template ngbNavContent>
                            <h1>Sayo</h1>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="6" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Accounts Payable
                        </a>
                        <ng-template ngbNavContent>
                            <h1>Sayo</h1>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body p-4">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="col-xxl-12">
    <div class="card">
        <div class="card-header">
            <ul ngbNav #PillsJustified="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                <li [ngbNavItem]="1" class="nav-item" (click)="changeTab(1)">
                    <a ngbNavLink href="/pages/settings/company/profile">
                        Profile
                    </a>
                    <ng-template ngbNavContent>
                        <app-company-settings></app-company-settings>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="nav-item" 
                    (click)="changeTab(2)">
                    <a ngbNavLink href="/pages/settings/company/public-profile">
                        <span class="d-sm-block">Public Profile</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-public-profile></app-public-profile>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="nav-item">
                    <a ngbNavLink href="#">
                        <span class="d-sm-block">Modes</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-modes></app-modes>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" class="nav-item" (click)="changeTab(4)">
                  <a ngbNavLink href="/pages/settings/company/service-types">
                      <span class="d-sm-block">Service Types</span>
                  </a>
                  <ng-template ngbNavContent>
                    <app-service-types></app-service-types>
                  </ng-template>
                </li>
                <!-- <li [ngbNavItem]="5" class="nav-item" (click)="changeTab(5)">
                    <a ngbNavLink href="/pages/settings/company/accounts-payable">
                        <span class="d-sm-block">Accounts Payable</span>
                    </a>
                    <ng-template ngbNavContent>
                        <app-accounts-payable></app-accounts-payable>
                    </ng-template>
                </li> -->
              </ul>
        </div>
        <div class="card-body">
            <div class="tab-content">
              <div [ngbNavOutlet]="PillsJustified"></div>
            </div>
        </div><!-- end card-body -->
    </div><!-- end card -->
</div><!--end col-->
