import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService, UrlObj } from 'src/app/core/services/http-service';
import { AddressComponent } from 'src/app/core/services/models/address-component.model';
import { BillingProfile, BillingProfilePayload, BillingType } from 'src/app/core/services/models/billing-profile.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { DefaultPlace, GoogleMapsSearchBoxComponent } from 'src/app/shared/google-maps-search-box/google-maps-search-box.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],

})
export class BillingComponent implements OnInit {
    billingInfoForm!: FormGroup;
    submitted = false;
    isLoading = false;
    billingProfiles: BillingProfile[] = [];
    pageMode: 'list' | 'save' = 'list';
    @ViewChild('googleMapsInput') 
    googleMapsInput: GoogleMapsSearchBoxComponent;
    addressComponent?: AddressComponent;
    defaultPlace?: DefaultPlace;
    originalBillingProfile?: BillingProfile;

    constructor(
        private auth: AuthService,
        private httpRequest: HttpService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {

    }
    ngOnInit(): void {

        const billingProfileId: string =  this.activatedRoute.snapshot.queryParams['billingProfileId'];
        this.initForm();
        this.goToListPage(billingProfileId);
    }

    goToListPage(billingProfileId?: string){
        this.pageMode = 'list';
        this.billingProfiles = [];
        this.httpRequest.listBillingProfiles().subscribe(
            res => {
                const successRes = <SuccessApiResponse<BillingProfile[]>> res;
                this.billingProfiles = successRes.data;

                const currentBillignProfile = this.billingProfiles
                    .find(billingProfile => billingProfile.billingProfileId === billingProfileId);

                if(currentBillignProfile){
                    this.gotToEditPage(currentBillignProfile);
                }
            }
        );
    }

    redirectToStripe(){
        this.isLoading = true;
        this.httpRequest.beginPaymentSetup(this.originalBillingProfile?.billingProfileId!)
          .subscribe(
              res => {
                  const successRes = <SuccessApiResponse<UrlObj>> res;
                  window.location.href = successRes.data.url;
              },
              error => {
                this.isLoading = false;
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to update credit card: '+ error.error.reason,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: 'rgb(60,76,128)',
                    confirmButtonText: 'Ok',
                }).then(result => {
                    //do nothing
                });
              }
          );
      }

    goToAddPage(){
        this.pageMode = 'save';
        this.originalBillingProfile = undefined;
        this.defaultPlace = undefined;
        this.initForm();
    }

    gotToEditPage(billingProfile: BillingProfile){
        this.pageMode = 'save';
        this.originalBillingProfile = billingProfile;
        const {street, city, state, country} = this.originalBillingProfile;
        this.defaultPlace = {
            input: `${street}, ${city}, ${state}, ${country}`
        };
        this.initForm();
        this.router.navigate([], {
            queryParams: {
                billingProfileId: this.originalBillingProfile.billingProfileId
            },
            queryParamsHandling: 'merge',
        });
    }

    initForm() {
        this.isLoading = false;
        this.submitted = false;
        this.billingInfoForm = this.formBuilder.group({
            name: [{value: this.originalBillingProfile?.name, disabled: !!this.originalBillingProfile}, [Validators.required]],
            companyName: [this.originalBillingProfile?.companyName, [Validators.required]],
            receiptEmail: [this.originalBillingProfile?.receiptEmail, [Validators.required]],
            firstName: [this.originalBillingProfile?.firstName, [Validators.required]],
            lastName: [this.originalBillingProfile?.lastName, [Validators.required]],
            phone: [this.originalBillingProfile?.phone, [Validators.required]],
            additionalEmails: [this.originalBillingProfile?.additionalEmails]
        })
    }

    get billingInfo() {
        return this.billingInfoForm.controls;
    }

    customSearchFn(term: string, item: any) {
        item.techName = item.techName.replace(',','');
        term = term.toLocaleLowerCase();
        if(item.techName.toLocaleLowerCase().indexOf(term) > -1) {
          return item.techName.toLocaleLowerCase().indexOf(term) > -1;
        } else {
          item.prettyName = item.prettyName.replace(',','');
          return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
        }
    }

    searchFn(term: string, item: string): boolean {
        return item.startsWith(term);
    }

    onPlaceChanged(address: google.maps.places.PlaceResult): void {
        if(address){
            this.addressComponent = new AddressComponent(address);
        }else{
            this.addressComponent = undefined;
        }
        
    }

    deleteBillingProfile(){
        this.isLoading = true;
        this.httpRequest
            .deleteBillingProfile(this.originalBillingProfile?.billingProfileId!)
            .pipe(
                finalize(()=>this.isLoading = false)
            )
            .subscribe(
                res=>{
                    Swal.fire({
                        title: 'Success',
                        text: 'Successfully deleted billing profile.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: 'rgb(60,76,128)',
                        confirmButtonText: 'Ok',
                      }).then((result) => {
                        this.goToListPage();
                      });
                },
                error=>{
                    Swal.fire({
                        title: 'Error',
                        text: 'Error deleting billing profile: ' + error.error.reason,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: 'rgb(60,76,128)',
                        confirmButtonText: 'Ok',
                      }).then((result) => {
                        //do nothing
                      });
                }
            );
    }

    submit() {
        this.submitted = true;
        this.isLoading = true;
        const isAdressInvalid = !this.googleMapsInput.validateForm();

        if(this.billingInfoForm.invalid || isAdressInvalid) {
            this.isLoading = false;
            return;
        }

        let type: BillingType = this.originalBillingProfile?.type as BillingType;

        if(!type){
            if(this.auth.currentAccountSelected.accountType == 'shipper-account' || this.auth.currentAccountSelected.accountType == 'broker-account') {
                type = 'postpaid';
            }else if(this.auth.currentAccountSelected.accountType == 'carrier-account'){
                type = 'prepaid';
            }
        }
        
        const payload: BillingProfilePayload = {
            ...this.addressComponent,
            street: this.addressComponent?.street1,
            ...this.billingInfoForm.value,
            type: type!
        };

        let httpCall;

        if(this.originalBillingProfile){
            httpCall = this.httpRequest.updateAccountBillingProfies(
                this.originalBillingProfile.billingProfileId,
                payload
            );
        }else{
            httpCall = this.httpRequest.addAccountBillingProfiles(
                payload,
                this.auth.currentAccountSelected.accountId!
            );
        }

        httpCall.pipe(
            finalize(()=>{
                this.isLoading = false;
                this.submitted = false;
            })
        ).subscribe(
            res => {
                Swal.fire({
                    title: 'Success',
                    text: 'Successfully saved billing profile.',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: 'rgb(60,76,128)',
                    confirmButtonText: 'Ok',
                  }).then((result) => {
                    this.goToListPage();
                  });
            },
            error => {
                Swal.fire({
                    title: 'Error',
                    text: 'Error saving billing profile: ' + error.error.reason,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: 'rgb(60,76,128)',
                    confirmButtonText: 'Ok',
                  }).then((result) => {
                    //do nothing
                  });
            }
        );
    }
}