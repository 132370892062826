import { Component, OnInit } from '@angular/core';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

export interface CARRIER_APP_MENU {
  name: string,
  key: string,
  redirectLink?: string,
  imgSrc?: string,
  childMenus?: Array<CARRIER_APP_MENU>
}

@Component({
  selector: 'app-carrier-apps',
  templateUrl: './carrier-apps.component.html',
  styleUrls: ['./carrier-apps.component.scss']
})
export class CarrierAppsComponent implements OnInit {

  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Carrier Apps'
    }
  ];

  carrierAppMenus: Array<CARRIER_APP_MENU> = [
    {
      name: "ELD",
      key: "eld",
      childMenus: [
        {
          name: "Samsara",
          key: "eld_samsara",
          redirectLink: "https://www.samsara.com/"
        },
        {
          name: "Motive",
          key: "eld_motive",
          redirectLink: "https://gomotive.com/"
        },
        {
          name: "EROAD Ehubo",
          key: "eld_eroad_ehubo",
          redirectLink: "https://www.eroad.com/"
        },
        {
          name: "Garmin eLog",
          key: "eld_garmin_elog",
          redirectLink: "https://www.garmin.com/en-US/p/592207",
          imgSrc: "/assets/images/screenshots/garmin.png"
        },
        {
          name: "Konexial My20 ELD",
          key: "eld_konexial",
          redirectLink: "https://konexial.com/my20-eld/"
        },
        {
          name: "Omnitracs",
          key: "eld_omnitracs",
          redirectLink: "https://www.omnitracs.com/"
        },
        {
          name: "J.J. Keller’s eld solution",
          key: "eld_jj_kellers",
          redirectLink: "https://www.jjkeller.com/shop/Product/J-J-Keller-ELogs-Solution",
          imgSrc: "/assets/images/screenshots/jj-keller.png"
        },
        {
          name: "GPS Trackit ELD",
          key: "eld_trackit",
          redirectLink: "https://gpstrackit.com/eld-fleet/",
          imgSrc: "/assets/images/screenshots/gps-trackit.png"
        },
      ]
    },
    {
      name: "Fuel Cards",
      key: "fuel_cards",
      childMenus: [
        {
          name: "WEX",
          key: "fuel_cards_wex",
          redirectLink: "https://www.wexinc.com/products/fuel-cards-fleet/compare-fleet-fuel-cards/"
        },
        {
          name: "EFS",
          key: "fuel_cards_efs",
          redirectLink: "https://www.wexinc.com/products/fuel-cards-fleet/select/business-gas-card/efs-fuel-card/"
        },
        {
          name: "Fleet One",
          key: "fuel_cards_fleet_one",
          redirectLink: "https://www.wexinc.com/products/fuel-cards-fleet/select/business-gas-card/fleet-one-edge-card/"
        },
        {
          name: "Axle Card",
          key: "fuel_cards_axle_card",
          redirectLink: "https://pilotflyingj.com/axle-fuel-card",
          imgSrc: "/assets/images/screenshots/axle-card.png"
        },
        {
          name: "Comdata",
          key: "fuel_cards_comdata",
          redirectLink: "https://www.comdata.com/",
          imgSrc: "/assets/images/screenshots/comdata.png"
        },
        {
          name: "Mudflap",
          key: "fuel_cards_mudflap",
          redirectLink: "https://www.mudflapinc.com/",
          imgSrc: "/assets/images/screenshots/mudflap.png"
        },
        {
          name: "Fuelman",
          key: "fuel_cards_fuelman",
          redirectLink: "https://www.fuelman.com/",
          imgSrc: "/assets/images/screenshots/fuelman.png"
        },
        {
          name: "AtoB",
          key: "fuel_cards_atob",
          redirectLink: "https://www.atob.com/"
        },
      ]
    },
    {
      name: "Factoring",
      key: "factoring",
      childMenus: [
        {
          name: "Triumph",
          key: "factoring_triumph",
          redirectLink: "http://invoicefactoring.com/",
          imgSrc: "/assets/images/screenshots/triumph.png"
        },
        {
          name: "OTR",
          key: "factoring_otr",
          redirectLink: "https://otrsolutions.com/solutions/services/"
        },
        {
          name: "Riviera Finance",
          key: "factoring_riviera",
          redirectLink: "https://info.rivierafinance.com/solution-scout-freight-factoring"
        },
        {
          name: "RTS Financial",
          key: "factoring_rts_financial",
          redirectLink: "https://www.rtsinc.com/",
          imgSrc: "/assets/images/screenshots/rtsinc-site.png"
        },
        {
          name: "eCapital",
          key: "factoring_ecapital",
          redirectLink: "https://ecapital.com/lp/solution-scout/?utm_source=Digital+Referral&utm_medium=Affiliate&utm_campaign=Solution+Scout",
          imgSrc: "/assets/images/screenshots/ecapital-site.png"
        }
      ]
    },
    {
      name: "Truck Rental",
      key: "truck_rental",
      childMenus: [
        {
          name: "Penske",
          key: "truck_rental_penske",
          redirectLink: "https://www.pensketruckrental.com/",
          imgSrc: "/assets/images/screenshots/penske-site.png"
        },
        {
          name: "Enterprise",
          key: "truck_rental_enterprise",
          redirectLink: "https://www.enterprisetrucks.com/truckrental/en_US.html",
          imgSrc: "/assets/images/screenshots/enterprise-site.png"
        },
        {
          name: "Budget",
          key: "truck_rental_budget",
          redirectLink: "https://www.budgettruck.com/",
          imgSrc: "/assets/images/screenshots/budget-site.png"
        }
      ]
    },
    {
      name: "Accounting",
      key: "accounting",
      childMenus: [
        {
          name: "Quickbooks",
          key: "accounting_quickbooks",
          redirectLink: "https://quickbooks.intuit.com/online/",
          imgSrc: "/assets/images/screenshots/quickbook-site.png"
        },
        {
          name: "Sage",
          key: "accounting_sage",
          redirectLink: "https://www.sage.com/en-us/",
          imgSrc: "/assets/images/screenshots/sage-site.png"
        },
        {
          name: "Feash Books",
          key: "accounting_feash_books",
          redirectLink: "https://www.freshbooks.com/",
          imgSrc: "/assets/images/screenshots/freshbooks-site.png"
        }
      ]
    },
    {
      name: "Broker Apps",
      key: "broker_apps",
      childMenus: [
        {
          name: "Carrier 411",
          key: "broker_apps_carrier_411",
          redirectLink: "https://www.carrier411.com/",
          imgSrc: "/assets/images/screenshots/carrier-411.png"
        },
        {
          name: "RMIS",
          key: "broker_apps_rmis",
          redirectLink: "https://truckstop.com/product/carrier-onboarding/broker/"
        },
        {
          name: "Carrier Assure",
          key: "broker_apps_carrier_assure",
          redirectLink: "https://www.carrierassure.com/",
          imgSrc: "/assets/images/screenshots/carrier-assure-site.png"
        },
        {
          name: "SaferWatch",
          key: "broker_apps_safer_watch",
          redirectLink: "https://truckstop.com/product/compliance/carrier-monitoring/"
        },
        {
          name: "HIGHWAY",
          key: "broker_apps_highway",
          redirectLink: "https://highway.com/",
          imgSrc: "/assets/images/screenshots/highway.png"
        },
        {
          name: "Green Screes",
          key: "broker_apps_green_screes",
          redirectLink: "http://greenscreens.ai/",
          imgSrc: "/assets/images/screenshots/green-screens.png"
        }
      ]
    },
    {
      name: "TruckInfo",
      key: "truckinfo",
      redirectLink: "https://www.truckinfo.net/",
      imgSrc: "/assets/images/screenshots/truckinfo-site.png"
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Redirect to link in new tab
   * @param link 
   */
  redirectToLink(link: string){
    window.open(link, '_blank');
  }

}
