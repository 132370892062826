import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

import { HttpService } from 'src/app/core/services/http-service';
import { ILicense } from 'src/app/core/services/models/models';

@Component({
  selector: 'ngbd-modal-content-licenses-list',
  templateUrl: './licenses-list.component.html',
  styleUrls: ['./licenses-list.component.scss'],

})
export class NgbdModalLicensesList implements OnInit{
  @Input() defaultZones: any = [];

  cancelLicenseLoading: string | null = null;
  licenseType: string;
  licenses: ILicense[] = [];
  refetchLicenses: () => void = () => {};

  constructor(
    public activeModal: NgbActiveModal,
    private httpRequest: HttpService,
   ) {}

  ngOnInit(): void {
  }

  close() {
    this.activeModal?.close();
  }

  onActivatePress() {

  }

  onPausePress() {
    
  }

  onCancelPress(licenseId: string) {
    this.cancelLicenseLoading = licenseId;
    this.httpRequest.cancelLicense(licenseId).toPromise().then((res) => {
      this.cancelLicenseLoading = null;
      Swal.fire({
        title: 'Success',
        text: 'License cancelled successfully.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      }).then((result) => {
        this.refetchLicenses();
        this.close();
      });
    }).catch(() => {
      this.cancelLicenseLoading = null;
      Swal.fire({
        title: 'Error',
        text: 'Failed to cancel the license',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      })
    })
  }

}