import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http-service';
import { ZonesAndRoutesService } from './zones-and-routes.service';
import { BranchesService } from '../origin-points/origin-point/branches.service';
import { TreeNode } from 'primeng/api';
import { Mode } from 'src/app/core/services/models/mode.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { Route } from 'src/app/core/services/models/route.model';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { combineLatest, of, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { Account } from 'src/app/core/services/models/account.model';
import { StringNullUndefined } from 'src/app/core/utils/commons';

const DEFAULT_LATITUDE = 39.833333;
const DEFAULT_LONGITUDE = -98.583333;
const NO_ADDRESS_ZOOM = 4;
const WITH_ADDRESS_ZOOM = 16;

@Component({
  selector: 'app-zones-and-routes',
  templateUrl: './zones-and-routes.component.html',
  styleUrls: ['./zones-and-routes.component.scss']
})

export class ZonesAndRoutesComponent implements OnInit, OnDestroy {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Routes'
    }
  ];
  modalType = '';
  error = '';
  errorRoute = '';
  submitted = false;
  submittedRoute = false;

  activeModal?: NgbModalRef;
  zonesForm!: FormGroup;
  addRoutesForm! : FormGroup;
  branchForm! : FormGroup;

  zipcodesJson: any;
  branches: any = [];
  accountsPayable: any = [];

  zones: any = [];
  mainBranches: any = [];
  selectedRow: any;

  addRouteZipCodes: any = [];

  deliverySkillSetsData = [
    {
      prettyName: 'Assembly',
      technicalName: 'assembly'
    },
    {
      prettyName: 'Installation',
      technicalName: 'installation'
    },
    {
      prettyName: 'Deluxe',
      technicalName: 'deluxe'
    }
  ];
  serviceTypeData = [
    {
      prettyName: 'Same Day',
      technicalName: 'same-day',
    },
    {
      prettyName: 'Next Day',
      technicalName: 'next-day',
    },
    {
      prettyName: 'Future',
      technicalName: 'future',
    }
  ];
  vehicleTypesData: any = [];
  deliveryServiceData: any = [];

  files!: TreeNode[];
  cols!: any[];

  selectedNode: any = '';
  selectedType = 'store';
  address?: google.maps.places.PlaceResult;
  googleMapSrc: string = '';
  statesJson: any = [];
  submittedBranch = false;
  lazy = false;

  capacityData: any = [];
  capDataDefault: any;

  selectedMode: (Mode | null);
  selectedBranch: (Branch | null);
  selectedAccount: (Account | null);
  selectedUpdateCnt = 0;
  showData = false;
  routes: Route[];
  subscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private serv: ZonesAndRoutesService,
    private branchService: BranchesService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
  ) {
    this.subscriptions.push(
      combineLatest([
        this.authService.selectedAccountSubject, 
        this.authService.selectedModeSubject,
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch)){
            this.setSelectedAndFetchRoutes(account, mode, branch);
            return;
          }

          if(this.allSelectedSame(account, mode, branch)){
            this.showData = true;
            return;
          }

          this.setSelectedAndFetchRoutes(account, mode, branch);
        }
      )
    );

  }

  private setSelectedAndFetchRoutes(account: Account | null, mode: Mode | null, branch: Branch | null): void {
    this.selectedAccount = account;
    this.selectedMode = mode;
    this.selectedBranch = branch;

    this.fetchRoutes(branch?.branchId, mode?.modeId, account?.accountId, ++this.selectedUpdateCnt);
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }
  
  ngOnInit(): void {
    fetch('./assets/jsons/zipcodes.json').then(res => res.json()).then(jsonData => {
      this.zipcodesJson = [...new Set(jsonData)];
    });
    fetch('./assets/jsons/vehicle-types.json').then(res => res.json()).then(jsonData => {
      this.vehicleTypesData = [...new Set(jsonData)];
    });
    fetch('./assets/jsons/delivery-service-types.json').then(res => res.json()).then(jsonData => {
      this.deliveryServiceData = [...new Set(jsonData)];
    });
    fetch('./assets/jsons/states.json').then(res => res.json()).then(jsonData => {
      this.statesJson = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

    this.cols = [
        { field: 'mainBranchName', header: 'Main Branch', width: '24%' },
        { field: 'name', header: 'Zone', width: '24%' },
        { field: 'zipCodes', header: 'ZIP Codes', width: '24%' },
    ];
    
    this.initForms();
    this.getAccountsPayable();
    this.setGoogleMapSrc();
  }

  private async fetchRoutes(
    branchId: StringNullUndefined, 
    modeId: StringNullUndefined, 
    accountId: StringNullUndefined,
    selectedUpdateCount: number
  ):Promise<void> {
    this.showData = false;
    this.routes = [];

    if(branchId || modeId) {
      const res = await this.httpRequest.getRoutes(branchId, modeId, accountId).toPromise(); 
      
      if(selectedUpdateCount < this.selectedUpdateCnt){
        console.warn(`[fetchRoutes] not updating routes since selectedUpdateCount is delayed ${selectedUpdateCount} < ${this.selectedUpdateCnt}`);
      }else{
        this.routes = res.data;
      }
    }

    this.showData = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  initForms() {
    this.zonesForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      zipCodes: ['', [Validators.required]],
      deliverableBranchIds: ['', [Validators.required]],
      mainBranchId: ['', [Validators.required]],
      accountPayableId: ['', [Validators.required]],
    });

    this.addRoutesForm  = this.formBuilder.group({
      name: ['', [Validators.required]],
      zoneId: ['', [Validators.required]],
      type: ['dedicated', [Validators.required]],
      vehicleType: ['', [Validators.required]],
      hasHelper: [false, [Validators.required]],
      hasOnsiteTruckParking: [false, [Validators.required]],
      deliveryServiceTypes: ['', [Validators.required]],
      deliverySkillSets: ['', [Validators.required]],
      serviceType: ['', [Validators.required]],
      capacityRules: [''],
      zipCodes: [''],
      
    });

    this.branchForm = this.formBuilder.group({
      parentId: [null, []],
      name: [{value: 'Store', disabled: true}, [Validators.required]],
      type: ['', [Validators.required]],
      number: ['', [Validators.required]],
      street: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      contactName: [''],
      contactPhone: [''],
      contactEmail: [''],
    });
    this.branches = [];
    this.mainBranches = [];
  }

  getRoute(event: any) {
    this.httpRequest.getRoutesViaZoneId(event.node.data.zoneId).subscribe((data) => {
      setTimeout(() => {
        const node = event.node;
        node.children = [];
        if(data.data) {
          for(let x = 0; x < data.data.length; x++) {
            node.children.push(
              {
                data: { 
                  capacityRules: data.data[x].capacityRules,
                  accountId: data.data[x].accountId,
                  deliveryServiceTypes: data.data[x].deliveryServiceTypes,
                  deliverySkillSets: data.data[x].deliverySkillSets,
                  hasOnsiteTruckParking: data.data[x].hasOnsiteTruckParking,
                  name: data.data[x].name,
                  routeId: data.data[x].routeId,
                  serviceType: data.data[x].serviceType,
                  type: data.data[x].type,
                  vehicleType: data.data[x].vehicleType,
                  zoneId: data.data[x].zoneId,
                  zipCodes: [],
                  zoneZipCodes: data.data[x].zoneZipCodes,
                  zoneName: data.data[x].zoneName
                },
                leaf: true
              },
            );
          }
        }
        this.files = [...this.files];

      }, 250);
    })
  }

  async openModal(content: any, type: string, data: any, rowNode: any) {
    this.selectedRow = data;
    if(type == 'addZone') {
      this.modalType = 'Add';
    } else if(type == 'editZone') {
        this.modalType = 'Edit';
        this.zonesForm.controls['name'].setValue(this.selectedRow.name);
        this.zonesForm.controls['zipCodes'].setValue(this.selectedRow.zipCodes);
        await this.getBranches();
        this.branches = this.serv.isDeliverableIdsInBranches(this.branches, {
          mainBranchName: this.selectedRow.mainBranchName,
          deliverableBranchIds: this.selectedRow.deliverableBranchIds
        });
        this.zonesForm.controls['deliverableBranchIds'].setValue(this.selectedRow.deliverableBranchIds);
        await this.getMain();
        this.zonesForm.controls['mainBranchId'].setValue(this.selectedRow.mainBranchId);
    } else if(type == 'addRoute') {
      this.modalType = 'Add';
      this.addRouteZipCodes = this.selectedRow.zipCodes;
      this.addRoutesForm.controls['zoneId'].setValue(this.selectedRow.zoneId);
      this.addRoutesForm.controls['zipCodes'].setValue(this.addRouteZipCodes);
    } else if(type == 'editRoute') {
      this.modalType = 'Edit';
      this.addRouteZipCodes = rowNode.zipCodes;
      this.addRoutesForm.controls['name'].setValue(this.selectedRow.name);
      this.addRoutesForm.controls['zoneId'].setValue(this.selectedRow.zoneId);
      this.addRoutesForm.controls['type'].setValue(this.selectedRow.type);
      this.addRoutesForm.controls['vehicleType'].setValue(this.selectedRow.vehicleType);
      this.addRoutesForm.controls['hasOnsiteTruckParking'].setValue(this.selectedRow.hasOnsiteTruckParking);
      this.addRoutesForm.controls['deliveryServiceTypes'].setValue(this.selectedRow.deliveryServiceTypes);
      this.addRoutesForm.controls['deliverySkillSets'].setValue(this.selectedRow.deliverySkillSets);
      this.addRoutesForm.controls['serviceType'].setValue(this.selectedRow.serviceType);
      this.addRoutesForm.controls['zipCodes'].setValue(this.selectedRow.zoneZipCodes);
    }
    this.activeModal = this.modalService.open(content, { size: 'lg', centered: true });
    
    this.activeModal.result.finally(() => {
      this.initForms();
    });
  
  }

  openCapacity(content: any) {
    this.selectedRow.zoneZipCodes = this.addRoutesForm.controls['zipCodes'].value;
    for(let x = 0; x < this.capacityData.length; x++) {
      this.selectedRow.zoneZipCodes = this.selectedRow.zoneZipCodes.filter((val:any) => !this.capacityData[x].zipCodes.includes(val));
    }
    this.activeModal = this.modalService.open(content, { size: 'lg', centered: false, windowClass: 'my-class' });
  }

  deleteCap(data: any, index: any) {
    this.capacityData.splice(index, 1);
  }

  get form() {
    return this.zonesForm.controls;
  }

  get formRoute() {
    return this.addRoutesForm.controls;
  }

  get formBranch() {
    return this.branchForm.controls;
  }

  searchFn(term: string, item: any) {
      item.name = item.name.replace(',','');
      term = term.toLocaleLowerCase();
      return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  customSearchFn(term: string, item: any) {
    item.techName = item.techName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.techName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.techName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  getEventValue($event:any) :string {
    return $event.target.value;
  }

  getBranches() {
    return new Promise<void>((resolve,reject)=>{
      let zipCodes = this.zonesForm.controls['zipCodes'].value;
      if(zipCodes.length == 0) {
        resolve();
        return;
      } else {
        let url = zipCodes.join();
        this.httpRequest.getBranchesViaZip(url).subscribe((data) => {
          this.branches = data.data;
          resolve();
        })  
      }
    });
   
  }

  getMain(){
    this.mainBranches = this.serv.getMainBranches(this.zonesForm.controls['deliverableBranchIds'].value, this.branches, this.serv.getLowestLevel(this.zonesForm.controls['deliverableBranchIds'].value, this.branches));
  }

  getAccountsPayable() {
    this.httpRequest.getAccountsPayable().subscribe((data) => {
      this.accountsPayable = data.data;
    })
  }
  
  addZone() {
    this.submitted = true;
    if(this.zonesForm.invalid) {
      return;
    } else {
      let delivIds = this.zonesForm.controls['deliverableBranchIds'].value;
      let delivPayload = [];
      let dataToSend = {
        name: this.zonesForm.controls['name'].value,
        zipCodes: this.zonesForm.controls['zipCodes'].value,
        deliverableBranchIds: this.zonesForm.controls['name'].value,
        mainBranchId: this.zonesForm.controls['mainBranchId'].value,
        accountPayableId: this.zonesForm.controls['accountPayableId'].value
      }
      for(let x = 0; x < delivIds.length; x++) {
        delivPayload.push(delivIds[x].branchId)
      }
      dataToSend.deliverableBranchIds = delivPayload;
      this.httpRequest.addZones(dataToSend).subscribe((data) => {
        this.activeModal?.close();
        this.submitted = false;
        this.branchService.navigateCurrentRoute();
      })
    }
  }

  addRoutes() {
    this.submittedRoute = true;
    if(this.addRoutesForm.invalid) {
      return;
    } else {
      this.httpRequest.addRoutes(this.addRoutesForm.value).subscribe((data) => {
        this.activeModal?.close();
        this.submittedRoute = false;
        this.branchService.navigateCurrentRoute();
      }, error => {
        this.errorRoute = error.error.reason
      })
    }
  }

  closeModal(event: any) {
    this.activeModal?.close();
  }

  initEditBranch(editBranchModal: any,rowData: any) {
    let branchData: any;
    this.httpRequest.getBranchesById(rowData.mainBranchId).subscribe((data) => {
      branchData = data.data[0];
      this.selectedNode = branchData;
      this.branchForm.controls['parentId'].setValue(branchData.parentId);
      this.branchForm.controls['name'].setValue(branchData.name);
      this.branchForm.controls['type'].setValue(branchData.type);
      this.branchForm.controls['number'].setValue(branchData.number);
      this.branchForm.controls['street'].setValue(branchData.street);
      this.branchForm.controls['city'].setValue(branchData.city);
      this.branchForm.controls['state'].setValue(branchData.state);
      this.branchForm.controls['zipcode'].setValue(branchData.zipCode);
      this.branchForm.controls['contactName'].setValue(branchData.contactName);
      this.branchForm.controls['contactPhone'].setValue(branchData.contactPhone);
      this.branchForm.controls['contactEmail'].setValue(branchData.contactEmail);
      this.setGoogleMapSrcEdit(branchData.street);
      this.activeModal = this.modalService.open(editBranchModal, { size: 'lg', centered: true });
      this.activeModal.result.finally(() => {
        this.initForms();
      });
    })
    // this.selectedBranch = rowData;
    // this.editBranchBool = true;
  }

  setGoogleMapSrc(lat?: number, lng?: number): void {
    this.googleMapSrc = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCmaLjr3UqS0vazENKvzV_J5UYhP2-RUDQ&q=${lat || DEFAULT_LATITUDE},${lng || DEFAULT_LONGITUDE}&zoom=${lat? WITH_ADDRESS_ZOOM : NO_ADDRESS_ZOOM}`;
  }

  setGoogleMapSrcEdit(street: any): void {
    this.googleMapSrc = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCmaLjr3UqS0vazENKvzV_J5UYhP2-RUDQ&q=${street}`;
  }

  onChangeType(event: any) {
    this.branchForm.controls['name'].setValue(this.branchService.returnPrettyTypeName(event.target.value) + ' '
      + this.branchForm.controls['number'].value);
  }

  onNumberChange(event: any) {
    this.branchForm.controls['name'].setValue(this.branchService.returnPrettyTypeName(this.branchForm.controls['type'].value) + ' '
      + event.target.value);
  }

  onAddressChange(address: google.maps.places.PlaceResult): void {
    if (!address.address_components || address.address_components.length === 0) {
      console.warn('no address_components returned by google api', address);
      return;
    }
    this.address = address;

    const streetNumber = this.address.address_components?.find(a => a.types.includes('street_number'))?.long_name;
    const route = this.address.address_components?.find(a => a.types.includes('route'))?.long_name;

    this.formBranch['street'].setValue([streetNumber, route].filter(Boolean).join(' '));
    this.formBranch['city'].setValue(this.address.address_components?.find(a => a.types.includes('locality'))?.long_name);
    this.formBranch['state'].setValue(this.address.address_components?.find(a => a.types.includes('administrative_area_level_1'))?.short_name);
    this.formBranch['zipcode'].setValue(this.address.address_components?.find(a => a.types.includes('postal_code'))?.long_name);

    if (!this.address.geometry) {
      console.warn('no geometry (lat, lng) returned by google api', address);
    }
    this.setGoogleMapSrc(this.address.geometry?.location?.lat(), this.address.geometry?.location?.lng());

    this.cdr.detectChanges(); 
  }

  editBranch() {
    let id = this.branchForm.controls['parentId'].value;
    this.branchForm.controls['parentId'].setValue(null);
    this.httpRequest.updateBranch(id, this.branchForm.value).subscribe((data:any) => {
      this.branchService.navigateCurrentRoute();
      })
  }

  formValue(capacityData: any) {
    this.capacityData.push(capacityData);
  }

  getDayPrettyName(numDay: any) {
    return this.serv.getDayPrettyName(numDay);
  }

  // openModalLane() {
  //   this.activeModal?.close();
  //   let modalRef = this.modalService.open(NgbdModalAddLane, { size: 'lg', centered: true });
  // }

  onSiteRadio(status: any) {
    if(status == 'active') {
      this.addRoutesForm.controls['hasOnsiteTruckParking'].setValue(true);
    } else {
      this.addRoutesForm.controls['hasOnsiteTruckParking'].setValue(false);
    }
  }

  hasHelperRadio(status: any) {
    if(status == 'active') {
      this.addRoutesForm.controls['hasHelper'].setValue(true);
    } else {
      this.addRoutesForm.controls['hasHelper'].setValue(false);
    }
  }

}
