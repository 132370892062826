import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from './core/services/http-service';
import { ErrorService } from './services/error.service';
import { ToastService } from './services/toast-service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from './core/services/authentication/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  title = 'web-client';

  private ngUnsubscribe = new Subject();

  constructor(
    private errorService: ErrorService,
    public toastService: ToastService,
    private httpRequest: HttpService,
    private bnIdle: BnNgIdleService,
    private auth: AuthService,
    private router: Router
  ) {
    let url: string = window.location.href;
    if(!this.auth.currentUserValue) {
      if(!url.includes('/shipper') && !url.includes('/carrier') && !url.includes('/bonafide') && !url.includes('/contract-driver') && !url.includes('/broker') && !url.includes('/sign-up-process')
      && !url.includes('/sign-up-success') && !url.includes('/password-reset') && !url.includes('/privacy') && !url.includes('/oauth/adobe') && !url.includes('/user/josh') && !url.includes('/users/') && !url.includes('/pricing-and-plans')) {
        this.router.navigate(["login"]);
      }
    }
      // this.initializeErrors();
  }

  ngOnInit(): void {
    window.addEventListener("beforeunload", function (e) {
        // this.auth.logout();
    });
    if(this.auth.currentUserValue) {
      let user = this.auth.currentUserValue;
      let expireTime = new Date((Number(user.expiration)) * 1000);
      let seconds = Math.abs((expireTime.getTime() - new Date().getTime()) / 1000);
      if((new Date().getTime() / 1000) >= (expireTime.getTime() / 1000)) {
        this.auth.logout();
      } else {
        this.bnIdle.startWatching(seconds).subscribe((isTimedOut: boolean) => {
          if (isTimedOut) {
            this.auth.logout();
          }
        });
      }
    }
  }

  ngAfterViewInit(): void {
    document.getElementById('livereloadscript')?.remove();
  }

  ngOnDestroy() {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
  }

  private initializeErrors() {
    this.errorService
      .getErrors()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((errors) => {
        errors.forEach(err => {
          // this.showDanger(JSON.stringify(err));
          // TODO: notify dev?
        });
      });
  }

  /**
   * Danger message
   */
  showDanger(msg: string) {
    this.toastService.show(msg, { classname: 'bg-danger text-center text-white', delay: 10000 });
  }

}
