import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { AddressComponent } from 'src/app/core/services/models/address-component.model';
import Swal from 'sweetalert2';

import branchesTestJson from '../../../../assets/jsons/test-branch-data.json';
import { AbstractPagesComponent } from '../../AbstractPagesComponent';
import { BranchTypeIcons } from '../constants/branch-type-icons.const';
import { BranchesService, BranchTreeNode } from './branches.service';
import TrimbleMaps, { LngLatLike, MapLayerMouseEvent, Popup } from '@trimblemaps/trimblemaps-js';
import { MapsDataSource, MapsEventListener, MapsLayer, TrimbleMapsHelper } from 'src/app/core/services/models/trimble-maps.helper';
import { Branch } from 'src/app/core/services/models/branch.model';
import { isArrayEmpty, truthyFilter } from 'src/app/core/utils/commons';
import { TrimbleMapComponent } from 'src/app/shared/trimble-map/trimble-map.component';
import { Point } from '@trimblemaps/trimblemaps-js/geojson';
import { Account } from 'src/app/core/services/models/account.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { EventService } from 'src/app/core/services/event.service';
import { MAP_POINT_OBJECT } from 'src/app/core/services/models/map.model';

// Sweet Alert
const DEFAULT_LATITUDE = 39.833333;
const DEFAULT_LONGITUDE = -98.583333;
const NO_ADDRESS_ZOOM = 4;
const WITH_ADDRESS_ZOOM = 16;

interface BranchBreadCrumbItem {
  isExpanded?: boolean;
  node: BranchTreeNode;
}

const MAPS_SOURCE_NAME = 'branchSource';
const MAPS_LAYER_ID = 'branchPoints';
const UNSPECIFIC_BRANCH_TYPE_COLOR = '#33E';

/** ordered by which layer is drawn first. layers drawn last will show on top of the other layer */
const BRANCH_TYPES = [
  {
    type: 'unspecified',
    color: UNSPECIFIC_BRANCH_TYPE_COLOR
  },
  {
    type: 'remote-store',
    color: 'black'
  },
  {
    type: 'store',
    color: 'blue'
  },
  {
    type: 'branch',
    color: 'blue'
  },
  {
    type: 'cross-dock',
    color: UNSPECIFIC_BRANCH_TYPE_COLOR
  },
  {
    type: '3pl-cross-dock',
    color: 'purple'
  },
  {
    type: 'hub',
    color: 'red'
  },
  {
    type: 'dc',
    color: 'red'
  },
  {
    type: 'region',
    color: 'red'
  },
  {
    type: 'organization',
    color: 'orange'
  },
  {
    type: 'subsidiary',
    color: 'yellow'
  },
  {
    type: 'hq',
    color: 'green'
  }
]

export type BranchEventType = 'list-branch' | 'add-branch' | 'edit-branch';
export type BranchEvent = {
  type: BranchEventType;
  branch: Branch | null;
};

@Component({
  selector: 'app-origin-point',
  templateUrl: './origin-point.component.html',
  styleUrls: ['./origin-point.component.scss'],
  providers: []
})
export class OriginPointComponent extends AbstractPagesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  isRouted = true;

  @Output()
  event = new EventEmitter<BranchEvent>();

  readonly ADDABLE_BRANCH_TYPE = new Set<string>(
    [
      'shipper-account',
      'carrier-account',
      'hq',
      'subsidiary',
      'organization',
      'region',
      'dc',
      'hub',
      '3pl-cross-dock',
      'cross-dock'
    ]
  );
  hoveredRowData?: any;
  branchesBreadCrumbItems: BranchBreadCrumbItem[] = [];
  originalFiles!: BranchTreeNode[];
  files!: BranchTreeNode[];
  cols!: any[];
  loading!: boolean;

  selectedNode?: BranchTreeNode;
  branchForm!: FormGroup;
  selectedType = 'store';
  submitted = false;
  editBranchBool = false;
  address!: google.maps.places.PlaceResult;

  activeModal?: NgbModalRef;
  statesJson: any = [];

  zipcodesJson: any;
  googleMapSrc: string = '';

  lazy = false;
  transpoModes = [];
  selectedModes = ['', '', '', ''];

  shipperCompanyBranchTypes: any = [];
  carrierCompanyBranchTypes: any = [];
  hqBranchTypes: any = [];
  subsidiaryBranchTypes: any = [];
  organizationBranchTypes: any = [];
  regionBranchTypes: any = [];
  dcHubCrossDockBranchTypes: any = [];

  rootAccountType = 'subsidiary';
  branchesJsonTest: any = branchesTestJson.data;
  subscriptions: Subscription[] = [];
  selectedMode: (Mode | null);
  selectedBranch: (Branch | null);
  selectedAccount: (Account | null);
  branchIcons: { [key: string]: string } = BranchTypeIcons;

  // trimble maps
  @ViewChild('trimbleMap', {static: true}) trimbleMap: TrimbleMapComponent;
  trimbleMapOptions: Omit<TrimbleMaps.MapOptions, 'container'> = {
    style: TrimbleMaps.Common.Style.TRANSPORTATION, // hosted style id
    center: [-98.38, 38.69], // starting position
    zoom: 3 // starting zoom
  };
  geoJsonDataSources: MapsDataSource[] = BRANCH_TYPES.map(branchType => TrimbleMapsHelper.generateGeoJsonDataSource(`${MAPS_SOURCE_NAME}-${branchType.type}`, []));
  mapLayers: MapsLayer[] = BRANCH_TYPES.map(branchType => ({
    layer: {
      id: `${MAPS_LAYER_ID}-${branchType.type}`,
      type: 'circle',
      source: `${MAPS_SOURCE_NAME}-${branchType.type}`,
      paint: {
          'circle-radius': 12,
          'circle-color': branchType.color,
          'circle-stroke-color': '#FFF',
          'circle-stroke-width': 4
      }
    }
  }));
  mapListeners: MapsEventListener[];
  trimblePopups: Popup[] = [];
  // end of trimble maps config

  private branchAddedOrChangedCnt = 0;
  googleMapData: Array<MAP_POINT_OBJECT> = [];

  constructor(
    private httpRequest: HttpService,
    private formBuilder: FormBuilder,
    private branchService: BranchesService,
    private modalService: NgbModal,
    private router: Router,
    private auth: AuthService,
    private eventService: EventService
  ) {
    super();
    this.loadData();
    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject,
        this.eventService.branchAddedOrChangedSubject
      ]).subscribe(
        ([account, mode, branch, branchAddedOrChangedCnt]) => {
          if(this.anyNull(account, mode, branch)){
            return;
          }

          if(branchAddedOrChangedCnt > this.branchAddedOrChangedCnt){
            this.branchAddedOrChangedCnt = branchAddedOrChangedCnt;
            this.setSelected(account, mode, branch);
            this.getBranchesFromHierarchy();
            return;
          }

          if(this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.setSelected(account, mode, branch);
          this.getBranchesFromHierarchy();
        }
      )
    );
    this.subscriptions.push(
      this.auth.selectedModeSubject.subscribe(mode => {
        if(!mode){
          this.files = [];
          this.geoJsonDataSources = BRANCH_TYPES.map(branchType => TrimbleMapsHelper.generateGeoJsonDataSource(`${MAPS_SOURCE_NAME}-${branchType.type}`, []));  
        }
      })
    );
  }

  private setSelected(account: Account | null, mode: Mode | null, branch: Branch | null): void {
    this.selectedAccount = account;
    this.selectedMode = mode;
    this.selectedBranch = branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  override async loadData(): Promise<void> {
    fetch('./assets/jsons/states.json').then(res => res.json()).then(jsonData => {
      this.statesJson = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });
    fetch('./assets/jsons/zipcodes.json').then(res => res.json()).then(jsonData => {
      this.zipcodesJson = [...new Set(jsonData)];
    });

    fetch('./assets/jsons/branch-types/shipper-company-child-types.json').then(res => res.json()).then(jsonData => {
      this.shipperCompanyBranchTypes = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

    fetch('./assets/jsons/branch-types/carrier-company-child-types.json').then(res => res.json()).then(jsonData => {
      this.carrierCompanyBranchTypes = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

    fetch('./assets/jsons/branch-types/hq-child-types.json').then(res => res.json()).then(jsonData => {
      this.hqBranchTypes = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

    fetch('./assets/jsons/branch-types/subsidiary-child-types.json').then(res => res.json()).then(jsonData => {
      this.subsidiaryBranchTypes = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

    fetch('./assets/jsons/branch-types/organization-child-types.json').then(res => res.json()).then(jsonData => {
      this.organizationBranchTypes = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

    fetch('./assets/jsons/branch-types/region-child-types.json').then(res => res.json()).then(jsonData => {
      this.regionBranchTypes = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

    fetch('./assets/jsons/branch-types/dc-hub-crossdock-child-types.json').then(res => res.json()).then(jsonData => {
      this.dcHubCrossDockBranchTypes = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.cols = [
        { field: 'name', header: 'Name', width: '80%' },
    ];
    this.initForms();
    this.loading = true;
  }

  ngAfterViewInit(): void {
    this.mapListeners = BRANCH_TYPES.map(branchType => [
      {
        event: 'click',
        layerId: `${MAPS_LAYER_ID}-${branchType.type}`,
        listenerFn: (event) => {
          const features = (event as MapLayerMouseEvent).features;
          if (!features || features.length === 0) {
            return;
          }

          if (features.length > 0) {
            console.warn('Multiple branches in the clicked coordinate. Will pick the first one');
          }

          const feature = features[0];

          // const coordinates = (feature.geometry as Point).coordinates.slice();
          const properties = feature.properties || {};
          const branchId = properties['branchId'];
          
          const branches = this.files
            .map(branchNode => this.branchService.getNestedBranchNodes(branchNode)).flat()
            .filter(f => {
              if (!f.data) {
                return false;
              }
              return branchId === f.data.branchId;
            });

          if (branches.length === 0) {
            console.warn('Branch not found with clicked branch id', branchId);
            return;
          }

          if (branches.length > 1) {
            console.warn('More than one Branch found with clicked branch id', branchId);
          }

          const branch = branches[0];
          this.editBranches(branch.data, {node: branch, parent: branch.parent});
        }
      },
      {
        event: 'mouseenter',
        layerId: `${MAPS_LAYER_ID}-${branchType.type}`,
        listenerFn: (event) => {
          // this.trimbleMap.maps.getCanvas().style.cursor = 'pointer';  // this works as well
          event.target.getCanvas().style.cursor = 'pointer';

          const features = (event as MapLayerMouseEvent).features;
          if (!features || features.length === 0) {
            return;
          }
          const feature = features[0];
          const coordinates = (feature.geometry as Point).coordinates.slice();
          const branchIds = features.map(feature => (feature.properties || {})['branchId']);
          
          const branches = this.files
            .map(branchNode => this.branchService.getNestedBranchNodes(branchNode)).flat()
            .filter(f => {
              if (!f.data) {
                return false;
              }
              return branchIds.includes(f.data.branchId);
            });

          if (branches.length === 0) {
            console.warn('Branch not found with clicked branch ids', branchIds);
            return;
          }

          const allBranches = branches.map(b => b.data?.name).filter(truthyFilter);


          const popupContent = branches.map(b => b.data?.name)
            .filter(truthyFilter)
            .filter((b, index) => {
              return index < 6; // only show max 6
            })
            .map((b, index) => {
              return index < 5 ? b : '...'; // 6th element is ...
            })
            .join('<br />');
          this.trimblePopups.push(
            new TrimbleMaps.Popup()
              .setLngLat(coordinates as LngLatLike)
              .setHTML(`<div>${popupContent}<div>`)
              .addTo(event.target)
          );
        }
      },
      {
        event: 'mouseleave',
        layerId: `${MAPS_LAYER_ID}-${branchType.type}`,
        listenerFn: (event) => {
          event.target.getCanvas().style.cursor = '';
          this.trimblePopups.forEach(p => {
            p.remove();
          });
          this.trimblePopups = [];
        }
      }
    ] as MapsEventListener[]).flat();
  }

  initForms() {
    this.branchForm = this.formBuilder.group({
      parentId: [null, []],
      name: [{value: 'Store', disabled: true}, [Validators.required]],
      type: ['', [Validators.required]],
      number: ['', [Validators.required]],
      street: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zipcode: ['', [Validators.required]],
      contactName: [''],
      contactPhone: [''],
      contactEmail: [''],
    });
  }

  get form() {
    return this.branchForm.controls;
  }

  searchFn(term: string, item: string): boolean {
    return item.startsWith(term);
  }

  getEventValue($event:any) :string {
    return $event.target.value;
  }

  getBranches() {
    this.files = this.branchService.manipulateDataFromTestJson(this.branchesJsonTest[0]);
    this.originalFiles = [...this.files];
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  async getBranchesFromHierarchy() {
    this.httpRequest.getBranchesByMode(this.selectedMode!.modeId!).subscribe((data: {data: Branch[]}) => {
      const parentBranch = this.getParentBranchHierarchy(this.selectedBranch!, data.data);
      const branches = [parentBranch];
      let result = {
        "data": [{
          "children": branches
        }]
      };
      this.files = this.branchService.manipulateDataFromTestJson(result.data[0]);
      const branchTypeMap: { [branchType in string]: Branch[] } = {};
      branches
        .map(branch => this.branchService.getNestedBranches(branches[0]!)).flat()
        .forEach(branch => {
          const branchType = BRANCH_TYPES.find(t => t.type === branch.type);
          const branchTypeName = branchType?.type || 'unspecified';
          if (!branchTypeMap[branchTypeName]) {
            branchTypeMap[branchTypeName] = [];
          }

          branchTypeMap[branchTypeName].push(branch);
        });

      this.geoJsonDataSources = BRANCH_TYPES.map(branchType => {
        const branches = branchTypeMap[branchType.type];
        return TrimbleMapsHelper.generateGeoJsonDataSource(
          `${MAPS_SOURCE_NAME}-${branchType.type}`,
          branches?.map(branch => {
            const coordinates = this.branchService.getCoordinates(branch);
            if (!coordinates) {
              return undefined;
            }

            return {
              coordinates,
              properties: {
                branchId: branch.branchId,
              }
            };
          }).filter(truthyFilter) || []
        )
      });
      this.prepareDataForGoogleMap(branchTypeMap);
    });
  }

  getParentBranchHierarchy(parent: Branch, branches?: Branch[]): Branch{
    if(isArrayEmpty(branches)){
      return parent;
    }

    for(const branch of branches!){
      if(branch.branchId === parent.branchId){
        return branch;
      }
    }

    for(const branch of branches!){
      return this.getParentBranchHierarchy(parent, branch.children);
    }

    return parent;
  }

  isVisibleRow(rowNode: any): boolean {
    if (!this.drillDownNode) {
      return true;
    }

    if (!this.drillDownNode.node.children || this.drillDownNode.node.children.length === 0) {
      // if the current drillDown node has no children, row node has to be the selected node to be visible
      return rowNode.node.key === this.drillDownNode.node.key;
    }

    // if any of its ancestor is the selected node, then show it
    let node = rowNode;
    while (node.parent) {
      if (node.parent.key === this.drillDownNode.node.key) {
        return true;
      }
      node = node.parent;
    }
    return false;
  }

  expandNode(key: string, expand?: boolean): void {
    if (!key) {
      console.warn(`[expandNode] - No key provided '${key}'`);
      return;
    }
    const theNode = this.branchService.getNode(key, this.files);
    if (!theNode) {
      console.warn('[expandNode] - Unable to expand node. Given key not found', key);
      return;
    }

    if (theNode.expanded === expand) {
      return; // nothing to change
    }

    theNode.expanded = expand;
    this.files = [...this.files];
    // this.cdr.detectChanges();
  }

  get drillDownNode(): BranchBreadCrumbItem | undefined {
    return this.branchesBreadCrumbItems[this.branchesBreadCrumbItems.length - 1];
  }

  drillDown(rowNode: {level: number; node: BranchTreeNode; parent: BranchTreeNode | null; visible: boolean}) {
    this.auth.setSelectedBranch(rowNode.node);
    const appendBreadCrumbItems: BranchBreadCrumbItem[] = [{
      node: rowNode.node,
      isExpanded: rowNode.node.expanded
    }];
    const currentDrillDownNode = this.drillDownNode;
    let node = rowNode.node;
    while (node.parent && (!currentDrillDownNode || node.parent.key !== currentDrillDownNode.node.key)) {
      appendBreadCrumbItems.unshift({
        node: node.parent,
        isExpanded: node.parent.expanded
      });
      node = node.parent;
    }

    this.branchesBreadCrumbItems.push(...appendBreadCrumbItems);
    this.expandNode(rowNode.node.key || '', true);
  }

  returnBranch(data?: BranchBreadCrumbItem) {
    if(data){
      this.auth.setSelectedBranch(data?.node!)
    }
    let breadcrumb = this.branchesBreadCrumbItems.pop();
    while (breadcrumb && (!data || breadcrumb.node.key !== data.node.key)) {
      this.expandNode(breadcrumb.node.key || '', breadcrumb.isExpanded);
      breadcrumb = this.branchesBreadCrumbItems.pop();
    }
    if (breadcrumb) {
      // put the last one back
      this.branchesBreadCrumbItems.push(breadcrumb);
    }
  }

  addBranches(branchData: any) {
    if(this.isRouted){
      this.router.navigateByUrl('/pages/add-branch', {state: {branchData: branchData}});
    }else{
      this.event.emit(
        {
          type: 'add-branch',
          branch: branchData
        }
      );
    }
  }

  editBranches(data: any, node: any) {
    if(this.isRouted){
      this.router.navigateByUrl('/pages/edit-branch', {state: {branchData: node}});
    }else{
      this.event.emit(
        {
          type: 'edit-branch',
          branch: node.node.data
        }
      );
    }

  }

  addBranch(branchType: string) {
    this.submitted = true;
    if(this.branchForm.controls['type'].value == '') {
      this.branchForm.controls['type'].setValue('store');
    }
    if (this.branchForm.invalid || !this.transpoModes.length
      || !this.selectedNode || !this.selectedNode.data || !this.branchService.isBranchNodeData(this.selectedNode.data)) {
      return;
    } else {
      // let zip = this.branchForm.controls['zipcode'].value;
      // let zipString = zip.join();
      // let zipCodes = zipString.split(',').map(function(item:any) {
      //     return parseInt(item, 10);
      // });
      let dataToSend = {
        parentId: this.branchForm.controls['parentId'].value,
        name: this.branchForm.controls['name'].value,
        type: this.branchForm.controls['type'].value,
        number: this.branchForm.controls['number'].value,
        street: this.branchForm.controls['street'].value,
        city: this.branchForm.controls['city'].value,
        state: this.branchForm.controls['state'].value,
        zipCode: this.branchForm.controls['zipcode'].value,
        // zipCode: zipCodes,
        contactName: this.branchForm.controls['contactName'].value,
        contactPhone: this.branchForm.controls['contactPhone'].value,
        contactEmail: this.branchForm.controls['contactEmail'].value,
      }
      if(branchType === 'child') {
        this.branchForm.controls['parentId'].setValue(this.selectedNode.data.branchId);
        // dataToSend['parentId'] = this.selectedNode.branchId;
        let dataToSend = {
          parentId: this.branchForm.controls['parentId'].value,
          name: this.branchForm.controls['name'].value,
          type: this.branchForm.controls['type'].value,
          number: this.branchForm.controls['number'].value,
          street: this.branchForm.controls['street'].value,
          city: this.branchForm.controls['city'].value,
          state: this.branchForm.controls['state'].value,
          zipCode: this.branchForm.controls['zipcode'].value,
          contactName: this.branchForm.controls['contactName'].value,
          contactPhone: this.branchForm.controls['contactPhone'].value,
          contactEmail: this.branchForm.controls['contactEmail'].value,
        }
        this.httpRequest.addBranch(
          {
            ...dataToSend,
            address: new AddressComponent(this.address)
          }
        ).subscribe((data:any) => {
          this.submitted = false;
          this.activeModal?.close();
          this.initForms();
          Swal.fire({
            title: 'Success',
            text: 'Branch has been added.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok'
          }).then(result => {
            this.branchService.navigateCurrentRoute();
          });
          }, error => {

          })
      } else {
        this.httpRequest.addBranch(
          {
            ...dataToSend,
            address: new AddressComponent(this.address)
          }
        ).subscribe((data:any) => {
          this.submitted = false;
          this.activeModal?.close();
          this.initForms();
          Swal.fire({
            title: 'Success',
            text: 'Branch has been added.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok'
          }).then(result => {
            this.branchService.navigateCurrentRoute();
          });
          }, error => {

          })
      }
    }
    this.selectedNode = undefined;
  }

  openAddBranchModal(addBranchModal: any, rowData: any) {
    this.editBranchBool = false;
    if(rowData == '') {

    } else {
      this.selectedNode = rowData;
    }
    this.activeModal = this.modalService.open(addBranchModal, { size: 'lg', centered: true });
    this.activeModal.result.finally(() => {
      this.initForms();
    });
  }

  initEditBranch(addBranchModal: any,rowData: any) {
    this.selectedNode = rowData;
    this.editBranchBool = true;
    this.branchForm.controls['parentId'].setValue(rowData.parentId);
    this.branchForm.controls['name'].setValue(rowData.name);
    this.branchForm.controls['type'].setValue(rowData.type);
    this.branchForm.controls['number'].setValue(rowData.number);
    this.branchForm.controls['street'].setValue(rowData.street);
    this.branchForm.controls['city'].setValue(rowData.city);
    this.branchForm.controls['state'].setValue(rowData.state);
    this.branchForm.controls['zipcode'].setValue(rowData.zipCode);
    this.branchForm.controls['contactName'].setValue(rowData.contactName);
    this.branchForm.controls['contactPhone'].setValue(rowData.contactPhone);
    this.branchForm.controls['contactEmail'].setValue(rowData.contactEmail);
    this.activeModal = this.modalService.open(addBranchModal, { size: 'lg', centered: true });
    this.activeModal.result.finally(() => {
      this.initForms();
    });
  }

  editBranch() {
    if (!this.selectedNode || !this.selectedNode.data || !this.branchService.isBranchNodeData(this.selectedNode.data)) {
      console.warn(`[editBranch] - No branch selected`);
      return;
    }
    this.httpRequest.updateBranch(
      this.selectedNode.data?.branchId || '',
      {...this.branchForm.value, address: new AddressComponent(this.address)}
    ).subscribe((data:any) => {
      this.submitted = false;
      this.editBranchBool = false;
      this.initForms();
      Swal.fire({
        title: 'Success',
        text: 'Branch has been updated.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok'
      }).then(result => {
        this.branchService.navigateCurrentRoute();
      });
      }, error => {

      })
  }

  cancel() {
    this.activeModal?.close();
    this.initForms();
    this.submitted = false;
  }

  onChangeType(event: any) {
    this.branchForm.controls['name'].setValue(this.branchService.returnPrettyTypeName(event.target.value) + ' '
      + this.branchForm.controls['number'].value);
  }

  onNumberChange(event: any) {
    this.branchForm.controls['name'].setValue(this.branchService.returnPrettyTypeName(this.branchForm.controls['type'].value) + ' '
      + event.target.value);
  }

  onAddressChange(address: google.maps.places.PlaceResult): void {
    this.address = address;
  }

  customSearchFn(term: string, item: any) {
    item.techName = item.techName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.techName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.techName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  findTranspoMode(mode: string): string | undefined {
    return this.transpoModes.find(t => t === mode);
  }

  isAddableBranchType(rowData: any): boolean {
    if(this.ADDABLE_BRANCH_TYPE.has(rowData?.type)){
      return true;
    }

    return false;
  }

  /**
   * Prepares data for displaying on Google Map
   * @param branchTypeWiseData - Object containing branch type wise data
   */
  prepareDataForGoogleMap(branchTypeWiseData: any) {
    this.googleMapData = [];
    BRANCH_TYPES.map(branchType => {
      const branches = branchTypeWiseData[branchType.type];
      let branchData = branches?.map((branch: any) => {
        const coordinates = this.branchService.getCoordinates(branch);
        if (!coordinates) {
          return undefined;
        }

        return {
          name: branch.name,
          coordinates: {lat: coordinates[1], lng: coordinates[0]},
          color: branchType.color,
        };
      }).filter(truthyFilter) || []
      this.googleMapData = this.googleMapData.concat(branchData)
    });
  }
}
