import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ArchwizardModule } from 'angular-archwizard';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LayoutsModule } from './layouts/layouts.module';
import { PagesModule } from "./pages/pages.module";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import {
  NgbModalModule, NgbToastModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// otp module
import { NgOtpInputModule } from 'ng-otp-input';
import { EditorModule } from '@tinymce/tinymce-angular';

// language
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
// Load Icons
// import { defineLordIconElement } from 'lord-icon-element';
// import lottie from 'lottie-web';

import { StripeCheckoutOverlayComponent } from './stripe-checkout-overlay/stripe-checkout-overlay.component';
import { VerifySignUpComponent } from './verify-sign-up/verify-sign-up.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ToastsContainer } from './toasts-container.component';
import { OwnerOperatorComponent } from './owner-operator/owner-operator.component';
import { FleetComponent } from './fleet/fleet.component';
import { SignupFooterComponent } from './shared/signup-footer/signup-footer.component';
import { ScheduleCallComponent } from './schedule-call/schedule-call.component';
import { GeneralMessageComponent } from './general-message/general-message.component';
import { ShipperNewComponent } from './sign-up/shipper-new/shipper-new.component';
import { CarrierNewComponent } from './sign-up/carrier-new/carrier-new.component';
import { DriverNewComponent } from './sign-up/driver-new/driver-new.component';
import { HelperNewComponent } from './sign-up/helper-new/helper-new.component';
import { BrokerNewComponent } from './sign-up/broker-new/broker-new.component';
import { SignUpProcessComponent } from './sign-up-process/sign-up-process.component';
import { TermsScrollDirective } from './core/directives/terms-scroll.directive';
import { SignUpSuccessComponent } from './sign-up/sign-up-success/sign-up-success.component';
// import { DashboardComponent } from './pages/dashboards/dashboard/dashboard.component';

// Services
import { TokenInterceptorService } from './core/services/token-interceptor.service';
import { GlobalErrorHandlerService } from './interceptors/global.error.handler';
import { SampleComponent } from './sample/sample.component';
import { SharedModule } from './shared/shared.module';
// import { PusherService } from './core/services/pusher.service';

import { BnNgIdleService } from 'bn-ng-idle';

import {SelectButtonModule} from 'primeng/selectbutton';
import {ButtonModule} from 'primeng/button';
import { PassResetModule } from './account/auth/pass-reset/pass-reset.module';
import { CheckboxModule } from 'primeng/checkbox';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { OauthLoginComponent } from './oauth-login/oauth-login.component';
import { DemoComponent } from './demo-public-profile/demo-public-profile.component';
import { PricingComponent } from './plans-and-pricing/plans-and-pricing.component';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { DemoPublicScheduleComponent } from './demo-public-schedule/demo-public-schedule.component';
import { OauthRedeemComponent } from './oauth-redeem/oauth-redeem.component';
import { LineBreaksPipe } from './core/utils/pipe/linebreak.pipe';
import { CoverComponentNotFound } from './account/auth/errors/cover/cover.component';
import { LineBreaksModule } from './core/utils/pipe/linebreak.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PlanPricingCardComponent } from './plans-and-pricing/plan-pricing-card/plan-pricing-card.component';
import { LicensesListModule } from './pages/settings/licenses/modals/licenses-list/licenses-list.module';

TrimbleMaps.APIKey = '1D346515D8570D4F8800369948421D5C';

class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
     // Encode parentheses
     url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
     // Use the default serializer.
     return this._defaultUrlSerializer.parse(url)
  }

  serialize(tree: UrlTree): string {
     return this._defaultUrlSerializer.serialize(tree).replace(/%28/g, '(').replace(/%29/g, ')');
  }
}

@NgModule({
  declarations: [
    AppComponent,
    StripeCheckoutOverlayComponent,
    ToastsContainer,
    VerifySignUpComponent,
    SignUpProcessComponent,
    OwnerOperatorComponent,
    FleetComponent,
    SignupFooterComponent,
    ScheduleCallComponent,
    GeneralMessageComponent,
    SampleComponent,
    PrivacyComponent,
    DemoComponent,
    PricingComponent,
    ShipperNewComponent,
    CarrierNewComponent,
    DriverNewComponent,
    HelperNewComponent,
    BrokerNewComponent,
    SignUpSuccessComponent,
    TermsScrollDirective,
    TermsComponent,
    OauthLoginComponent,
    DemoPublicScheduleComponent,
    OauthRedeemComponent,
    CoverComponentNotFound,
    PlanPricingCardComponent,
    // DashboardComponent
  ],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    ArchwizardModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModalModule,
    NgbToastModule,
    NgSelectModule,
    ReactiveFormsModule,
    LayoutsModule,
    PagesModule,
    PassResetModule,
    NgOtpInputModule,
    SharedModule,
    SelectButtonModule,
    ButtonModule,
    NgbModule,
    CheckboxModule,
    DragDropModule,
    OrganizationChartModule,
    EditorModule,
    LicensesListModule,
    LineBreaksModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    BnNgIdleService,
    { provide: UrlSerializer, useClass: CustomUrlSerializer }
    // PusherService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {
    // defineLordIconElement(lottie.loadAnimation);
  }
}
