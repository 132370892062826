<app-breadcrumbs [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <ul ngbNav #carrierAppsNav="ngbNav" [activeId]="0" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    
                    <li [ngbNavItem]="menuIndex" class="nav-item" *ngFor="let menu of carrierAppMenus; let menuIndex = index">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            {{menu.name}}
                        </a>
                        <ng-template ngbNavContent>
                            <!-- <span *ngIf="!menu.childMenus || menu.childMenus.length == 0">{{menu.name}}</span> -->
                            <div *ngIf="!menu.childMenus || menu.childMenus.length == 0">
                                <div class="ratio ratio-16x9" *ngIf="!menu.imgSrc && menu.redirectLink">
                                    <iframe [src]="menu.redirectLink | safe" [title]="menu.name"></iframe>
                                </div>
                                <img 
                                    *ngIf="menu.imgSrc && menu.redirectLink" 
                                    [src]="menu.imgSrc" 
                                    class="w-100 mb-4" role="button" 
                                    (click)="redirectToLink(menu.redirectLink)" />
                            </div>
                            <div *ngIf="menu.childMenus && menu.childMenus.length > 0" class="card">
                                <div class="card-header">
                                    <ul ngbNav #carrierAppsChildNav="ngbNav" [activeId]="100" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                        <li [ngbNavItem]="(100 + cMenuIndex)" 
                                            class="nav-item"
                                            *ngFor="let cMenu of menu.childMenus; let cMenuIndex = index"> 
                                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                                <i class="fas fa-home"></i> {{cMenu.name}}
                                            </a>
                                            <ng-template ngbNavContent>
                                                <div class="ratio ratio-16x9" *ngIf="!cMenu.imgSrc && cMenu.redirectLink">
                                                    <iframe [src]="cMenu.redirectLink | safe" [title]="cMenu.name"></iframe>
                                                </div>
                                                <img 
                                                    *ngIf="cMenu.imgSrc && cMenu.redirectLink" 
                                                    [src]="cMenu.imgSrc" 
                                                    class="w-100 mb-4" role="button" 
                                                    (click)="redirectToLink(cMenu.redirectLink)" />
                                            </ng-template>
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-body">
                                    <div class="tab-content">
                                        <div [ngbNavOutlet]="carrierAppsChildNav"></div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div [ngbNavOutlet]="carrierAppsNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>