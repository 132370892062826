import { Component, OnInit } from '@angular/core';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-compass-ai-hub',
  templateUrl: './compass-ai-hub.component.html',
  styleUrls: ['./compass-ai-hub.component.scss']
})
export class CompassAiHubComponent implements OnInit {

  breadCrumbItems:BreadCrumbItem[] = [
    {label: 'Compass AI Hub'}
  ];

  constructor() { }

  ngOnInit(): void {
  }

  redirectToSite() {
    window.open('https://compassaihub.directtocarrier.io/', '_blank');
  }

}
