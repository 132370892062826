import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { ProfileService } from './profile.service';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { User } from 'src/app/core/services/models/auth.models';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { UserProfile } from 'src/app/core/services/models/user-profile.model';
import { DatePipe } from '@angular/common';
import { JpgToPngConvertor, isArrayEmpty } from 'src/app/core/utils/commons';
import { Subscription } from 'rxjs';
import { AccountPlan } from 'src/app/core/services/models/account-plan.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBuyLicenses } from '../../settings/licenses/modals/buy-licenses/buy-licenses.component';
import { ConfirmedValidator } from './confirmed-validator';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

/**
 * Profile Settings Component
 */
export class SettingsComponent implements OnInit {
  currentUser: User;
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'User Profile'
    },
    {
      label: 'Profile'
    }
  ];
  userForm!: FormGroup;
  experienceForm!: FormGroup;
  passwordForm!: FormGroup;
  submitted = false;
  submittedPass: boolean = false;
  userData: any;
  activeTab = 1;
  statesJson: any = [];

  countries = [  { techName: 'US', prettyName: 'USA' },  { techName: 'CA', prettyName: 'Canada' },];
  skills = [  
    { prettyName: 'Soft Skills' },
    { prettyName: 'Supply Chain' },
    { prettyName: 'Logistics' },
    { prettyName: 'Distribution' },
    { prettyName: 'Inventory' },
    { prettyName: 'Management' },
    { prettyName: 'Warehousing' },
    { prettyName: 'Analytical Skills' },
    { prettyName: 'Problem-solving' },
    { prettyName: 'Leadership' },
    { prettyName: 'Collaboration' },
    { prettyName: 'Sales' },
    { prettyName: 'Creativity' },
    { prettyName: 'Project Management' },
    { prettyName: 'Team Leadership' },
    { prettyName: 'Account Management ' },
    { prettyName: 'Truckload Shipping' },
    { prettyName: 'Trucking' },
    { prettyName: 'Less Then Truck Load' },
    { prettyName: 'Final Mile' },
    { prettyName: 'Carrier Procurement' },
    { prettyName: 'Freight Forwarding' },
    { prettyName: 'Attentive Listening' },
    { prettyName: 'Diplomacy' },
    { prettyName: 'Marketing' },
    { prettyName: 'Patience' },
    { prettyName: 'Troubleshooting' },
    { prettyName: 'E-mail Management' },
    { prettyName: 'Microsoft Office' },
    { prettyName: 'Research' },
    { prettyName: 'Social Media' },
    { prettyName: 'Systems Administration' },
    { prettyName: 'Web Design' },
    { prettyName: 'Graphic Design' },
    { prettyName: 'Microsoft Windows' },
    { prettyName: 'Mac OS' },
    { prettyName: 'Editing' },
    { prettyName: 'Interpersonal Skills' },
    { prettyName: 'Negotiation' },
    { prettyName: 'Public Speaking' },
    { prettyName: 'Teamwork' },
    { prettyName: 'Technical Writing' },
    { prettyName: 'Training' },
    { prettyName: 'Verbal Communication' },
    { prettyName: 'Written Communication' },
    { prettyName: 'Ability To Speak Another Language' },
    { prettyName: 'Copywriting' },
    { prettyName: 'Data Analysis' },
    { prettyName: 'Event Planning' },
    { prettyName: 'Food Preparation' },
    { prettyName: 'Search Engine Optimization (SEO)' },
    { prettyName: 'Typing Speed' },
    { prettyName: 'Ability To Work Well Under Pressure' },
    { prettyName: 'Adaptability' },
    { prettyName: 'Attention To Detail' },
    { prettyName: 'Decision Making' },
    { prettyName: 'Strong Work Ethic' },
    { prettyName: 'Time Management' },
    { prettyName: 'Numerical And Analytical Skills' },
    { prettyName: 'Invoice Development' },
    { prettyName: 'Customs Documentation' },
    { prettyName: 'Inventory Management Software' },
    { prettyName: 'Distribution Management' },
    { prettyName: 'Quality Assurance' },
    { prettyName: 'Customer Service' },
    { prettyName: 'Compliance' },
    { prettyName: 'MS Office' },
    { prettyName: 'Supply Chain Experience' },
    { prettyName: 'Scheduling' },
    { prettyName: 'Auto Delivery' },
    { prettyName: 'Inventory Management' },
    { prettyName: 'Forecasting' },
    { prettyName: 'Accuracy' },
    { prettyName: 'Vendor Management' },
    { prettyName: 'Purchasing' },
    { prettyName: 'Invoicing' },
    { prettyName: 'Inventory Control' },
    { prettyName: 'Microsoft Excel' },
    { prettyName: 'TMS' },
    { prettyName: 'WMS' },
    { prettyName: 'EDI' },
    { prettyName: 'API' }
  ];

  subscriptions: Subscription[] = [];
  currentAccountPlan: AccountPlan;
  isLoading= false;

  addNewFlag: boolean = false;
  workExperiences: any = [];
  isAddEdit: string = 'add';
  workExperienceId: string;
  origin = window.location.origin;
  userProfle?: UserProfile;
  tabsVisibility = {
    'profile': true,
    'security_and_privacy': true
  }

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private httpRequest: HttpService,
    private profService: ProfileService,
    private router: Router,
    private modalService: NgbModal,
    private permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    fetch('./assets/jsons/states.json').then(res => res.json()).then(jsonData => {
      this.statesJson = [...new Set(jsonData)].map((i:any) => { i.fullState = i.prettyName + ', ' + i.techName; return i; });
    });
    this.currentUser = this.auth.currentUserValue;
    this.subscriptions.push(
      this.auth.currentAccountPlanSubject.subscribe(
        accountPlan => {
          this.currentAccountPlan = accountPlan;
        }
      )
    );
    this.setTabsVisibilityBasedOnPermissions();
    this.getUserValue();
    this.getWorkExperience();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  getUserValue() {
    this.httpRequest.getUsersByIdOrEmail(this.currentUser.userId!).subscribe(res => {
      const successRes = <SuccessApiResponse<UserProfile>> res;
      this.userProfle = successRes.data;
      this.initForms(successRes.data);
    })
  }

  getWorkExperience() {
    this.httpRequest.getUserWorkExperience(this.currentUser.userId!).subscribe((data: any) => {
      this.workExperiences = data.data;
    })
  }

  isProfilePublicChanged(event: any){
    const isProfilePublic = event.target.checked;
    this.httpRequest.updateUser(this.userProfle?.userId!, {isProfilePublic}).subscribe(
      res => {
        const successRes = <SuccessApiResponse<UserProfile>> res;
        this.userProfle = successRes.data;
      },
      error=>{
        Swal.fire({
          title: 'Error',
          text: 'Failed to save setting: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok'
        }).then(result => {
          //do nothing
        });
      }
    );
  }

  initForms(user: any) {
    this.userForm = this.formBuilder.group({
      firstName: [user.firstName, [Validators.required]],
      lastName: [user.lastName, [Validators.required]],
      phone: [user.phone, [Validators.required]],
      email: [{value:user.email, disabled: true}, [Validators.required, Validators.email]],
      title: [user.title],
      state: [user.state],
      city: [user.city],
      country: [user.country],
      zipcode: [user.zipcode],
      skills: [user.skills ? JSON.parse(user.skills): user.skills],
      description: [user.description],
      linkedinUrl: [user.linkedinUrl],
      website: [user.website],
    });

    this.experienceForm = this.formBuilder.group({
      jobTitle: ['', [Validators.required]],
      jobDescription: ['', [Validators.required]],
      companyName: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
    });

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      oldPassword: ['', [Validators.required]],
    }, { validator: ConfirmedValidator('password', 'confirmPassword') })

    this.userData = user;
  }

  get form() {
    return this.userForm.controls;
  }

  get pform() {
    return this.passwordForm.controls;
  }

  addNewExp(type: string) {
    if(type == 'add') {
      this.addNewFlag = true;
      this.isAddEdit = 'add';
      this.experienceForm.controls['companyName'].setValue('');
      this.experienceForm.controls['endDate'].setValue('');
      this.experienceForm.controls['jobDescription'].setValue('');
      this.experienceForm.controls['jobTitle'].setValue('');
      this.experienceForm.controls['startDate'].setValue('');
    } else {
      this.addNewFlag = false;
    }
  }

  updateUser() {
    this.submitted = true;
    this.isLoading = true;
    if (this.userForm.invalid) {
      this.isLoading = false;
      return;
    } else {
      this.userForm.controls['skills'].setValue(JSON.stringify(this.userForm.controls['skills'].value));
      this.httpRequest.post('users/' + this.userData.userId, this.userForm.value).subscribe(data => {
        this.isLoading = false;
        this.initForms(data.data);
        this.profService.updateCurrentUser(data.data.firstName, data.data.lastName, data.data.role);
        Swal.fire({
            title: 'Success',
            text: 'User data has been updated.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok'
          }).then(result => {
            this.reloadCurrentComponent();
          });
      }, error => {
        this.isLoading = false;
        Swal.fire({
          title: 'Error',
          text: error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok'
        }).then(result => {
          this.getWorkExperience();
        });
      });
    }
  }

  updateExperience() {
    this.submitted = true;
    this.isLoading = true;
    if (this.experienceForm.invalid) {
      this.isLoading = false;
      return;
    } else {
      if(this.isAddEdit == 'add') {
        this.httpRequest.post('user/workExperiences', this.experienceForm.value).subscribe(data => {
          this.isLoading = false;
          this.submitted = false;
          Swal.fire({
              title: 'Success',
              text: 'User experiences has been updated.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok'
            }).then(result => {
              this.isAddEdit = '';
              this.workExperienceId = '';
              this.addNewFlag = false;
              this.getWorkExperience();
            });
        }, error => {
          Swal.fire({
            title: 'Error',
            text: error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok'
          }).then(result => {
            this.isLoading = false;
          });
        });
      } else {
        this.httpRequest.editUserWorkExperience(this.workExperienceId, this.experienceForm.value).subscribe(data => {
          this.isLoading = false;
          this.submitted = false;
          Swal.fire({
              title: 'Success',
              text: 'User experiences has been updated.',
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: 'rgb(60,76,128)',
              confirmButtonText: 'Ok'
            }).then(result => {
              this.isAddEdit = '';
              this.workExperienceId = '';
              this.addNewFlag = false;
              this.getWorkExperience();
            });
        }, error => {
          Swal.fire({
            title: 'Error',
            text: error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok'
          }).then(result => {
            this.isLoading = false;
          });
        });
      }
    }
  }

  setActiveTab(tabId: number) {
    this.activeTab = tabId;
    this.breadCrumbItems = [
      {
        label: 'User Profile'
      }
    ]
    if(tabId == 1) {
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Profile'}];
    } else if(tabId == 2) {;
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Calendar'}];
    }
  }

  customSearchFn(term: string, item: any) {
    item.techName = item.techName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.techName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.techName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.techName === c2.techName : c1 === c2;
  }

  get f() { return this.userForm.controls; }

  editExp(exp: any) {
    this.isAddEdit = 'edit';
    this.workExperienceId = exp.userWorkExperienceId;
    this.experienceForm.controls['companyName'].setValue(exp.companyName);
    this.experienceForm.controls['endDate'].setValue(exp.endDate);
    this.experienceForm.controls['jobDescription'].setValue(exp.jobDescription);
    this.experienceForm.controls['jobTitle'].setValue(exp.jobTitle);
    this.experienceForm.controls['startDate'].setValue(exp.startDate);
    this.addNewFlag = true;
  }

  deleteExp(exp: any) {
    Swal.fire({
      title: 'Warning',
      text: 'Are you sure you want to delete this experience?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Yes'
    }).then(result => {
      if(result.isConfirmed) {
        this.httpRequest.deleteUserWorkExperience(exp.userWorkExperienceId).subscribe((data: any) => {
          this.getWorkExperience();
          this.getUserValue();
          this.isAddEdit = 'add';
        }, error => {
          this.getWorkExperience();
          this.getUserValue();
          this.isAddEdit = 'add';
        })
      }
    });
  }

  async selectFiles(event: any){
    const files: File[] = Array.from(event.target.files);
    console.log('[selectFiles] files', files);

    if(isArrayEmpty(files)){
      alert('No file available!');
      return;
    }

    let file = files[0];
    if(file.type === 'image/jpeg'){
      console.log('[selectFiles] converting jpeg to png...');
      JpgToPngConvertor(file, async (pngFile: File)=>{
        console.log('[selectFiles] converting jpeg to png file', pngFile);
        await this.uploadProfilePic(pngFile);
      }).process();
    }else{
      await this.uploadProfilePic(file);
    }
  }

  private async uploadProfilePic(file: File){
    await this.httpRequest.uploadfileAWSS3(this.userProfle?.profilePictureUploadURL!, file).toPromise();
    this.reloadCurrentComponent();
  }

  handleError(event: any) {
    event.target.src = 'assets/images/users/user-dummy-img.jpg';
  }

  reloadCurrentComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }


  getPrettyDate(date: string) {
    let dateString = date;
    return new DatePipe('en-US').transform(dateString, 'MMMM d, y');
  }

  generateExperienceYears(): number[] {
    const startYear = 1920;
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
  
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
  
    return years;
  }

  promptUpgrade(){
    if(this.currentAccountPlan?.status === 'paid'){
      return;
    }

    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
      }
    });
  }

  changePassword() {
    this.submittedPass = true;

    if (this.passwordForm.invalid) {
      return;
    } else {
      console.log(this.passwordForm.value);
      console.log(this.auth.currentUserValue);
      let userId = this.auth.currentUserValue.userId!;
      this.httpRequest.resetPassword(userId, this.passwordForm.value).subscribe(data => {
        Swal.fire({
          title: 'Success',
          text: 'Password has been updated.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok'
        }).then(result => {
          this.passwordForm.reset();
          this.passwordForm.markAsUntouched();
        });
      }, error => {
        Swal.fire({
          title: 'Error',
          text: error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok'
        }).then(result => { });
      })
    }

    // Password form is valid, proceed with submitting the form
    console.log('Password form submitted');
  }

  /**
   * Set tabs visibility based on permissions
   */
  setTabsVisibilityBasedOnPermissions() {
    // this.tabsVisibility.profile = this.permissionsService.isUserHasFeatureAccess("user_profile_profile_info");
    this.tabsVisibility.security_and_privacy = this.permissionsService.isUserHasFeatureAccess("user_profile_security_privacy");
  }
}
