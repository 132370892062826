import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { CreateTruckTrailerComboPayload, TrailerModel, TruckModel, TruckTrailerCombModel } from 'src/app/core/services/models/models';
import { VehiclesService } from 'src/app/core/services/vehicles-service/vehicle.service';
import { removeFromArray } from 'src/app/core/utils/commons';
import { AbstractPagesComponent } from '../../AbstractPagesComponent';
import { NgbdGridJsSortableHeader, SortEvent } from 'src/app/core/services/vehicles-service/gridjs-sortable.directive';

@Component({
  selector: 'app-vehicle-combos-tab',
  templateUrl: './vehicle-combos-tab.component.html',
  styleUrls: ['./vehicle-combos-tab.component.scss']
})
export class VehicleCombosTabComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  activeModal?: NgbModalRef;
  error = '';
  createForm!: FormGroup;
  submitted = false;
  truckOptions: TruckModel[] = [];
  trailerOptions: TrailerModel[] = [];
  subscriptions: Subscription[] = [];
  modalType: 'Create' | 'Edit';
  truckTrailerComboId = '';
  searchTerm = '';
  allVehicles: TruckTrailerCombModel[] = [];

  @ViewChildren(NgbdGridJsSortableHeader) headers!: QueryList<NgbdGridJsSortableHeader>;

  constructor(
    public service: VehiclesService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private authService: AuthService
  ) {
    super();
    this.subscriptions.push(
      this.authService.subscribeToSelectedAccount(async (data: any) => {
        return this.loadData();
      })
    );
  }

  ngOnInit(): void {
    this.initForms()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async loadData(): Promise<void> {
    const resp = await Promise.all([
      this.initTruckOptions(),
      this.initTrailerOptions(),
      this.httpService.listTruckTrailerCombos().toPromise()
    ]);
    this.allVehicles = resp[2];
  }

  initForms() {
    this.createForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      truckId: ['', [Validators.required]],
      trailerId1: ['', [Validators.required]],
    });
  }

  async initTruckOptions(): Promise<void> {
    this.truckOptions = await this.httpService.listTrucks().toPromise();
  }

  getTruckNameById(id: string) {
    return this.truckOptions?.find(el => el?.truckId === id)?.name;
  }

  async initTrailerOptions(): Promise<void> {
    this.trailerOptions = await this.httpService.listTrailers().toPromise();
  }

  getTrailerNameById(id: string) {
    return this.trailerOptions?.find(el => el?.trailerId === id)?.name;
  }

  get createFormControls() {
    return this.createForm.controls as {[key in keyof CreateTruckTrailerComboPayload]: FormControl};
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  onClickAddCombo(modal: TemplateRef<any>): void {
    this.modalType = 'Create';
    this.activeModal = this.modalService.open(modal, { size: 'lg', centered: true });
    this.activeModal.result.finally(() => {
      this.initForms();
    })
  }

  onClickEditCombo(modal: TemplateRef<any>, vehicle: TruckTrailerCombModel): void {
    this.modalType = 'Edit';
    this.truckTrailerComboId = vehicle.truckTrailerComboId;
    this.createForm.controls['name'].setValue(vehicle.name);
    this.createForm.controls['truckId'].setValue(vehicle.truckId);
    this.createForm.controls['trailerId1'].setValue(vehicle.trailerId1);
    this.activeModal = this.modalService.open(modal, { size: 'lg', centered: true });
    this.activeModal.result.finally(() => {
      this.initForms();
    })
  }

  createCombo(): void {
    this.submitted = true;
    if (this.createForm.invalid) {
      return;
    } else {
      const payload: CreateTruckTrailerComboPayload = {
        name: this.createFormControls.name?.value,
        truckId: this.createFormControls.truckId.value,
        trailerId1: this.createFormControls.trailerId1.value,
      };
      this.httpService.createTruckTrailerCombo(payload).subscribe(combo => {
        this.activeModal?.close();
        this.allVehicles = [...this.allVehicles, combo];
      });
    }
  }

  updateCombo(): void {
    this.submitted = true;
    if (this.createForm.invalid) {
      return;
    } else {
      const payload: CreateTruckTrailerComboPayload = {
        truckTrailerComboId: this.truckTrailerComboId,
        name: this.createFormControls.name?.value,
        truckId: this.createFormControls.truckId.value,
        trailerId1: this.createFormControls.trailerId1.value,
      };
      this.httpService.editTruckTrailerCombo(payload).subscribe(combo => {
        this.activeModal?.close();
        this.allVehicles = removeFromArray(this.allVehicles, (a) => a.truckTrailerComboId === this.truckTrailerComboId);
        this.allVehicles = [...this.allVehicles, combo];
      });
    }
  }

  saveCombo(): void {
    switch (this.modalType) {
      case 'Create':
        this.createCombo();
        break;
      case 'Edit':
        this.updateCombo();
        break;
    }
  }

  deleteVehicles(id: string) {
    this.httpService.deleteTruckTrailerCombo(id).subscribe((data) => {
      this.allVehicles = removeFromArray(this.allVehicles, (a) => a.truckTrailerComboId === id);
    });
  }
}
