<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel">{{licenseType}} Licenses</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="close()"></button>
</div>

<div class="modal-body">
    <table class="table align-middle table-nowrap">
        <thead class="table-light">
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">State</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let license of licenses">
                <td>{{license.license_id}}</td>
                <td>{{license.product_name}}</td>
                <td class="center"><p class="state-badge" [ngClass]="{ 'state-badge-active': license.cancelled === false, 'state-badge-inactive': license.cancelled === true}">{{license.cancelled === true ? 'Inactive' : 'Active' }}</p></td>
                <td class="center">
                    <!-- <button type="button" class="btn btn-sm mr-2 action-btn" [ngClass]="{ 'pause-btn': license.cancelled === false, 'btn-primary': license.cancelled === true }" (click)="license.cancelled === false ? onPausePress() : onActivatePress()">{{license.cancelled === true ? 'Pause' : 'Activate'}}</button> -->
                    <span class="flex-grow-1 me-2" *ngIf="license.cancelled">
                        License Cancelled
                    </span>
                    <button *ngIf="!license.cancelled" type="button" class="btn btn-sm btn-danger action-btn" (click)="onCancelPress(license.license_id)" [disabled]="cancelLicenseLoading === license.license_id">
                        <span class="flex-grow-1 me-2" *ngIf="cancelLicenseLoading !== license.license_id">
                            Cancel
                        </span>
                        <span class="spinner-border flex-shrink-0 spinner" role="status" *ngIf="cancelLicenseLoading === license.license_id">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
