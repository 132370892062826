import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import $ from 'jquery';
import { MapService } from 'src/app/core/services/map.service';
import { AddressComponent } from 'src/app/core/services/models/address-component.model';
import { GeoLocateResponse } from 'src/app/core/services/models/map.model';

const ENTER_KEY = 'Enter';
const ARROW_DOWN_CODE = 40;

export type GmapPlace = {
  placeId: string, 
  lat: number, 
  lng: number
}

export type DefaultPlace = {
  gmap?: GmapPlace,
  input?: string;
};

@Component({
  selector: 'app-google-maps-search-box',
  templateUrl: './google-maps-search-box.component.html',
  styleUrls: ['./google-maps-search-box.component.scss']
})
export class GoogleMapsSearchBoxComponent implements OnInit, AfterViewInit {

  @Output()
  onPlaceChanged: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();
  @Input()
  showMap = true;
  @Input()
  isDisabled = false;
  @Input()
  defaultText = '';
  @Input()
  inputId = 'pac-input';
  @Output() onPlaceChangeIsEmpty: EventEmitter<boolean> = new EventEmitter();
  
  private _defaultPlace?: DefaultPlace;
  @Input()
  set defaultPlace(defaultPlace: DefaultPlace | undefined){
    const oldDefaultPlace = this._defaultPlace;
    this._defaultPlace = defaultPlace;

    if(!this.mapInput){
      return;
    }

    if(defaultPlace?.gmap?.placeId){
      this.initInputElementWithPlaceId(defaultPlace?.gmap?.placeId);
      return;
    }

    if(defaultPlace?.input){
      this.initInputElementWithInput(defaultPlace?.input);
      return;
    }
  }

  @Input()
  isSubmittedOnce = false;
  @Input()
  isRequired = false;
  @Input()
  isRequiredPostalCode = false;

  address?: google.maps.places.PlaceResult;
  loading = true;
  formErrors: Partial<{
    address: string; //'required' | 'noPostalCode';
  }> = {};

  private placesService: google.maps.places.PlacesService;
  private placeChanged: (place?: google.maps.places.PlaceResult)=>void;
  private mapInput: HTMLInputElement;

  constructor(private mapService: MapService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let loader = new Loader({
      apiKey: this.mapService.API_KEY,
      libraries: ['places'],
      version: 'weekly'
    });

    loader.load().then(() => {
      this.initMapWithPlaceOrIP();
    });
  }

  reset() {
    let loader = new Loader({
      apiKey: this.mapService.API_KEY,
      libraries: ['places'],
      version: 'weekly'
    });

    loader.load().then(() => {
      this.initMapWithPlaceOrIP();
      
    });
  }

  isValidAddress(): boolean {
    if (!this.address) {
      if (this.isRequired) {
        this.formErrors.address = 'Enter Address';
        return false;
      } else {
        delete this.formErrors.address;
        return true;
      }
    }

    const branchAddress = new AddressComponent(this.address);
    if(this.isRequiredPostalCode){
      if (!branchAddress.postalCode){
        this.formErrors.address = 'Postal code is missing';
        return false;
      }

      if(!branchAddress.street1){
        this.formErrors.address = 'Street 1 is missing';
        return false
      }

      if(!branchAddress.streetNumber) {
        this.formErrors.address = 'Street number is missing or invalid';
        return false
      }
    }

    delete this.formErrors.address;
    return true;
  }

  validateForm(): boolean {
    console.log('[GoogleMapsSearchBoxComponent] [validateForm]');
    const validAddress = this.isValidAddress();
    return validAddress;
  }

  private initMapWithPlaceOrIP(): void {
    if(this._defaultPlace?.gmap){
      const {lat, lng, placeId} = this._defaultPlace?.gmap;
      this.initMap({lat, lng}, {placeId});
      return;
    }

    if(this._defaultPlace?.input){
      const input = this._defaultPlace?.input;
      this.initMap(this.mapService.CHICAGO, {input});
      return;
    }

    this.initMapWithIP();
  }

  private initMapWithIP(): void{
    this.mapService.geoLocateIP()
        .subscribe((geoLocateResponse: GeoLocateResponse) => {
          this.initMap(geoLocateResponse.location);
        }, error => {
          this.initMap(this.mapService.CHICAGO);
        });
  }

  private initMap(initialLatLng: {lat: number, lng: number}, defaultPlace ?: {placeId?: string, input?: string}): void {
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        center: initialLatLng,
        zoom: 13,
        mapTypeId: "roadmap",
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        scaleControl: true,
        styles: [
          {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
              { "visibility": "off" }
            ]
          }
        ]
      }
    );

    // Create the search box and link it to the UI element.
    this.mapInput = document.getElementById(this.inputId) as HTMLInputElement;

    const autocomplete = this.initializeAutoComplete(this.mapInput);

    let markers: google.maps.Marker[] = [];

    this.placeChanged = (place?: google.maps.places.PlaceResult) => {
      place = place || autocomplete.getPlace();
      console.log('selected address: ', this.mapInput.value);
      console.log('selected place: ', place.formatted_address);
      console.log('selected place\'s id: ', place.place_id);
      console.log('selected place\'s latitude ', place.geometry?.location?.lat());
      console.log('selected place\'s longitude ', place.geometry?.location?.lng());
      console.log('selected place\'s object: ', place);

      this.invokeEvent(place);

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      if (!place.geometry || !place.geometry.location) {
        console.log("Returned place contains no geometry");
        return;
      }

      // Create a marker for each place.
      markers.push(
        new google.maps.Marker({
          map,
          title: place.name,
          label: {
              text: place.name!,
              color: 'red',
              fontSize: '12px',
              className: 'marker-position',
          },
          position: place?.geometry?.location,
        })
      );

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      if (place?.geometry?.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry?.location);
      }

      map.fitBounds(bounds);
    };

    google.maps.event.addListener(autocomplete, 'place_changed', this.placeChanged);

    this.mapInput.addEventListener('keydown', (event) => {
      if (event.key === ENTER_KEY) {
        event.preventDefault();
      }
    });

    this.loading = false;

    this.placesService = new google.maps.places.PlacesService(map);

    if(defaultPlace?.placeId){
      this.initInputElementWithPlaceId(defaultPlace?.placeId);
      return;
    }

    if(defaultPlace?.input){
      this.initInputElementWithInput(defaultPlace?.input);
      return;
    }
  }

  private initInputElementWithInput(input: string) {
    this.placesService.findPlaceFromQuery({fields: ['place_id'], query: input}, (places, status) => {
      if ( 
        status === google.maps.places.PlacesServiceStatus.OK &&
        places &&
        places.length > 0 &&
        places[0].place_id
      ) {
        this.initInputElementWithPlaceId(places[0].place_id);
      }
    });
  }

  private initInputElementWithPlaceId(placeId: string){
    const request = {placeId};
    this.placesService.getDetails(request, (place, status) => {
      if (
        status === google.maps.places.PlacesServiceStatus.OK &&
        place &&
        place.geometry &&
        place.geometry.location
      ) {
        const inputValue = place.formatted_address?.startsWith(place?.name ?? '') ? 
          place.formatted_address: `${place.name}, ${place.formatted_address}`;
        this.mapInput.value = inputValue;
        this.placeChanged(place);
      }else{
        console.warn('PlacesService did not return successfully');
      }
    });
  }

  invokeEvent(place: google.maps.places.PlaceResult) {
    this.address = place;
    this.validateForm();
    this.onPlaceChanged.emit(place);
  }

  private initializeAutoComplete(input: HTMLInputElement):google.maps.places.Autocomplete {

    this.addEventListenerToSelectFirstOptionOnEnter(input);

    return new google.maps.places.Autocomplete(input,
      {
        componentRestrictions: { country: ['US', 'CA'] },
        types: ['establishment', 'geocode'],
      });
  }

  //Reference: https://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter
  private addEventListenerToSelectFirstOptionOnEnter(input: HTMLInputElement): void{
    // store the original event binding function
    var _addEventListener = input.addEventListener;
    const addEventListenerWrapper = (type: any, listener: any) => {
        // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
        // and then trigger the original listener.
        if (type == "keydown") {
            var orig_listener = listener;
            listener = function(event:any) {
                var suggestion_selected = $(".pac-item-selected").length > 0;
                if (event.key == ENTER_KEY && !suggestion_selected) {
                    var simulated_downarrow = $.Event("keydown", {
                        keyCode: ARROW_DOWN_CODE,
                        which: ARROW_DOWN_CODE
                    });
                    orig_listener.apply(input, [simulated_downarrow]);
                }

                orig_listener.apply(input, [event]);
            };
        }

        _addEventListener.apply(input, [type, listener]);
    }
    input.addEventListener = addEventListenerWrapper;
  }

  changeAddress(event: any) {
    if(event.target.value == '') {
      this.onPlaceChangeIsEmpty.emit(true);
    } else if(this.containsOnlySpaces(event.target.value)) {
      this.onPlaceChangeIsEmpty.emit(true);
    } else {
      this.onPlaceChangeIsEmpty.emit(false);
    }
  }

  containsOnlySpaces(str: string) {
    return str.trim().length === 0;
  }
}
