import { Component, OnInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { Branch } from 'src/app/core/services/models/branch.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { TradingRelationship } from 'src/app/core/services/models/trading-relationship.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-trading-relationships',
  templateUrl: './trading-relationships.component.html',
  styleUrls: ['./trading-relationships.component.scss']
})
export class TradingRelationshipsComponent implements OnInit {
  isLoading = true;
  tradingRelationships: TradingRelationship[] = [];

  private subscriptions: Subscription[] = [];
  private selectedMode: (Mode | null);
  private selectedBranch: (Branch | null);
  selectedAccount: (Account | null);

  constructor(
    public auth: AuthService,
    private httpRequest: HttpService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject,
        this.auth.selectedBranchSubject
      ]).subscribe(
        ([account, mode, branch]) => {
          if(this.anyNull(account, mode, branch) || this.allSelectedSame(account, mode, branch)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;
          this.selectedBranch = branch;

          this.getTradingRelationships();
        }
      )
    );
  }

  private anyNull(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return !account || !mode || !branch;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null, branch: Branch | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
            && this.selectedBranch?.branchId == branch?.branchId
  }

  getTradingRelationships() {
    this.isLoading = true;
    this.httpRequest.fetchTradingRelationshipList(
      this.selectedMode?.modeId!,
      this.selectedBranch?.branchId!
    ).pipe(
      finalize(()=>this.isLoading = false)
    )
    .subscribe(
      res => {
        const successRes = <SuccessApiResponse<TradingRelationship[]>> res;
        this.tradingRelationships = successRes.data;
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch trading relationships: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    )
  }

}
