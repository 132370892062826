import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/core/services/http-service';
import { CarrierCostingPlan, MonthlyPlan, PlanFeatures } from './data';
import { PlanObj, MonthlyPlanModel } from './pricing.model';
export type acceptedType = 'carrier-plan' | 'shipper-plan' | 'broker-plan';


@Component({
  selector: 'app-plans-and-pricing',
  templateUrl: './plans-and-pricing.component.html',
  styleUrls: ['./plans-and-pricing.component.scss']
})
export class PricingComponent implements OnInit {

    breadCrumbItems!: Array<{}>;
    MonthlyPlan!: MonthlyPlanModel[];

    @Output() selectedPlan: EventEmitter<any> = new EventEmitter();
    type: string;

    @Input()
    planFor: acceptedType; 
    planFeatures: Array<PlanObj> = PlanFeatures;

    constructor(
      private httpRequest: HttpService,
      private route: ActivatedRoute,
      private router: Router
    ) { }

    ngOnInit(): void {
      this._fetchData();
      this.type = this.router.url;
      
    }

    private _fetchData() {
      if(this.planFor === 'carrier-plan') {
        this.MonthlyPlan = CarrierCostingPlan;
      } else {
        this.MonthlyPlan = MonthlyPlan;
      }
    }

    selectPlan(planType: any) {
      this.selectedPlan.emit(planType);
    }

    /**
     * On get started a plan
     * @param user 
     * @param plan 
     */
    onGetStarted(user: string, plan: string) {
      let monthlyPlan: MonthlyPlanModel;
      if (user === 'carrier') {
        if (plan === 'paid') {
          monthlyPlan = CarrierCostingPlan[1];
        } else {
          monthlyPlan = CarrierCostingPlan[0]
        }
        this.selectedPlan.emit(monthlyPlan);
      } else if (user === 'shipper' || user === 'broker') {
        if (plan === 'paid') {
          monthlyPlan = MonthlyPlan[1];
        } else {
          monthlyPlan = MonthlyPlan[0]
        }
        this.selectedPlan.emit(monthlyPlan);
      }
    }

}
