import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { ToastService } from 'src/app/services/toast-service';
import { AccountsPayableService } from 'src/app/core/services/accounts-payable-service/accounts-payable.service';
import { DecimalPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDropdownConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractPagesComponent } from '../AbstractPagesComponent';
import { Subject, Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { Permissions } from 'src/app/core/services/models/permissions.model';
import { takeUntil } from 'rxjs/operators';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { SettingsService } from 'src/app/core/services/settings/settings.service';
import {BranchEvent} from 'src/app/pages/origin-points/origin-point/origin-point.component';
import Swal from 'sweetalert2';
import { AccountPlan, AccountPlanUtil } from 'src/app/core/services/models/account-plan.model';
import { NgbdModalBuyLicenses } from './licenses/modals/buy-licenses/buy-licenses.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [AccountsPayableService, DecimalPipe, NgbDropdownConfig],
})
export class UserCompanySettingsComponent
  extends AbstractPagesComponent
  implements OnInit, OnDestroy
{
  breadCrumbItems: BreadCrumbItem[] = [
    {label: 'Settings'}
  ];
  currentUser: any;
  @Input() activeTab: any;
  @Input() childTab: any;
  @Input() contractDetailsData: any = '';
  showAddContractTemplate = false;
  showViewContractTemplate = false;
  profileTaskRem = 5;

  showMode: boolean = false;
  showRoles: boolean = false;
  showUsers: boolean = false;

  private selectedAccountSubscription: Subscription;
  subscriptions: Subscription[] = [];

  private unsubscribe$ = new Subject<void>();
  currentAccountPlan: AccountPlan;
  
  openModal: boolean = false;

  branchEvent: BranchEvent = {
    type: 'list-branch',
    branch: null
  };

  tabsVisibility = {
    'global_settings_equiupment_compliance': true,
    'global_settings_driver_qualification': true
  }

  constructor(
    public auth: AuthService,
    public toastService: ToastService,
    public service: AccountsPayableService,
    private router: Router,
    public config: NgbDropdownConfig,
    private permissionsService: PermissionsService,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private modalService: NgbModal,
  ) {
    super();
    this.selectedAccountSubscription = this.auth.subscribeToSelectedAccount(
      async () => {
        return this.loadData();
      },
      'Settings'
    );

    config.autoClose = 'outside';
  }

  isDisableIntegration(): boolean {
    const accountType = this.auth.currentAccountSelected.accountType;
    return this.isTrial() && accountType === 'carrier-account';
  }

  isDisableUsers(): boolean {
    const accountType = this.auth.currentAccountSelected.accountType;
    return this.isTrial();
  }

  isTrial(): boolean {
    return AccountPlanUtil.isTrial(this.currentAccountPlan);
  }

  override async loadData(): Promise<void> {
    return;
  }

  get permissions(): Permissions {
    return this.permissionsService.permissions;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
    this.selectedAccountSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    // Init the read of permissions as sometimes when doing refresh it doesnt update the UI
    this.checkReadPermissions();
    this.setTabsVisibilityBasedOnPermissions();
    this.permissionsService.onAuthUserPermissionsChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.checkReadPermissions();
      });
    this.currentUser = this.auth.currentUserValue;
    this.subscriptions.push(
      this.auth.currentAccountPlanSubject.subscribe(
        accountPlan => {
          this.currentAccountPlan = accountPlan;
          this.setTabViaUrlParam();
        }
      )
    );
  }

  setTabViaUrlParam() {
    this.route.params.subscribe((param: any) => {
      this.setActiveTab(this.getActiveTab(param.activeTab));
      this.childTab = this.settingsService.getChildTabNumber(
        param.activeTab , 
        param.activeChildTab,
        this.auth.currentAccountPlanValue
      );
      if(param.activeTab == 'integrations' && param.activeChildTab == 'add') {
        this.openModal = true;
      }
    })
  }

  checkReadPermissions() {
    this.showMode = this.permissions.Modes.Read;
    this.showRoles = this.permissions.Roles.Read;
    this.showUsers = this.permissions.Users.Read;
  }

  setActiveTab(tabId: number, type?: string) {
    if(type === 'nav') {
      if(
        (
          AccountPlanUtil.isTrial(this.currentAccountPlan)
          && (tabId === 5)
        )
        || (
          this.isDisableIntegration() && tabId === 8
        )
        || (
          this.isDisableUsers() && tabId === 7
        )
      ){
        return;
      }
    }
    this.activeTab = tabId;
    this.breadCrumbItems = [
      {label: 'Settings'}
    ];
    if (this.activeTab == 1) {
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Company'}, {label: 'Profile'}];
    } else if (this.activeTab == 2) {
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Service Areas'}, {label: 'Service Areas'}];
    } else if (this.activeTab == 3) {
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Lanes'}, {label: 'Lanes'}];
    } else if (this.activeTab == 4) {
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Branches'}];
    } else if (this.activeTab == 5) {
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Trading Relationships'}, {label: 'Document Templates'}];
    } else if (this.activeTab == 6) {
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Billing and Invoicing'}, {label: 'Billing'}];
    } else if (this.activeTab == 7) {
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Users'}, {label: 'Users'}];
    } else if (this.activeTab == 8) {
      this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Integrations'}];
    }
    this.branchEvent = {
      type: 'list-branch',
      branch: null
    };
  }

  setRoute(tabId: number) {
    if(
      (
        AccountPlanUtil.isTrial(this.currentAccountPlan)
        && (tabId === 5)
      )
      || (
        this.isDisableIntegration() && tabId === 8
      )
      || (
        this.isDisableUsers() && tabId === 7
      )
    ){
      return;
    } else {
      if (tabId == 1) {
        this.router.navigateByUrl('/pages/settings/company/profile');
      } else if (tabId == 2) {
        this.router.navigateByUrl('/pages/settings/zones/service-areas');
      } else if (tabId == 3) {
        this.router.navigateByUrl('/pages/settings/lanes/lanes');
      } else if (tabId == 4) {
        this.router.navigateByUrl('/pages/settings/branches/');
      } else if (tabId == 5) {
        this.router.navigateByUrl('/pages/settings/trading-relationships/document-templates');
      } else if (tabId == 6) {
        this.router.navigateByUrl('/pages/settings/billing-and-invoicing/billing');
      } else if (tabId == 7) {
        this.router.navigateByUrl('/pages/settings/users/users');
      } else if (tabId == 8) {
        this.router.navigateByUrl('/pages/settings/integrations/');
      }
    }
  }

  reloadComponentCancel() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(currentUrl, { state: { activeTab: 7 } });
  }

  breadcrumbItems(event: any) {
    this.breadCrumbItems = [
      ...this.breadCrumbItems, 
      {label: event}
    ];
  }

  getActiveTab(url: string):number {
    if(
      (
        AccountPlanUtil.isTrial(this.currentAccountPlan)
        && url === 'trading-relationships'
      )
      || (
        this.isDisableIntegration() && url === 'integrations'
      )
      || (
        this.isDisableUsers() && url === 'users'
      )
    ){
      return 1;
    }


    switch(url) {
      case 'company':
        return 1;
      case 'zones':
        return 2;
      case 'lanes':
        return 3;
      case 'branches':
        return 4;
      case 'trading-relationships':
        return 5;
      case 'billing-and-invoicing':
        return 6;
      case 'users':
        return 7;
      case 'integrations':
        return 8;
      case 'api-integrations':
        return 8;
      default:
        return 1;
    }
  }

  changeChildTab(event: any, type: string) {
    this.breadCrumbItems = [
      {label: 'Settings'}
    ];
    if(type == 'company') {
      if(event == 1) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Company'}, {label: 'Profile'}];
      } else if(event == 2) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Company'}, {label: 'Public Profile'}];
      } else if(event == 3) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Company'}, {label: 'Modes'}];
      } else if(event == 4) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Company'}, {label: 'Service Types'}];
      } else if(event == 5) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Company'}, {label: 'Accounts Payable'}];
      }
    } else if(type == 'zones') {
      if(event == 1) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Service Areas'}, {label: 'Service Areas'}];
      } else if(event == 2) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Service Areas'}, {label: 'Zones'}];
      } else if(event == 3) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Service Areas'}, {label: 'Rates'}];
      } else if(event == 3) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Service Areas'}, {label: 'Routes'}];
      }
    } else if(type == 'lanes') {
      if(event == 1) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Lanes'}, {label: 'Lanes'}];
      } else if(event == 2) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Lanes'}, {label: 'Setup'}];
      } else if(event == 3) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Lanes'}, {label: 'Rates'}];
      }
    } else if(type == 'trading-relationships') {
      if(event == 1) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Trading Relationships'}, {label: 'Document Templates'}];
      } else if(event == 2) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Trading Relationships'}, {label: 'Contract Signers'}];
      } else if(event == 3) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Trading Relationships'}, {label: 'Pending'}];
      } else if(event == 4) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Trading Relationships'}, {label: 'Accounts Payable'}];
      } else if(event == 5){
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Trading Relationships'}, {label: 'Department Groups'}];
      }
      
    } else if(type == 'billing-and-invoice') {
      if(event == 1) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Billing and Invoice'}, {label: 'Billing'}];
      } else if(event == 2) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Billing and Invoice'}, {label: 'Invoice'}];
      } else if(event == 3) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Billing and Invoice'}, {label: 'Licenses'}];
      }
    } else if(type == 'users') {
      if(event == 1) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Users'}, {label: 'Users'}];
      } else if(event == 2) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Users'}, {label: 'Roles'}];
      } else if(event == 3) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Users'}, {label: 'Chat Requests'}];
      } else if(event == 4) {
        this.breadCrumbItems = [...this.breadCrumbItems, {label: 'Users'}, {label: 'Drivers'}];
      }
      
    }
  }

  promptIntegrationUpgrade(): void {
    if(
      !this.isDisableIntegration()
    ){
      return;
    }
    this.fireUpgrade();
  }

  promptUpgrade(): void {
    if(
      this.auth.currentAccountPlanValue.status === 'paid'
    ){
      return;
    }
    this.fireUpgrade();
  }

  private fireUpgrade(){
    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
      }
    });
  }

  /**
   * Set tabs visibility based on permissions
   */
  setTabsVisibilityBasedOnPermissions() {
    this.tabsVisibility.global_settings_equiupment_compliance = this.permissionsService.isUserHasFeatureAccess("equiupment_compliance");
    this.tabsVisibility.global_settings_driver_qualification = this.permissionsService.isUserHasFeatureAccess("driver_qualification_files");
  }
}
