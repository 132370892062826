<div class="row">
    <div class="col-lg-3">
        <button
            type="button" 
            class="btn btn-primary add-btn" 
            data-bs-toggle="modal" 
            id="create-btn"
            data-bs-target="#showModal"
            (click)="openServiceWindowModal(serviceWindowContent)">
            <i class="ri-add-line align-bottom me-1"></i> Add Service Window
        </button>
    </div>
    <div class="col-lg-5"></div>
    <div class="col-lg-4">
        <div class="col-sm">
            <div class="d-flex justify-content-sm-end">
                <div class="search-box ms-2">
                    <input type="text" class="form-control search" 
                        placeholder="Search..."
                        (input)="tt.filterGlobal(getEventValue($event), 'contains')">
                    <i class="ri-search-line search-icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<p-treeTable #tt [value]="files2" [columns]="cols2">
    <ng-template pTemplate="header" let-rowNode let-columns>
        <tr [ttRow]="rowNode">
            <th *ngFor="let col of columns">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{col.header}}
            </th>
            <th>
                Action
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
        <tr>
            <td *ngFor="let col of columns; let i = index">
                <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                <a *ngIf="rowData.type == 'service-window'" 
                    style="text-decoration: underline !important; color: #405189; cursor: pointer;"
                    (click)="openServiceWindowModal(serviceWindowContent, rowData.data)">{{rowData[col.field]}}
                </a>
                <a *ngIf="rowData.type == 'service-type'" 
                    style="text-decoration: underline !important; color: #405189; cursor: pointer;"
                    (click)="openServiceTypeModal(serviceTypeContent, rowNode.parent.data.data, rowData.data)">{{rowData[col.field]}}
                </a>
            </td>
            <td>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="add" *ngIf="rowData.type == 'service-window'">
                            <button class="btn btn-primary add-service-type-btn"
                                (click)="openServiceTypeModal(serviceTypeContent, rowData.data)">
                                <i class="ri-add-line align-bottom me-1"></i> Add Service Type
                            </button>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-treeTable>

<!-- Service Type Modal -->
<ng-template #serviceTypeContent role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{currentServiceType? 'Update': 'Add'}} Service Type</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click');"></button>
    </div>
    <form class="app-serice-types-modal" [formGroup]="serviceTypeForm" (ngSubmit)="submitServiceType()">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Name: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p-dropdown 
                        name="name"
                        [options]="SERVICE_TYPE_NAME_OPTIONS[selectedMode?.type?.toUpperCase()!] || []"
                        optionLabel="name" optionValue="name"
                        [autoDisplayFirst]="false"
                        formControlName="name"
                        scrollHeight="none"
                        [ngClass]="{ 'is-invalid': submitted && serviceTypeControls['name'].errors }">
                    </p-dropdown>
                    <div *ngIf="submitted && serviceTypeControls['name'].errors" 
                        class="invalid-feedback" align="left">
                        <div *ngIf="serviceTypeControls['name'].errors['required']">Select type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="averageDeliveryTime" class="form-label">Average Delivery Time: </label>
                </div>
                <div class="col-lg-9">
                    <div class="d-flex gap-1 flex-fill me-1">
                        <input type="number" min="1" id="averageDeliveryTime" class="form-control" required 
                            formControlName="averageDeliveryTime"
                            [ngClass]="{ 
                                'is-invalid': submitted && serviceTypeControls['averageDeliveryTime'].errors,
                                'w-11': true
                            }" 
                        />
                        <span class="user-form-label">minutes</span>
                    </div>
                    <div *ngIf="submitted && serviceTypeControls['averageDeliveryTime'].errors" 
                        class="text-danger" align="left">
                        <div *ngIf="serviceTypeControls['averageDeliveryTime'].errors['required']">Enter Average Delivery Time</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="averageLoadOutTime" class="form-label">Average Load Out Time: </label>
                </div>
                <div class="col-lg-9">
                    <div class="d-flex gap-1 flex-fill me-1">
                        <input type="number" min="1" id="averageLoadOutTime" class="form-control" required 
                            formControlName="averageLoadOutTime"
                            [ngClass]="{ 
                                'is-invalid': submitted && serviceTypeControls['averageLoadOutTime'].errors,
                                'w-11': true
                            }" 
                        />
                        <span class="user-form-label">minutes per stop</span>
                    </div>
                    <div *ngIf="submitted && serviceTypeControls['averageLoadOutTime'].errors" 
                        class="text-danger" align="left">
                        <div *ngIf="serviceTypeControls['averageLoadOutTime'].errors['required']">Enter Average Load Out Time</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Skills: </label>
                </div>
                <div class="col-lg-9">
                    <table class="table align-middle table-nowrap mb-0 mt-n2" *ngIf="selectedServiceTypeSkillNames.length != 0">
                        <tbody>
                            <tr *ngFor="let skillName of selectedServiceTypeSkillNames">
                                <td class="w-70">
                                    {{ skillName }}
                                </td>
                                <td>
                                    <div class="d-flex gap-2">
                                        <div class="remove">
                                            <button class="btn btn-sm btn-danger remove-item-btn" (click)="skillUnselected(skillName)">Delete</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p-dropdown 
                        name="skills"
                        placeholder="Select Skill"
                        [options]="currentServiceTypeSkillNameOptions"
                        optionLabel="name" optionValue="name"
                        [disabled]="true"
                        (onChange)="skillSelected($event)"
                        [autoDisplayFirst]="false">
                    </p-dropdown>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Vehicles: </label>
                </div>
                <div class="col-lg-9">
                    <table class="selected-vehicles-container table align-middle table-nowrap mb-0 mt-n2" *ngIf="selectedTruckTrailerTypeIds.length != 0">
                        <tbody>
                            <tr *ngFor="let truckTrailerTypes of selectedTruckTrailerTypeIds">
                                <td class="w-70">
                                    {{ truckTypeIdMap[truckTrailerTypes.truckTypeId].name }}
                                    {{ !truckTrailerTypes.trailerTypeId || truckTrailerTypes.trailerTypeId === 'none' ? '' : ' with ' + trailerTypeIdMap[truckTrailerTypes.trailerTypeId].name }}
                                    &nbsp;
                                    {{getTotalWeightAndVolumeDisplay(truckTrailerTypes)}}
                                </td>
                                <td>
                                    <div class="d-flex gap-2">
                                        <div class="remove">
                                            <button class="btn btn-sm btn-danger remove-item-btn" (click)="truckTrailerTypeUnselected(truckTrailerTypes)">Delete</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex gap-1 me-1 vehicle-dropdown-container">
                        <p-dropdown
                            (onChange)="truckTrailerTypeSelected()"
                            placeholder="Select Truck"
                            [(ngModel)]="currentSelectedTruckTypeId"
                            [ngModelOptions]="{standalone: true}"
                            [options]="truckTypeOptions"
                            optionLabel="prettyName" optionValue="technicalName"
                            [autoDisplayFirst]="false">
                        </p-dropdown>
                        <p-dropdown
                            (onChange)="truckTrailerTypeSelected()"
                            placeholder="Select Trailer"
                            [(ngModel)]="currentSelectedTrailerTypeId"
                            [ngModelOptions]="{standalone: true}"
                            [options]="trailerTypeOptions"
                            optionLabel="prettyName" optionValue="technicalName"
                            [autoDisplayFirst]="false">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex gap-2 p-4" [ngClass]="{'justify-content-end': !currentServiceType}">
            <span *ngIf="currentServiceType" class="clickable-column me-auto d-flex align-items-center text-danger">
                <div *ngIf="!loading" class="inline-block"
                    (click)="deleteServiceType()">
                    Delete  
                </div>
                <div *ngIf="loading"
                    class="spinner-border inline-block service-type-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </span>
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
            <button type="submit" class="btn btn-primary action-btn" id="add-btn">
                <div *ngIf="!loading"   
                    class="inline-block">
                    Save  
                </div>
                <div *ngIf="loading"  
                    class="spinner-border inline-block service-type-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
    </form>
</ng-template>

<!-- Service Window Modal -->
<ng-template serviceWindowContent #serviceWindowContent role="document" let-modal>
    <div class="bg-light p-3 d-flex justify-content-between">
        <div></div>
        <h5 class="modal-title" id="exampleModalLabel">{{currentServiceWindow? 'Update': 'Add'}} Service Window For {{selectedMode?.name}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click');"></button>
    </div>
    <form class="app-serice-types-modal" [formGroup]="serviceWindowForm" (ngSubmit)="submitServiceWindow()">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="type" class="form-label">Type: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p-dropdown 
                        name="type"
                        [options]="SERVICE_WINDOW_TYPE_OPTIONS[selectedMode?.type?.toUpperCase()!] || []"
                        optionLabel="name" optionValue="name"
                        [autoDisplayFirst]="false"
                        formControlName="type"
                        scrollHeight="none"
                        (onChange)="typeChange()"
                        [ngClass]="{ 'is-invalid': submitted && serviceWindowControls['type'].errors }">
                    </p-dropdown>
                    <div *ngIf="submitted && serviceWindowControls['type'].errors" 
                        class="invalid-feedback" align="left">
                        <div *ngIf="serviceWindowControls['type'].errors['required']">Select type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="isDutyStatusDay" class="form-label">Duty Status: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p-dropdown 
                        name="isDutyStatusDay"
                        [options]="DUTY_STATUS_OPTIONS"
                        optionLabel="prettyName" optionValue="techName"
                        [autoDisplayFirst]="false"
                        formControlName="isDutyStatusDay"
                        scrollHeight="none"
                        (onChange)="timeChange()"
                        [ngClass]="{ 'is-invalid': submitted && serviceWindowControls['isDutyStatusDay'].errors }"
                    >
                    </p-dropdown>
                    <div *ngIf="submitted && serviceWindowControls['isDutyStatusDay'].errors" 
                        class="invalid-feedback" align="left">
                        <div *ngIf="serviceWindowControls['isDutyStatusDay'].errors['required']">Select Duty Status</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Name: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p *ngIf="!edittingName" class="user-form-label d-inline-block me-4 w-50">{{serviceWindowControls['name'].value}}</p>
                    <a *ngIf="!edittingName && serviceWindowControls['type'].value" class="clickable-column" (click)="editName()">Edit</a>
                    <input *ngIf="edittingName" type="text" 
                        [disabled]="!edittedName" class="form-control d-inline-block me-4 w-50" 
                        [(ngModel)]="edittedName" [ngModelOptions]="{standalone: true}"/>
                    <a *ngIf="edittingName" class="clickable-column" (click)="closeEdittingName()">Close</a>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Mode: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p class="user-form-label">{{selectedMode?.name}}</p>
                </div>
            </div>
            <div class="row mb-3" (click)="onFieldClick()">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Pickup Start Time: <span *ngIf="accountPlanStatus !== 'trial_expired'" class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p-dropdown 
                        name="pickupStartTime"
                        [disabled]="accountPlanStatus === 'trial_expired' || accountPlanStatus === 'trial'"
                        [options]="pickupStartTimeOptions"
                        optionLabel="prettyName" optionValue="techName"
                        [autoDisplayFirst]="false"
                        formControlName="pickupStartTime"
                        [ngClass]="{ 'is-invalid': submitted && serviceWindowControls['pickupStartTime'].errors }"
                        (onChange)="timeChange()">
                    </p-dropdown>
                    <div *ngIf="submitted && serviceWindowControls['pickupStartTime'].errors" 
                        class="invalid-feedback" align="left">
                        <div *ngIf="serviceWindowControls['pickupStartTime'].errors['required']">Select type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3" (click)="onFieldClick()">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Pickup End Time: <span *ngIf="accountPlanStatus !== 'trial_expired'" class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p-dropdown 
                        name="pickupEndTime"
                        [disabled]="accountPlanStatus === 'trial_expired' || accountPlanStatus === 'trial'"
                        [options]="pickupEndTimeOptions"
                        optionLabel="prettyName" optionValue="techName"
                        [autoDisplayFirst]="false"
                        formControlName="pickupEndTime"
                        [ngClass]="{ 'is-invalid': submitted && serviceWindowControls['pickupEndTime'].errors }"
                        (onChange)="timeChange()">
                    </p-dropdown>
                    <div *ngIf="submitted && serviceWindowControls['pickupEndTime'].errors" 
                        class="invalid-feedback" align="left">
                        <div *ngIf="serviceWindowControls['pickupEndTime'].errors['required']">Select type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3" (click)="onFieldClick()">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Request Cutoff Time: <span *ngIf="accountPlanStatus !== 'trial_expired'" class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9" *ngIf="!serviceWindowControls['type'].value || serviceWindowControls['type'].value === 'Same Day'">
                    <p-dropdown 
                        name="requestCutoffTime"
                        [disabled]="accountPlanStatus === 'trial_expired' || accountPlanStatus === 'trial'"
                        [options]="requestCutoffTimeOptions"
                        optionLabel="prettyName" optionValue="techName"
                        [autoDisplayFirst]="false"
                        formControlName="requestCutoffTime"
                        [ngClass]="{ 'is-invalid': submitted && serviceWindowControls['requestCutoffTime'].errors }"
                        (onChange)="timeChange()">
                    </p-dropdown>
                    <div *ngIf="submitted && serviceWindowControls['requestCutoffTime'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="serviceWindowControls['requestCutoffTime'].errors['required']">Select type</div>
                    </div>
                </div>
                <div class="col-lg-9" *ngIf="serviceWindowControls['type'].value && serviceWindowControls['type'].value !== 'Same Day'">
                    <p class="user-form-label">
                        {{getTimeDisplay(serviceWindowControls['requestCutoffTime'].value)}}
                    </p>
                </div>
            </div>
            <div class="row mb-3" (click)="onFieldClick()">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Delivery Before: <span *ngIf="accountPlanStatus !== 'trial_expired'" class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p-dropdown 
                        name="deliveryBefore"
                        [disabled]="accountPlanStatus === 'trial_expired' || accountPlanStatus === 'trial'"
                        [options]="deliveryBeforeOptions"
                        optionLabel="prettyName" optionValue="techName"
                        [autoDisplayFirst]="false"
                        formControlName="deliveryBefore"
                        [ngClass]="{ 'is-invalid': submitted && serviceWindowControls['deliveryBefore'].errors }"
                        (onChange)="timeChange()">
                    </p-dropdown>
                    <div *ngIf="submitted && serviceWindowControls['deliveryBefore'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="serviceWindowControls['deliveryBefore'].errors['required']">Select type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3" (click)="onFieldClick()">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Delivery Windows: <span *ngIf="accountPlanStatus !== 'trial_expired'" class="text-danger">*</span></label>
                </div>
                <div class="col-lg-2">
                    <p-dropdown 
                        name="deliveryWindowTime"
                        [disabled]="accountPlanStatus === 'trial_expired' || accountPlanStatus === 'trial'"
                        [options]="deliveryWindowOptions"
                        optionLabel="prettyName" optionValue="techName"
                        [autoDisplayFirst]="false"
                        formControlName="deliveryWindowTime"
                        [ngClass]="{ 'is-invalid': submitted && serviceWindowControls['deliveryWindowTime'].errors }">
                    </p-dropdown>
                    <div *ngIf="submitted && serviceWindowControls['deliveryWindowTime'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="serviceWindowControls['deliveryWindowTime'].errors['required']">Select Delivery Window</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Service Days: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <div class="field-checkbox" *ngFor="let day of days"
                        [ngClass]="{ 'is-invalid': submitted && serviceWindowControls['serviceDays'].errors }">
                        <p-checkbox [name]="day" [value]="day"
                            [formControl]="$any(serviceWindowControls['serviceDays'])">
                        </p-checkbox>
                        {{day}}
                    </div>
                    <div class="invalid-feedback">
                        Pick at least 1 day
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex gap-2 p-4" [ngClass]="{'justify-content-end': !currentServiceWindow}">
            <span *ngIf="currentServiceWindow" class="clickable-column me-auto d-flex align-items-center text-danger">
                <div *ngIf="!loading" class="inline-block"
                    (click)="deleteServiceWindow()">
                    Delete  
                </div>
                <div *ngIf="loading"
                    class="spinner-border inline-block service-type-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </span>
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
            <button type="submit" class="btn btn-primary action-btn" id="add-btn">
                <div *ngIf="!loading"   
                    class="inline-block">
                    Save  
                </div>
                <div *ngIf="loading"  
                    class="spinner-border inline-block service-type-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
    </form>
</ng-template>