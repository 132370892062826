<div class="table-responsive table-card mt-3 mb-1">
    <table class="table align-middle table-nowrap mb-0">
        <thead class="table-light">
            <tr>
                <th scope="col">{{selectedAccount?.type === 'carrier-account' ? 'Shipper/Freight Broker' : selectedAccount?.type === 'shipper-account' ? 'Carrier' : ''}}</th>
                <th scope="col">Route</th>
                <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody *ngIf="!isLoading && tradingRelationships.length != 0"> 
            <tr *ngFor="let tradingRelationhhip of tradingRelationships">
                <td class="carrier-cell">{{tradingRelationhhip.carrierName}}</td>
                <td>{{tradingRelationhhip.routeName}}</td>
                <td>{{tradingRelationhhip.status | titlecase}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="isLoading">
            <tr class="loader-body">
                <td class="placeholder-glow name-td carrier-cell">
                    <span class="placeholder col-6"></span>
                </td>
                <td class="placeholder-glow">
                    <span class="placeholder col-6"></span>
                </td>
                <td class="placeholder-glow">
                    <span class="placeholder col-6"></span>
                </td>
                <td class="placeholder-glow">
                    <span class="placeholder col-6"></span>
                </td>
                <td class="placeholder-glow">
                    <span class="placeholder col-6"></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>