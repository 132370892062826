import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { NgbdModalBuyLicenses } from './modals/buy-licenses/buy-licenses.component';
import { NgbdModalContactSales } from './modals/contact-sales/contact-sales.component';
import { NgbdModalLicensesList } from './modals/licenses-list/licenses-list.component';
import { ILicense, SuccessApiResponse } from 'src/app/core/services/models/models';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss'],

})
export class LicensesComponent implements OnInit {
  breadCrumbItems!: Array<{}>;
  activeModal?: NgbModalRef;

  tableLoading: boolean = false;
  licenses: ILicense[] = [];
  availableForEverFreeLicenses: ILicense[] = [];
  activeForEverFreeLicenses: ILicense[] = [];
  availableTrialWithComplianceLicenses: ILicense[] = [];
  activeTrialWithComplianceLicenses: ILicense[] = [];
  availablePreSaleLicenses: ILicense[] = [];
  activePreSaleLicenses: ILicense[] = [];
  availableForEverLicenses: ILicense[] = [];
  activeForEverLicenses: ILicense[] = [];
  availablePaidLicenses: ILicense[] = [];
  activePaidLicenses: ILicense[] = [];

  driverOnlyAvailable = 0;
  trucks = 0;
  driverWithHelperAvailable = 0;
  driverOnlyActiveLoadOnBoard = 0;
  driverWithHelperActiveLoadOnBoard = 0;
  availableForRefund = 0;
  driverOnlyActive = 0;
  driverWithHelperActive = 0;
  loadSpinner = false;

  companyInfo?: CompanyInfo;
  availablePowerUnits: number;

  constructor(
    private modalService: NgbModal,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private httpRequest: HttpService,
  ) {
   
  }

  ngOnInit(): void {
    this.breadCrumbItems = [];
    this.getAllLicenses();
    this.getAvailableLicenses();
    this.getActiveOnLoadBoardLicenses();
    this.getActiveLicenses();
    setTimeout(() => {
      this.fetchCompanyInfo();
    }, 500);
  }

  getAllLicenses() {
    this.tableLoading = true;
    this.httpRequest.getAllLicenses().subscribe((res: {data: ILicense[]}) => {
      this.tableLoading = false;
      if(res?.data?.length) {
        this.licenses = res?.data;
        /* const forEverFree = res?.data?.filter(el => el.product_name?.includes("Carrier Free For Ever") && (el?.expiration_date * 1000 > Date.now()));
        const trialWithCompliance = res?.data?.filter(el => el?.product_name?.includes("Carrier FEF Plus Compliance") && (el?.expiration_date * 1000 > Date.now())); */
        const presaleLicenses = res?.data?.filter(el => el?.product_name?.toLowerCase()?.includes("calps") && !el?.cancelled);
        const foreverLicenses = res?.data?.filter(el => {
          return el?.product_name?.toLowerCase()?.includes("cfeal") && !el?.cancelled;
        });
        /* this.availableTrialWithComplianceLicenses = trialWithCompliance;
        this.activeTrialWithComplianceLicenses = trialWithCompliance; */
        this.availablePreSaleLicenses = presaleLicenses;
        this.availableForEverLicenses = foreverLicenses;
        this.activePreSaleLicenses = []; //res?.data?.filter(el => el?.product_name?.toLowerCase()?.includes("pre-sale") && !el?.cancelled);
        // this.availableForEverFreeLicenses = forEverFree;
        this.activeForEverLicenses = [];
      }
    })
  }

  getAvailableLicenses() {
    this.driverWithHelperAvailable = 0;
    this.trucks = 0;
    this.httpRequest.getAvailableLicenses().subscribe((data) => {
      if(data.data.length != 0) {
        for(let x = 0 ; x < data.data.length ; x++) {
          if(data.data[x].hasHelper) {
            this.driverWithHelperAvailable += 1;
          } else {
            this.driverOnlyAvailable += 1;
            this.trucks += 1;
          }
        }
      }
    })
  }

  getActiveOnLoadBoardLicenses() {
    this.driverWithHelperActiveLoadOnBoard = 0;
    this.driverOnlyActiveLoadOnBoard = 0;
    this.httpRequest.getUsedLicenses().subscribe((data) => {
      if(data.data.length != 0) {
        for(let x = 0 ; x < data.data.length ; x++) {
          if(data.data[x].hasHelper) {
            this.driverWithHelperActiveLoadOnBoard += 1;
          } else {
            this.driverOnlyActiveLoadOnBoard += 1;
          }
        }
      }
    })
  }

  getActiveLicenses() {
    this.driverWithHelperActive = 0;
    this.driverOnlyActive = 0;
    this.httpRequest.getActiveLicenses().subscribe((data) => {
      if(data.data.length != 0) {
        for(let x = 0 ; x < data.data.length ; x++) {
          if(data.data[x].hasHelper) {
            this.driverWithHelperActive += 1;
          } else {
            this.driverOnlyActive += 1;
          }
        }
      }
    })
  }

  openModalBuyLicense() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
    modalRef.componentInstance.data = {
      availablePreSaleAssets: this.availablePowerUnits,
      availableForEverAssets: this.availablePreSaleLicenses.length
    };
    
    modalRef.result.then((result: any) => { 
      if(result) {
        this.getAvailableLicenses();
        this.getActiveOnLoadBoardLicenses();
        this.getActiveLicenses();
      }
     })
  }

  openModalContactSales() {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalContactSales, { size: 'xl', centered: true });
  }

  openModalLicensesList(type: string) {
    this.activeModal?.close();
    let modalRef = this.modalService.open(NgbdModalLicensesList, { size: 'xl', centered: true });
    switch (type) {
      case 'Trial With Compliance':
        modalRef.componentInstance.licenses = [...this.availableTrialWithComplianceLicenses];
        break;
      case 'Pre Sale':
        modalRef.componentInstance.licenses = [...this.availablePreSaleLicenses];
        break;
      case 'ForEver':
        modalRef.componentInstance.licenses = [...this.availableForEverLicenses];
        break;        
      default:
        modalRef.componentInstance.licenses = [...this.availableForEverFreeLicenses];
        break;
    }
    modalRef.componentInstance.licenseType = type;
    modalRef.componentInstance.refetchLicenses = this.getAllLicenses;
    }

  refresh() {
    this.driverOnlyAvailable = 0;
    this.trucks = 0;
    this.driverWithHelperAvailable = 0;
    this.driverOnlyActiveLoadOnBoard = 0;
    this.driverWithHelperActiveLoadOnBoard = 0;
    this.driverOnlyActive = 0;
    this.driverWithHelperActive = 0;
    this.loadSpinner = true;

    this.httpRequest.getAvailableLicenses().subscribe((data) => {
      if(data.data.length != 0) {
        for(let x = 0 ; x < data.data.length ; x++) {
          if(data.data[x].hasHelper) {
            this.driverWithHelperAvailable += 1;
          } else {
            this.trucks += 1;
          }
        }
      }
      this.httpRequest.getUsedLicenses().subscribe((data) => {
        if(data.data.length != 0) {
          for(let x = 0 ; x < data.data.length ; x++) {
            if(data.data[x].hasHelper) {
              this.driverWithHelperActiveLoadOnBoard += 1;
            } else {
              this.driverOnlyActiveLoadOnBoard += 1;
            }
          }
        }
        this.httpRequest.getActiveLicenses().subscribe((data) => {
          this.loadSpinner = false;
          if(data.data.length != 0) {
            for(let x = 0 ; x < data.data.length ; x++) {
              if(data.data[x].hasHelper) {
                this.driverWithHelperActive += 1;
              } else {
                this.driverOnlyAvailable += 1;
                this.driverOnlyActive += 1;
              }
            }
          }
        })
      })
    })
  }

  upgrade(type: string) {
    let modalRef = this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
    modalRef.componentInstance.data = {
      type: type,
      upgradeTo: 'CFEAL',
      quantity: this.availablePreSaleLicenses.length,
      availableForEverAssets: this.availablePreSaleLicenses.length,
      availablePreSaleAssets: this.availablePowerUnits
    };
  }

  private fetchCompanyInfo(): void {

    const selectedBranchStr = sessionStorage.getItem('selectedBranch');
    const selectedBranch = selectedBranchStr ? JSON.parse(selectedBranchStr) : null;

    if(!selectedBranch) return;

    this.httpRequest.getCompanyInfos(selectedBranch.companyInfoId).subscribe(
      res => {
        const successRes = <SuccessApiResponse<CompanyInfo>> res;
        this.companyInfo = successRes.data;
        this.availablePowerUnits = this.companyInfo?.totalPowerUnits;
        if (this.availablePreSaleLicenses.length > 0) {
          this.availablePowerUnits = this.companyInfo?.totalPowerUnits - this.availablePreSaleLicenses.length;
        }
        console.log("companyInfo", this.companyInfo);
      },
      error =>{
        console.error('Failed to fetch company info: ' + error.error.reason);
        this.companyInfo = undefined;
      }
    )
  }

}
