import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GeoLocateResponse } from "./models/map.model";

import ZIP_LNG_LAT from '../../../assets/jsons/zip-lng-lat.json';

@Injectable({ providedIn: 'root' })
export class MapService {
    readonly API_KEY = 'AIzaSyCmaLjr3UqS0vazENKvzV_J5UYhP2-RUDQ';
    readonly CHICAGO = { lat: 41.850033, lng: -87.6500523 };
    readonly GRAPHHOPPER_API_KEY = '22092fe1-9b35-4857-bd5e-42b785c68fba';
    readonly GRAPHHOPPER_API_URL = 'https://graphhopper.com/api/1/';
    readonly USA_LAT_LNG = { lat: 40.004476, lng: -99.244309 };

    private readonly GEO_LOCATE_API = `https://www.googleapis.com/geolocation/v1/geolocate?key=${this.API_KEY}`;
    
    constructor(private http: HttpClient) {
    }

    geoLocateIP(): Observable<GeoLocateResponse> {
        return this.http.post<GeoLocateResponse>(this.GEO_LOCATE_API, {considerIp: true});
    }

    /**
     * Get Graphhopper Route
     * @param params 
     * @returns 
     */
    getGraphhopperRoute(params: any = {}): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = `${this.GRAPHHOPPER_API_URL}route?key=${this.GRAPHHOPPER_API_KEY}`;
        params = {
            profile: "truck",
            points_encoded: false,
            ...params
        };

        return this.http.post(url, params, {headers});
    }

    /**
     * Convert Meters to Miles
     * @param meters 
     * @returns 
     */
    convertMeterToMiles(meters: number) {
        return meters / 1609.344;
    }

    /**
     * Convert Milliseconds to Time
     * @param milliseconds 
     * @returns 
     */
    convertMillisecondsToTime(milliseconds: number) {
        const minutes = Math.floor(milliseconds / 60000); // 1 minute = 60,000 milliseconds
        const hours = Math.floor(minutes / 60);
      
        return `${hours}:${minutes % 60}`;
      }
}