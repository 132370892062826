import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { MapService } from 'src/app/core/services/map.service';
import { AddressComponent } from 'src/app/core/services/models/address-component.model';
declare const google: any;

export interface AddressComponentAuto {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface LatLngLiteral {
  lat: () => number;
  lng: () => number;
}

export interface Bounds {
  northeast: LatLngLiteral;
  southwest: LatLngLiteral;
}

export interface Gemotery {
  location: LatLngLiteral;
  viewport: Bounds;
}

const ENTER_KEY = 'Enter';

// read https://developers.google.com/maps/documentation/javascript/places-autocomplete#session_tokens for session billing
// used Autocomplete Widget, so automatically uses session: https://developers.google.com/maps/documentation/javascript/places-autocomplete#summary-of-classes
@Component({
  selector: 'app-google-maps-autocomplete',
  templateUrl: './google-maps-autocomplete.component.html',
  styleUrls: ['./google-maps-autocomplete.component.scss']
})
export class GoogleMapsAutocompleteComponent implements OnInit, AfterViewInit {

  @Input() addressTypes: ("establishment" | "address" | "geocode" )[] = [];
  @Output() onPlaceChanged: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();
  @Output() onPlaceChangeIsEmpty: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('addresstext') addresstext!: ElementRef<HTMLInputElement>;
  @Input() isRequiredPostalCode = false;
  @Input() isRequired = false;

  autocompleteInput!: any;
  queryWait!: boolean;

  formErrors: Partial<{
    address: string; //'required' | 'noPostalCode';
  }> = {};
  address?: google.maps.places.PlaceResult;


  constructor(
    private mapService: MapService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let loader = new Loader({
      apiKey: this.mapService.API_KEY,
      libraries: ['places'],
      version: 'weekly'
    });
    loader.load().then(() => {
      this.getPlaceAutocomplete();
    });
    
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'US' },
        types: this.addressTypes,
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
    
    google.maps.event.addDomListener(this.addresstext.nativeElement, 'keydown', (event: KeyboardEvent) => {
      if (event.key !== ENTER_KEY) {
        return;
      }

      // Check if any of Google's dropdown DOM nodes is visible. If yes, prevent default (i.e. submit of form)
      const googleDOMNodes = document.getElementsByClassName('pac-container') as HTMLCollectionOf<HTMLElement>;
      const googleDOMNodeIsVisible = (
        Array.from(googleDOMNodes).some(node => node.offsetParent !== null)
      );
      if (googleDOMNodeIsVisible) {
        event.preventDefault();
      }
    });
  }

  invokeEvent(place: google.maps.places.PlaceResult) {
    this.address = place;
    this.validateForm();
    this.onPlaceChanged.emit(place);
  }

  changeAddress(event: any) {
    if(this.autocompleteInput == '') {
      this.onPlaceChangeIsEmpty.emit(true);
    } else {
      this.onPlaceChangeIsEmpty.emit(false);
    }
  }

  validateForm(): boolean {
    console.log('[GoogleMapsSearchBoxComponent] [validateForm]');
    const validAddress = this.isValidAddress();
    return validAddress;
  }

  isValidAddress(): boolean {
    if (!this.address) {
      if (this.isRequired) {
        this.formErrors.address = 'Enter Address';
        return false;
      } else {
        delete this.formErrors.address;
        return true;
      }
    }

    const branchAddress = new AddressComponent(this.address);

    if(this.isRequiredPostalCode){
      if (!branchAddress.postalCode){
        this.formErrors.address = 'Postal code is missing';
        return false;
      }

      if(!branchAddress.street1){
        this.formErrors.address = 'Street 1 is missing';
        return false
      }

      if(!branchAddress.streetNumber) {
        this.formErrors.address = 'Street number is missing or invalid';
        return false
      }
    }

    delete this.formErrors.address;
    return true;
  }

}
