
<div class="modal-header bg-light p-3">
    <h5 class="modal-title" id="exampleModalLabel" style="margin: 0 auto">
        <span *ngIf="origServiceArea">{{origServiceArea.name}}</span>
        <span *ngIf="!origServiceArea">Add Service Area</span>
    </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click');" style="margin-left: 0"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="app-serice-areas-modal col-lg-3" (ngSubmit)="submitServiceArea()">
            <div class="card">
                <div class="card-header">
                    <ul ngbNav #customNav="ngbNav" [destroyOnHide]="false" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                        <li [ngbNavItem]="tabEnum.SERVICE_AREA" class="nav-item" (click)="setActiveTab(tabEnum.SERVICE_AREA)">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                Service Area
                            </a>
                            <ng-template ngbNavContent>
                                <div class="row mb-4">
                                    <div class="row">
                                        <label for="name" class="form-label">Name: <span class="text-danger">*</span></label>
                                    </div>
                                    <div class="col-lg-9">
                                        <input type="text" id="name" class="form-control"
                                            [(ngModel)]="serviceAreaForm.name"
                                            [placeholder]="'Enter Service Area Name'"
                                            [ngClass]="{'is-invalid': submitted && formError.serviceAreaName}" />
                                        <div class="invalid-feedback" *ngIf="submitted && formError.serviceAreaName" align="left">
                                            <div *ngIf="formError.serviceAreaName === 'required'">Enter name</div>
                                            <div *ngIf="formError.serviceAreaName === 'non-unique'">Name is not unique</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="form-label">Branches: <span class="text-danger">*</span></label>
                                </div>
                                <div class="row mb-3">
                                    <div class="">
                                        <app-tree-checkbox [options]="branches" [(selected)]="selectedBranches"></app-tree-checkbox>
                                        <div class="text-danger" *ngIf="submitted && selectedBranches.length === 0">
                                            <small>Pick at least 1 branch</small>
                                        </div>
                                        <div class="d-flex mt-3">
                                            <div class="branches-container text-muted">
                                                <small>* Hold Shift Key while clicking checkbox to prevent propagation X.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="tabEnum.ZONES" class="nav-item" (click)="setActiveTab(tabEnum.ZONES)">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                Zones
                            </a>
                            <ng-template ngbNavContent>
                                <p-table
                                    [value]="getLeftMenuZones()"
                                    selectionMode="single" 
                                    [(selection)]="selectedZone"
                                    dataKey="zoneName"
                                    (onRowSelect)="onZoneSelect()"
                                    (onRowUnselect)="onZoneSelect()"
                                >
                                    <ng-template pTemplate="body" let-zone let-rowIndex="rowIndex">
                                        <tr [pSelectableRow]="zone">
                                            <td *ngIf="currentEditZoneIndex !== rowIndex - 1">
                                                <i class="las la-edit fs-4" (click)="currentEditZoneIndex = rowIndex - 1" *ngIf="rowIndex > 0"></i> 
                                                &nbsp;&nbsp; {{zone.zoneName}}
                                            </td>
                                            <td *ngIf="currentEditZoneIndex === rowIndex - 1">
                                                <div class="d-flex">
                                                    <input #zoneNameElement type="text" class="form-control" [value]="zone.zoneName"
                                                        (keyup)="onKeyupEditZone(zone, zoneNameElement.value)"
                                                    />
                                                    <button class="btn btn-primary action-btn ms-2" 
                                                        (click)="onClickSaveZone(zone, zoneNameElement.value)">
                                                        Save
                                                    </button>
                                                    </div>
                                                <div class="invalid-feedback" [class.has-error]="currentEditZoneError">
                                                    {{currentEditZoneError}}
                                                </div>
                                            </td>
                                            <td>
                                                <div style="width:25px;height:25px" [style.background-color]="zone.color" [class.has-error-margin]="currentEditZoneError"></div>
                                            </td>
                                            <td>
                                                <span *ngIf="rowIndex > 0" class="clickable-column text-danger"
                                                    [class.has-error-margin]="currentEditZoneError"
                                                    (click)="onClickRemoveZone(rowIndex - 1)"
                                                    [class.disabled]="currentEditZoneIndex === rowIndex - 1"
                                                >
                                                    Delete
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div class="text-center mt-2">
                                    <button class="btn btn-primary action-btn" 
                                        (click)="onClickAddZone()">
                                        Add
                                    </button>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="tabEnum.UNUSED" class="nav-item" (click)="setActiveTab(tabEnum.UNUSED)">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                Unused ({{unusedZipCodes.length}})
                            </a>
                            <ng-template ngbNavContent>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="tabEnum.DATA" class="nav-item">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                Data
                            </a>
                            <ng-template ngbNavContent>
                                <div class="row mb-2">
                                    <div (click)="onSpecialFieldsClick()">
                                        <label>Upload Heatmap Zip Codes: </label>
                                        <input type="file" class="form-control" [ngClass]="{ 'pe-none': accountPlanStatus === 'trial_expired' }" (change)="onHeatmapUpload($event)">
                                    </div>
                                </div>
                                <div class="flex-shrink-0" (click)="onSpecialFieldsClick()">
                                    <a class="btn btn-primary" [ngClass]="{ 'pe-none': accountPlanStatus === 'trial_expired' }" id="download-btn" 
                                        download="example-heatmap-data.csv"
                                        href="/assets/example-heatmap-data.csv">
                                        Download Example File
                                    </a>
                                </div>
                                <div class="row mb-4">
                                    <div>
                                        <div class="form-check form-switch form-switch-md mt-2" (click)="onSpecialFieldsClick()">
                                            <label class="form-check-label" for="showHeatMap">Show Heatmap:</label>
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="showHeatMap"
                                                [(ngModel)]="showHeatMap"
                                                [disabled]="accountPlanStatus === 'trial_expired'"
                                            />
                                        </div>
                                        <div class="form-check form-switch form-switch-md mt-2">
                                            <label class="form-check-label" for="allowPoBoxes">Allow PO Boxes:</label>
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="allowPoBoxes"
                                                [(ngModel)]="serviceAreaForm.allowPOBoxes"
                                                (click)="clickAllowPoBoxes()"
                                            />
                                        </div>
                                        <div class="form-check form-switch form-switch-md tab mt-2">
                                            <label class="form-check-label" for="showPoBoxes">Show PO Boxes:</label>
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="showPoBoxes"
                                                [(ngModel)]="serviceAreaForm.showPOBoxes"
                                                [disabled]="!serviceAreaForm.allowPOBoxes"
                                            />
                                        </div>
                                        <div class="form-check form-switch form-switch-md tab mt-2">
                                            <label class="form-check-label" for="autoSelectPoBoxes">Auto Select PO Boxes:</label>
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="autoSelectPoBoxes"
                                                [(ngModel)]="serviceAreaForm.autoSelectPOBoxes"
                                                [disabled]="!serviceAreaForm.allowPOBoxes"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content">
                        <div [ngbNavOutlet]="customNav"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-8">
            <!-- <app-mapbox-zipcode-picker #mapboxZipcodePicker
                [selectableZipCodes]="activeTab === tabEnum.SERVICE_AREA ? undefined : serviceAreaForm.postalCodes"
                [(zipCodeGroups)]="zipCodeGroups"
                [focusedGroupId]="focusedGroupId"
                (onZipCodeGroupChange)="onZipCodeGroupChange($event)"
                (onLoad)="onMapLoad()"
            >
            </app-mapbox-zipcode-picker> -->

            <app-mapbox-heatmap #mapbox
                [zipCodeCounts]="zipCodeCounts"
                [selectableZipCodes]="selectableZipCodes"
                [(zipCodeGroups)]="zipCodeGroups"
                [focusedGroupId]="focusedGroupId"
                (onLoad)="onMapLoad()"
                [groupOpacity]="groupOpacity"
                [showPoBoxes]="!!(serviceAreaForm.allowPOBoxes && serviceAreaForm.showPOBoxes)"
                [autoSelectPoBoxes]="!!(serviceAreaForm.allowPOBoxes && serviceAreaForm.autoSelectPOBoxes)"
                [(focusedZipCode)]="focusedZipCode"
            >
            </app-mapbox-heatmap>
        </div>
        <div class="col-lg-1">
            <div class="card">
                <div class="card-header text-center" #target>
                    {{selectedZipCodes?.length || 0}} Zips
                    &nbsp; 
                    <i placement="top"
                        container="body"
                        [attr.positionTarget]="target"
                        class="pointer las la-copy fs-4 d-inline-block align-middle" 
                        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                        [ngbPopover]="currentAccountPlan.status === 'paid' ? popContent : null"
                        popoverClass="app-service-area-modal-popoverClass"
                        #popup="ngbPopover"
                        [ngClass]="{'disabled': !isPaidPlan}"
                        (click)="isPaidPlan ? copyZipCodes(popup) : promptUpgrade()">
                    </i>
                </div>
                <ng-template #popContent>
                    <span class="text-success">
                        <i class="las la-check-circle"></i>
                        Zips copied
                    </span>
                </ng-template>
                <div class="zip-codes-section card-body text-center p-0">
                    <p-chips
                        id="zip-chips"
                        [(ngModel)]="selectedZipCodes"
                        [styleClass]="'form-control p-0 border-0'" 
                        [inputStyleClass]="'form-control'"
                        [separator]="$any(separatorExp)"
                        (onAdd)="onAddZipCodes($event)"
                        (onRemove)="onRemoveZipCodes()"
                        (onChipClick)="onClickZipCode($event)"
                        [disabled]="activeTab === tabEnum.ZONES && !selectedZone"
                        [ngClass]="{ 'is-invalid': submitted && !(selectedZipCodes?.length || 0 > 0)}"
                        [class.read-only]="activeTab === tabEnum.UNUSED || (activeTab === tabEnum.ZONES && this.selectedZone?.uiId === unusedId)">
                        <ng-template let-item pTemplate="item">
                            {{item}}
                            <br />
                        </ng-template>
                    </p-chips>
                    <div *ngIf="activeTab !== tabEnum.UNUSED && !(activeTab === tabEnum.ZONES && this.selectedZone?.uiId === unusedId)" class="text-muted mt-n3" (click)="focusOnZipChipsInput()">
                        Enter Zips
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-2">
            <span *ngIf="origServiceArea"
                class="clickable-column me-auto d-flex align-items-center text-danger"
            >
                <div *ngIf="!saveLoading" id="btn-delete" class="inline-block" (click)="deleteServiceArea()">
                    Delete  
                </div>
                <div *ngIf="saveLoading"
                    class="spinner-border inline-block mode-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </span>
        </div>
        <div class="col-lg-2 d-flex align-items-center">
            <span class="text-danger">Limited to 250 miles*</span>
        </div>
        <div class="col-lg-5 gap-2 map-footer">
            <span for="input-radius-mile">Select All Zips In A</span>
            <input type="number" id="input-radius-mile"
                class="form-control"
                [(ngModel)]="radiusMile" />
            <span for="input-radius-zipcode">Mile Radius Of</span>
            <input type="text" id="input-radius-zipcode"
                class="form-control"
                placeholder="Zip Code"
                [(ngModel)]="radiusZipCode" />
            <button type="button" id="btn-radius-select"
                class="btn btn-primary action-btn"
                [disabled]="!radiusMile || !radiusZipCode || activeTab === tabEnum.UNUSED"
                (click)="onClickSelectZipsRadius()"
            >
                Select Zips
            </button>
        </div>
        <div class="col-lg-3 d-flex gap-2 justify-content-end" style="padding-right: 65px">
            <button type="button" class="btn btn-light action-btn" data-bs-dismiss="modal" (click)="modal.close('CANCEL')">Cancel</button>
            <button type="submit" class="btn btn-primary action-btn" id="add-btn" (click)="submitServiceArea()">
                <div *ngIf="!saveLoading"   
                    class="inline-block">
                    Save  
                </div>
                <div *ngIf="saveLoading"  
                    class="spinner-border inline-block mode-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
    </div>
    
</div>