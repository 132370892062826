import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { NgbdModalUploadVerificationDocument } from '../modal/upload-verification-document-modal.component';
import { UpgradePlanModalComponent } from '../modal/upgrade-plan-modal/upgrade-plan-modal.component';
import Swal from 'sweetalert2';
import { NgbdModalBuyLicenses } from '../../settings/licenses/modals/buy-licenses/buy-licenses.component';
import { AccountType } from 'src/app/core/services/models/account.model';
import { AccountPlanStatus } from 'src/app/core/services/models/account-plan.model';

@Component({
  selector: 'app-setup-carrier',
  templateUrl: './setup-carrier.component.html',
  styleUrls: ['./setup-carrier.component.scss']
})
export class SetupCarrierComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Quick Start Links'
    }
  ];
  activeModal?: NgbModalRef;
  activeIds = ['static-1', 'static-2', 'static-3']
  branchId: any;
  hasDotNumber: boolean = false;
  currentAccountType: AccountType;
  currentPlanStatus: AccountPlanStatus;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private httpRequest: HttpService,
    private auth: AuthService
  ) {
  }

  async ngOnInit() {
    this.branchId = this.auth.currentAccountSelected.branchId;
    if(this.branchId) {
      this.fetchCompanyInfo();
    }
    
    setTimeout(() => {
      this.currentAccountType = this.auth.currentAccountSelected.accountType;
      this.currentPlanStatus = this.auth.currentAccountPlanValue.status;
    }, 1000);
  }

  redirectTo(url: string, activeTab?: number, childTab?: number) {
    if(activeTab != 0) {
      this.router.navigateByUrl(url, { state: { activeTab: activeTab, childTab: childTab } });
    } else {
      this.router.navigateByUrl(url);
    }
  }

  private fetchCompanyInfo(): void {
    this.httpRequest.getCompanyInfo(this.branchId).subscribe(
      res => {
        const successRes = <SuccessApiResponse<CompanyInfo>> res;
        console.log(res);
        if(successRes.data.dotNumber) {
          this.hasDotNumber = true;
        } else {
          this.hasDotNumber = false;
        }
      },
      error =>{
        console.error('Failed to fetch company info: ' + error.error.reason);
        this.hasDotNumber = false;
      }
    );
  }

  openRestrictedFeatureModal() {
      Swal.fire({
        title: "",
        text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Buy Pre-Release Assets'
      }).then(result => {
        if(result.isConfirmed){
          this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
        }
      });
  }

  openModalHelp(type: string) {
    this.activeModal?.close();
    if(type == 'ss4' && this.currentAccountType == 'carrier-account' && this.currentPlanStatus != 'paid') {
      this.openRestrictedFeatureModal();
      return;
    }
    let modalRef = this.modalService.open(NgbdModalUploadVerificationDocument, { size: 'md', centered: true });
    modalRef.componentInstance.type = type;
  }

  navigateTo(url: string) {
    this.router.navigate([url]);
  }

  openUpgradePlanModal(){
    this.activeModal?.close();
    this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });

  }
}
