import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { VehiclesService } from 'src/app/core/services/vehicles-service/vehicle.service';
import { NgbdGridJsSortableHeader, SortEvent } from 'src/app/core/services/vehicles-service/gridjs-sortable.directive';
import { HttpService } from 'src/app/core/services/http-service';
import { TruckModel, TruckTypeModel } from 'src/app/core/services/models/models';
import { removeFromArray } from 'src/app/core/utils/commons';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicle-trucks-tab',
  templateUrl: './vehicle-trucks-tab.component.html',
  styleUrls: ['./vehicle-trucks-tab.component.scss']
})
export class VehicleTrucksTabComponent implements OnInit {
  vehicleTypesData: TruckTypeModel[]  = [];
  allVehicles: TruckModel[] = [];

  @ViewChildren(NgbdGridJsSortableHeader) headers!: QueryList<NgbdGridJsSortableHeader>;

  constructor(
    public service: VehiclesService,
    private httpRequest: HttpService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getAllVehicles();
    this.loadVehicles();
  }

  async loadVehicles(): Promise<void> {
    this.vehicleTypesData = await this.httpRequest.listTruckTypes().toPromise();
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  getVehicleTypeNameById(id: string) {
    return this.vehicleTypesData?.find(el => el?.truckTypeId === id)?.name;
  }

  getAllVehicles() {
    this.httpRequest.listTrucks().subscribe((data) => {
      this.allVehicles = data;
    })
  }

  customSearchFn(term: string, item: any) {
    item.technicalName = item.technicalName.replace(',','');
    term = term.toLocaleLowerCase();
    if(item.technicalName.toLocaleLowerCase().indexOf(term) > -1) {
      return item.technicalName.toLocaleLowerCase().indexOf(term) > -1;
    } else {
      item.prettyName = item.prettyName.replace(',','');
      return item.prettyName.toLocaleLowerCase().indexOf(term) > -1;
    }
  }

  deleteVehicles(truck: TruckModel) {
    Swal.fire({
      title: 'Are you Sure?',
      text: 'Are you sure you want to delete ' + truck.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.value) {
        this.httpRequest.deleteTruck(truck.truckId).subscribe((data) => {
          this.allVehicles = removeFromArray(this.allVehicles, (a) => a.truckId === truck.truckId);
        });
      }
    });
    
  }

  onEditClick(truck: TruckModel) {
    this.router.navigateByUrl("/pages/vehicles-and-equipment/trucks/edit", {state: truck})
  }
}
