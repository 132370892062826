<div class="auth-page-wrapper pt-5 pricing-plan">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div class="bg-overlay"></div>
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>
    <div class="auth-page-content">
        <div class="container">
          <!-- <div [ngClass]="{'container': planFor !== 'carrier-plan'}"> -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-1 mb-4 text-white">
                        <div>
                            <a routerLink="" class="d-inline-block auth-logo">
                                <img src="../../../assets/logo-blue-background-login.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <div class="text-center mb-4">
                        <div class="d-inline-flex">
                            <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-pills arrow-navtabs plan-nav rounded mb-3 p-1" id="pills-tab" role="tablist" role="tablist" style="display: none;">
                                <li [ngbNavItem]="1" class="nav-item">
                                    <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab"> Monthly </a>
                                    <ng-template ngbNavContent>
                                        <div class="row" *ngIf="planFor === 'carrier-plan'">
                                            <!-- <div class="col-xxl-6 col-lg-6" *ngFor="let data of MonthlyPlan">
                                                <app-plan-pricing-card
                                                    [monthlyPlan]="data"
                                                    (selectedPlan)="selectPlan($event)"
                                                ></app-plan-pricing-card>
                                            </div> -->
                                            <div class="plans-container carrier-plans-container">
                                              <section class='content'>
                                                <div class="container">
                                                  <div class="row-fluid clearfix">
                                                    <div class="pricing-wrapper comparison-table clearfix style-3">
                                                      <div class="col-md-3 pricing-col list-feature">
                                                        <div class="pricing-card">
                                                          <div class="pricing-header">
                                                            <h5><b>Motor Carrier Asset License Plan</b></h5>
                                                            <p> Price Per Asset License *Asset License Equals Number Of Power Units Posted With FMCSA
                                                                Turn on screen reader </p>
                                                          </div>
                                                          <div class="pricing-feature">
                                                            <li *ngFor="let feature of planFeatures">
                                                              <p class="text-right">{{feature.name}}</p>
                                                            </li>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div class="col-md-3 pricing-col person">
                                                        <div class="pricing-card">
                                                          <div class="pricing-header">
                                                            <h5>Free For <br/> Ever</h5>
                                                            <div class="price-box">
                                                              <div class="price">0
                                                                <div class="currency">$</div>
                                                                <!-- <div class="plan">/ Mo</div> -->
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="pricing-feature">
                                                            <li *ngFor="let feature of planFeatures">
                                                              <p>
                                                                <i class="mdi mdi-check-bold" *ngIf="feature.carrier.ffe"></i>
                                                                <i class="mdi mdi-close-thick" *ngIf="!feature.carrier.ffe"></i>
                                                              </p>
                                                            </li>
                                                          </div>
                                                          <div class="pricing-footer">
                                                            <a (click)="onGetStarted('carrier', 'free')" class="btn btn-act rounded btn-line">
                                                              <span>Get Started</span>
                                                              <i class="mdi mdi-arrow-right-bold"></i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <!-- <div class="col-md-2 pricing-col current unlim">
                                                        <div class="pricing-card">
                                                          <div class="pricing-header">
                                                            <h5>Free For Ever Plus Compliance</h5>
                                                            <a href="javascript:void(0)"  class="ribbon">
                                                              <i class="mdi mdi-star-outline"></i>
                                                              <span>Feature</span>
                                                            </a>
                                                            <div class="price-box">
                                                              <div class="price">30
                                                                <div class="currency">$</div>
                                                                <div class="plan">/ Mo</div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="pricing-feature">
                                                            <li *ngFor="let feature of planFeatures">
                                                              <p>
                                                                <i class="mdi mdi-check-bold" *ngIf="feature.ffe_plus_compliance"></i>
                                                                <i class="mdi mdi-close-thick" *ngIf="!feature.ffe_plus_compliance"></i>
                                                              </p>
                                                            </li>
                                                          </div>
                                                          <div class="pricing-footer">
                                                            <a [routerLink]="['/carrier']" [queryParams]="{ plan: 'free'}" class="btn btn-act rounded btn-line">
                                                              <span>Get Started</span>
                                                              <i class="mdi mdi-arrow-right-bold"></i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div> -->

                                                      <div class="col-md-3 pricing-col business current">
                                                        <div class="pricing-card">
                                                          <div class="pricing-header">
                                                            <h5>Pre-Sale <br/> Asset License</h5>
                                                            <a href="javascript:void(0)" class="ribbon">
                                                              <i class="mdi mdi-star-outline"></i>
                                                              <span>Feature</span>
                                                            </a>
                                                            <div class="price-box">
                                                              <div class="price">150
                                                                <div class="currency">$</div>
                                                                <div class="plan">/ 24 Mo</div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="pricing-feature">
                                                            <li *ngFor="let feature of planFeatures">
                                                              <p>
                                                                <i class="mdi mdi-check-bold" *ngIf="feature.carrier.ps_asset_license"></i>
                                                                <i class="mdi mdi-close-thick" *ngIf="!feature.carrier.ps_asset_license"></i>
                                                              </p>
                                                            </li>
                                                          </div>
                                                          <div class="pricing-footer">
                                                            <a (click)="onGetStarted('carrier', 'paid')" class="btn btn-act rounded btn-line">
                                                              <span>Get Started</span>
                                                              <i class="mdi mdi-arrow-right-bold"></i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <!-- Temporary hide the "Forever" plan from the UI -->
                                                      <!-- Need to add more configurations as per this plan to other pages also -->
                                                      <div *ngHide class="col-md-3 pricing-col business">
                                                        <div class="pricing-card">
                                                          <div class="pricing-header">
                                                            <h5>For Ever <br/> Asset License</h5>
                                                            <div class="price-box">
                                                              <div class="price">450
                                                                <div class="currency">$</div>
                                                                <div class="plan">For Ever</div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="pricing-feature">
                                                            <li *ngFor="let feature of planFeatures">
                                                              <p>
                                                                <i class="mdi mdi-check-bold" *ngIf="feature.carrier.for_ever_asset_licence"></i>
                                                                <i class="mdi mdi-close-thick" *ngIf="!feature.carrier.for_ever_asset_licence"></i>
                                                              </p>
                                                            </li>
                                                          </div>
                                                          <div class="pricing-footer">
                                                            <a (click)="onGetStarted('carrier', 'paid')" class="btn btn-act rounded btn-line">
                                                              <span>Get Started</span>
                                                              <i class="mdi mdi-arrow-right-bold"></i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <!-- <div class="col-md-2 pricing-col business">
                                                        <div class="pricing-card">
                                                          <div class="pricing-header">
                                                            <h5>Carrier <br/> Asset License</h5>
                                                            <div class="price-box">
                                                              <div class="price">200
                                                                <div class="currency">$</div>
                                                                <div class="plan">/ Mo</div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="pricing-feature">
                                                            <li *ngFor="let feature of planFeatures">
                                                              <p>
                                                                <i class="mdi mdi-check-bold" *ngIf="feature.carrier_asset_licence"></i>
                                                                <i class="mdi mdi-close-thick" *ngIf="!feature.carrier_asset_licence"></i>
                                                              </p>
                                                            </li>
                                                          </div>
                                                          <div class="pricing-footer">
                                                            <a [routerLink]="['/carrier']" [queryParams]="{ plan: 'paid'}" class="btn btn-act rounded btn-line">
                                                              <span>Get Started</span>
                                                              <i class="mdi mdi-arrow-right-bold"></i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div> -->
                                                    </div>
                                                  </div>
                                                </div>
                                              </section>
                                            </div>
                                        </div>
                                        
                                        <div class="row" *ngIf="planFor === 'shipper-plan'">
                                            <!-- <div class="col-xxl-6 col-lg-6" *ngFor="let data of MonthlyPlan">
                                                <app-plan-pricing-card
                                                    [monthlyPlan]="data"
                                                    (selectedPlan)="selectPlan($event)"
                                                ></app-plan-pricing-card>
                                            </div> -->
                                            <div class="plans-container shipper-plans-container">
                                              <section class='content'>
                                                <div class="container">
                                                  <div class="row-fluid clearfix">
                                                    <div class="pricing-wrapper comparison-table clearfix style-3">
                                                      <div class="col-md-3 pricing-col list-feature">
                                                        <div class="pricing-card">
                                                          <div class="pricing-header">
                                                            <h5><b>Shipper Asset License Plan </b></h5>
                                                            <p>Price Per Asset License</p>
                                                            <p>Equals Unlimted Accpted Load Tender Per One Carrier Asset License With 24H Duty Status</p>
                                                          </div>
                                                          <div class="pricing-feature">
                                                            <li *ngFor="let feature of planFeatures">
                                                              <p class="text-right">{{feature.name}}</p>
                                                            </li>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div class="col-md-3 pricing-col person">
                                                        <div class="pricing-card">
                                                          <div class="pricing-header">
                                                            <h5>Free For <br/> Ever</h5>
                                                            <div class="price-box">
                                                              <div class="price">0
                                                                <div class="currency">$</div>
                                                                <!-- <div class="plan">/ Mo</div> -->
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="pricing-feature">
                                                            <li *ngFor="let feature of planFeatures">
                                                              <p>
                                                                <i class="mdi mdi-check-bold" *ngIf="feature.shipper.ffe"></i>
                                                                <i class="mdi mdi-close-thick" *ngIf="!feature.shipper.ffe"></i>
                                                              </p>
                                                            </li>
                                                          </div>
                                                          <div class="pricing-footer">
                                                            <a (click)="onGetStarted('shipper', 'free')" class="btn btn-act rounded btn-line">
                                                              <span>Get Started</span>
                                                              <i class="mdi mdi-arrow-right-bold"></i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div class="col-md-3 pricing-col business">
                                                        <div class="pricing-card">
                                                          <div class="pricing-header">
                                                            <h5>Pre-Sale <br/> Asset License</h5>
                                                            <a href="javascript:void(0)"  class="ribbon">
                                                              <i class="mdi mdi-star-outline"></i>
                                                              <span>Feature</span>
                                                            </a>
                                                            <div class="price-box">
                                                              <div class="price">150
                                                                <div class="currency">$</div>
                                                                <div class="plan">/ 24 Mo</div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="pricing-feature">
                                                            <li *ngFor="let feature of planFeatures">
                                                              <p>
                                                                <i class="mdi mdi-check-bold" *ngIf="feature.shipper.ps_asset_license"></i>
                                                                <i class="mdi mdi-close-thick" *ngIf="!feature.shipper.ps_asset_license"></i>
                                                              </p>
                                                            </li>
                                                          </div>
                                                          <div class="pricing-footer">
                                                            <a (click)="onGetStarted('carrier', 'paid')" class="btn btn-act rounded btn-line">
                                                              <span>Get Started</span>
                                                              <i class="mdi mdi-arrow-right-bold"></i>
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>

                                                    </div>
                                                  </div>
                                                </div>
                                              </section>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="planFor === 'broker-plan'">
                                          <!-- <div class="col-xxl-6 col-lg-6" *ngFor="let data of MonthlyPlan">
                                              <app-plan-pricing-card
                                                  [monthlyPlan]="data"
                                                  (selectedPlan)="selectPlan($event)"
                                              ></app-plan-pricing-card>
                                          </div> -->
                                          <div class="plans-container broker-plans-container">
                                            <section class='content'>
                                              <div class="container">
                                                <div class="row-fluid clearfix">
                                                  <div class="pricing-wrapper comparison-table clearfix style-3">
                                                    <div class="col-md-3 pricing-col list-feature">
                                                      <div class="pricing-card">
                                                        <div class="pricing-header">
                                                          <h5><b>Freight Intermediaies Asset License Plan</b></h5>
                                                          <p>Price Per Asset License</p>
                                                          <p>Equals Unlimted Accpted Load Tender Per One Carrier Asset License With 24H Duty Status</p>
                                                        </div>
                                                        <div class="pricing-feature">
                                                          <li *ngFor="let feature of planFeatures">
                                                            <p class="text-right">{{feature.name}}</p>
                                                          </li>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div class="col-md-3 pricing-col person">
                                                      <div class="pricing-card">
                                                        <div class="pricing-header">
                                                          <h5>Free For <br/> Ever</h5>
                                                          <div class="price-box">
                                                            <div class="price">0
                                                              <div class="currency">$</div>
                                                              <!-- <div class="plan">/ Mo</div> -->
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="pricing-feature">
                                                          <li *ngFor="let feature of planFeatures">
                                                            <p>
                                                              <i class="mdi mdi-check-bold" *ngIf="feature.broker.ffe"></i>
                                                              <i class="mdi mdi-close-thick" *ngIf="!feature.broker.ffe"></i>
                                                            </p>
                                                          </li>
                                                        </div>
                                                        <div class="pricing-footer">
                                                          <a (click)="onGetStarted('broker', 'free')" class="btn btn-act rounded btn-line">
                                                            <span>Get Started</span>
                                                            <i class="mdi mdi-arrow-right-bold"></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div class="col-md-3 pricing-col business">
                                                      <div class="pricing-card">
                                                        <div class="pricing-header">
                                                          <h5>Pre-Sale <br/> Asset License</h5>
                                                          <a href="javascript:void(0)"  class="ribbon">
                                                            <i class="mdi mdi-star-outline"></i>
                                                            <span>Feature</span>
                                                          </a>
                                                          <div class="price-box">
                                                            <div class="price">150
                                                              <div class="currency">$</div>
                                                              <div class="plan">/ 24 Mo</div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="pricing-feature">
                                                          <li *ngFor="let feature of planFeatures">
                                                            <p>
                                                              <i class="mdi mdi-check-bold" *ngIf="feature.broker.ps_asset_license"></i>
                                                              <i class="mdi mdi-close-thick" *ngIf="!feature.broker.ps_asset_license"></i>
                                                            </p>
                                                          </li>
                                                        </div>
                                                        <div class="pricing-footer">
                                                          <a (click)="onGetStarted('broker', 'paid')" class="btn btn-act rounded btn-line">
                                                            <span>Get Started</span>
                                                            <i class="mdi mdi-arrow-right-bold"></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>

                                                  </div>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                      </div>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div [ngbNavOutlet]="customNav"></div>
            </div>
        </div>
    </div>
</div>