import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LatLngTuple, Map, Polyline, icon, marker, polyline, tileLayer } from 'leaflet';
import { MapService } from 'src/app/core/services/map.service';
import { GRAPHHOPPER_MAP_POINT } from 'src/app/core/services/models/map.model';

@Component({
  selector: 'app-open-street-map',
  templateUrl: './open-street-map.component.html',
  styleUrls: ['./open-street-map.component.scss']
})
export class OpenStreetMapComponent implements OnInit, OnChanges {

  @Input() mapPoints: Array<GRAPHHOPPER_MAP_POINT>;
  private map: Map;
  private markers: any[] = [];
  private polyline: Polyline;

  constructor(
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mapPoints'] 
        && JSON.stringify(changes['mapPoints']['currentValue']) !== JSON.stringify(changes['mapPoints']['previousValue'])) {
          this.clearMarkersAndRoute();
          this.drawRoute();
          this.addMarkers();
    }
  }

  /**
   * Initial Openstreet Map
   */
  private initMap(): void {
    
    this.map = new Map('open-street-map').setView([this.mapService.USA_LAT_LNG.lat, this.mapService.USA_LAT_LNG.lng], 4);

    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors'
    }).addTo(this.map);

    setTimeout(() => {
      this.clearMarkersAndRoute();
      this.drawRoute();
      this.addMarkers();
    }, 100);

  }

  /** 
   * Draw route on map based on coordinates
   */
  private drawRoute(): void {
    if (this.mapPoints.length == 0) return;

    let coordinates: Array<LatLngTuple>  = [];
    this.mapPoints.forEach((point: GRAPHHOPPER_MAP_POINT) => { 
      coordinates.push([point.coordinates.lng, point.coordinates.lat]);
    });
    
    let params = {
     points: coordinates
    }

    this.mapService.getGraphhopperRoute(params).subscribe(
      (response) => {
        const route = response.paths[0];
        const coordinates = route.points.coordinates.map((coord: Array<number>) => [coord[1], coord[0]]);
        this.polyline = polyline(coordinates, { color: 'blue' }).addTo(this.map);
        this.map.fitBounds(polyline(coordinates).getBounds());
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /**
   * Add Markers on Map
   * @returns 
   */
  private addMarkers(): void {
    if (this.mapPoints.length == 0) return;
    this.markers = this.mapPoints.map((point: GRAPHHOPPER_MAP_POINT, i) => {  
      let markerColor = this.setMarkerColor(i);
      
      return marker([
        point.coordinates.lat,
        point.coordinates.lng
      ], {
        icon: this.createCustomMarkerIcon(markerColor)
      }).addTo(this.map)
        .bindPopup(point.name);
    });
  }

  /**
   * Clean map, Remove Markers and Route
   */
  private clearMarkersAndRoute(): void {
    this.markers.forEach(marker => this.map.removeLayer(marker));
    this.markers = [];

    if (this.polyline) {
      this.polyline.remove();
    }
  }

  /**
   * Create Custom Marker
   * @param color 
   * @returns 
   */
  private createCustomMarkerIcon(color: string): any {
    return icon({
      iconUrl: `assets/icons/map-pin-${color}.png`, // provide your custom marker icon
      iconSize: [30, 30]
    });
  }

  /**
   * Set marker color based on marker position on map
   * @param pointPosition 
   * @returns 
   */
  private setMarkerColor(pointPosition: number): string {
    let color = "blue";

    if(pointPosition == 0) {
      color = "green";
    } else if (pointPosition == (this.mapPoints.length - 1)) {
      color = "red";
    }

    return color;
  }
}
