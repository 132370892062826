import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloadAllModules } from '@angular/router';

import { VerifySignUpComponent } from './verify-sign-up/verify-sign-up.component';
import { LayoutComponent } from './layouts/layout.component';
import { CoverComponentNotFound } from './account/auth/errors/cover/cover.component';

import { AuthGuard } from './core/guards/auth.guard';
import { ScheduleCallComponent } from './schedule-call/schedule-call.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { OwnerOperatorComponent } from './owner-operator/owner-operator.component';
import { FleetComponent } from './fleet/fleet.component';
import { GeneralMessageComponent } from './general-message/general-message.component';
import { SampleComponent } from './sample/sample.component';
import { ShipperNewComponent } from './sign-up/shipper-new/shipper-new.component';
import { CarrierNewComponent } from './sign-up/carrier-new/carrier-new.component';
import { DriverNewComponent } from './sign-up/driver-new/driver-new.component';
import { HelperNewComponent } from './sign-up/helper-new/helper-new.component';
import { LoginComponent } from './account/login/login.component';
import { SignUpProcessComponent } from './sign-up-process/sign-up-process.component';
import { SignUpSuccessComponent } from './sign-up/sign-up-success/sign-up-success.component';
import { LoginGuard } from './core/guards/login.guard';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { OauthLoginComponent } from './oauth-login/oauth-login.component';
import { DemoComponent } from './demo-public-profile/demo-public-profile.component';
import { DemoPublicScheduleComponent } from './demo-public-schedule/demo-public-schedule.component';
import { PricingComponent } from './plans-and-pricing/plans-and-pricing.component';
import { OauthRedeemComponent } from './oauth-redeem/oauth-redeem.component';
import { BrokerNewComponent } from './sign-up/broker-new/broker-new.component';
const routes: Routes = [
  {
    path: 'pages',
    component: LayoutComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  { path: 'password-reset', loadChildren: () => import('../app/account/auth/pass-reset/pass-reset.module').then(m => m.PassResetModule)  },
  // { path: '', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)  },
  {
    path: '',
    canActivate: [LoginGuard],
    component: LoginComponent
  },
  {
    path: 'oauth/:type/login',
    component: OauthLoginComponent
  },
  {
    path: 'oauth/:type/redeem',
    component: OauthRedeemComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'users/:slug',
    component: DemoComponent
  },
  {
    path: 'users/:slug/schedule',
    component: DemoPublicScheduleComponent
  },
  {
    path: 'pricing-and-plans',
    component: PricingComponent
  },
  {
    path: 'shipper',
    component: ShipperNewComponent
  },
  {
    path: 'carrier',
    component: CarrierNewComponent
  },
  // {
  //   path: 'carrier?plan=:planType',
  //   component: CarrierNewComponent
  // },
  {
    path: 'contract-driver',
    component: DriverNewComponent
  },
  {
    path: 'bonafide',
    component: HelperNewComponent
  },
  {
    path: 'broker',
    component: BrokerNewComponent
  },
  {
    path: 'verify-signup/:token',
    component: VerifySignUpComponent
  },
  {
    path: 'sign-up-process/:code/:metadata/:email',
    component: SignUpProcessComponent
  },
  {
    path: 'sign-up-success',
    component: SignUpSuccessComponent
  },
  {
    path: 'schedule-call',
    component: ScheduleCallComponent
  },
  {
    path: 'owner-operator',
    component: OwnerOperatorComponent
  },
  {
    path: 'fleet',
    component: FleetComponent
  },
  {
    path: 'general-message',
    component: GeneralMessageComponent
  },
  {
    path: 'sample',
    component: SampleComponent
  },
  { path: '**', pathMatch: 'full',
    component: CoverComponentNotFound
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
