import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { AddressComponent } from 'src/app/core/services/models/address-component.model';
import { Location } from '@angular/common';
import { LaneStop } from 'src/app/core/services/models/lane-stop.model';
import { Lane, LanePayload } from 'src/app/core/services/models/lane.model';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { PrettyTechName } from 'src/app/core/services/models/pretty-technical-name';
import { MapsDataSource, MapsEventListener, MapsLayer, TrimbleMapsHelper } from 'src/app/core/services/models/trimble-maps.helper';
import { isArrayEmpty, numberDisplay } from 'src/app/core/utils/commons';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { DefaultPlace, GoogleMapsSearchBoxComponent } from 'src/app/shared/google-maps-search-box/google-maps-search-box.component';
import { LatLng } from 'src/app/shared/trimble-map/trimble-map.component';
import Swal from 'sweetalert2';
import { BranchesService, TreeNodeBranch } from '../../origin-points/origin-point/branches.service';
import { TreeNode } from 'primeng/api';
import { MapService } from 'src/app/core/services/map.service';
import { param } from 'jquery';
import { GRAPHHOPPER_MAP_POINT } from 'src/app/core/services/models/map.model';

const MAPS_SOURCE_NAME = 'branchSource';
const MAPS_LAYER_ID = 'branchPoints';

type Stop = {
  name?: string, 
  address?: google.maps.places.PlaceResult, 
  defaultPlace?:DefaultPlace
};

@Component({
  selector: 'app-save-lane',
  templateUrl: './save-lane.component.html',
  styleUrls: ['./save-lane.component.scss']
})
export class SaveLaneComponent implements OnInit, AfterViewInit {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Lanes',
      url: '/pages/lanes'
    },
    {
      label: 'Add'
    }
  ];
  trimbleMapOptions: Omit<TrimbleMaps.MapOptions, 'container'> = {
    style: TrimbleMaps.Common.Style.TRANSPORTATION, // hosted style id
    center: [-98.38, 38.69], // starting position
    zoom: 3 // starting zoom
  };
  geoJsonDataSources: MapsDataSource[] = [TrimbleMapsHelper.generateGeoJsonDataSource(MAPS_SOURCE_NAME, [])];
  mapLayers: MapsLayer[] = [
    {
      layer: {
        id: MAPS_LAYER_ID,
        type: 'circle',
        source: MAPS_SOURCE_NAME,
        paint: {
            'circle-radius': 12,
            'circle-color': '#33E',
            'circle-stroke-color': '#FFF',
            'circle-stroke-width': 4
        }
      }
    }
  ];
  mapListeners: MapsEventListener[];
  trailerAndTruckTypeOptions: PrettyTechName[] = [];
  SOFTWARE_OPTIONS: PrettyTechName[] = [
    {prettyName: "PC Miler v36", techName: 'PCM36'},
    {prettyName: "PC Miler v35", techName: 'PCM35'},
    {prettyName: "PC Miler v34", techName: 'PCM34'},
    {prettyName: "PC Miler v33", techName: 'PCM33'},
    {prettyName: "PC Miler v32", techName: 'PCM32'},
    {prettyName: "PC Miler v31", techName: 'PCM31'},
    {prettyName: "PC Miler v30", techName: 'PCM30'},
    {prettyName: "PC Miler v29", techName: 'PCM29'},
    {prettyName: "PC Miler v28", techName: 'PCM28'},
    {prettyName: "PC Miler v27", techName: 'PCM27'},
    {prettyName: "PC Miler v26", techName: 'PCM26'},
    {prettyName: "PC Miler v25", techName: 'PCM25'},
    {prettyName: "PC Miler v24", techName: 'PCM24'},
    {prettyName: "PC Miler v23", techName: 'PCM23'},
    {prettyName: "PC Miler v22", techName: 'PCM22'},
    {prettyName: "PC Miler v21", techName: 'PCM21'},
    {prettyName: "PC Miler v20", techName: 'PCM20'},
    {prettyName: "PC Miler v19", techName: 'PCM19'},
    {prettyName: "PC Miler v18", techName: 'PCM18'}
  ];
  COMMODITY_OPTIONS = [
    {id: 1, name: 'Live animals and fish'},
    {id: 2, name: 'Cereal grains'},
    {id: 3, name: 'Other agricultural products'},
    {id: 4, name: 'Feed and products of animal origin'},
    {id: 5, name: 'Meat and seafood'},
    {id: 6, name: 'Milled grain products'},
    {id: 7, name: 'Other prepared foodstuffs'},
    {id: 8, name: 'Alcoholic beverages'},
    {id: 9, name: 'Tobacco products'},
    {id: 10, name: 'Building stone'},
    {id: 11, name: 'Natural sands'},
    {id: 12, name: 'Gravel and crushed stone'},
    {id: 13, name: 'Other nonmetallic minerals'},
    {id: 14, name: 'Metallic ores'},
    {id: 15, name: 'Coal'},
    {id: 16, name: 'Crude petroleum'},
    {id: 17, name: 'Gasoline, kerosene, and ethanol'},
    {id: 18, name: 'Diesel and other fuel oils'},
    {id: 19, name: 'Other fossil fuel products'},
    {id: 20, name: 'Basic chemicals'},
    {id: 21, name: 'Pharmaceutical products'},
    {id: 22, name: 'Fertilizers'},
    {id: 23, name: 'Chemical products and preparations'},
    {id: 24, name: 'Plastics and rubber'},
    {id: 25, name: 'Logs and wood in the rough'},
    {id: 26, name: 'Wood products'},
    {id: 27, name: 'Pulp, paper, and paperboard'},
    {id: 28, name: 'Paper or paperboard articles'},
    {id: 29, name: 'Printed products'},
    {id: 30, name: 'Textiles and leather'},
    {id: 31, name: 'Nonmetallic mineral products'},
    {id: 32, name: 'Metal in basic shapes'},
    {id: 33, name: 'Articles of metal'},
    {id: 34, name: 'Machinery'},
    {id: 35, name: 'Electronics'},
    {id: 36, name: 'Motorized and other vehicles'},
    {id: 37, name: 'Other transportation equipment'},
    {id: 38, name: 'Precision instruments'},
    {id: 39, name: 'Furniture'},
    {id: 40, name: 'Miscellaneous manufactured products'},
    {id: 41, name: 'Waste and scrap'},
    {id: 43, name: 'Mixed freight'},
    {id: 99, name: 'Unknown'}
  ];
  toNumber = Number;
  routeStops:LatLng[] = [];
  totalSuggestedRateWithFuel?:number;
  totalMiles?:number;
  totalHours?:number;
  totalMinutes?:number;
  software: string = this.SOFTWARE_OPTIONS[0].techName;
  stops: Stop[] = [
    {name: 'Shipper'},
    {name: 'Consignee'},
  ];

  form: FormGroup;
  submitted = false;
  originalLane?: Lane;
  @ViewChildren('googleMapsInputs') googleMapsInputs: GoogleMapsSearchBoxComponent[];
  router: Router;
  loading = false;
  selectedBranches: TreeNode<TreeNodeBranch>[] = [];
  branches: TreeNode<TreeNodeBranch>[] = [];
  laneRouteStops: Array<GRAPHHOPPER_MAP_POINT> = [];

  constructor(
    private httpService: HttpService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private location: Location,
    router: Router,
    private mapService: MapService
  ) { 
    this.router = router;
  }

  async ngOnInit(): Promise<void> {
    this.originalLane = (this.location.getState() as {[key: string]: any})['lane'];
    this.initForm();
    const trailerTypes = await this.httpService.listTrailerTypes().toPromise();
    const trailerTypesDropdownData = trailerTypes.map(
      trailerType => ({
        prettyName: trailerType.name,
        techName: trailerType.trailerTypeId
      })
    );
    const truckTypes = await this.httpService.listTruckTypes().toPromise();
    const truckTypesDropdownData = truckTypes.map(
      truck => ({
        prettyName: truck.name,
        techName: truck.truckTypeId
      })
    );
    this.trailerAndTruckTypeOptions = [...trailerTypesDropdownData/*, ...truckTypesDropdownData*/]
    this.httpService.getBranchesByHierarchy()
    .subscribe(res => {
      this.branches = BranchesService.getRecursiveTreeNodeBranch(res.data);
      this.selectedBranches = BranchesService.getSelectedTreeNodeBranch(
        this.originalLane?.branches.map(branch=>branch.branchId) ?? [],
        this.branches
      );
    });
  }

  async ngAfterViewInit(): Promise<void>{
    if(this.originalLane){
      const laneStops = (<SuccessApiResponse<LaneStop[]>> await 
        this.httpService.listLaneStops(this.originalLane.laneId).toPromise()).data;
      this.stops = laneStops.sort((a, b) => a.number - b.number)
        .map(
          (laneStop, ndx) => ({
            name: ndx == 0 ? 'Shipper': 'Consignee',
            defaultPlace: {
              gmap: {
                placeId: laneStop.googlePlaceId,
                lat: laneStop.latitude, 
                lng: laneStop.longitude
              }
            }
          }) as Stop
        );
    }
  }

  initForm(): void {
    this.loading = false;
    this.submitted = false;
    this.form = this.formBuilder.group({
      name: [this.originalLane?.name, [Validators.required]],
      trailerTypeId: [this.originalLane?.trailerTypeId, [Validators.required]],
      commodities: [
        this.originalLane?.commodities?.map(commodity => commodity.commodityId) ?? [], 
        Validators.required
      ],
      weight: [this.originalLane?.weight,  Validators.required],
      pallets: [this.originalLane?.pallets, Validators.required],
      softwareVersion: [
        this.originalLane 
          ? this.originalLane.softwareVersion
          : this.SOFTWARE_OPTIONS[0].techName, 
        Validators.required
      ],
      highestRate: [numberDisplay(this.originalLane?.highestRate), Validators.required],
      suggestedRate: [numberDisplay(this.originalLane?.suggestedRate), Validators.required],
      lowestRate: [numberDisplay(this.originalLane?.lowestRate), Validators.required],
      fuelSurchargeRate: [numberDisplay(this.originalLane?.fuelSurchargeRate), Validators.required]
    });
  }

  onStopNameChanged(event: any, index: number, ): void{
    const name = event.target.value;
    this.stops[index].name = name;
  }

  onStopPlaceChanged(index: number, address: google.maps.places.PlaceResult): void{
    this.stops[index].address = address;
    // this.computeTrimbleMileage();
    this.calculateGraphhopperMileage();
  }

  deleteStop(index: number){
    const tmp = [...this.stops];
    tmp.splice(index, 1);
    this.stops = [...tmp];
    // this.computeTrimbleMileage();
    this.calculateGraphhopperMileage();

  }

  computeTrimbleMileage(): void {
    this.loading = true;
    if(!this.software){
      return;
    }
    this.totalMiles = undefined;
    this.totalHours = undefined;
    this.totalMinutes = undefined;
    this.routeStops = [];
    for(const stop of this.stops){
      if(stop.address && stop.address.geometry){
        const location = stop.address.geometry.location!;
        this.routeStops.push({
          lat: location.lat()!,
          lng: location.lng()!,
        });
      }
    }
    this.httpService.getTrimbleMileage(
      this.software,
      this.routeStops
    ).pipe(
      finalize(()=>this.loading = false)
    ).subscribe(
      res => {
        let totalMiles = 0;
        let totalHours = 0;
        let totalMinutes = 0;
        for(const trimbleMileage of res){
          for(const reportLine of trimbleMileage.ReportLines){
            const miles = reportLine.TMiles;
            const [hours, minutes] = reportLine.THours.split(':');
            totalMiles += Number(miles);
            totalHours += Number(hours);
            totalMinutes += Number(minutes);
          }
        }
        this.totalMiles = totalMiles;
        this.totalHours = totalHours + parseInt((totalMinutes/60).toString());
        this.totalMinutes = totalMinutes % 60;
      },
      error => {
        console.error('[Failed to get mileage] error', error);
        Swal.fire({
          title: 'Error',
          text: 'Failed to get mileage: ' + error,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  handleRateChange(controlKey: string, event: any){
    this.form.controls[controlKey].setValue(this.maskMoney(event));
  }

  handleRateKeyDown(controlKey: string, event: any): void {
    const value = this.form.controls[controlKey].value;
    if(!value && event.key === '.'){
      this.form.controls[controlKey].setValue('0.');
    }
  }

  computeInvalidStops(): boolean{
    if(isArrayEmpty(this.googleMapsInputs)){
      return true;
    }

    const hasInvalidAdress = this.googleMapsInputs.map(
      map => map.validateForm()
    ).some(valid => !valid);

    console.log('[computeInvalidStops] hasInvalidAdress', hasInvalidAdress);

    return hasInvalidAdress;
  }

  saveLane(){
    this.submitted = true;
    this.loading = false;

    const isAnyStopInvalid = this.computeInvalidStops();

    const {highestRate: highestRateString, suggestedRate: suggestedRateString, lowestRate: lowestRateString} = this.form.value;

    const highestRate = Number(highestRateString);
    const suggestedRate = Number(suggestedRateString);
    const lowestRate = Number(lowestRateString);

    const rateValid = highestRate >= suggestedRate && highestRate >= lowestRate && suggestedRate >= lowestRate;

    if(this.form.invalid || isAnyStopInvalid || !rateValid || isArrayEmpty(this.selectedBranches)){
      return;
    }
    const selectedMode = JSON.parse(sessionStorage.getItem('selectedMode')!);
    const {weight, pallets,fuelSurchargeRate} = this.form.value;
    const payload:LanePayload = {
      ...this.form.value,
      accountId: this.authService.currentAccountSelected.accountId,
      modeId: selectedMode.modeId,
      softwareType: "PC Miler",
      weight: Number(weight),
      pallets: Number(pallets),
      estimatedDistance: this.totalMiles,
      estimatedTime: Number(this.totalHours) * 60 + Number(this.totalMinutes),
      highestRate,
      suggestedRate,
      lowestRate,
      fuelSurchargeRate: Number(fuelSurchargeRate),
      branches: this.selectedBranches.map(branch=>branch.data?.branchId)
    };
    this.loading = true;
    let httpCall;
    if(this.originalLane){
      httpCall = this.httpService.updateLane(
        this.originalLane.laneId,
        payload
      );
    }else{
      httpCall = this.httpService.addLane(payload);
    }
    httpCall.subscribe(
      async res => {
        const successRes = <SuccessApiResponse<Lane>> res;
        const lane = successRes.data;
        const {laneId} = lane;

        const addLanesCall = this.stops.map(
          (stop, ndx) => this.httpService.addLaneStop(
            laneId,
            {
              number: ndx+1,
              type: ndx==0 ? 'pickup' : 'delivery',
              ...new AddressComponent(stop.address!)
            } as LaneStop
          )
        );

        const deleteLaneStopCalls = (<SuccessApiResponse<LaneStop[]>> await 
          this.httpService.listLaneStops(laneId).toPromise()).data.map(
            laneStops => this.httpService.deleteLaneStop(laneStops.laneStopId).toPromise()
          );
        
        await Promise.all(deleteLaneStopCalls);

        try {
          for(const addCall of addLanesCall){
            await addCall.toPromise();
          }
          this.alertSaveLane();
        }catch(error: any){
          console.log('[Failed to save lane stop] error', error);
          Swal.fire({
            title: 'Error',
            text: 'Failed to save lane: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }finally {
          this.loading = false;
        }
      }
    );
  }

  deleteLane(){
    this.loading = true;
    this.httpService.deleteLane(this.originalLane?.laneId!)
      .subscribe(
        res => {
          this.loading = false;
          Swal.fire({
            title: 'Success',
            text: 'Successfully deleted lane.',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            this.router.navigate(["/pages/lanes"]);
          });
        },
        error => {
          this.loading = false;
          Swal.fire({
            title: 'Error',
            text: 'Failed to delete lane: ' + error.error.reason,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: 'rgb(60,76,128)',
            confirmButtonText: 'Ok',
          }).then((result) => {
            //do nothing
          });
        }
      );
  }

  alertSaveLane(): void{
    this.loading = false;
    Swal.fire({
      title: 'Success',
      text: 'Successfully saved lane.',
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Ok',
    }).then((result) => {
      this.router.navigate(["/pages/lanes"]);
    });
  }

  maskMoney(event: any): string{
    return numberDisplay(Number(event.target.value));
  }

  /**
   * Calculate Route Miles and Time using Graphhoppers API
   * @returns 
   */
  calculateGraphhopperMileage() {

    let isAllStopsHasAddress = this.stops.every(stop => stop.address);
    if  (!isAllStopsHasAddress) return;

    this.loading = true;
    this.totalMiles = undefined;
    this.totalHours = undefined;
    this.totalMinutes = undefined;
    let routeStops = [];
    this.laneRouteStops = [];
    for(const stop of this.stops){
      if(stop.address && stop.address.geometry){
        const location = stop.address.geometry.location!;
        routeStops.push([location.lng(), location.lat()]);
        this.laneRouteStops.push({
          name: stop.name || "Consignee " + this.laneRouteStops.length,
          coordinates: {
            lat: location.lat(),
            lng: location.lng()
          }
        })
      }
    }

    let params = {
      points: routeStops
    }
    this.mapService.getGraphhopperRoute(params)
    .pipe(
      finalize(()=>this.loading = false)
    ).subscribe(
      (response) => {
        let totalMiles = 0;
        let totalHours = 0;
        let totalMinutes = 0;

        response.paths.forEach((path: any) => {
          totalMiles += this.mapService.convertMeterToMiles(path.distance);
          const time = this.mapService.convertMillisecondsToTime(path.time);
          const [hours, minutes] = time.split(':');
          totalHours += Number(hours);
          totalMinutes += Number(minutes);
        }); 

        this.totalMiles = totalMiles;
        this.totalHours = totalHours + parseInt((totalMinutes/60).toString());
        this.totalMinutes = totalMinutes % 60;
        this.loading = false;
      },
      (error) => {
        console.error(error);
        // Handle errors
        this.loading = false;
      }
    );
    
  }

}
