import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { DriverQualificationFilesService } from 'src/app/core/services/driver-qualification-files.service';
import { HttpService } from 'src/app/core/services/http-service';
import { User } from 'src/app/core/services/models/auth.models';
import { Branch } from 'src/app/core/services/models/branch.model';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model';
import { ApiResponse, SuccessApiResponse } from 'src/app/core/services/models/models';
import { UserProfile } from 'src/app/core/services/models/user-profile.model';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import Swal from 'sweetalert2';

export type Sections = 'iframe' | 'form';

@Component({
  selector: 'app-driver-qualification-files',
  templateUrl: './driver-qualification-files.component.html',
  styleUrls: ['./driver-qualification-files.component.scss']
})
export class DriverQualificationFilesComponent implements OnInit {
  
  @Input() showBreadCrumb: boolean = true;
  @Input() displaySection: Sections = 'iframe';

  breadCrumbItems:BreadCrumbItem[] = [
    {label: 'Driver Qualification Files'}
  ];
  showForm: boolean = true;
  requestForm: FormGroup;
  submitted: boolean = false;
  currentUser: User;
  private selectedBranch: Branch | null;
  companyInfo: CompanyInfo;
  userProfle: UserProfile;
  driverQualificationData: any = {};

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private httpRequest: HttpService,
    private driverQualificationFiles: DriverQualificationFilesService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.authService.selectedBranchSubject.subscribe(branch => {
      if (!branch) return;

      this.selectedBranch = branch
      this.loadDriverQualificationFiles();
    });
  }

  /**
   * Load Driver Qualification Files
   */
  loadDriverQualificationFiles() {
    this.driverQualificationFiles.get(this.selectedBranch?.companyInfoId!).subscribe((resp: SuccessApiResponse<any>) => {
      const data = resp.data
      if (data.length > 0) {
        this.driverQualificationData = data[0];
        this.showForm = false;
        return;
      }

      this.loadFormAndData();

    }, error => {
      console.log("error =>",error);
      this.loadFormAndData();
    })
  }

  /**
   * Load form and data from server or set default values for new record
   */
  loadFormAndData(): void {
    forkJoin([this.fetchCompanyInfo(), this.fetchUserData()])
        .subscribe(([companyInfoResp, userProfileResp]: [SuccessApiResponse<CompanyInfo>, SuccessApiResponse<UserProfile>]) => {
          this.companyInfo = companyInfoResp.data;
          this.userProfle = userProfileResp.data;
          this.initForm();
        }, error => {});
  }

  /**
   * Init Form and set default values
   */
  initForm() {
    console.log("Init Form", this.companyInfo, this.userProfle);
    const address = this.companyInfo.street + ', ' + this.companyInfo.city + ', ' + this.companyInfo.state + ', ' + this.companyInfo.zipCode;
    const name = this.userProfle.firstName + ' ' + this.userProfle.lastName;
    const fmcsa = this.companyInfo.includes?.fmcsa;
    this.requestForm = this.formBuilder.group({
      userName: [{value: name, disabled: true}, [Validators.required]],
      userRole: [{value: 'Owner', disabled: true}, [Validators.required]],
      companyName: [{value: this.companyInfo.legalName, disabled: true}, [Validators.required]],
      companyAddress: [{value: address, disabled: true}, [Validators.required]],
      FEINNumber: [{value:  fmcsa.einNumber || this.companyInfo.einNumber, disabled: true}, [Validators.required]],
      DOTNumber: [{value: fmcsa.dotNumber || this.companyInfo.dotNumber, disabled: true}, [Validators.required]],
      MCNumber: [{value: fmcsa.mcNumber || this.companyInfo.mcNumber, disabled: true}, [Validators.required]],
      contactPersonName: [{value: "", disabled: false}],
      companyPhoneNumber: [{value: "", disabled: false}],
      email: [{value: "", disabled: false}],
      totalTrucks: [{value: "0", disabled: false}],
      totalTrailers: [{value: "0", disabled: false}],
    })
  }

  /** 
   * get form controls
   */
  get form() {
    return this.requestForm.controls;
  }

  /**
   * Get Company Information from API Service
   * @returns 
   */
  private fetchCompanyInfo(): Observable<any> {
    const includes = ['fmcsa'];
    return this.httpRequest.getCompanyInfos(this.selectedBranch?.companyInfoId!, includes);
  }
  
  /**
   * Get User Information from API Service
   * @returns 
   */
  private fetchUserData(): Observable<any> {
    return this.httpRequest.getUsersByIdOrEmail(this.currentUser.userId!);
  }

  /**
   * Submit form data to add a new request
   * @returns 
   */
  onSubmit() {
    this.submitted = true;
    if(this.requestForm.invalid) return;

    const payload = {
      "userName": this.requestForm.controls['userName'].value,
      "userRole": this.requestForm.controls['userRole'].value,
      "companyName": this.requestForm.controls['companyName'].value,
      "companyAddress": this.requestForm.controls['companyAddress'].value,
      "companyPhoneNumber": this.requestForm.controls['companyPhoneNumber'].value,
      "companyEINNumber": this.requestForm.controls['FEINNumber'].value,
      "companyDOTNumber": this.requestForm.controls['DOTNumber'].value,
      "companyMCNumber": this.requestForm.controls['MCNumber'].value,
      "companyTotalTrucks": parseInt(this.requestForm.controls['totalTrucks'].value) || 0,
      "companyTotalTrailers": parseInt(this.requestForm.controls['totalTrailers'].value) || 0,
      "contactPerson": this.requestForm.controls['contactPersonName'].value,
      "contactEmail": this.requestForm.controls['email'].value
    }

    this.driverQualificationFiles.create(this.selectedBranch?.companyInfoId!, payload).subscribe(res => {

      this.submitted = false;
      Swal.fire({
        title: 'Success',
        text: 'Successfully created driver qualification request.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok'
      }).then(result => {
        //do nothing
        window.location.reload();
      });

    }, err => {})
  }
  

}
