<div class="row">
    <div id="row-title-container-general">
        <h4 class="row-title">General</h4>
        <button *ngIf="companyInformation" type="button" class="btn btn-sm btn-primary add-btn side-edit-button" (click)="openModalGeneralInformation()">Edit</button>
    </div>
    <div class="col-lg-12">
        <div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">Type:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span *ngIf="companyInfo.type === 'carrier-account'">Carrier</span>
                    <span *ngIf="companyInfo.type === 'shipper-account'">Shipper</span>
                    <span *ngIf="companyInfo.type === 'broker-account'">Broker</span>
                    <span *ngIf="companyInfo.type === 'helper-account'">Helper</span>
                    <span *ngIf="companyInfo.type === 'driver-account'">Driver</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">Legal Entity:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span *ngIf="companyInfo.legalEntity == 'sole-proprietor'">Sole Proprietor</span>
                    <span *ngIf="companyInfo.legalEntity == 'llc'">LLC</span>
                    <span *ngIf="companyInfo.legalEntity == 'c-corp'">C Corporation</span>
                    <span *ngIf="companyInfo.legalEntity == 's-corp'">S Corporation</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">Legal Name:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{ companyInfo.legalName }}</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">DBA Name:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{ companyInfo.dbaName }}</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">EIN Number:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{ companyInfo.einNumber }}</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">Street:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{ companyInfo.street }}</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">City:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{ companyInfo.city }}</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">State:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{ companyInfo.state }}</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">ZIP Code:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{ companyInfo.zipCode }}</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
        <div class="row-title-container">
            <h4 class="row-title-margin-top">FMCSA Verified</h4>
            <button  *ngIf="companyInformation" type="button" class="btn btn-sm btn-primary add-btn side-edit-button" (click)="openModalTransportationInformation()">Edit</button>
        </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">DOT Number:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{companyInfo.dotNumber}}</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">MC Number:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{companyInfo.mcNumber}}</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">Authority:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span *ngIf="companyInfo.hasAuthority" style="color: green;">Active</span>
                    <span *ngIf="!companyInfo.hasAuthority" style="color: red;">Inactive</span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
        <div class="row-title-container">
            <h4 class="row-title-margin-top">Contact Information</h4>
        </div>
        <div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">Phone:</label>
                </div>
                <div class="col-lg-4 form-values" *ngIf="companyInfo">
                    <span>{{companyInfo.phone}}</span>
                    <span class="side-edit-button" *ngIf="companyInformation">
                        <button type="button" class="btn btn-sm btn-primary add-btn" (click)="openModalContactInformation('phoneInformation')">Edit</button>
                    </span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
            <div class="row row-data">
                <div class="col-lg-6 label-column padding-top">
                    <label class="form-label">Email:</label>
                </div>
                <div class="col-lg-6 form-values email-data" *ngIf="companyInfo">
                    <span>{{companyInfo.email}}</span>
                    <span class="side-edit-button" *ngIf="companyInformation">
                        <button type="button" class="btn btn-sm btn-primary add-btn" (click)="openModalContactInformation('emailInformation')">Edit</button>
                    </span>
                </div>
                <div class="col-lg-4 form-values" *ngIf="!companyInfo">
                    <span class="placeholder col-10"></span>
                </div>
            </div>
        </div>
    </div>
</div>