import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { MapService } from 'src/app/core/services/map.service';
import { MAP_POINT_OBJECT } from 'src/app/core/services/models/map.model';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() mapOptions: Object = {};
  @Input() mapPoints: Array<MAP_POINT_OBJECT> = [];

  private mapObj: google.maps.Map;
  private markers: google.maps.Marker[] = [];

  defaultMapOptions: Object = {
    center: this.mapService.USA_LAT_LNG,
    zoom: 4,
    mapTypeId: "roadmap",
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    scaleControl: true,
    styles: [
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [
          { "visibility": "off" }
        ]
      }
    ]
  }

  constructor(private mapService: MapService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
      this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mapPoints'] 
        && JSON.stringify(changes['mapPoints']['currentValue']) !== JSON.stringify(changes['mapPoints']['previousValue'])) {
      this.loadMap()
    }
  }

  /**
   * Initialize map using google map js api loader
   */
  initMap() {
    let loader = new Loader({
      apiKey: this.mapService.API_KEY,
      version: 'weekly',
      libraries: ['places']
    });

    loader.load().then(() => {
      this.loadMap();
    });
  }

  /**
   * Load Map and Add Markers on it
   */
  loadMap() {
    this.defaultMapOptions = {...this.defaultMapOptions, ...this.mapOptions};
    this.mapObj = new google.maps.Map(document.getElementById("google-map") as HTMLElement, this.defaultMapOptions);
    this.addMarkersOnMap();
  }

  /**
   * Load google map
   */
  addMarkersOnMap() {
    this.markers = [];
    this.mapPoints.forEach(point => {
      const marker = this.renderMarker(point);
      this.markers.push(marker);
    })
  }

  /**
   * Adds a marker to the given map at the specified coordinates with the provided icon.
   */
  private renderMarker(point: MAP_POINT_OBJECT): google.maps.Marker {
    const markerIcon = {
      path: 'M12 2C8.13 2 5 5.13 5 9C5 10.74 5.5 12.37 6.41 13.84C7.36 15.38 8.61 16.7 9.57 18.24C10.04 18.99 10.38 19.69 10.74 20.5C11 21.05 11.21 22 12 22C12.79 22 13 21.05 13.25 20.5C13.62 19.69 13.95 18.99 14.42 18.24C15.38 16.71 16.63 15.39 17.58 13.84C18.5 12.37 19 10.74 19 9C19 5.13 15.87 2 12 2ZM12 11.75C10.62 11.75 9.5 10.63 9.5 9.25C9.5 7.87 10.62 6.75 12 6.75C13.38 6.75 14.5 7.87 14.5 9.25C14.5 10.63 13.38 11.75 12 11.75Z',
      fillColor: point.color,
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 1.8,
      anchor: new google.maps.Point(12, 24)
    };
    
    const marker = new google.maps.Marker({
      map: this.mapObj,
      position: point.coordinates,
      title: point.name,
      icon: markerIcon
    });

    return marker;
  }

}
