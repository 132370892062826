<div class="auth-page-wrapper pt-5">
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div class="bg-overlay"></div>
        <div class="shape">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
            </svg>
        </div>
    </div>
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-5 mb-4 text-white-50">
                        <div>
                            <img src="../../../assets/logo-blue-background-login.png" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-6">
                    <div class="card sign-up-process-card mt-4">
                        <div class="card-body form-steps">
                            <aw-wizard [navBarLayout]="'large-empty-symbols'" class="nav nav-pills progress-bar-tab custom-nav">
                                <aw-wizard-step>
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill active" data-progressbar="custom-progress-bar" id="pills-gen-info-tab" data-bs-toggle="pill" data-bs-target="#pills-gen-info" type="button" role="tab" aria-controls="pills-gen-info" aria-selected="true">1</button>
                                        </li>
                                    </ng-template>
                                    <div class="row mb-4">
                                        <h5 style="text-align: center;"></h5>
                                    </div>
                                    <div class="welcome-video">
                                        <div class="ratio ratio-16x9">
                                            <iframe *ngIf="metaData.type === 'shipper'" src="https://share.synthesia.io/embeds/videos/d272f1b7-a60a-408d-a3f8-ced169359b3f" loading="lazy" title="Synthesia video player - Shipper Welcome Videos " allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe>
                                            <iframe *ngIf="metaData.type === 'broker'" src="https://share.synthesia.io/embeds/videos/6facf77a-933f-4859-91fd-c856514a3047" loading="lazy" title="Synthesia video player - Broker Welcome Videos " allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe>
                                            <iframe *ngIf="metaData.type === 'carrier'" src="https://share.synthesia.io/embeds/videos/ede549a1-7d83-46bb-bdff-da06ca8ca533" loading="lazy" title="Synthesia video player - Carrier Welcome Videos" allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe>
                                            <iframe *ngIf="metaData.type === 'bonafide'" src="https://share.synthesia.io/embeds/videos/627838ff-a1e0-4634-a8e9-908466726d59" loading="lazy" title="Synthesia video player - Carrier Welcome Videos" allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-start gap-3 mt-4">
                                        <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-info-desc-tab" awNextStep>
                                            <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Next </button>
                                    </div>
                                </aw-wizard-step>
                                <aw-wizard-step>
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">2</button>
                                        </li>
                                    </ng-template>
                                    <div class="row mb-4">
                                        <h5 style="text-align: center;">Conditions Of Use</h5>
                                    </div>
                                    <pdf-viewer src="/assets/pdf/terms.pdf" appCustomScrollTerms #appScrollElement="appCustomScrollTerms" [original-size]="true" [autoresize]="true" [fit-to-page]="true" [zoom-scale]="'page-fit'" [zoom]="2.0" style="width: 100%; height: 33.15em; display: block; overflow-x: hidden;"></pdf-viewer>
                                    <div class="d-flex align-items-start gap-3 mt-4">
                                        <button type="button" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                            <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                        <p class="error-message text-align-center">{{showErrorRead && appScrollElement.disableBtn ? 'Please read the entire agreement.' : ''}}</p>
                                        <div (click)="showError()" class="d-flex align-items-start gap-3 agree-btn">
                                            <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-info-desc-tab" awNextStep [disabled]="appScrollElement.disableBtn">
                                                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Next </button>
                                        </div>
                                    </div>
                                </aw-wizard-step>
                                <aw-wizard-step>
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">3</button>
                                        </li>
                                    </ng-template>
                                    <div class="row mb-4">
                                        <h5 style="text-align: center;">User Information</h5>
                                    </div>
                                    <form [formGroup]="passwordForm">
                                        <div class="col-md-12 form-password">
                                            <div class="row mb-3">
                                                <div class="col-lg-4 label">
                                                    <label class="form-label">First Name: <span class="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" id="firstName-input" placeholder="" formControlName="firstName" required [ngClass]="{ 'is-invalid': submitted && form['firstName'].errors }">
                                                        <div class="invalid-feedback" *ngIf="form['password'].errors"> Enter First Name </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2"></div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-lg-4 label">
                                                    <label class="form-label">Last Name: <span class="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" id="lastName-input" placeholder="" formControlName="lastName" required [ngClass]="{ 'is-invalid': submitted && form['lastName'].errors }">
                                                        <div class="invalid-feedback" *ngIf="form['password'].errors"> Enter Last Name </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2"></div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-lg-4 label">
                                                    <label class="form-label">Title: <span class="opacity-0">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" id="title-input" placeholder="" formControlName="title">
                                                    </div>
                                                </div>
                                                <div class="col-lg-2"></div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-lg-4 label">
                                                    <label class="form-label">Phone: <span class="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" id="phone-input" placeholder="" formControlName="phone" required [ngClass]="{ 'is-invalid': submitted && form['phone'].errors }">
                                                        <div class="invalid-feedback" *ngIf="form['phone'].errors"> Enter Phone </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2"></div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-lg-4 label">
                                                    <label class="form-label">Email: <span class="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control email-input" placeholder="" formControlName="email" [attr.disabled]="true" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-2"></div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-lg-4 label">
                                                    <label class="form-label">Password: <span class="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="input-group">
                                                        <div class="position-relative auth-pass-inputgroup">
                                                            <input [type]="fieldTextType ? 'text' : 'password'" class="form-control pe-5" id="password-input" formControlName="password" [ngClass]="{ 'is-invalid': submitted && form['password'].errors }">
                                                            <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon">
                                                                <i class="mdi align-middle" [ngClass]="{'mdi-eye-off-outline': !fieldTextType, 'mdi-eye-outline': fieldTextType
                                                        }" (click)="toggleFieldTextType()"></i>
                                                            </button>
                                                            <div *ngIf="passwordSubmitted" class="password-requirement-error">
                                                                <ul *ngFor="let pwError of getPasswordRequirmentErrors()">
                                                                    <li>{{pwError}}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-start gap-3 mt-4">
                                            <button type="button" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                                <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                            <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab" (click)="moveNextPassword()">
                                                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2" *ngIf="!isLoading"></i>
                                                <span class="flex-grow-1 me-2"> Next </span>
                                                <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </aw-wizard-step>
                                <aw-wizard-step *ngIf="metaData.type == 'shipper' || metaData.type == 'broker'">
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">4</button>
                                        </li>
                                    </ng-template>
                                    <div class="row mb-4">
                                        <h5 style="width: 100%; text-align: center;">Company Information</h5>
                                    </div>
                                    <form [formGroup]="shipperCompanyInfoForm">
                                        <div class="col-md-12 form-password">
                                            <ng-container formGroupName="legalInfo">
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Legal Entity: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <ng-select class="form-control" [items]="CURRENT_LEGAL_ENTITY_OPTIONS" [multiple]="false" formControlName="legalEntity" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="prettyName" bindValue="technicalName" [ngClass]="{ 'is-invalid': submitted && shipperCompanyInfo_legalInfoControls['legalEntity'].errors }"></ng-select>
                                                        <div class="invalid-feedback" *ngIf="shipperCompanyInfo_legalInfoControls['legalEntity'].errors"> Select Legal Entity </div>
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Company Type: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <ng-select class="form-control mb-3" [items]="shipperType" [multiple]="false" formControlName="type" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="name" bindValue="name" [ngClass]="{ 'is-invalid': submitted && shipperCompanyInfo_legalInfoControls['type'].errors }" (change)="shipperCompanyInfo_legalInfoControls['subtype'].setValue(null)" *ngIf="metaData.type !== 'broker'"></ng-select>
                                                        <ng-select class="form-control mb-3" [items]="shipperTypeBroker" [multiple]="false" formControlName="type" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="name" bindValue="name" [ngClass]="{ 'is-invalid': submitted && shipperCompanyInfo_legalInfoControls['type'].errors }" (change)="shipperCompanyInfo_legalInfoControls['subtype'].setValue(null)" *ngIf="metaData.type === 'broker'"></ng-select>
                                                        <ng-select class="form-control" [items]="shipperSubType[shipperCompanyInfo_legalInfoControls['type'].value]" [multiple]="false" formControlName="subtype" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="name" bindValue="name" [ngClass]="{ 'is-invalid': submitted && shipperCompanyInfo_legalInfoControls['subtype'].errors }" *ngIf="metaData.type !== 'broker'"></ng-select>
                                                        <ng-select class="form-control" [items]="shipperSubTypeBroker" [multiple]="false" formControlName="subtype" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="name" bindValue="name" [ngClass]="{ 'is-invalid': submitted && shipperCompanyInfo_legalInfoControls['subtype'].errors }" *ngIf="metaData.type === 'broker'"></ng-select>
                                                        <div class="invalid-feedback" *ngIf="
                                                            shipperCompanyInfo_legalInfoControls['type'].errors 
                                                                || shipperCompanyInfo_legalInfoControls['subtype'].errors
                                                        "> Select Type </div>
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="isDOTlookupNeeded() === false" formGroupName="companyInfo">
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Legal Company Name: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="legalName-input" placeholder="" formControlName="legalName" required [ngClass]="{ 'is-invalid': submitted && shipperCompanyInfo_companyInfoControls['legalName'].errors }">
                                                            <div class="invalid-feedback" *ngIf="shipperCompanyInfo_companyInfoControls['legalName'].errors"> Enter Legal Company Name </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">DBA Name: </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="dbaName-input" placeholder="" formControlName="dbaName">
                                                            <div class="invalid-feedback" *ngIf="shipperCompanyInfo_companyInfoControls['dbaName'].errors"> Enter DBA Name </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label for="address-field" class="form-label">Address <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <!-- <app-google-maps-search-box #googleMapsInput [showMap]="false" [isRequired]="true" [isSubmittedOnce]="submittedAddress" [isRequiredPostalCode]="true" [defaultPlace]="defaultPlace" (onPlaceChanged)="onAddressChangeCompanyInfo($event)" (onPlaceChangeIsEmpty)="onAddressChangeCompanyInfoIsEmpty($event)"></app-google-maps-search-box> -->
                                                        <app-google-maps-autocomplete #googleMapsInputAuto [addressTypes]="['address']" [isRequiredPostalCode]="true" (onPlaceChanged)="onAddressChangeCompanyInfo($event)" (onPlaceChangeIsEmpty)="onAddressChangeCompanyInfoIsEmpty($event)"></app-google-maps-autocomplete>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">EIN Number: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="input-group">
                                                            <input type="text" class="form-control" id="einNumber-input" placeholder="" formControlName="einNumber" required [ngClass]="{ 'is-invalid': submitted && shipperCompanyInfo_companyInfoControls['einNumber'].errors }">
                                                            <div class="invalid-feedback" *ngIf="shipperCompanyInfo_companyInfoControls['einNumber'].errors"> Enter EIN Number </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="isDOTlookupNeeded() === true">
                                                <form [formGroup]="dotForm">
                                                    <div class="col-md-12 form-password">
                                                        <div class="row mb-3">
                                                            <div class="col-lg-4 label">
                                                                <label class="form-label">DOT Number: <span class="text-danger">*</span>
                                                                </label>
                                                            </div>
                                                            <div class="col-lg-7">
                                                                <div class="row">
                                                                    <div class="col-lg-9 pe-0">
                                                                        <input formControlName="number" type="number" class="form-control" placeholder="Enter DOT Number" required [ngClass]="{ 'is-invalid': submitted && dotFormControls['number'].errors }">
                                                                        <div *ngIf="submitted && dotFormControls['number'].hasError('required')" class="text-danger"> Enter DOT Number </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <button type="button" class="btn btn-primary action-btn" (click)="lookupFMCSA()" [disabled]="dotLookupLoading">
                                                                            <div *ngIf="!dotLookupLoading" class="inline-block" style="font-size: 12px"> Lookup </div>
                                                                            <div *ngIf="dotLookupLoading" class="spinner-border inline-block dot-save-loading" role="status">
                                                                                <span class="sr-only">Loading...</span>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-1"></div>
                                                        </div>
                                                        <ng-template [ngTemplateOutlet]="FCMSA" [ngTemplateOutletContext]="{fmcsa}"></ng-template>
                                                    </div>
                                                </form>
                                            </ng-container>
                                        </div>
                                        <div class="d-flex align-items-start gap-3 mt-4">
                                            <button type="button" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                                <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                            <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab" (click)="metaData.type == 'broker' ? moveNextLegalEntitySteps() : nextFMCSA()" [disabled]="!companyInfoAddressValid">
                                                <!-- <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab" (click)="moveNextLegalEntitySteps()" [disabled]="!companyInfoAddressValid"> -->
                                                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2" *ngIf="!isLoading"></i>
                                                <span class="flex-grow-1 me-2"> Next </span>
                                                <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </aw-wizard-step>
                                <aw-wizard-step *ngIf="metaData.type == 'carrier'">
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">4</button>
                                        </li>
                                    </ng-template>
                                    <div class="row spacer"></div>
                                    <form [formGroup]="dotForm">
                                        <div class="col-md-12 form-password">
                                            <div class="row mb-3">
                                                <div class="col-lg-4 label">
                                                    <label class="form-label">DOT Number: <span class="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <ng-select formControlName="type" class="form-control" [items]="DOT_TYPE_OPTIONS" [multiple]="false" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="prettyName" bindValue="technicalName" (change)="changeFMCAType()"></ng-select>
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row mb-3" [hidden]="dotForm.value.type === 'none'">
                                                <div class="col-lg-4 label"></div>
                                                <div class="col-lg-7">
                                                    <div class="row">
                                                        <div class="col-lg-9 pe-0">
                                                            <input formControlName="number" type="number" class="form-control" placeholder="Enter DOT Number" required [ngClass]="{ 'is-invalid': dotLookupTriggered && dotFormControls['number'].errors }">
                                                            <div *ngIf="dotLookupTriggered && dotFormControls['number'].hasError('required')" class="text-danger"> Enter DOT Number </div>
                                                        </div>
                                                        <div class="col-lg-3">
                                                            <button type="button" class="btn btn-primary action-btn" (click)="lookupFMCSA()" [disabled]="dotLookupLoading">
                                                                <div *ngIf="!dotLookupLoading" class="inline-block" style="font-size: 12px"> Lookup </div>
                                                                <div *ngIf="dotLookupLoading" class="spinner-border inline-block dot-save-loading" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row mb-3" *ngIf="fmcsa?.isFleet">
                                                <div class="col-lg-4 label">
                                                    <label class="form-label">Fleet Type: <span class="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <ng-select formControlName="fleetType" class="form-control" [items]="FLEET_TYPE" [multiple]="false" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="prettyName" bindValue="technicalName"></ng-select>
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <ng-template [ngTemplateOutlet]="FCMSA" [ngTemplateOutletContext]="{fmcsa}"></ng-template>
                                        </div>
                                        <div class="d-flex align-items-start gap-3 mt-4">
                                            <button type="button" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                                <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                            <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" *ngIf="!dotLookupLoading" [disabled]="fmcsa?.isFleet && !this.dotFormControls['fleetType'].value" data-nexttab="pills-success-tab" (click)="nextFMCSA()">
                                                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Next </button>
                                        </div>
                                    </form>
                                </aw-wizard-step>
                                <aw-wizard-step *ngIf="metaData.type == 'carrier'">
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">5</button>
                                        </li>
                                    </ng-template>
                                    <form>
                                        <div class="col-md-12 form-password" *ngIf="isFMCAX() || (fmcsa?.hasAuthority && fmcsa?.hasBrokerAuthority && !accountTypeSelected)" style="margin-bottom: 4.25rem;">
                                            <ng-template [ngTemplateOutlet]="X" [ngTemplateOutletContext]="{step: 'after-fmcsa'}"></ng-template>
                                        </div>
                                        <div class="col-md-12 carrier-legal-step" *ngIf="!isFMCAX() && !(fmcsa?.hasAuthority && fmcsa?.hasBrokerAuthority && !accountTypeSelected)">
                                            <div class="row mb-3">
                                                <div class="col-lg-4 label">
                                                    <label class="form-label">Legal Entity: <span class="text-danger">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <ng-select class="form-control" [items]="CURRENT_LEGAL_ENTITY_OPTIONS" [multiple]="false" [(ngModel)]="selectedLegalEntity" [ngModelOptions]="{standalone: true}" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="prettyName" bindValue="technicalName"></ng-select>
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-start gap-3 mt-4">
                                            <button type="button" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                                <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                            <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab" awNextStep *ngIf="!isFMCAX()">
                                                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Next </button>
                                        </div>
                                    </form>
                                </aw-wizard-step>
                                <aw-wizard-step>
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">{{metaData.type == 'shipper' ? 5: metaData.type == 'broker' ? 5: metaData.type == 'contract-driver' ? 4: metaData.type == 'bonafide' ? 4: 6}}</button>
                                        </li>
                                    </ng-template>
                                    <div class="col-md-12 form-password" *ngIf="fmcsa?.hasAuthority && fmcsa?.hasBrokerAuthority && !accountTypeSelected" style="margin-bottom: 4.25rem;">
                                        <ng-template [ngTemplateOutlet]="X" [ngTemplateOutletContext]="{step: 'after-fmcsa'}"></ng-template>
                                    </div>
                                    <div *ngIf="!(fmcsa?.hasAuthority && fmcsa?.hasBrokerAuthority && !accountTypeSelected)">
                                        <div class="row mb-4">
                                            <h5 style="width: 100%; text-align: center;">Billing Contact Information</h5>
                                        </div>
                                        <form [formGroup]="billingInfoForm">
                                            <div class="col-md-12 billing-form">
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Company Name: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-8">
                                                        <input formControlName="billingCompanyName" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['billingCompanyName'].errors }">
                                                        <div class="invalid-feedback"> Enter Company Name </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">First Name: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-8">
                                                        <input formControlName="billingContactFirstName" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['billingContactFirstName'].errors }">
                                                        <div class="invalid-feedback"> Enter Contact First Name </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Last Name: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-8">
                                                        <input formControlName="billingContactLastName" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['billingContactLastName'].errors }">
                                                        <div class="invalid-feedback"> Enter Last Name </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label for="address-field" class="form-label">Address <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-8" *ngIf="metaData.type == 'carrier' || metaData.type == 'contract-driver' || metaData.type == 'bonafide'">
                                                        <app-google-maps-search-box #googleMapsInput [showMap]="false" [isRequired]="true" [defaultPlace]="defaultPlace" [isRequiredPostalCode]="true" [isSubmittedOnce]="submittedAddress" (onPlaceChanged)="onAddressChange($event)" (onPlaceChangeIsEmpty)="onAddressChangIsEmpty($event)"></app-google-maps-search-box>
                                                    </div>
                                                    <div class="col-lg-8" *ngIf="metaData.type == 'shipper' || metaData.type == 'broker'">
                                                        <app-google-maps-search-box #googleMapsInput [showMap]="false" [isRequired]="true" [isSubmittedOnce]="submittedAddress" [isRequiredPostalCode]="true" [defaultPlace]="defaultPlace" (onPlaceChanged)="onAddressChange($event)" (onPlaceChangeIsEmpty)="onAddressChangIsEmpty($event)"></app-google-maps-search-box>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Phone: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-8">
                                                        <input formControlName="billingContactPhone" type="text" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['billingContactPhone'].errors }">
                                                        <div class="invalid-feedback"> Enter Phone </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Email: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-8">
                                                        <input formControlName="billingContactEmail" type="email" class="form-control" placeholder="" required [ngClass]="{ 'is-invalid': submitted && billingInfo['billingContactEmail'].errors }">
                                                        <div class="invalid-feedback"> Enter Email </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">CC Emails: <span class="opacity-0">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-8">
                                                        <textarea formControlName="billingContactEmailCC" class="form-control" rows="2"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-start gap-3 mt-4">
                                                <button type="button" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                                    <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                                <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab" awNextStep [disabled]="billingInfoForm.invalid || !billingInfoAddress || isBillingInfoAddressEmpty || !billingAddressValid" *ngIf="metaData.type == 'carrier'">
                                                    <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2" *ngIf="!isLoading"></i>
                                                    <span class="flex-grow-1 me-2"> Next </span>
                                                    <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </span>
                                                </button>
                                                <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab" (click)="goToSummary()" [disabled]="billingInfoForm.invalid || !billingInfoAddress || isBillingInfoAddressEmpty || !billingAddressValid" *ngIf="metaData.type != 'carrier'">
                                                    <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2" *ngIf="!isLoading"></i>
                                                    <span class="flex-grow-1 me-2"> Next </span>
                                                    <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </aw-wizard-step>
                                <aw-wizard-step *ngIf="metaData.type == 'carrier'">
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">7</button>
                                        </li>
                                    </ng-template>
                                    <ng-container>
                                        <div class="row fuel-providers">
                                            <h5 style="width: 100%; text-align: center;">Fuel Providers</h5>
                                        </div>
                                        <form [formGroup]="fuelProvidersForm">
                                            <div class="col-md-12 form-password">
                                                <div class="row mb-3" id="fuel-provider-cont">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Fuel Provider Name: <!-- <span class="text-danger">*</span> -->
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-7">
                                                        <div class="input-group">
                                                            <ng-select 
                                                                formControlName="name" 
                                                                class="form-control" 
                                                                [items]="fuelProviders" 
                                                                [multiple]="false" 
                                                                [virtualScroll]="true" 
                                                                [closeOnSelect]="true" 
                                                                [hideSelected]="false" 
                                                                [searchWhileComposing]="true" 
                                                                [clearable]="false" 
                                                                bindLabel="prettyName" 
                                                                bindValue="techName"
                                                                (change)="selectFuelProviders($event);">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-1"></div>
                                                    <div class="col-lg-10">
                                                        <table class="table align-middle table-nowrap mb-0" *ngIf="selectedFuelProviders.length != 0">
                                                            <thead class="table-light">
                                                                <tr>
                                                                    <th scope="col" class="name-td">Name</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let providers of selectedFuelProviders">
                                                                    <td class="name-td">
                                                                        {{ providers.prettyName }}
                                                                    </td>
                                                                    <td>
                                                                        <div class="d-flex gap-2">
                                                                            <div class="remove">
                                                                                <button class="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" (click)="deleteContractSigner(providers)">Delete</button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-start gap-3 mt-4">
                                                <button type="button" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                                    <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                                <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab" awNextStep>
                                                    <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Next </button>
                                            </div>
                                        </form>
                                    </ng-container>
                                </aw-wizard-step>
                                <aw-wizard-step *ngIf="metaData.type == 'carrier'">
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">8</button>
                                        </li>
                                    </ng-template>
                                    <ng-container>
                                        <div class="row eld-information">
                                            <h5 style="width: 100%; text-align: center;">ELD Information</h5>
                                        </div>
                                        <form [formGroup]="eldForm">
                                            <div class="col-md-12 form-password">
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">ELD Name: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="input-group">
                                                            <ng-select formControlName="companyName" class="form-control" [items]="uniqueEldCompanyName" [multiple]="false" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="companyName" bindValue="companyName" (change)="selectCompanyName($event); deviceNameDropdown.handleClearClick()"></ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Device Name: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="input-group">
                                                            <ng-select #deviceNameDropdown formControlName="deviceName" class="form-control" [items]="uniqueEldDeviceName" [multiple]="false" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="deviceName" bindValue="deviceName" (change)="selectDeviceName($event); modelNameDropdown.handleClearClick()"></ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Model Name: <span class="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="input-group">
                                                            <ng-select #modelNameDropdown formControlName="modelNumber" class="form-control" [items]="uniqueEldModelName" [multiple]="false" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="modelNumber" bindValue="modelNumber"></ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-lg-4 label">
                                                        <label class="form-label">Association: </label>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <ng-select #modelNameDropdown [(ngModel)]="selectedAssociation" [ngModelOptions]="{standalone: true}" class="form-control sign-up-process-association-select" [items]="association" [multiple]="true" [virtualScroll]="true" [closeOnSelect]="true" [hideSelected]="false" [searchWhileComposing]="true" [clearable]="false" bindLabel="prettyName" bindValue="technicalName"></ng-select>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-start gap-3 mt-4">
                                                <button type="button" [disabled]="isLoading" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                                    <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                                <button type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab" (click)="goToSummary()" [disabled]="isLoading || eldForm.invalid">
                                                    <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2" *ngIf="!isLoading"></i>
                                                    <span class="flex-grow-1 me-2"> Next </span>
                                                    <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </form>
                                    </ng-container>
                                </aw-wizard-step>
                                <aw-wizard-step *ngIf="metaData.type == 'carrier'">
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">9</button>
                                        </li>
                                    </ng-template>
                                    <ng-container>
                                        <div class="row summary-row">
                                            <h5 style="width: 100%; text-align: center;">Summary</h5>
                                            <div class="row summary">
                                                <h6 style="width: 100%; text-align: center;">
                                                    <strong>User Information</strong>
                                                </h6>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>First Name:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{passwordForm.controls['firstName'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Last Name:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{passwordForm.controls['lastName'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Title:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{passwordForm.controls['title'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Phone:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{passwordForm.controls['phone'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Email:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{passwordForm.controls['email'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                            </div>
                                            <div class="row summary">
                                                <h6 style="width: 100%; text-align: center;">
                                                    <strong>Company Information</strong>
                                                </h6>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Legal Company Name:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.legalName}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>DBA Name:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.dbaName}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Address:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.street}}, {{fmcsa?.city}}, {{fmcsa?.state}}, {{fmcsa?.zipcode}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Operation Classification:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.operationClassDesc}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Carrier Authority:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.hasAuthority ? 'Yes': 'No'}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Broker Authority:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.hasBrokerAuthority ? 'Yes': 'No'}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Fleet:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.isFleet? 'Yes': 'No'}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>DOT Number:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.dotNumber}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>MC Number:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.mcNumber}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>EIN Number:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.einNumber}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Total Drivers:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.totalDrivers}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Total Power Units:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.totalPowerUnits}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                            </div>
                                            <div class="row summary">
                                                <h6 style="width: 100%; text-align: center;">
                                                    <strong>Legal Entity</strong>
                                                </h6>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Legal Entity:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{LEGAL_ENTITY_OPTIONS_MAP.get(selectedLegalEntity)}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                            </div>
                                            <div class="row summary">
                                                <h6 style="width: 100%; text-align: center;">
                                                    <strong>Billing Contact Information</strong>
                                                </h6>
                                                <div class="row" *ngIf="fmcsa?.legalName">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Name:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{fmcsa?.legalName}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>First Name:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{billingInfoForm.controls['billingContactFirstName'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Last Name:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{billingInfoForm.controls['billingContactLastName'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Address:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{billingInfoAddress?.formatted_address}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Phone:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{billingInfoForm.controls['billingContactPhone'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Email:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{billingInfoForm.controls['billingContactEmail'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>CC Emails:</label>
                                                    </div>
                                                    <div class="col-lg-8 ">
                                                        {{billingInfoForm.controls['billingContactEmailCC'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                            </div>
                                            <div class="row summary">
                                                <h6 style="width: 100%; text-align: center;">
                                                    <strong>Fuel Providers</strong>
                                                </h6>
                                                <div class="row" *ngFor="let providers of selectedFuelProviders">
                                                    <div class="col-lg-2 label summary-label"></div>
                                                    <div class="col-lg-8">
                                                        {{providers.prettyName}}
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                </div>
                                            </div>
                                            <div class="row summary">
                                                <h6 style="width: 100%; text-align: center;">
                                                    <strong>ELD Information</strong>
                                                </h6>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Company Name:</label>
                                                    </div>
                                                    <div class="col-lg-8">
                                                        {{eldForm.controls['companyName'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Device Name:</label>
                                                    </div>
                                                    <div class="col-lg-8">
                                                        {{eldForm.controls['deviceName'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                                <div class="row summary-details-row">
                                                    <div class="col-lg-4 label summary-label">
                                                        <label>Model Name:</label>
                                                    </div>
                                                    <div class="col-lg-8">
                                                        {{eldForm.controls['modelNumber'].value}}
                                                    </div>
                                                    <div class="col-lg-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-start gap-3 mt-4">
                                            <button type="button" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                                <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                            <button awNextStep [disabled]="isLoading" type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab">
                                                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2" *ngIf="!isLoading"></i>
                                                <span class="flex-grow-1 me-2"> Next </span>
                                                <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                    <span class="visually-hidden">Loading...</span>
                                                </span>
                                            </button>
                                        </div>
                                    </ng-container>
                                </aw-wizard-step>
                                <aw-wizard-step *ngIf="metaData.type == 'shipper' || metaData.type == 'contract-driver' || metaData.type == 'bonafide' || metaData.type == 'broker'">
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">{{metaData.type == 'shipper' ? 6: metaData.type == 'broker' ? 6: metaData.type == 'contract-driver' ? 5: metaData.type == 'bonafide' ? 5: 7}}</button>
                                        </li>
                                    </ng-template>
                                    <div class="row summary-row">
                                        <h5 style="width: 100%; text-align: center;">Summary</h5>
                                        <div class="row summary">
                                            <h6 style="width: 100%; text-align: center;">
                                                <strong>User Information</strong>
                                            </h6>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>First Name:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{passwordForm.controls['firstName'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Last Name:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{passwordForm.controls['lastName'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Title:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{passwordForm.controls['title'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Phone:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{passwordForm.controls['phone'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Email:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{passwordForm.controls['email'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                        </div>
                                        <div class="row summary" *ngIf="metaData.type == 'shipper' || metaData.type == 'broker'">
                                            <h6 style="width: 100%; text-align: center;">
                                                <strong>Company Information</strong>
                                            </h6>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Legal Company Name:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{shipperCompanyInfo_companyInfoControls['legalName'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>DBA Name:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{shipperCompanyInfo_companyInfoControls['dbaName'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>EIN Number:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{shipperCompanyInfo_companyInfoControls['einNumber'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row" *ngIf="metaData.type == 'broker'">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Total Drivers:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{fmcsa?.totalDrivers}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row" *ngIf="metaData.type == 'broker'">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Total Power Units:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{fmcsa?.totalPowerUnits}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Address:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{ shipperCompanyInfo_companyInfoControls['address'].value?.formatted_address
                                                        ?? shipperCompanyInfo_companyInfoControls['address'].value
                                                }}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                        </div>
                                        <div class="row summary" *ngIf="metaData.type == 'shipper' || metaData.type == 'broker'">
                                            <h6 style="width: 100%; text-align: center;">
                                                <strong>Legal Entity</strong>
                                            </h6>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Legal Entity:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{LEGAL_ENTITY_OPTIONS_MAP.get(shipperCompanyInfo_legalInfoControls['legalEntity'].value)}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Type:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{shipperCompanyInfo_legalInfoControls['subtype'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                        </div>
                                        <div class="row summary">
                                            <h6 style="width: 100%; text-align: center;">
                                                <strong>Billing Contact Information</strong>
                                            </h6>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>First Name:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{billingInfoForm.controls['billingContactFirstName'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Last Name:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{billingInfoForm.controls['billingContactLastName'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Address:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{billingInfoAddress?.formatted_address}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Phone:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{billingInfoForm.controls['billingContactPhone'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>Email:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{billingInfoForm.controls['billingContactEmail'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <div class="row summary-details-row">
                                                <div class="col-lg-4 label summary-label">
                                                    <label>CC Emails:</label>
                                                </div>
                                                <div class="col-lg-8 ">
                                                    {{billingInfoForm.controls['billingContactEmailCC'].value}}
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-start gap-3 mt-4">
                                        <button type="button" class="btn btn-primary text-decoration-none btn-label previestab" data-previous="pills-gen-info-tab" awPreviousStep>
                                            <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back </button>
                                        <button *ngIf="metaData.type !== 'broker'" type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab" (click)="submit()" [disabled]="isLoading">
                                            <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2" *ngIf="!isLoading"></i>
                                            <span class="flex-grow-1 me-2"> Create Account </span>
                                            <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                <span class="visually-hidden">Loading...</span>
                                            </span>
                                        </button>
                                        <button *ngIf="metaData.type == 'broker'" awNextStep [disabled]="isLoading" type="button" class="btn btn-primary btn-label right ms-auto nexttab nexttab" data-nexttab="pills-success-tab">
                                            <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2" *ngIf="!isLoading"></i>
                                            <span class="flex-grow-1 me-2"> Next </span>
                                            <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                <span class="visually-hidden">Loading...</span>
                                            </span>
                                        </button>
                                    </div>
                                </aw-wizard-step>
                                <aw-wizard-step *ngIf="metaData.type == 'carrier' || metaData.type == 'broker'">
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">{{metaData.type == 'carrier' ? 10: metaData.type == 'broker' ? 7 : 7}}</button>
                                        </li>
                                    </ng-template>
                                    <div class="row" style="margin-top: 1em;">
                                        <div class="d-grid gap-2 step-eight-buttons" *ngIf="metaData.plan === 'free'">
                                            <h5 class="step-button-desc">Welcome to Direct To Carrier!</h5>
                                            <br>
                                            <h5 class="step-button-desc">To activate your For Ever Free account, click on the link below. A credit card is required to verify your identity and activate your account.</h5>
                                            <br>
                                            <h5 class="step-button-desc"><b class="col-org">Congratulations!</b> Your account has been selected to try out three premium features at no cost for 7-days.</h5>
                                            <ul>
                                                <li>Carrier Safety & Compliance Suit</li>
                                                <li>Service Area and Zone Mapping</li>
                                                <li>User Public Profile</li>
                                            </ul>

                                            <br>
                                            <h5 class="step-button-desc">Don't worry; your account will not be charged during the 7-day trial period. If you continue using these features after the trial, it will only cost $30.00 monthly, including taxes and fees. Please feel free to cancel at any time. Once your card is verified, you'll be taken to your dashboard.</h5>
                                            
                                            <br>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" [(ngModel)]="isAgreeForTrail" id="consent-check">
                                                <label class="form-check-label" for="consent-check">You agree to the 7-day trial by checking this box.</label>
                                            </div>
                                            <br>
                                            <p id="dashboard-text" class="col-org" (click)="redirectTo = 'dashboard'; !redirectLoading && submit()"> <b>Get Started</b></p>
                                            <div *ngIf="redirectLoading" class="spinner-border inline-block loading" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="d-grid gap-2 step-eight-buttons" [ngClass]="{ 'carrier-paid-text': metaData.type == 'carrier'}" *ngIf="metaData.plan === 'paid'">
                                            <h5 class="step-button-desc">Welcome to Direct To Carrier!</h5>
                                            <br>
                                            <h5 class="step-button-desc"><b class="col-org">Congratulations!</b> Thank you for supporting our platform. We are excited to have you join our growing community.</h5>
                                            <br>
                                            <h5 class="step-button-desc">To activate your Pre-Sale License, click on the <b class="col-org cursor-pointer" (click)="redirectTo = 'dashboard'; !redirectLoading && submit()">Get Started</b> link below. Your credit card will be charged $150 for one Pre-Sale Asset License. Once on the platform, you can purchase additional pre-sale asset licenses for up to the number of power units listed with the FMCSA.</h5>
                                            <br>
                                            <h5 class="step-button-desc">Each pre-sale asset license Purchased qualifies for a free $150 monthly for 24 months. Your asset license must remain active to maintain the $150 per month pre-sale price.</h5>
                                            <br>
                                            <h5 class="step-button-desc">The platform goes live on <span class="col-org">5.1.24</span>. This account will be placed in a limited status during the pre-sale period: every Wednesday & Friday leading up to the live date <span class="col-org">5.1.24</span>, Additional features will be released.</h5>
                                            <br>
                                            <h5 class="step-button-desc" *ngIf="metaData.type == 'carrier'"><span class="col-org">For Limited Time Only,</span> ForEVER Asset license,  the first 2500 pre-sale asset licenses activated on or before 4.30.2024 or until the ForEver asset Licenses sell out, you are eligible to be converted to a ForEVER Asset License. Once on the platform, navigate to the green box “Buy Pre-Sale Assets” at the top of the page. You can select the number of ForEver licenses up to the number of active pre-sales licenses.  The credit card on file will be charged an additional $450 each for the ForEVER asset licenses. The pre-sale asset license will be merged with the ForEVER license. ForEVER Asset allows for ForEver access to the platform's highest available carrier asset license.  You’ll need to purchase a ForEVER license per power unit listed with the FMCSA. To purchase more ForEVER asset licenses on the platform, you’ll need to buy a pre-sale asset license first to unlock access to the ForEVER licenses. The total price for the ForEVER license is $600 each. ForEVER licenses are non-refundable or transferable.</h5>
                                            
                                            <br>
                                            <p id="dashboard-text" class="col-org" (click)="redirectTo = 'dashboard'; !redirectLoading && submit()"> <b>Get Started</b></p>
                                            <div *ngIf="redirectLoading" class="spinner-border inline-block loading" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<ng-container *ngIf="fmcsa?.hasAuthority || fmcsa?.hasBrokerAuthority">
                                        <div class="row" style="margin-top: 2em;">
                                            <div class="d-grid gap-2 step-eight-buttons" *ngIf="metaData.plan === '14daytrial' || metaData.plan === 'free'">
                                                <p id="dashboard-text" (click)="redirectTo = 'dashboard'; !redirectLoading && submit()"> Go to dashboard </p>
                                                <div *ngIf="redirectLoading" class="spinner-border inline-block loading" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <div class="d-grid gap-2 step-eight-buttons" *ngIf="metaData.plan === '30daytrial'">
                                                <h5 class="step-button-desc">Set up your credit card in Stripe.</h5>
                                                <h5 class="step-button-desc">Afterwards, you will be taken to the dashboard.</h5>
                                                <p id="dashboard-text" (click)="redirectTo = 'dashboard'; !redirectLoading && submit()"> Go to Stripe </p>
                                                <div *ngIf="redirectLoading" class="spinner-border inline-block loading" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <div class="d-grid gap-2 step-eight-buttons" *ngIf="metaData.plan === 'paid'">
                                                <h5 class="step-button-desc">Set up your credit card in Stripe.</h5>
                                                <h5 class="step-button-desc">Afterwards, you will be charged $500,</h5>
                                                <h5 class="step-button-desc">and taken to the dashboard.</h5>
                                                <p id="dashboard-text" (click)="redirectTo = 'dashboard'; !redirectLoading && submit()"> Go to Stripe </p>
                                                <div *ngIf="redirectLoading" class="spinner-border inline-block loading" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>-->
                                </aw-wizard-step>
                                <aw-wizard-step *ngIf="metaData.type == 'shipper' || metaData.type == 'contract-driver' || metaData.type == 'bonafide'">
                                    <ng-template awWizardStepSymbol>
                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link rounded-pill" data-progressbar="custom-progress-bar" id="pills-info-desc-tab" data-bs-toggle="pill" data-bs-target="#pills-info-desc" type="button" role="tab" aria-controls="pills-info-desc" aria-selected="false">{{(metaData.type == 'bonafide' || metaData.type == 'contract-driver' ) ? 6 : 7}}</button>
                                        </li>
                                    </ng-template>
                                    <div class="row" style="margin-top: 1em;"  *ngIf="metaData.type == 'shipper'">
                                        <div class="d-grid gap-2 step-eight-buttons" *ngIf="metaData.plan === 'free'">
                                            <h5 class="step-button-desc">Welcome to Direct To Carrier!</h5>
                                            <br>
                                            <h5 class="step-button-desc">To activate your For Ever Free account, click on the link below.</h5>
                                            <br>
                                            <h5 class="step-button-desc"><b class="col-org">Congratulations!</b> Thank you for supporting our platform. We are excited to have you join our growing community.</h5>
                                        </div>
                                        <div class="d-grid gap-2 step-eight-buttons" *ngIf="metaData.plan === 'paid'">
                                            <h5 class="step-button-desc">Welcome to Direct To Carrier!</h5>
                                            <br>
                                            <h5 class="step-button-desc">To activate your Pre-Sale asset license, click on the link below. The system will provide an invoice inside the platform for purchasing a pre-sale asset license. Once inside the platform, Additional asset licenses are available.</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <h2 *ngIf="metaData.type == 'shipper'" class="step-desc">Welcome to Direct To Carrier. Thank you for setting up a shipper account.</h2> -->
                                        <h2 *ngIf="metaData.type == 'contract-driver'" class="step-desc">Welcome to Direct To Carrier. Thank you for setting up a contract driver account.</h2>
                                        <h2 *ngIf="metaData.type == 'bonafide'" class="step-desc">Welcome to Direct To Carrier. Thank you for setting up a Bona Fide Agent account.</h2>
                                        <!--<h2 *ngIf="metaData.type == 'broker'" class="step-desc">Welcome to Direct To Carrier. Thank you for setting up a Freight Intermediary account.</h2>-->
                                    </div>
                                    <!--<div class="row">
                                        <div class="d-grid gap-2 step-eight-buttons">
                                            <h5 class="step-button-desc">Schedule Call With Integration Team</h5>
                                            <button type="button" class="btn btn-primary waves-effect waves-light" (click)="openLinkAndCloseTab()">
                                                <span class="flex-grow-1 me-2"> Schedule a call </span>
                                            </button>
                                        </div>
                                    </div>-->
                                    <div class="row" style="margin-top: 2em;">
                                        <div class="d-grid gap-2 step-eight-buttons">
                                            <!--<h5 class="step-button-desc">Schedule Call Later</h5>-->
                                            <p id="dashboard-text" class="col-org" (click)="redirectToDashboard()"><b>Get Started</b></p>
                                        </div>
                                    </div>
                                </aw-wizard-step>
                            </aw-wizard>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #popContent>
                <p>Must have at least 10 characters in length.</p>
                <p>Must have lowercase letter.</p>
                <p>Must have uppercase letter.</p>
                <p>Must have number.</p>
                <p>Must have special character.</p>
            </ng-template>
        </div>
    </div>
</div>
<ng-template #FCMSA let-fmcsa="fmcsa">
    <div id="fsca-result" *ngIf="fmcsa">
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>Legal Company Name:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.legalName}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>DBA Name:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.dbaName}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>Address:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.street}}, {{fmcsa.city}}, {{fmcsa.state}}, {{fmcsa.zipcode}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>Operation Classification:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.operationClassDesc}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>Carrier Authority:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.hasAuthority ? 'Yes': 'No'}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>Broker Authority:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.hasBrokerAuthority ? 'Yes': 'No'}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>Fleet:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.isFleet? 'Yes': 'No'}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>DOT Number:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.dotNumber}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>MC Number:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.mcNumber}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>EIN Number:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa.einNumber}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>Total Drivers:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa?.totalDrivers}}
            </div>
            <div class="col-lg-1"></div>
        </div>
        <div class="row fmcsa-row">
            <div class="col-lg-4 label fmcsa-label">
                <label>Total Power Units:</label>
            </div>
            <div class="col-lg-8 ">
                {{fmcsa?.totalPowerUnits}}
            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>
</ng-template>
<ng-template #X let-step="step">
    <div class="row mb-3" *ngIf="step == 'after-fmcsa' && metaData.type == 'carrier' && (!fmcsa || !fmcsa.hasAuthority)">
        <div class="col-lg-12 text-align-center text-danger">
            <p>DOT Number Required To Signup</p>
            <p>Operating Authority/MC Number Required To Signup</p>
            <br>
            <p> We have determined that you are a not a federal motor carrier. Please follow the link below to sign up as Independent Driver</p>
            <button type="button" class="btn btn-primary waves-effect waves-light" (click)="openLinkAndCloseTab()">
                <span class="flex-grow-1 me-2">
                    <a [href]="domain + '/contract-driver'" class="text-white"> Go to Independent Driver Signup </a>
                </span>
            </button>
            <!-- <p *ngIf="step == 'after-fmcsa' && !fmcsa?.dotNumber"> You need a DOT Number to signup. </p><p *ngIf="step == 'after-fmcsa' && !fmcsa?.mcNumber"> You need a MC Number to signup. </p><p *ngIf="step == 'after-fmcsa' && !fmcsa?.hasAuthority"> Active Operating Authority/MC Number Required To Signup. </p><p *ngIf="step == 'after-fmcsa' && fmcsa?.isBroker"> You can't signup as a broker. </p> -->
            <!-- <p *ngIf="step == 'after-legalEntity' && this.selectedLegalEntity && selectedLegalEntity === 'sole-proprietor'"> You can't sign up as a Sole Proprietor. </p> -->
        </div>
    </div>
    <div class="row mb-3" *ngIf="step == 'after-fmcsa' && metaData.type == 'broker' && (!fmcsa || !fmcsa?.hasBrokerAuthority)">
        <div class="col-lg-12 text-align-center text-danger">
            <p *ngIf="step == 'after-fmcsa' && !fmcsa?.hasAuthority && !fmcsa?.hasBrokerAuthority"> Operating Authority/MC Number Required To Signup. </p>
        </div>
    </div>
    <div class="row mb-3" *ngIf="step == 'after-fmcsa' && fmcsa?.hasAuthority && fmcsa?.hasBrokerAuthority">
        <div class="col-lg-12 text-align-center text-danger">
            <p>We have determined you have both the Federal Motor Carrier Authority and Freight Intermediary Authority.</p>
            <p>This platform will allow you to have both types of accounts but they must be setup separately.</p>
            <div *ngIf="metaData.type === 'broker'">
                <div class="row" style="margin-bottom: 1em;">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="accountTypeSelected = true">
                            <span class="flex-grow-1 me-2">
                                <a class="text-white"> I would like to continue setting up a Freight Intermediary account </a>
                            </span>
                        </button>
                    </div>
                    <div class="col-lg-3"></div>
                </div>
                <div class="row" style="margin-bottom: 1em;">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-primary waves-effect waves-light">
                            <span class="flex-grow-1 me-2">
                                <a [href]="domain + '/carrier'" class="text-white"> I would like to switch and setup a Carrier account </a>
                            </span>
                        </button>
                    </div>
                    <div class="col-lg-3"></div>
                </div>
            </div>
            <div *ngIf="metaData.type === 'carrier'">
                <div class="row" style="margin-bottom: 1em;">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-primary waves-effect waves-light" (click)="accountTypeSelected = true">
                            <span class="flex-grow-1 me-2">
                                <a class="text-white"> I would like to continue setting up a Carrier account </a>
                            </span>
                        </button>
                    </div>
                    <div class="col-lg-3"></div>
                </div>
                <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-primary waves-effect waves-light">
                            <span class="flex-grow-1 me-2">
                                <a [href]="domain + '/broker'" class="text-white"> I would like to switch and setup a Freight Intermediary account </a>
                            </span>
                        </button>
                    </div>
                    <div class="col-lg-3"></div>
                </div>
            </div>
            <!-- <p *ngIf="step == 'after-fmcsa' && !fmcsa?.dotNumber"> You need a DOT Number to signup. </p><p *ngIf="step == 'after-fmcsa' && !fmcsa?.mcNumber"> You need a MC Number to signup. </p><p *ngIf="step == 'after-fmcsa' && !fmcsa?.hasAuthority"> Active Operating Authority/MC Number Required To Signup. </p><p *ngIf="step == 'after-fmcsa' && fmcsa?.isBroker"> You can't signup as a broker. </p> -->
            <!-- <p *ngIf="step == 'after-legalEntity' && this.selectedLegalEntity && selectedLegalEntity === 'sole-proprietor'"> You can't sign up as a Sole Proprietor. </p> -->
        </div>
    </div>
</ng-template>