<div class="row g-4 mb-3">
    <div class="col-sm-auto">
        <div>
            <button type="button" class="btn btn-primary add-btn" data-bs-toggle="modal" id="create-btn" routerLink="./create">
                <i class="ri-add-line align-bottom me-1"></i> Add Trailer </button>
        </div>
    </div>
    <div class="col-sm">
        <div class="d-flex justify-content-sm-end">
            <div class="search-box ms-2">
                <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                <i class="ri-search-line search-icon"></i>
            </div>
        </div>
    </div>
</div>
<div class="table-responsive table-card mt-3 mb-1">
    <table class="table align-middle table-nowrap mb-0" *ngIf="allTrailers.length != 0">
        <thead class="table-light">
            <tr>
                <th class="sort" data-sort="unitNumber" sortable="unitNumber">Unit Number</th>
                <th class="sort" data-sort="year" sortable="year">Year</th>
                <th class="sort" data-sort="make" sortable="make">Make</th>
                <th class="sort" data-sort="model" sortable="model">Model</th>
                <th class="sort" data-sort="vin" sortable="vin">VIN</th>
                <th class="sort" data-sort="licensePlate" sortable="licensePlate">License Plate</th>
                <th class="sort" data-sort="type" sortable="type">Type</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let trailer of allTrailers">
                <td>
                    <ngb-highlight [result]="trailer.unitNumber + ''" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [term]="service.searchTerm"></ngb-highlight>
                    {{trailer.year}}
                </td>
                <td>
                    <ngb-highlight [result]="trailer.make" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="trailer.model" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="trailer.vehicleIdNumber" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="trailer.licensePlateNumber" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <ngb-highlight [result]="getTrailerTypeNameById(trailer.type)" [term]="service.searchTerm"></ngb-highlight>
                </td>
                <td>
                    <div class="d-flex gap-2">
                        <div class="edit">
                            <button type="button" class="btn btn-sm btn-primary add-btn" id="create-btn" (click)="onEditClick(trailer)">Edit</button></div>
                        <div class="remove">
                            <button class="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" (click)="deleteTrailer(trailer)">Delete</button>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>