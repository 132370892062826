import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  /**
   * Check is input date matched with today.
   * @param timestamp 
   * @returns 
   */
  isToday(date: string): boolean {
    const inputDate = new Date(date).toDateString();
    const currentDate = new Date().toDateString();

    return inputDate === currentDate;
  }
}
