// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  fmcsa_api_url: 'https://carrier-api.directtocarrier.io/fmcsa/dotNumber',
  signup_submit_url: 'https://carrier-api.directtocarrier.io/companies',
  verify_signup_url: 'https://carrier-api.directtocarrier.io/verifySignup',
  exceptions_api_url: 'https://carrier-api.directtocarrier.io/exceptions',
  api: 'https://carrier-api.directtocarrier.io',
  verify_signup_redirect_url: 'https://carrier.directtocarrier.io/sign-up-process/%%CODE%%/%%METADATA%%/%%EMAIL%%',
  calendly_url: 'https://calendly.com/directtocarrier/carrier-integration',
  domain: 'https://carrier.directtocarrier.io',
  pusher: {
    key: '7bbee93f5ef736688278',
    cluster: 'mt1',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
