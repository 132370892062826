import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { SuccessApiResponse } from 'src/app/core/services/models/models';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { CompanyInfo } from 'src/app/core/services/models/company-info.model'
import { AccountPlan, AccountPlanUtil } from 'src/app/core/services/models/account-plan.model';
import { Subscription, combineLatest } from 'rxjs';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalBuyLicenses } from '../settings/licenses/modals/buy-licenses/buy-licenses.component';
import { Branch } from 'src/app/core/services/models/branch.model';
import { AppService } from 'src/app/core/services/app.service';
import { DOL_DATA, DOL_TAB_DATA } from './dol-data';

export interface FMCSA_OBJ {
  dbaName: string,
  legalName: string,
  street: string,
  city: string,
  state: string,
  zipcode: string,
  country: string,
  drivers: number,
  powerUnits: number,
  hasAuthority: boolean,
  isFleet: boolean,
  isBroker: boolean,
  hasBrokerAuthority: boolean,
  dotNumber: number,
  mcNumber: number,
  einNumber: number,
} 

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnInit, OnDestroy {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Compliance'
    }
  ];

  window = window;
  fmcsaLink: string;
  fmcsa: FMCSA_OBJ;
  isLoading: boolean = true;
  errorMessage: string = '';

  subscriptions: Subscription[] = [];
  currentAccountPlan: AccountPlan;
  private selectedBranch: (Branch | null);
  dolLinks: Array<DOL_TAB_DATA> = DOL_DATA;
  selectedParentDol: DOL_TAB_DATA | undefined;

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private modalService: NgbModal,
    private appService: AppService
  ) {
  }

  async ngOnInit() {
    this.subscriptions.push(
      combineLatest([
        this.authService.currentAccountPlanSubject, 
        this.authService.selectedBranchSubject
      ]).subscribe(
        ([accoundPlan, selectedBranch]) => {
          if(this.anyNull(accoundPlan, selectedBranch) || this.allSelectedSame(accoundPlan, selectedBranch)){
            return;
          }

          this.currentAccountPlan = accoundPlan;
          this.selectedBranch = selectedBranch;
        }
      )
    );

    try {
      if (!this.selectedBranch) return;

      const includes = ['fmcsa'];
      const res = await this.httpService.getCompanyInfos(this.selectedBranch?.companyInfoId!, includes).toPromise()
      const successRes = <SuccessApiResponse<CompanyInfo>> res;
      const companyInfo = successRes.data;
      this.fmcsaLink = `https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${companyInfo.dotNumber}`;
      
      let isCompanyHasLatestFmcsa = true;
      const fmcsaUpdatedAt = companyInfo.includes?.fmcsa?.updatedAt || null;
      if (fmcsaUpdatedAt) {
        isCompanyHasLatestFmcsa = this.appService.isToday(fmcsaUpdatedAt);
      }
      
      if(companyInfo && !isCompanyHasLatestFmcsa) {
        this.getFMCSA(companyInfo.dotNumber!);
      } else  {
        this.fmcsa = this.transformFMCSAData(companyInfo);
        this.isLoading = false;
      }
    } catch (error: any) {
      this.errorMessage = error.error.reason;
      this.isLoading = false;
    }
    
  }

  private anyNull(accountPlan: AccountPlan | null, branch: Branch | null): boolean{
    return !accountPlan || !branch;
  }

  private allSelectedSame(account: AccountPlan | null, branch: Branch | null): boolean{
    return this.currentAccountPlan?.accountId == account?.accountId && this.selectedBranch?.branchId == branch?.branchId
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  redirectToFmcsa(){
    window.open(this.fmcsaLink, '_blank');
  }
  
  getFMCSA(dotNumber: string) {
    this.httpService.getByDotNumber(dotNumber).subscribe((data:any) => {
      this.fmcsa = data.data;
      this.isLoading = false;
    })
  }

  isTrial(): boolean {
    return AccountPlanUtil.isTrial(this.currentAccountPlan);
  }

  promptUpgrade(): void {
    if(this.authService.currentAccountPlanValue.status === 'paid'){
      return;
    }

    Swal.fire({
      title: "",
      text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: 'rgb(60,76,128)',
      confirmButtonText: 'Buy Pre-Release Assets'
    }).then(result => {
      if(result.isConfirmed){
        this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
      }
    });
  }

  /**
   * Transform FMCSA Data
   * @param data 
   * @returns 
   */
  transformFMCSAData(data: any): FMCSA_OBJ {
    const fmcsaIncludedData = (data.includes?.fmcsa) ? data.includes.fmcsa : {};
    const fmcsa: FMCSA_OBJ = {
      dbaName: data.dbaName,
      legalName: data.legalName,
      street: data.street,
      city: data.city,
      state: data.state,
      zipcode: data.zipCode,
      country: fmcsaIncludedData.country || "",
      drivers: fmcsaIncludedData.drivers || null,
      powerUnits: fmcsaIncludedData.powerUnits || null,
      hasAuthority: fmcsaIncludedData.hasAuthority || false,
      isFleet: fmcsaIncludedData.isFleet || false,
      isBroker: fmcsaIncludedData.isBroker || false,
      hasBrokerAuthority: fmcsaIncludedData.hasBrokerAuthority || false,
      dotNumber: fmcsaIncludedData.dotNumber || null,
      mcNumber: fmcsaIncludedData.mcNumber || null,
      einNumber: fmcsaIncludedData.einNumber || null,
    }

    return fmcsa
  }

  /** 
   * Select Dol Link on Change Event
   * @param event 
   */
  onSelectDolLink(event: any){
    const selectedDolLink = event.target.value;
    this.selectedParentDol = this.dolLinks.find((link) => link.link === selectedDolLink);
  }
}
