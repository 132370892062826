import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { CreateContractPayload, HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { ContractModel, DocumentModel } from 'src/app/core/services/models/models';
import { User } from 'src/app/core/services/models/user.model';
import { truthyFilter } from 'src/app/core/utils/commons';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { AbstractPagesComponent } from '../AbstractPagesComponent';

const POLL_INTERVAL_MS = 5000;

@Component({
  selector: 'app-create-contract',
  templateUrl: './create-contract.component.html',
  styleUrls: ['./create-contract.component.scss']
})
export class CreateContractComponent extends AbstractPagesComponent implements OnInit, OnDestroy {
  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Create Contract'
    }
  ];

  contractName: string | undefined;

  selectedCarrierAccountId: string | undefined;
  carrierOptions: Account[] = [];
  contract: ContractModel | undefined;
  poll: {
    intervalHandler?: number | undefined;
    countdownHandler?: number | undefined;
    countdown: number
  } = { countdown: POLL_INTERVAL_MS / 1000 };
  
  selectedDocumentNodes: TreeNode<DocumentModel>[] = [];
  documentNodes: TreeNode<DocumentModel>[] = [];

  selectedShipperSigners: TreeNode<User>[] = [];
  shipperNodes: TreeNode<User>[] = [];

  carrierSigners: Pick<User, 'userId'>[] = [];

  subscriptions: Subscription[] = [];
  dropdownScrollHeight = '50vh';

  formErrors: Partial<{
    selectedCarrierAccountId: 'required' | 'noCarrierSigners';
    selectedDocuments: 'required';
    selectedShipperSigners: 'required';
    name: 'required';
  }> = {};
  isSubmittedFormOnce = false;

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpService,
    private router: Router,
    private authService: AuthService,
  ) {
    super();
    const currentUser = this.authService.currentUserValue;
    console.log('[ngOnInit] currentUser', currentUser);

    /* if(authService.currentAccountSelected.accountType == 'carrier-account'){
      this.router.navigate(['/pages/dashboard']);
      return;
    } */
    
    this.subscriptions.push(
      this.authService.subscribeToSelectedAccount(async () => {
        this.isSubmittedFormOnce = false;
        return this.loadData();
      })
    );
  }

  ngOnInit(): void {
    this.selectedCarrierAccountId = this.route.snapshot.paramMap.get('accountId') || undefined;
  }

  ngOnDestroy(): void {
    this.clearPollInterval();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  override async loadData(): Promise<void> {
    void this.fetchDocuments();
    void this.fetchUsers();
    void this.fetchCarriers()
      .then(() => {
        return this.fetchCarrierSigners();
      });
  }

  isValidCarrier(): boolean {
    if (!this.selectedCarrierAccountId) {
      this.formErrors.selectedCarrierAccountId = 'required';
      return false;
    }

    if (!this.carrierSigners || this.carrierSigners.length === 0) {
      this.formErrors.selectedCarrierAccountId = 'noCarrierSigners';
      return false;
    }

    delete this.formErrors.selectedCarrierAccountId;
    return true;
  }

  isValidDocuments(): boolean {
    if (this.selectedDocumentNodes.map(n => n.data?.documentId).filter(truthyFilter).length === 0) {
      this.formErrors.selectedDocuments = 'required';
      return false;
    }

    delete this.formErrors.selectedDocuments;
    return true;
  }

  isValidShipperSigners(): boolean {
    if (this.selectedShipperSigners.map(n => n.data?.userId).filter(truthyFilter).length === 0) {
      this.formErrors.selectedShipperSigners = 'required';
      return false;
    }

    delete this.formErrors.selectedShipperSigners;
    return true;
  }

  isValidContractName(): boolean {
    if (!this.contractName) {
      this.formErrors.name = 'required';
      return false;
    }

    delete this.formErrors.name;
    return true;
  }

  validateForm(): boolean {
    const validCarrier = this.isValidCarrier();
    const validDocuments = this.isValidDocuments();
    const validShipperSigners = this.isValidShipperSigners();
    const validName = this.isValidContractName();

    return validCarrier && validDocuments && validShipperSigners && validName;
  }

  buildCreateContractsPayload(): CreateContractPayload {
    return {
      files: this.selectedDocumentNodes.map(n => n.data?.documentId).filter(truthyFilter),
      shipperSigners: this.selectedShipperSigners.map(n => n.data?.userId).filter(truthyFilter),
      carrierSigners: this.carrierSigners.map(n => n.userId).filter(truthyFilter),
      senderAccountId: this.authService.currentAccountSelected.accountId || '',
      receiverAccountId: this.selectedCarrierAccountId || '',
      name: this.contractName || ''
    };
  }

  stringify(json: object): string {
    return JSON.stringify(json, null, 4);
  }

  clearPollInterval(): void {
    if (this.poll.intervalHandler) {
      clearInterval(this.poll.intervalHandler);
      this.poll.intervalHandler = undefined;
    }
    this.clearPollCountdownInterval();
  }

  clearPollCountdownInterval(): void {
    if (this.poll.countdownHandler) {
      clearInterval(this.poll.countdownHandler);
      this.poll.countdownHandler = undefined;
    }
    this.poll.countdown = POLL_INTERVAL_MS / 1000;
  }

  async fetchDocuments(): Promise<void> {
    const documents = await this.httpService.getAllDocuments()
      .pipe(
        map(response => {
          if (response.error) {
            throw new Error(response.reason);
          }

          return response.data;
        })
      ).toPromise();
    
    this.documentNodes = documents.map(d => this.convertDocumentToNode(d));
    this.selectedDocumentNodes = [];
  }

  async fetchUsers(): Promise<void> {
    const shipperUsers = await this.httpService.getUsersHasShippers()
      .pipe(
        map(response => {
          if (response.error) {
            throw new Error(response.reason);
          }

          return response.data;
        })
      ).toPromise();
    this.shipperNodes = shipperUsers.map(u => this.convertUserToNode(u));
    this.selectedShipperSigners = [];
  }

  async fetchCarriers(): Promise<void> {
    this.carrierOptions = await this.httpService.carrierSearch('hasSigners=true')
      .pipe(
        map(response => {
          return response.data;
        })
      ).toPromise();

    // if (this.selectedCarrierAccountId) {
    //   const carrier = this.carrierOptions.find(c => c.accountId === this.selectedCarrierAccountId);
    //   this.breadCrumbItems[1] = {label: carrier?.accountName || ''};
    // }
  }

  async fetchCarrierSigners(): Promise<void> {
    if (this.selectedCarrierAccountId) {
      this.carrierSigners = await this.httpService.getSigners(this.selectedCarrierAccountId || '').toPromise()
        .catch((e) => {
          console.error('[CreateContractComponent] [onChangeCarrier] - error fetching carrier signers', e);
          return [];
        });
    } else {
      this.carrierSigners = [];
    }
  }

  async onChangeCarrier(): Promise<void> {
    await this.fetchCarrierSigners();
    this.isValidCarrier();
  }

  onChangeDocuments(): void {
    this.isValidDocuments();
  }

  onChangeShipperSigners(): void {
    this.isValidShipperSigners();
  }

  onChangeName(): void {
    this.isValidContractName();
  }

  convertDocumentToNode(document: DocumentModel): TreeNode<DocumentModel> {
    return {
      data: document,
      key: document.documentId,
      label: document.displayName
    };
  }

  convertUserToNode(user: User): TreeNode<User> {
    return {
      data: user,
      key: user.userId,
      label: [user.firstName, user.lastName].join(' ')
    };
  }

  createContract(): void {
    this.isSubmittedFormOnce = true;
    if (!this.validateForm()) {
      return;
    }

    this.clearPollInterval();
    this.httpService.createContract(this.buildCreateContractsPayload())
      .subscribe(createResponse => {
        console.log('[CreateContractComponent] [createContract]', createResponse);
        this.contract = createResponse;

        this.poll.countdownHandler = window.setInterval(() => {
          this.poll.countdown--;
        }, 1000);

        this.poll.intervalHandler = window.setInterval(() => {
          this.clearPollCountdownInterval();

          console.log(`[CreateContractComponent] - polling contractId '${this.contract?.contractId || createResponse.contractId}'`);
          this.httpService.getContract(this.contract?.contractId || createResponse.contractId)
            .subscribe(pollResponse => {
              console.log(`[CreateContractComponent] - polling response '${this.contract?.contractId || createResponse.contractId}'`, pollResponse);
              this.contract = pollResponse;
              if (['created', 'error'].includes(pollResponse.status)) { // no more poll if already in success/error status
                this.clearPollInterval();
              } else {
                this.poll.countdownHandler = window.setInterval(() => {
                  this.poll.countdown--;
                }, 1000);
              }

              if (pollResponse.status === 'created') {
                this.goToSignContract(pollResponse.contractId);
              } else if (pollResponse.status === 'error') {
                this.isSubmittedFormOnce = false;
              }
            });
        }, POLL_INTERVAL_MS);
      }, error => {
        console.error('[CreateContractComponent] [createContract] - error creating contract', error);
        this.isSubmittedFormOnce = false;
      });
  }

  goToSignContract(contractId: string): void {
    this.router.navigate([`./contracts/${contractId}`], {
      relativeTo: this.route.parent
    });
  }
}
