<app-breadcrumbs *ngIf="showBreadCrumb" [breadCrumbItems]="breadCrumbItems"></app-breadcrumbs>

<div class="ratio ratio-16x9" *ngIf="displaySection == 'iframe'">
    <iframe src="https://directtocarrier.ourdqf.com/" frameborder="0"></iframe>
</div>

<div class="row justify-content-center" *ngIf="displaySection == 'form'">
    <div class="col-lg-10">
        <div class="card">
            <div class="bg-primary position-relative">
                <div class="card-body p-5">
                    <div class="text-center">
                        <h3>Request Account</h3>
                    </div>
                </div>
            </div>
            <div class="card-body p-4">
                <div class="row">
                    <p class="text-muted">
                        Direct To Carrier makes driver qualification files more effortless than ever! Our program will guide you through DOT qualifications for each driver, allowing you to complete the required files online via electronic forms or manually upload copies of your current forms.    
                    </p>
                </div>
                <div class="row content-section">
                    <p class="text-muted">
                        The driver qualification continues after the initial requirements are met. Our system will track your driver's licenses and Medical card expiration dates and when annual record reviews are due, and help make sure your drivers stay qualified and compliant with DOT regulations.
                    </p>
                </div>
                <div class="row content-section">
                    <p class="text-muted">
                        The driver qualification requirements are confusing and time-consuming. When you use Direct To Carrier, the platform guides you every step of the way by providing instructions on what to do next. Our interface is straightforward and makes it easy to see what has been completed and what still needs your attention.
                    </p>
                </div>
                <div class="row content-section">
                    <p class="text-muted">
                        We also guide more than just DOT qualification requirements. The days of hiring multiple companies to obtain documents such as Pre-Employment Screening Program (PSP) Reports and Motor Vehicle Reports (MVR) are finally over! We provide all these documents for you directly in the program!
                    </p>
                    <p class="text-muted">
                        Each Asset License with Direct To Carrier includes access to driver qualification software.
                    </p>
                </div>
                <div class="row content-section">
                    <h5>Asset License Access Includes:</h5>
                    <p class="text-muted">Monthly Access Driver Qualification File</p>
                </div>
                <div class="row content-section">
                    <h5>Online Driver Application Portal</h5>
                    <p class="text-muted">Multilingual Interface</p>
                </div>
                <div class="row content-section">
                    <h5>DOT Compliant Online Application</h5>
                    <p class="text-muted">Electronic Previous Employer Investigations</p>
                </div>
                <div class="row content-section">
                    <h5>Law Enforcement Portal</h5>
                    <p class="text-muted">Medical Card Expiration Reminders</p>
                    <p class="text-muted">All Electronic Forms & Format</p>
                    <p class="text-muted">Physical Form Upload</p>
                    <p class="text-muted">Automatic Annual Record Reviews</p>
                    <p class="text-muted">Electronic Signatures</p>
                </div>
                <div class="row content-section">
                    <h5>Driver Reports Completed Are Charged To Card On File </h5>
                    <p class="text-muted">DOT Pre-Employment Screening Program (PSP) Reports $19.99</p>
                    <p class="text-muted">State Motor Vehicle Reports (MVR) $25.00</p>
                    <p class="text-muted">Clearing House Set Up Fee $10.00</p>
                </div>
                <div class="row content-section" *ngIf="showForm">
                    <h5>Let's Get Started</h5>
                    <p class="text-muted">All Items with stars need to be prepopulated and not editable.</p>
                </div>
                <div class="row content-section" *ngIf="!showForm">
                    <h5>Submitted Details</h5>
                </div>
                <div class="row">
                    <form [formGroup]="requestForm" *ngIf="showForm && companyInfo && userProfle" (submit)="onSubmit()">
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="userNameInput" class="form-label">User Name</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text" class="form-control" id="userNameInput" formControlName="userName" [ngClass]="{ 'is-invalid': submitted && form['userName'].errors }">
                                <div *ngIf="submitted && form['userName'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['userName'].errors['required']">Enter Name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="userRoleInput" class="form-label">User Role</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text" class="form-control" id="userRoleInput" formControlName="userRole" [ngClass]="{ 'is-invalid': submitted && form['userRole'].errors }">
                                <div *ngIf="submitted && form['userRole'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['userRole'].errors['required']">Enter User Role</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="companyNameInput" class="form-label">Company Name</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text" class="form-control" id="companyNameInput" formControlName="companyName" [ngClass]="{ 'is-invalid': submitted && form['companyName'].errors }">
                                <div *ngIf="submitted && form['companyName'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['companyName'].errors['required']">Enter Company Name</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="companyAddressInput" class="form-label">Company Address</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text" class="form-control" id="companyAddressInput" formControlName="companyAddress"  [ngClass]="{ 'is-invalid': submitted && form['companyAddress'].errors }">
                                <div *ngIf="submitted && form['companyAddress'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['companyAddress'].errors['required']">Enter Company Address</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="FEINNumberInput" class="form-label">FEIN #</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text" class="form-control" id="FEINNumberInput" formControlName="FEINNumber"  [ngClass]="{ 'is-invalid': submitted && form['FEINNumber'].errors }">
                                <div *ngIf="submitted && form['FEINNumber'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['FEINNumber'].errors['required']">Enter FEIN Number</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="dotNumberInput" class="form-label">DOT #</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text" class="form-control" id="dotNumberInput" formControlName="DOTNumber"  [ngClass]="{ 'is-invalid': submitted && form['DOTNumber'].errors }">
                                <div *ngIf="submitted && form['DOTNumber'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['DOTNumber'].errors['required']">Enter DOT Number</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="mCNumberInput" class="form-label">MC #</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text" class="form-control" id="mCNumberInput" formControlName="MCNumber" [ngClass]="{ 'is-invalid': submitted && form['MCNumber'].errors }">
                                <div *ngIf="submitted && form['MCNumber'].errors" class="invalid-feedback" align="left">
                                    <div *ngIf="form['MCNumber'].errors['required']">Enter MC Number</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="contactPersonNameInput" class="form-label">Contact Person Name</label>
                            </div>
                            <div class="col-lg-10">
                                <input class="form-control" id="contactPersonNameInput"  formControlName="contactPersonName">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="phoneNumberInput" class="form-label">Company Phone Number</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text" class="form-control" id="phoneNumberInput" formControlName="companyPhoneNumber">
                                
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="emailInput" class="form-label">Email Communications</label>
                            </div>
                            <div class="col-lg-10">
                                <input class="form-control" id="emailInput" formControlName="email">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="trucksInput" class="form-label">Total Trucks</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="number" class="form-control" id="trucksInput" formControlName="totalTrucks">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2">
                                <label for="rrailersInput" class="form-label">Total Trailers</label>
                            </div>
                            <div class="col-lg-10">
                                <input type="number" class="form-control" id="rrailersInput" formControlName="totalTrailers">
                            </div>
                        </div>
                        <div class="text-end">
                            <button type="submit" 
                                class="btn btn-primary"
                                [disabled]="submitted">Submit</button>
                        </div>
                    </form>

                    <div class="row summary" *ngIf="!showForm">
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>User Name:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.userName}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>User Role:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.userRole}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>Company Name:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.companyName}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>Company Address:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.companyAddress}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>FIEN Number:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.companyEINNumber}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>DOT Number:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.companyDOTNumber}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>MC Number:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.companyMCNumber}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>Contact Person Name:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.contactPerson}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>Company Phone Number:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.companyPhoneNumber}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>Email Communications:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.contactEmail}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>Totat Trucks:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.companyTotalTrucks}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>Total Trailers:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.companyTotalTrailers}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 label">
                                <label>Application Status:</label>
                            </div>
                            <div class="col-lg-8 ">
                                {{driverQualificationData?.status}}
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
