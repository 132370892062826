<div class="position-relative mx-n4 mt-n4">
    <div class="profile-wid-bg profile-setting-img">
        <img src="assets/images/profile-bg.jpg" class="profile-wid-img" alt="">
    </div>
</div>
<div class="row">
    <div class="col-xxl-3">
        <div class="card mt-n5">
            <div class="card-body p-4">
                <div class="text-center">
                    <div class="profile-user position-relative d-inline-block mx-auto  mb-4">
                        <img [src]="userProfle?.profilePicture" class="rounded-circle avatar-xl img-thumbnail user-profile-image" (error)="handleError($event)">
                        <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <input id="profile-img-file-input" type="file" accept="image/png,image/jpeg" class="profile-img-file-input" (change)="selectFiles($event)" />
                            <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                                <span class="avatar-title rounded-circle bg-light text-body">
                                    <i class="ri-camera-fill"></i>
                                </span>
                            </label>
                        </div>
                    </div>
                    <h5 class="fs-16 mb-1" *ngIf="userData">{{f['firstName'].value}} {{f['lastName'].value}}</h5>
                    <p class="text-muted mb-0" *ngIf="userData">{{f['title'].value}}</p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <form [formGroup]="userForm" *ngIf="userData">
                    <div class="d-flex align-items-center mb-4">
                        <div class="flex-grow-1">
                            <h5 class="card-title mb-0">Links</h5>
                        </div>
                    </div>
                    <div class="mb-3 d-flex scroll-x">
                        <div class="avatar-xs d-block flex-shrink-0 me-3">
                            <span class="avatar-title rounded-circle fs-16 bg-primary">
                                <i>
                                    <img src="./assets/dtc-icon-title-no-bg.png" width="35px">
                                </i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="websiteInput" placeholder="Website" [value]="origin + '/users/' + userProfle?.profileSlug" readonly>
                    </div>
                    <div *ngIf="currentAccountPlan?.status === 'paid'" 
                        class="mb-3 d-flex scroll-x">
                        <div class="avatar-xs d-block flex-shrink-0 me-3">
                            <span class="avatar-title rounded-circle fs-16 bg-success">
                                <i class="ri-calendar-event-fill"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="dribbleName" placeholder="Website" [value]="origin + '/users/' + userProfle?.profileSlug + '/schedule'" readonly>
                    </div>
                    <div class="mb-3 d-flex">
                        <div class="avatar-xs d-block flex-shrink-0 me-3">
                            <span class="avatar-title rounded-circle fs-16 bg-primary">
                                <i class="ri-linkedin-box-fill"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="pinterestName" placeholder="Website" formControlName="linkedinUrl">
                    </div>
                    <div class="mb-3 d-flex">
                        <div class="avatar-xs d-block flex-shrink-0 me-3">
                            <span class="avatar-title rounded-circle fs-16 bg-primary">
                                <i class="bx bx-globe"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="pinterestName" placeholder="Website" formControlName="website">
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
            <div class="card-header">
                <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <li [ngbNavItem]="1" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="fas fa-home"></i> Profile </a>
                        <ng-template ngbNavContent>
                            <form [formGroup]="userForm" *ngIf="userData">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="firstnameInput" class="form-label">First Name <span class="text-danger">*</span>
                                            </label>
                                            <input type="text" id="firtname-field" class="form-control" required formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && form['firstName'].errors }" />
                                            <div *ngIf="submitted && form['firstName'].errors" class="invalid-feedback" align="left">
                                                <div *ngIf="form['firstName'].errors['required']">Enter First Name</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="lastnameInput" class="form-label">Last Name <span class="text-danger">*</span>
                                            </label>
                                            <input type="text" id="lastname-field" class="form-control" required formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && form['lastName'].errors }" />
                                            <div *ngIf="submitted && form['lastName'].errors" class="invalid-feedback" align="left">
                                                <div *ngIf="form['lastName'].errors['required']">Enter Last Name</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="phonenumberInput" class="form-label">Phone Number <span class="text-danger">*</span>
                                            </label>
                                            <input type="text" id="phone-field" class="form-control" required formControlName="phone" [ngClass]="{ 'is-invalid': submitted && form['phone'].errors }" />
                                            <div *ngIf="submitted && form['phone'].errors" class="invalid-feedback" align="left">
                                                <div *ngIf="form['phone'].errors['required']">Enter Phone</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="emailInput" class="form-label">Email Address <span class="text-danger">*</span>
                                            </label>
                                            <input type="email" id="email-field" class="form-control" required formControlName="email" [ngClass]="{ 'is-invalid': submitted && form['email'].errors }" [disabled]="true" />
                                            <div *ngIf="submitted && form['email'].errors" class="invalid-feedback">
                                                <div *ngIf="form['email'].errors['required']">Enter Email</div>
                                                <div *ngIf="form['email'].errors['email']">Email must be a valid email address </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="designationInput" class="form-label">Title <span class="opacity-0">*</span>
                                            </label>
                                            <input type="text" id="title-field" class="form-control" formControlName="title" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="JoiningdatInput" class="form-label">Member Since</label>
                                            <p id="member-since-label">{{getPrettyDate(userData.creationDate)}}</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="mb-3">
                                            <label for="cityInput" class="form-label">City</label>
                                            <input type="text" class="form-control" id="cityInput" placeholder="City" formControlName="city" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="mb-3">
                                            <label for="cityInput" class="form-label">State</label>
                                            <ng-select class="custom form-control" [items]="statesJson" bindLabel="prettyName" bindValue="techName" formControlName="state" [searchFn]="customSearchFn" [ngClass]="{ 'is-invalid': submitted && form['state'].errors }"></ng-select>
                                            <div class="invalid-feedback"> Enter State </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="mb-3">
                                            <label for="zipcodeInput" class="form-label">Zip Code</label>
                                            <input type="text" class="form-control" minlength="5" maxlength="6" id="zipcodeInput" placeholder="Enter zipcode" formControlName="zipcode">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="mb-3">
                                            <label for="countryInput" class="form-label">Country</label>
                                            <ng-select [items]="countries" formControlName="country" bindLabel="prettyName" bindValue="techName"></ng-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="skillsInput" class="form-label">Skills</label>
                                            <ng-select [items]="skills" [multiple]="true" formControlName="skills" bindLabel="prettyName" bindValue="prettyName"></ng-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3 pb-2">
                                            <label for="exampleFormControlTextarea" class="form-label">Description</label>
                                            <textarea class="form-control" id="exampleFormControlTextarea" placeholder="Enter your description" rows="3" formControlName="description"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="hstack gap-2 justify-content-end">
                                            <button type="button" class="btn btn-soft-success">Cancel</button>
                                            <button type="submit" class="btn btn-primary" (click)="updateUser()">
                                                <span class="d-flex align-items-center">
                                                    <span class="flex-grow-1 me-2"> Save </span>
                                                    <span class="spinner-border flex-shrink-0" role="status" *ngIf="isLoading">
                                                        <span class="visually-hidden">Loading...</span>
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-user"></i> Change Password </a>
                        <ng-template ngbNavContent>
                            <form [formGroup]="passwordForm">
                                <div class="row g-2">
                                    <div class="col-lg-4">
                                        <div>
                                            <label for="oldpasswordInput" class="form-label">Old Password*</label>
                                            <input type="password" class="form-control" id="oldpasswordInput" placeholder="Enter current password" formControlName="oldPassword" [ngClass]="{ 'is-invalid': submittedPass && pform['oldPassword'].errors }">
                                            <div *ngIf="submittedPass && pform['oldPassword'].errors" class="invalid-feedback" align="left">
                                                <div *ngIf="pform['oldPassword'].errors['required']">Enter Old Password</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div>
                                            <label for="newpasswordInput" class="form-label">New Password*</label>
                                            <input type="password" class="form-control" id="newpasswordInput" placeholder="Enter new password" formControlName="password" [ngClass]="{ 'is-invalid': submittedPass && pform['password'].errors }">
                                            <div *ngIf="submittedPass && pform['password'].errors" class="invalid-feedback" align="left">
                                                <div *ngIf="pform['password'].errors['required']">Enter New Password</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div>
                                          <label for="confirmpasswordInput" class="form-label">Confirm Password*</label>
                                          <input type="password" class="form-control" id="confirmpasswordInput" placeholder="Confirm password" formControlName="confirmPassword"
                                            [ngClass]="{ 'is-invalid': submittedPass && pform['confirmPassword'].invalid }">
                                          <div *ngIf="submittedPass && pform['confirmPassword'].errors" class="invalid-feedback" align="left">
                                            <div *ngIf="pform['confirmPassword'].errors?.['required']">Enter Confirm Password</div>
                                            <div *ngIf="pform['confirmPassword'].errors['confirmedValidator']">Password and Confirm Password must be match.</div>
                                          </div>
                                        </div>
                                      </div>
                                    <div class="col-lg-12">
                                        <div class="text-end">
                                            <button type="submit" class="btn btn-primary" (click)="changePassword()">Change Password</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Experience </a>
                        <ng-template ngbNavContent>
                            <form [formGroup]="experienceForm" *ngIf="userData">
                                <div id="newlink">
                                    <div id="1">
                                        <div class="row" *ngIf="workExperiences.length == 0 || addNewFlag">
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="jobTitle" class="form-label">Job Title</label>
                                                    <input type="text" class="form-control" id="jobTitle" placeholder="Job title" formControlName="jobTitle">
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="companyName" class="form-label">Company Name</label>
                                                    <input type="text" class="form-control" id="companyName" placeholder="Company name" formControlName="companyName">
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="mb-3">
                                                    <label for="experienceYear" class="form-label">Experience Years</label>
                                                    <div class="row">
                                                        <div class="col-lg-5">
                                                            <select class="form-control" data-choices data-choices-search-false name="experienceYear" id="experienceYear" formControlName="startDate">
                                                                <option value="" disabled selected>Select Year</option>
                                                                <option value="" *ngFor="let year of generateExperienceYears()" [value]="year + '-01'">{{year}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-auto align-self-center"> to </div>
                                                        <div class="col-lg-5">
                                                            <select class="form-control" data-choices data-choices-search-false name="choices-single-default2" formControlName="endDate">
                                                                <option value="" disabled selected>Select Year</option>
                                                                <option value="" *ngFor="let year of generateExperienceYears()" [value]="year + '-01'">{{year}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <label for="jobDescription" class="form-label">Job Description</label>
                                                    <textarea class="form-control" id="jobDescription" rows="3" placeholder="Enter description" formControlName="jobDescription"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="workExperiences.length != 0 && !addNewFlag">
                                            <div class="col-lg-12">
                                                <div class="mb-3" *ngFor="let exp of workExperiences">
                                                    <h5>{{ exp.jobTitle }}
                                                        <i class="ri-edit-fill edit-btn" (click)="editExp(exp)"></i>
                                                        <i class=" ri-delete-bin-2-fill delete-btn" (click)="deleteExp(exp)"></i>
                                                    </h5>
                                                    <p class="exp-par-cname">{{ exp.companyName }}</p>
                                                    <p class="exp-par text-muted">{{ exp.startDate | date:'MMM y' }} - {{ exp.endDate | date:'MMM y' }}</p>
                                                    <p class="exp-par text-muted" [innerHTML]="exp.jobDescription | lineBreaks"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="newForm" style="display: none;"></div>
                                <div class="col-lg-12">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="submit" class="btn btn-soft-success" (click)="addNewExp('cancel')" *ngIf="addNewFlag">Cancel</button>
                                        <button type="submit" class="btn btn-primary" (click)="updateExperience()" *ngIf="addNewFlag || workExperiences.length == 0">Save</button>
                                        <a class="btn btn-primary" (click)="addNewExp('add')" *ngIf="!addNewFlag && workExperiences.length != 0">Add New</a>
                                    </div>
                                </div>
                            </form>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4" class="nav-item">
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Security & Privacy </a>
                        <ng-template ngbNavContent>
                            <div class="mb-4 pb-2">
                                <h5 class="card-title text-decoration-underline mb-3">Security:</h5>
                                <div class="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                                    <div class="flex-grow-1">
                                        <h6 class="fs-14 mb-1">User Public Profile</h6>
                                        <p class="text-muted"></p>
                                    </div>
                                    <div class="flex-shrink-0">
                                        <div class="form-check form-switch form-switch-lg d-inline-block align-middle" dir="ltr">
                                            <input 
                                                type="checkbox" 
                                                class="form-check-input" 
                                                id="customSwitchsizelg" 
                                                [checked]="userProfle?.isProfilePublic" 
                                                [disabled]="!tabsVisibility.security_and_privacy"
                                                (change)="isProfilePublicChanged($event)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="5" class="nav-item"
                        (click)="promptUpgrade()"
                        [disabled]="currentAccountPlan.status !== 'paid'"
                    >
                        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                            <i class="far fa-envelope"></i> Calendar </a>
                        <ng-template ngbNavContent>
                            <app-calendar-settings></app-calendar-settings>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="card-body p-4">
                <div class="tab-content">
                    <div [ngbNavOutlet]="customNav"></div>
                </div>
            </div>
        </div>
    </div>
</div>