import { Component, OnInit } from '@angular/core';
import { BreadCrumbItem } from 'src/app/shared/breadcrumbs/breadcrumbs.component';

export interface CARRIER_DRIVER_APP_MENU {
  name: string,
  key: string,
  redirectLink?: string,
  imgSrc?: string,
  childMenus?: Array<CARRIER_DRIVER_APP_MENU>
}

@Component({
  selector: 'app-carrier-driver-apps',
  templateUrl: './carrier-driver-apps.component.html',
  styleUrls: ['./carrier-driver-apps.component.scss']
})
export class CarrierDriverAppsComponent implements OnInit {

  breadCrumbItems: BreadCrumbItem[] = [
    {
      label: 'Carrier Driver Apps'
    }
  ];

  carrierAppMenus: Array<CARRIER_DRIVER_APP_MENU> = [
    {
      name: "Cat Scale",
      key: "cat_scale",
      redirectLink: "https://catscale.com/"
    },
    {
      name: "Exit Guilde",
      key: "exit_guilde",
      childMenus: [
        {
          name: "Fuelbook",
          key: "exit_guilde_fuelbook",
          redirectLink: "http://fuelbook.com/",
          imgSrc: "/assets/images/screenshots/fuelbook.png"
        },
        {
            name: "Love's",
            key: "exit_guilde_loves",
            redirectLink: "http://loves.com/",
            imgSrc: "/assets/images/screenshots/loves-site.png"
        },
        {
            name: "Travel Centers",
            key: "exit_guilde_travel_centers",
            redirectLink: "http://ta-petro.com/",
            imgSrc: "/assets/images/screenshots/travel-centers-site.png"
        },
        {
            name: "Pilot Flying J",
            key: "exit_guilde_pilot_flying_j",
            redirectLink: "http://pilotflyingj.com/",
            imgSrc: "/assets/images/screenshots/pilot-flying.png"
        },
        {
            name: "GasBuddy",
            key: "exit_guilde_gasbuddy",
            redirectLink: "http://gasbuddy.com/",
            imgSrc: "/assets/images/screenshots/gasbuddy.png"
        },
        {
            name: "iExit",
            key: "exit_guilde_iexit",
            redirectLink: "http://iexitapp.com/",
            imgSrc: "/assets/images/screenshots/iexit.png"
        },
        {
            name: "CoPilot",
            key: "exit_guilde_copilot",
            redirectLink: "http://copilottruck.com/",
            imgSrc: "/assets/images/screenshots/copilot.png"
        },
        {
            name: "FleetPride",
            key: "exit_guilde_fleetpride",
            redirectLink: "http://fleetpride.com/",
            imgSrc: "/assets/images/screenshots/fleetpride.png"
        },
        {
            name: "Trucker Path",
            key: "exit_guilde_trucker_path",
            redirectLink: "http://truckerpath.com/",
            imgSrc: "/assets/images/screenshots/trucker-path.png"
        },
        {
            name: "Trucker Tools",
            key: "exit_guilde_trucker_tools",
            redirectLink: "https://www.truckertools.com/",
            imgSrc: "/assets/images/screenshots/trucker-tools.png"
        }
      ]
    },
    {
      name: "Maps",
      key: "maps",
      childMenus: [
        {
          name: "Rand McNally Official Store",
          key: "maps_rand_mc_nally_store",
          redirectLink: "http://store.randmcnally.com/",
          imgSrc: "/assets/images/screenshots/rand-mc-nally-store.png"
        },
        {
          name: "PC*MILER Web",
          key: "maps_pc_miler_web",
          redirectLink: "http://pcmilerweb.com/",
          imgSrc: "/assets/images/screenshots/pc-miler.png"
        },
        {
          name: "Trimble Maps",
          key: "maps_trimble_maps",
          redirectLink: "http://maps.trimble.com/"
        },
        {
          name: "Waze",
          key: "maps_waze",
          redirectLink: "https://www.waze.com/live-map/",
          imgSrc: "/assets/images/screenshots/waze.png"
        },
        {
          name: "Google Maps",
          key: "maps_google_maps",
          redirectLink: "https://www.google.com/maps",
          imgSrc: "/assets/images/screenshots/google-maps.png"
        }
      ]
    },
    {
      name: "Weather",
      key: "weather",
      childMenus: [
        {
          name: "Windy",
          key: "weather_windy",
          redirectLink: "https://www.windy.com/",
          imgSrc: "/assets/images/screenshots/windy.png"
        },
        {
          name: "Weather",
          key: "weather_weather",
          redirectLink: "http://weather.com/",
          imgSrc: "/assets/images/screenshots/weather.png"
        },
        {
          name: "Accu Weather",
          key: "weather_accurate_weather",
          redirectLink: "https://www.accuweather.com/"
        }
      ]
    },
    {
      name: "Load Board",
      key: "load_board",
      childMenus: [
        {
          name: "123Loadboard",
          key: "load_board_123loadboard",
          redirectLink: "https://www.123loadboard.com/"
        },
        {
          name: "DAT",
          key: "load_board_dat",
          redirectLink: "http://dat.com/",
          imgSrc: "/assets/images/screenshots/dat.png"
        },
        {
          name: "Truckstop",
          key: "load_board_truckstop",
          redirectLink: "http://truckstop.com/"
        },
        {
          name: "Freight finder",
          key: "load_board_freight_finder",
          redirectLink: "https://www.freightfinder.com/"
        }
      ]
    },
    {
      name: "Scale & Toll",
      key: "scale_and_toll",
      childMenus: [
        {
          name: "PrePass App",
          key: "scale_and_toll_pre_pass",
          redirectLink: "http://prepass.com/"
        },
        {
          name: "Drivewyze",
          key: "scale_and_toll_drivewyze",
          redirectLink: "http://drivewyze.com/",
          imgSrc: "/assets/images/screenshots/drivewyze.png"
        },
        {
          name: "Bestpass",
          key: "scale_and_toll_bestpass",
          redirectLink: "https://bestpass.com/"
        }
      ]
    },
    {
      name: "Roadside",
      key: "roadside",
      childMenus: [
        {
          name: "Roadside Masters",
          key: "roadside_roadside_masters",
          redirectLink: "https://www.roadsidemasters.com/"
        }
      ]
    },
    {
      name: "Scanners",
      key: "scanners",
      childMenus: [
        {
          name: "CamScanner",
          key: "scanners_camscanner",
          redirectLink: "https://www.camscanner.com/"
        },
        {
          name: "Genius scan",
          key: "scanners_genius_scan",
          redirectLink: "https://geniusscansdk.com/"
        }
      ]
    },
    {
      name: "Miscellaneous",
      key: "miscellaneous",
      childMenus: [
        {
          name: "Audible",
          key: "miscellaneous_audible",
          redirectLink: "https://www.audible.com/",
          imgSrc: "/assets/images/screenshots/audible.png"
        },
        {
          name: "Slack",
          key: "miscellaneous_slack",
          redirectLink: "https://slack.com/",
          imgSrc: "/assets/images/screenshots/slack.png"
        }
      ]
    },
    {
      name: "Tracking",
      key: "tracking",
      childMenus: [
        {
          name: "Project44",
          key: "tracking_project44",
          redirectLink: "https://www.project44.com/",
          imgSrc: "/assets/images/screenshots/project44.png"
        },
        {
          name: "FourKites",
          key: "tracking_fourkites",
          redirectLink: "https://www.fourkites.com/",
          imgSrc: "/assets/images/screenshots/fourkites.png"
        },
        {
          name: "MacroPoint",
          key: "tracking_macro_point",
          redirectLink: "https://www.macropoint.com/",
          imgSrc: "/assets/images/screenshots/macropoint.png"
        }
      ]
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Redirect to link in new tab
   * @param link 
   */
  redirectToLink(link: string){
    window.open(link, '_blank');
  }

}
