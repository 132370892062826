export const CARRIER_PRODUCT_IDS = {
    CALPS  : "a4131068-c840-4ee7-8f04-2f5cafbf8177",
    CFEAL  : "364f40b5-d888-4c1f-91d8-e85ba2b3a391",
	CFEFPC : "925d9e1e-3d31-4e97-8d22-325e60a8d46d"
};

export const SHIPPER_PRODUCT_IDS = {
    SALPS   : "474b788f-1226-4332-8bb3-5d469fb5b8fb",
    SFEAL   : "e2e65d69-81cc-4e09-92d3-c906185113bf",
    SFEFPC  : "6963ef94-d275-437d-9904-ff3bc0e0b821"
};

export const BROKER_PRODUCT_IDS = {
    FIALPS  : "8ee4bf93-637a-4e82-825f-f891679bc776",
    FIFEAL  : "06e45fdf-20cc-404b-8c0b-710783d417c0",
    FEFFIPC : "43460fc0-5a50-428b-9821-74f1a7c6d722"
};

export const BFA = "4e603a59-8edc-45ce-b7ce-adf2494b51b9";