import { Injectable } from '@angular/core';
import { HttpService } from './http-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DriverQualificationFilesService {

  constructor(private httpService: HttpService) { }

  /**
   * Get Driver Qualification Files
   * @param id 
   * @returns 
   */
  get(id: string): Observable<any> {
    const url = `companyInfos/${id}/driverQualificationRequests`;

    return this.httpService.get(url);
  }
  
  /**
   * Create Driver Qualification Files
   * @param id 
   * @param payload 
   * @returns 
   */
  create(id: string, payload: any): Observable<any> {
    const url = `companyInfos/${id}/driverQualificationRequests`;

    return this.httpService.post(url, payload);
  }
}
