<div class="row">
    <div class="col-lg-2">
        <button *ngIf="modesPermissions && modesPermissions.Create"
            type="button" 
            class="btn btn-primary add-btn" 
            data-bs-toggle="modal" 
            id="create-btn"
            data-bs-target="#showModal"
            (click)="openModal(content)">
            <i class="ri-add-line align-bottom me-1"></i> Add
        </button>
    </div>
    <div class="col-lg-6"></div>
    <div class="col-lg-4">
        <div class="col-sm">
            <div class="d-flex justify-content-sm-end">
                <div class="search-box ms-2">
                    <input type="text" class="form-control search" placeholder="Search..." [(ngModel)]="service.searchTerm">
                    <i class="ri-search-line search-icon"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<table class="table align-middle table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th class="name-col" data-sort="firstName">Name</th>
            <th class="type-col" data-sort="lastName">Type</th>
            <th class="zones-col" data-sort="title">Service Areas</th>
            <th class="lanes-col" data-sort="email">Lanes</th>
            <th class="last-col"></th>
        </tr>
    </thead>
    <tbody *ngIf="!showData">
        <tr>
            <td class="name-col">
                <span class="placeholder col-8"></span>
            </td>
            <td class="type-col">
                <span class="placeholder col-8"></span>
            </td>
            <td class="zones-col">
                <span class="placeholder col-8"></span>
            </td>
            <td class="lanes-col">
                <span class="placeholder col-8"></span>
            </td>
            <td class="last-col">
                <span class="placeholder col-8"></span>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="showData">
        <tr *ngFor="let data of ListJsList$ | async">
            <td class="name-col">
                <ngb-highlight [ngClass]="modesPermissions && modesPermissions.Update ? 'clickable-column' : ''" [result]="data.name" [term]="service.searchTerm" (click)="openModal(content, data)"></ngb-highlight>
            </td>
            <td class="type-col">
                <ngb-highlight [result]="data.type" [term]="service.searchTerm"></ngb-highlight>
            </td>
            <td class="zones-col">
                <i class="ri-checkbox-circle-fill is-verified" *ngIf="data.hasServiceAreas"></i>
            </td>
            <td class="lanes-col">
                <i class="ri-checkbox-circle-fill is-verified" *ngIf="data.hasLanes"></i>
            </td>
            <td class="last-col"></td>
        </tr>
    </tbody>
</table>
<div class="row justify-content-md-between align-items-md-center" *ngIf="(total$ | async) as total">
    <div class="col col-sm-6">
        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
            Showing {{service.startIndex}} to {{service.endIndex}} of {{service.totalRecords}} entries
        </div>
    </div>
    <div class="col col-sm-6">
        <div class="text-sm-right float-sm-end listjs-pagination">
            <ngb-pagination [collectionSize]="total" [(page)]="service.page" [pageSize]="service.pageSize">
            </ngb-pagination>
        </div>
    </div>
</div>


<!-- Add Modes Data -->
<ng-template #content role="document" let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{edit ? 'Edit' : 'Add'}} Mode</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" (click)="modal.close('Close click');"></button>
    </div>
    <form (ngSubmit)="saveMode()" [formGroup]="modeForm" class="app-modes-modal">
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Name: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <input type="text" id="name" class="form-control" name="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && form['name'].errors }"/>
                    <div *ngIf="submitted && form['name'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['name'].errors['required']">Enter name</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="type-field" class="form-label">Type: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p-dropdown 
                        name="type"
                        [disabled]="edit"
                        [options]="TYPE_MENU_OPTIONS"
                        optionLabel="name" optionValue="code"
                        [autoDisplayFirst]="false"
                        [ngClass]="{ 'is-invalid': submitted && form['type'].errors }"
                        formControlName="type">
                    </p-dropdown>
                    <div *ngIf="submitted && form['type'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['type'].errors['required']">Select type</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="route-types-field" class="form-label">Route Types: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9">
                    <p-dropdown 
                        name="routeTypes"
                        [options]="ROUTE_TYPES_MENU_OPTIONS"
                        optionLabel="name" optionValue="code"
                        [autoDisplayFirst]="false"
                        [ngClass]="{ 'is-invalid': submitted && form['routeTypes'].errors }"
                        formControlName="routeTypes">
                    </p-dropdown>
                    <div *ngIf="submitted && form['routeTypes'].errors" class="invalid-feedback" align="left">
                        <div *ngIf="form['routeTypes'].errors['required']">Select route types</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg-3 user-form-label text-align-right">
                    <label for="name" class="form-label">Branches: <span class="text-danger">*</span></label>
                </div>
                <div class="col-lg-9" [ngStyle]="{'opacity': (isModeBranchesLoading) ? '0.5' : '1'}">
                    <span class="spinner-border save-loading" role="status" *ngIf="isModeBranchesLoading">
                        <span class="visually-hidden">Loading...</span>
                    </span>
                    <app-tree-checkbox [options]="branches" [(selected)]="selectedBranches"></app-tree-checkbox>
                    <div class="text-danger" *ngIf="submitted && selectedBranches.length === 0">
                        <small>Pick at least 1 branch</small>
                    </div>
                    <div class="d-flex mt-3">
                        <div class="branches-container text-muted">
                            <small>* Hold Shift Key while clicking checkbox to prevent propagation.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex gap-2 p-4" [ngClass]="{'justify-content-end': !(edit && modesPermissions && modesPermissions.Delete)}">
            <span *ngIf="edit && modesPermissions && modesPermissions.Delete" 
                    class="clickable-column me-auto d-flex align-items-center text-danger"
                    (click)="deleteMode()">
                <div *ngIf="!saveLoading"   
                    class="inline-block">
                    Delete  
                </div>
                <div *ngIf="saveLoading"  
                    class="spinner-border inline-block mode-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </span>
            <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
            <button type="submit" class="btn btn-primary action-btn" id="add-btn">
                <div *ngIf="!saveLoading"   
                    class="inline-block">
                    Save  
                </div>
                <div *ngIf="saveLoading"  
                    class="spinner-border inline-block mode-save-loading" 
                    role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
    </form>
</ng-template>