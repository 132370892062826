<header id="page-topbar">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex align-items-center">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a href="" class="logo logo-dark">
                        <span class="logo-sm">
                            <h4>Direct to Carrier</h4>
                        </span>
                        <span class="logo-lg">
                            <h4>Direct to Carrier</h4>
                        </span>
                    </a>
                    <a href="" class="logo logo-light">
                        <span class="logo-sm">
                            <h4>Direct to Carrier</h4>
                        </span>
                        <span class="logo-lg">
                            <h4>Direct to Carrier</h4>
                        </span>
                    </a>
                </div>
                <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>
                <button type="button"
                        class="btn btn-green"
                        (click)="openUpgradePlanModal()">
                    Buy Pre-Release Asset
                </button>
            </div>
            <div class="d-flex align-items-center name-box">
                <span class="d-xl-inline-block" *ngIf="windowSize > 1350 && currentUser.accountType != 'driver' && currentUser.accountType != 'helper'">
                    <span>
                        <h6 class="header-selection-text">Account:</h6>
                    </span>
                    <span>
                        <div class="left-select selection">
                            <p-treeSelect [(ngModel)]="defaultAccount" [options]="accountsList" [metaKeySelection]="false" selectionMode="single" placeholder="Select Item" [filter]="true" [filterInputAutoFocus]="true" (onNodeSelect)="selectAnAccount($event)" emptyMessage="No results found"></p-treeSelect>
                        </div>
                    </span>
                </span>

                <span class="d-xl-inline-block" *ngIf="windowSize > 1350 && currentUser.accountType != 'driver' && currentUser.accountType != 'helper'">
                    <span>
                        <h6 class="header-selection-text">Mode:</h6>
                    </span>
                    <span>
                        <div class="selection">
                            <p-treeSelect [(ngModel)]="defaultMode" [options]="modes" [metaKeySelection]="false" selectionMode="single" placeholder="{{ selectModesPlaceholder }}" [filter]="true" [filterInputAutoFocus]="true" (onNodeSelect)="selectAMode($event)" compareSelectionBy="deepEquals"></p-treeSelect>
                        </div>
                    </span>
                </span>

                <span class="d-xl-inline-block" *ngIf="windowSize > 1350 && currentUser.accountType != 'driver' && currentUser.accountType != 'helper'">
                    <span>
                        <h6 class="header-selection-text-location">Location:</h6>
                    </span>
                    <span>
                        <div class="branch-selection" style="margin-left: 1em;">
                            <p-treeSelect [(ngModel)]="default" [options]="branchNodes" [metaKeySelection]="false" selectionMode="single" placeholder="{{ selectBranchPlaceholder }}" [filter]="true" [filterInputAutoFocus]="true" (onNodeSelect)="selectABranch($event)" compareSelectionBy="deepEquals"></p-treeSelect>
                        </div>
                    </span>
                </span>
                
                <div class="d-flex align-items-center selection-dropdown" *ngIf="windowSize < 1350 && this.currentUser.accountType != 'driver'">
                    <div class="selection">
                        <h6 style="color: white; margin-bottom: 0;">Account: <span>
                                <p-treeSelect [(ngModel)]="defaultAccount" [options]="accountsList" [metaKeySelection]="false" selectionMode="single" placeholder="Select Item" [filter]="true" [filterInputAutoFocus]="true" (onNodeSelect)="selectAnAccount($event)" emptyMessage="No results found"></p-treeSelect>
                            </span>
                        </h6>
                    </div>
                    <div class="selection" *ngIf="currentUser.accountType != 'driver' && currentUser.accountType != 'helper'">
                        <h6 style="color: white; margin-bottom: 0;">Mode: <span>
                                <p-treeSelect [(ngModel)]="defaultMode" [options]="modes" [metaKeySelection]="false" selectionMode="single" placeholder="{{ selectModesPlaceholder }}" [filter]="true" [filterInputAutoFocus]="true" (onNodeSelect)="selectAMode($event)" compareSelectionBy="deepEquals"></p-treeSelect>
                            </span>
                        </h6>
                    </div>
                    <div class="branch-selection" style="margin-left: 1em;" *ngIf="currentUser.accountType != 'driver' && currentUser.accountType != 'helper'">
                        <h6 style="color: white; margin-bottom: 0;">Location: <span>
                                <p-treeSelect [(ngModel)]="default" [options]="branchNodes" [metaKeySelection]="false" selectionMode="single" placeholder="{{ selectBranchPlaceholder }}" [filter]="true" [filterInputAutoFocus]="true" (onNodeSelect)="selectABranch($event)" compareSelectionBy="deepEquals"></p-treeSelect>
                            </span>
                        </h6>
                    </div>
                </div>
                <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
                    <button type="button" class="btn topbar-user-dropdown-btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" [src]="userProfle?.profilePicture" (error)="handleError($event)">
                            <span class="text-start" (window:resize)="onResize($event)">
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text" *ngIf="currentUser.firstName && (windowSize > 520)" style="text-align: center;">{{currentUser.firstName}} {{currentUser.lastName}}</span>
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text" *ngIf="currentUser.firstName && (windowSize < 520)" style="text-align: center;">{{userInitials}}
                                </span>
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text" *ngIf="!currentUser.firstName && (windowSize > 520)">{{currentUser.email}}</span>
                                <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text" *ngIf="currentUser.accountType != 'driver' && currentUser.accountType != 'helper'">
                                    {{defaultAccount.label}}
                                </span>
                            </span>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <a class="dropdown-item" [routerLink]="PAGES_USER_PROFILE">
                            <i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle">User Settings</span>
                        </a>
                        <a class="dropdown-item" [routerLink]="PAGES_SETTINGS">
                            <i class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle">Settings</span>
                        </a>
                        <a class="dropdown-item" [routerLink]="PAGES_FAQ">
                            <i class="ri-question-line text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle">FAQs</span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">
                            <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle" data-key="t-logout">Logout</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>