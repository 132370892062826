import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbdModalLicensesList } from './licenses-list.component';

@NgModule({
    declarations: [NgbdModalLicensesList],
    imports: [CommonModule],
    exports: [NgbdModalLicensesList],
  })
  export class LicensesListModule { }
  