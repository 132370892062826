import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TreeNode } from 'primeng/api';
import { combineLatest, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/authentication/auth.service';
import { HttpService } from 'src/app/core/services/http-service';
import { Account } from 'src/app/core/services/models/account.model';
import { ServiceTypeSkill } from 'src/app/core/services/models/service-type-skill.model';
import { ServiceTypeTruckTrailerCombo, ServiceTypeTruckTrailerComboUtil } from 'src/app/core/services/models/service-type-truck-trailer-combo.model';
import { ServiceType, ServiceTypePayload } from 'src/app/core/services/models/service-type.model';
import { Mode } from 'src/app/core/services/models/mode.model';
import { SuccessApiResponse, TrailerTypeModel, TruckTypeModel } from 'src/app/core/services/models/models';
import { PrettyTechName, PrettyTechnicalName, PrettyTechnicalName_NONE } from 'src/app/core/services/models/pretty-technical-name';
import { ServiceWindow, ServiceWindowServiceType, ServiceWindowPayload } from 'src/app/core/services/models/service-window.model';
import Swal from 'sweetalert2';
import { DAYS, numberDisplay, timeToNumber } from 'src/app/core/utils/commons';
import { Router } from '@angular/router';
import { AccountPlanStatus } from 'src/app/core/services/models/account-plan.model';
import { NgbdModalBuyLicenses } from '../licenses/modals/buy-licenses/buy-licenses.component';

export const DUTY_STATUS_OPTIONS: PrettyTechName[] = [
  {prettyName: 'Day (Midnight to Midnight)', techName: 'true'},
  {prettyName: 'Night (Noon to Noon)', techName: 'false'}
];

export const SERVICE_TYPE_NAME_OPTIONS: {[key: string]: {name: string}[]} = {
  'FM': [{name:'Curbside'}, {name:'Threshold'}, {name: 'Room Of Choice'}, {name: 'First Available Space'}, {name: 'Outside'}, {name: 'White-Glove'}, {name: 'Basic'}, {name: 'Delivery & Assembly (Furniture)'}, {name: 'Delivery & Set-Up (Appliance) or (Household Item)'}, {name: 'Delivery and Install (Appliance)'}, {name: 'Assembly Only'}, {name: 'Install Only'}, {name: 'Complex Installation'}],
  'TL': [{name:'Expedite'}, {name: 'Hot Shot'}, {name:'Time Critical'}, {name:'Standard'}, {name:'Guaranteed'}, {name:'Straight Through'}, {name:'Team'}],
  'LTL': [{name:'Expedite'}, {name: 'Hot Shot'}, {name:'Time Critical'}, {name:'Standard'}, {name:'Guaranteed'}, {name:'Straight Through'}, {name:'Team'}],
  'DRAYAGE': [{name:'Expedite'}, {name: 'Hot Shot'}, {name:'Time Critical'}, {name:'Standard'}, {name:'Guaranteed'}, {name:'Straight Through'}, {name:'Team'}]
};

interface ServiceWindowTreeNode {
  type: 'service-window' | 'service-type';
  name: string;
  data: ServiceWindow | ServiceWindowServiceType;
}

type TruckTrailerTypeId = {truckTypeId: string, trailerTypeId: string};

@Component({
  selector: 'app-service-types',
  templateUrl: './service-types.component.html',
  styleUrls: ['./service-types.component.scss']
})
export class ServiceTypesComponent implements OnInit {
  submitted = false;
  loading = false;
  alertSuccessSave = false;
  serviceWindowForm!: FormGroup;
  serviceTypeForm!: FormGroup;
  activeModal?: NgbModalRef;
  accountPlanStatus: AccountPlanStatus;
  
  readonly DUTY_STATUS_OPTIONS = DUTY_STATUS_OPTIONS;
  readonly DUTY_STATUS_TIME_OPTIONS: Map<'true' | 'false',PrettyTechName[]> = new Map([
    ['true', []],
    ['false', []]
  ]);
  readonly deliveryWindowOptions = [
    {prettyName: '30 Minutes', techName: '30'},
    {prettyName: '1 Hour', techName: '60'},
    {prettyName: '2 Hours', techName: '120'},
    {prettyName: '3 Hours', techName: '180'},
    {prettyName: '4 Hours', techName: '240'},
    {prettyName: '6 Hours', techName: '360'},
    {prettyName: '12 Hours', techName: '720'}
  ];
  readonly SERVICE_WINDOW_TYPE_OPTIONS: {[key: string]: {name: string}[]} = {
    'FM': [{name:'Same Day'}, {name: 'Next Day'}, {name: 'Future'}],
    'TL': [{name: 'Local'}, {name: 'Regional'}, {name: 'Over-The-Road'}],
    'LTL': [{name:'LTL SW 1'}, {name: 'LTL SW 2'}, {name:'LTL SW 3'}, {name:'LTL SW 4'}],
    'DRAYAGE': [{name:'Dray SW 1'}, {name: 'Dray SW 2'}, {name:'Dray SW 3'}, {name:'Dray SW 4'}],
  };
  readonly SERVICE_WINDOW_TYPE_DEFAULT_DELIVERY_WINDOW: Map<string, string> = new Map([
    ['Same Day', '120'],
    ['Next Day', '240'],
    ['Future', '720'],
    ['Local', '120'],
    ['Regional', '240'],
    ['Over-The-Road', '720']
  ])
  readonly SERVICE_TYPE_NAME_OPTIONS = {...SERVICE_TYPE_NAME_OPTIONS};
  readonly SERVICE_TYPE_SKILL_NAME_OPTIONS: {[key: string]: {name: string}[]} = {
    'FM': [{name:'Dishwasher'}, {name:'Refrigerator'}, {name: 'OTR'}, {name: 'BBQ'}, {name: 'Basketball Hoop'}, {name: 'Bike'}],
    'TL': [{name:'Skill TL 1'}, {name:'Skill TL 2'}, {name:'Skill TL 3'}],
    'LTL': [{name:'Skill LTL 1'}, {name:'Skill LTL 2'}, {name:'Skill LTL 3'}],
    'DRAYAGE': [{name:'Skill Dray 1'}, {name:'Skill Dray 2'}, {name:'Skill Dray 3'}],
  };
  readonly days = [...DAYS];
  readonly units = [{name: 'days'}, {name: 'hours'}, {name: 'minutes'}];
  readonly cols2 = [{ field: 'name', header: 'Name' }];

  selectedMode: Mode | null;
  selectedAccount: Account | null;
  subscriptions: Subscription[] = [];
  
  hours:PrettyTechName[] = [];
  pickupStartTimeOptions: PrettyTechName[] = [];
  pickupEndTimeOptions: PrettyTechName[] = [];
  requestCutoffTimeOptions: PrettyTechName[] = [];
  deliveryBeforeOptions: PrettyTechName[] = [];

  currentServiceTypeSkillNameOptions: {name: string}[];
  selectedServiceTypeSkillNames: string[] = [];
  files2: TreeNode<ServiceWindowTreeNode>[] = [];

  currentServiceWindow?: ServiceWindow;
  currentServiceType?: ServiceType;
  currentServiceTypeSkills?: ServiceTypeSkill[];

  currentSelectedTruckTypeId: string | null;
  currentSelectedTrailerTypeId: string | null;
  truckTypeOptions: PrettyTechnicalName[] = [];
  trailerTypeOptions: PrettyTechnicalName[] = [PrettyTechnicalName_NONE];
  currentTruckTrailerTypeCombos?: ServiceTypeTruckTrailerCombo[];
  
  truckTypeIdMap: {[key: string]: TruckTypeModel} = {};
  trailerTypeIdMap: {[key: string]: TrailerTypeModel} = {};

  selectedTruckTrailerTypeIds: TruckTrailerTypeId[] = [];

  edittingName = false;
  edittedName?: string;
  
  @ViewChild('serviceWindowContent') serviceWindowContent!: TemplateRef<any>;
  @ViewChild('stripeCheckoutModal') stripeCheckoutModal!: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private router: Router
  ) { }

  ngOnInit(): void {
    fetch('./assets/jsons/hours.json').then(res => res.json()).then(jsonData => {
      this.hours = [...new Set(jsonData)] as PrettyTechName[];
      this.initializeTimeOptions();
    });
    this.subscriptions.push(
      combineLatest([
        this.auth.selectedAccountSubject, 
        this.auth.selectedModeSubject
      ]).subscribe(
        ([account, mode]) => {
          if(this.anyNull(account, mode)){
            return;
          }

          if(this.allSelectedSame(account, mode)){
            return;
          }

          this.selectedAccount = account;
          this.selectedMode = mode;

          this.loadData();
        }
      )
    );
    this.accountPlanStatus = this.auth.currentAccountPlanValue.status;
    this.initServiceWindowForm();
    this.loadTruckTypeOptions();
    this.loadTrailerTypeOptions();
  }

  ngAfterViewInit() {
    if(this.router.url === '/pages/settings/company/service-types-add-service-window') {
      this.activeModal = this.modalService.open(this.serviceWindowContent, {
        size: 'lg',
        centered: true,
      });
    }
  }

  private initializeTimeOptions(){
    const isDutyStatusDay = this.serviceWindowControls['isDutyStatusDay'].value;
    this.DUTY_STATUS_TIME_OPTIONS.set('true', [...this.hours]);
    this.DUTY_STATUS_TIME_OPTIONS.set(
      'false', 
      [...this.hours].sort((a, b) => this.dutyStatusAwareTimeToNumber(a.techName) - this.dutyStatusAwareTimeToNumber(b.techName))
    );
    this.pickupStartTimeOptions = [...this.DUTY_STATUS_TIME_OPTIONS.get(isDutyStatusDay) ?? this.hours];
    this.pickupEndTimeOptions = [...this.DUTY_STATUS_TIME_OPTIONS.get(isDutyStatusDay) ?? [...this.hours]];
    this.requestCutoffTimeOptions = [...this.DUTY_STATUS_TIME_OPTIONS.get(isDutyStatusDay) ?? [...this.hours]];
    this.deliveryBeforeOptions = [...this.DUTY_STATUS_TIME_OPTIONS.get(isDutyStatusDay) ?? [...this.hours]];
  }

  getTimeDisplay(time: string): string{
    return this.hours.find(hour => hour.techName === time)?.prettyName ?? '';
  }

  private anyNull(account: Account | null, mode: Mode | null): boolean{
    return !account || !mode;
  }

  private allSelectedSame(account: Account | null, mode: Mode | null): boolean{
    return this.selectedAccount?.accountId == account?.accountId 
            && this.selectedMode?.modeId == mode?.modeId
  }

  editName(): void {
    this.edittingName = true;
  }

  closeEdittingName(): void {
    this.edittingName = false;
    this.serviceWindowControls['name'].setValue(this.edittedName);
  }

  typeChange(): void {
    const type = this.serviceWindowControls['type'].value;
    this.serviceWindowControls['name'].setValue(type);
    this.edittedName = type;

    this.serviceWindowControls['deliveryWindowTime'].setValue(
      this.SERVICE_WINDOW_TYPE_DEFAULT_DELIVERY_WINDOW.get(type)
    );

    this.timeChange();
  }

  private async loadTruckTypeOptions() {
    const truckTypes = await this.httpService.listTruckTypes().toPromise();
    this.truckTypeOptions = truckTypes.map(truckType => ({
      prettyName: `${truckType.name} \u00A0 Weight = ${numberDisplay(truckType.capacityWeight)},`
        + ` Volume = ${numberDisplay(Number(truckType.capacityVolume))}`,
      technicalName: truckType.truckTypeId
    } as PrettyTechnicalName));
    this.truckTypeIdMap = truckTypes.reduce(
      (acc, cur) => {
        acc[cur.truckTypeId] = cur;
        return acc;
      }, 
      {} as {[key: string]: TruckTypeModel}
    );
  }

  private async loadTrailerTypeOptions(){
    const trailerTypes = await this.httpService.listTrailerTypes().toPromise();
    this.trailerTypeOptions = [
      PrettyTechnicalName_NONE,
      ...trailerTypes.map(trailerType => ({
        prettyName: `${trailerType.name} \u00A0 Weight = ${numberDisplay(trailerType.capacityWeight)}`
          + `, Volume = ${numberDisplay(Number(trailerType.capacityVolume))}`,
        technicalName: trailerType.trailerTypeId
      } as PrettyTechnicalName))
    ];
    this.trailerTypeIdMap = trailerTypes.reduce(
      (acc, cur) => {
        acc[cur.trailerTypeId] = cur;
        return acc;
      }, 
      {} as {[key: string]: TrailerTypeModel}
    );
  }

  getTotalWeightAndVolumeDisplay(truckTrailerTypeId: TruckTrailerTypeId): string{
    return ServiceTypeTruckTrailerComboUtil.getTotalWeightVolumeCapacityFromTruckTypeAndTrailerType(
      truckTrailerTypeId.truckTypeId,
      truckTrailerTypeId.trailerTypeId,
      this.truckTypeIdMap,
      this.trailerTypeIdMap
    );
  }

  truckTrailerTypeSelected(){
    if(!this.currentSelectedTruckTypeId || !this.currentSelectedTrailerTypeId){
      return;
    }

    const truckId_trailerIdsMap = this.selectedTruckTrailerTypeIds.reduce(
      (acc, cur) => {
        const trailerIds = acc[cur.truckTypeId] ?? [];
        if(!trailerIds.includes(cur.trailerTypeId!)){
          trailerIds.push(cur.trailerTypeId!);
        }
        acc[cur.truckTypeId] = trailerIds;
        return acc;
      }, {} as {[key: string]: string[]}
    );

    const selectedTrailerIds = truckId_trailerIdsMap[this.currentSelectedTruckTypeId] ?? [];
    if(!selectedTrailerIds.includes(this.currentSelectedTrailerTypeId!)){
      this.selectedTruckTrailerTypeIds.push({
        truckTypeId: this.currentSelectedTruckTypeId,
        trailerTypeId: this.currentSelectedTrailerTypeId
      });
    }
    
    setTimeout(() => {
      this.currentSelectedTruckTypeId = null;
      this.currentSelectedTrailerTypeId = null;  
    });
  }

  truckTrailerTypeUnselected(truckTrailerTypes: TruckTrailerTypeId){
    const {truckTypeId, trailerTypeId} = truckTrailerTypes;

    this.selectedTruckTrailerTypeIds = this.selectedTruckTrailerTypeIds
    .filter(
      curr => !(curr.trailerTypeId == trailerTypeId && curr.truckTypeId == truckTypeId)
    );
  }

  private loadData(){
    this.httpService.listServiceWindows(
      this.selectedAccount?.accountId!,
      this.selectedMode?.modeId!,
    ).subscribe(
      async res => {
        const successRes = <SuccessApiResponse<ServiceWindow[]>>res;

        const serviceWindows = successRes.data;
        
        this.files2 = this.convertTreeNode(serviceWindows);
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to list service windows: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  private convertTreeNode(serviceWindows: ServiceWindow[]): TreeNode<ServiceWindowTreeNode>[]{
    return serviceWindows.map(
      serviceWindow => ({
        data: {
          name: serviceWindow.name, 
          type: 'service-window',
          data: serviceWindow
        },
        expanded: true,
        children: serviceWindow.serviceTypes?.map(
          serviceType => ({
            data: {
              name: serviceType.serviceTypeName,
              type: 'service-type',
              data: serviceType
            },
            expanded: true
          }) || []
        )
      })
    );
  }

  get serviceWindowControls() {
    return this.serviceWindowForm.controls;
  }

  get serviceTypeControls() {
    return this.serviceTypeForm.controls;
  }

  skillSelected(event: any){
    const skillName = event.value;
    this.selectedServiceTypeSkillNames.push(skillName);
    this.currentServiceTypeSkillNameOptions = this.currentServiceTypeSkillNameOptions.filter(
      skill => skill.name != skillName
    );
  }

  skillUnselected(skillName: string){
    this.selectedServiceTypeSkillNames = this.selectedServiceTypeSkillNames.filter(
      skillName1 => skillName1 != skillName
    );
    this.currentServiceTypeSkillNameOptions.push({name: skillName});
  }

  submitServiceWindow(){
    this.submitted = true;
    if(this.serviceWindowForm.invalid){
      return;
    }
    const {serviceDays, isDutyStatusDay} = this.serviceWindowForm.value;
    const serviceWindowPayload: ServiceWindowPayload = {
      ...this.serviceWindowForm.value,
      deliveryWindowTime: Number(this.serviceWindowForm.value.deliveryWindowTime),
      modeId: this.selectedMode?.modeId,
      serviceDays: (<string[]>serviceDays).join(','),
      isDutyStatusDay: isDutyStatusDay === 'true',
      deliveryWithin: 0,
      deliveryWithinUnits: 'days'
    };

    this.loading = true;

    let saveServiceWindowCall;
    if(this.currentServiceWindow){
      saveServiceWindowCall = this.httpService.updateServiceWindow(this.currentServiceWindow.serviceWindowId, serviceWindowPayload);
    }else{
      saveServiceWindowCall = this.httpService.addServiceWindow(serviceWindowPayload);
    }
    
    saveServiceWindowCall.pipe(
      finalize(()=>{
        this.loading = false;
      })
    )
    .subscribe(
      res => {
        this.loadData();
        this.activeModal?.close();
        Swal.fire({
          title: 'Success',
          text: 'Successfully saved service window.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to save service window: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  submitServiceType(){
    this.submitted = true;
    if(this.serviceTypeForm.invalid){
      return;
    }

    this.loading = true;

    const payload: ServiceTypePayload = {
      ...this.serviceTypeForm.value,
      serviceWindowId: this.currentServiceWindow?.serviceWindowId,
      averageDeliveryTime: 1,
      averageLoadOutTime: 1
    };

    let httpCalls;

    if(this.currentServiceType){
      httpCalls = this.httpService.updateServiceType(
        this.currentServiceType.serviceTypeId,
        payload
      );
    }else{
      httpCalls = this.httpService.addServiceType(payload);
    }

    httpCalls.subscribe(
      async res => {
        const successRes = <SuccessApiResponse<ServiceType>> res;
        const serviceType = successRes.data;

        if(this.currentServiceTypeSkills && this.currentServiceTypeSkills.length != 0){
          const deleteSkills = this.currentServiceTypeSkills?.map(
            skill => this.httpService.deleteServiceTypeSkills(skill.serviceTypeSkillId)
          ) || [];
  
          combineLatest(deleteSkills).subscribe(
            res => {
              this.addSkills(serviceType)
            },
            error => {
              Swal.fire({
                title: 'Error',
                text: 'Failed to delete service type skills: ' + error.error.reason,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
              }).then((result) => {
                //do nothing
              });
            }
          );
        } else {
          this.addSkills(serviceType);
        }

        if(this.currentTruckTrailerTypeCombos && this.currentTruckTrailerTypeCombos.length != 0){
          const deleteTruckTrailerTypeCombos = this.currentTruckTrailerTypeCombos?.map(
            truckTrailerTypeCombo => this.httpService.deleteServiceTypeTruckTrailerCombo(
              truckTrailerTypeCombo.serviceTypeTruckTrailerComboId
            )
          ) || [];
  
          combineLatest(deleteTruckTrailerTypeCombos).subscribe(
            res => {
              this.addTruckTrailerCombos(serviceType)
            },
            error => {
              Swal.fire({
                title: 'Error',
                text: 'Failed to delete truck and trailer combos: ' + error.error.reason,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: 'rgb(60,76,128)',
                confirmButtonText: 'Ok',
              }).then((result) => {
                //do nothing
              });
            }
          );
        }else{
          this.addTruckTrailerCombos(serviceType);
        }

        this.handleSuccessSaveServiceType();
      },
      error => {
        this.loading = false;
        Swal.fire({
          title: 'Error',
          text: 'Failed to save service type: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  addTruckTrailerCombos(serviceType: ServiceType){
    const addTruckTrailerCombos = this.selectedTruckTrailerTypeIds.map(
      truckTrailerTypeId => {
        const {truckTypeId, trailerTypeId: trailerType1} = truckTrailerTypeId;
        return this.httpService.createServiceTypeTruckTrailerCombo(
          serviceType.serviceTypeId, 
          {
            truckTypeId, 
            trailerType1: trailerType1 === 'none' ? null: trailerType1
          }
        )
      }
    );

    combineLatest(addTruckTrailerCombos)
    .pipe(finalize(()=>this.loading = false))
    .subscribe(
      res => {
        this.handleSuccessSaveServiceType();
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to save truck and trailer combos: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  addSkills(serviceType: ServiceType){
    const addSkills = this.selectedServiceTypeSkillNames.map(
      skillName => this.httpService.addServiceTypeSkill(
        serviceType.serviceTypeId, 
        {name: skillName}
      )
    );
            
    combineLatest(addSkills)
    .pipe(finalize(()=>this.loading = false))
    .subscribe(
      res => {
        this.handleSuccessSaveServiceType();
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to save service type skills: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      }
    );
  }

  handleSuccessSaveServiceType(){
    if(!this.alertSuccessSave){
      this.alertSuccessSave = true;
      this.loadData();
      this.activeModal?.close();
      Swal.fire({
        title: 'Success',
        text: 'Successfully saved service type.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Ok',
      }).then((result) => {
        //do nothing
      });
    }  
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getEventValue($event:any) :string {
    return $event.target.value;
  }

  deleteServiceType(){
    this.loading = true;
    this.httpService.deleteServiceType(this.currentServiceType?.serviceTypeId!)
    .pipe(
      finalize(()=>this.loading = false)
    )
    .subscribe(
      res => {
        this.loadData();
        this.activeModal?.close();
        Swal.fire({
          title: 'Success',
          text: 'Successfully deleted service type.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete service type: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });   
      }
    );
  }

  deleteServiceWindow(){
    this.loading = true;
    this.httpService.deleteServiceWindow(this.currentServiceWindow?.serviceWindowId!)
    .pipe(finalize(()=>this.loading = false))
    .subscribe(
      res => {
        this.loadData();
        this.activeModal?.close();
        Swal.fire({
          title: 'Success',
          text: 'Successfully deleted service window.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });
      },
      error => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to delete service window: ' + error.error.reason,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'rgb(60,76,128)',
          confirmButtonText: 'Ok',
        }).then((result) => {
          //do nothing
        });   
      }
    );
  }

  openServiceWindowModal(content: any, serviceWindow?: ServiceWindow) {
    console.log(content);
    this.submitted = false;
    this.initServiceWindowForm(serviceWindow);
    this.loading = false;
    this.activeModal = this.modalService.open(content, {
      size: 'lg',
      centered: true,
    });
  }

  async openServiceTypeModal(
    content: any,  
    serviceWindow: ServiceWindow, 
    serviceWindowServiceType?: ServiceWindowServiceType
  ) {
    this.alertSuccessSave = false;
    this.submitted = false;
    this.loading = false;

    let serviceType;
    
    if(serviceWindowServiceType){
      const serviceTypeRes = <SuccessApiResponse<ServiceType>> await this.httpService
        .getServiceType(serviceWindowServiceType.serviceTypeId!).toPromise();
      serviceType = serviceTypeRes.data;    
    }

    this.initServiceTypeForm(serviceWindow, serviceType);

    this.activeModal = this.modalService.open(content, {
      size: 'lg',
      centered: true,
    });
  }

  async initServiceTypeForm(serviceWindow: ServiceWindow, serviceType?: ServiceType){
    this.currentServiceWindow = serviceWindow;
    this.currentServiceType = serviceType;
    this.serviceTypeForm = this.formBuilder.group({
      name: [serviceType?.name, [Validators.required]],
      averageDeliveryTime: [{value: serviceType?.averageDeliveryTime, disabled: true}, []],
      averageLoadOutTime: [{value: serviceType?.averageLoadOutTime, disabled: true}, []],
    });
    
    this.currentServiceTypeSkills= [];
    this.currentServiceTypeSkillNameOptions = [];
    this.selectedServiceTypeSkillNames = [];

    this.currentTruckTrailerTypeCombos = [];
    this.currentSelectedTruckTypeId = null;
    this.currentSelectedTrailerTypeId = null;
    this.selectedTruckTrailerTypeIds = [];

    if(serviceType){
      this.initSkills(serviceType);
      this.initTruckTrailerTypeCombos(serviceType);
    }else{
      this.currentServiceTypeSkillNameOptions = [...this.SERVICE_TYPE_SKILL_NAME_OPTIONS[this.selectedMode?.type?.toUpperCase()!]];
    }
  }

  private async initTruckTrailerTypeCombos(serviceType: ServiceType){
    const truckTrailerTypesRes = <SuccessApiResponse<ServiceTypeTruckTrailerCombo[]>> await this.httpService
        .listServiceTypeTruckTrailerCombos(serviceType.serviceTypeId).toPromise();
    this.currentTruckTrailerTypeCombos = truckTrailerTypesRes.data;

    this.selectedTruckTrailerTypeIds = this.currentTruckTrailerTypeCombos.map(
      combo => ({
        truckTypeId: combo.truckType,
        trailerTypeId: combo.trailerType1
      })
    );
  }

  private async initSkills(serviceType: ServiceType){
      const skillsRes = <SuccessApiResponse<ServiceTypeSkill[]>> await this.httpService
        .listServiceTypeSkills(serviceType.serviceTypeId).toPromise();
      this.currentServiceTypeSkills = skillsRes.data;
      
      const skillNames = skillsRes.data.map(skill => skill.name);
      this.currentServiceTypeSkillNameOptions = this.SERVICE_TYPE_SKILL_NAME_OPTIONS[this.selectedMode?.type?.toUpperCase()!].filter(
        skill => !skillNames.includes(skill.name)
      );
      this.selectedServiceTypeSkillNames = skillNames;
  }

  initServiceWindowForm(serviceWindow?: ServiceWindow){
    this.currentServiceWindow = serviceWindow;
    const defaultType = serviceWindow?.type ?? this.SERVICE_WINDOW_TYPE_OPTIONS[this.selectedMode?.type?.toUpperCase()!][0].name;
    this.serviceWindowForm = this.formBuilder.group({
      type: [defaultType, [Validators.required]],
      isDutyStatusDay: [
        (serviceWindow?.isDutyStatusDay === undefined || serviceWindow?.isDutyStatusDay === null)  
          ? 'true'
          : String(serviceWindow?.isDutyStatusDay)
        , Validators.required
      ],
      name: [serviceWindow?.name ?? defaultType],
      pickupStartTime: [serviceWindow?.pickupStartTime ?? '06:00:00', [Validators.required]],
      pickupEndTime: [serviceWindow?.pickupEndTime ?? '08:00:00', [Validators.required]],
      requestCutoffTime: [serviceWindow?.requestCutoffTime ?? '00:00:00', [Validators.required]],
      deliveryBefore: [serviceWindow?.deliveryBefore ?? '20:00:00', [Validators.required]],
      deliveryWindowTime: [
        serviceWindow?.deliveryWindowTime?.toString()
          ?? this.SERVICE_WINDOW_TYPE_DEFAULT_DELIVERY_WINDOW.get(defaultType),
        [Validators.required]
      ],
      serviceDays: [serviceWindow?.serviceDays.split(','),[Validators.required]]
    });
    this.edittingName = false;
    this.edittedName = this.serviceWindowForm.controls['name'].value;
    this.timeChange();
  }

  timeChange(): void {
    this.initializeTimeOptions();

    const pickupStartTime = this.dutyStatusAwareTimeToNumber(this.serviceWindowControls['pickupStartTime'].value);
    if(pickupStartTime && this.pickupEndTimeOptions?.length){
      this.pickupEndTimeOptions = this.pickupEndTimeOptions.filter(
        opt => this.dutyStatusAwareTimeToNumber(opt.techName) > pickupStartTime
      );
      this.setServiceWindowControlsTime(this.pickupEndTimeOptions, 'pickupEndTime');
    }

    const pickupEndTime = this.dutyStatusAwareTimeToNumber(this.serviceWindowControls['pickupEndTime'].value);
    const deliveryBeforeLimit = pickupEndTime + timeToNumber('12:00:00');
    if(pickupEndTime && this.deliveryBeforeOptions?.length){
      this.deliveryBeforeOptions = this.deliveryBeforeOptions.filter(
        opt => this.dutyStatusAwareTimeToNumber(opt.techName) > pickupEndTime
          && this.dutyStatusAwareTimeToNumber(opt.techName) <= deliveryBeforeLimit
      );
      this.setServiceWindowControlsTime(this.deliveryBeforeOptions, 'deliveryBefore');
    }

    const serviceWindowType = this.serviceWindowControls['type'].value;
    if(serviceWindowType) {
      if(serviceWindowType === 'Same Day'){
        if(pickupEndTime && this.requestCutoffTimeOptions?.length){
          this.requestCutoffTimeOptions = this.requestCutoffTimeOptions.filter(
            opt => this.dutyStatusAwareTimeToNumber(opt.techName) <= pickupEndTime
          );
          this.setServiceWindowControlsTime(this.requestCutoffTimeOptions, 'requestCutoffTime');
        }
      }else{
        this.serviceWindowControls['requestCutoffTime'].setValue(this.requestCutoffTimeOptions[0]?.techName);
      }
    }else{
      this.serviceWindowControls['requestCutoffTime'].setValue(null);
    }
  }

  private setServiceWindowControlsTime(options: PrettyTechName[], key: string): void{
    const value = this.serviceWindowControls[key].value;
    if(!value){
      return;
    }
    const timeOptions = options.map(option => option.techName);
    if(!timeOptions.includes(value)){
      this.serviceWindowControls[key].setValue('');
    }
  }

  private dutyStatusAwareTimeToNumber(time: string): number {
    if(!time){
      return 0;
    }
    const isDutyStatusDay = this.serviceWindowControls['isDutyStatusDay'].value === 'true';
    const timeNumber = timeToNumber(time);
    if(isDutyStatusDay){
      return timeNumber;
    }
    return timeNumber < timeToNumber('12:00:00') 
      ? timeNumber + timeToNumber('24:00:00')
      : timeNumber;
  }

  onFieldClick() {
    if(this.accountPlanStatus === 'trial_expired' || this.accountPlanStatus === 'trial') {
      Swal.fire({
        title: "",
        text: "Buy Pre-Release Assets at a reduced price to unlock this feature and more in the future.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(60,76,128)',
        confirmButtonText: 'Buy Pre-Release Assets'
      }).then(result => {
        if(result.isConfirmed){
          this.modalService.open(NgbdModalBuyLicenses, { size: 'lg', centered: true });
        }
      });
    }
  }

 
}
